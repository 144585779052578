<template>
	<div>
		<div class='HomeRecommend'>
			<div class='Recommend-Title'>
				<div class='left'>店铺推荐</div>
				<div class='right' @click='ck_goPage("/ShopList")'>更多好店</div>
			</div>
		</div>
		
		<div>
			<div 
				class='Recommend-item'
				v-for='(item,index) in shops'
				:key='index'
			>
				<div style='display: flex;align-items: center;'>
					<div class='name' @click='ck_goPage("/ShopHome",item.shopId)'>{{item.shopName}}</div>
					<img 
						class='item-img'
						v-for='(item,index) in 4'
						:key='index'
						src="../../assets/images/huanzhuang.png" 
						alt=""
					>
				</div>
				<div class='content'>
					<div class='content-text'>
						<div>近30天</div>
						<div style='margin-left: 0.125rem;color: #333333;'>
							成功交易 
							<span style='color: #EE4454;'>{{item.orderNum}}</span> 
							笔
						</div>
					</div>
					<div class='evaluate'>
						好评：<span style='color: #EE4454;'>{{item.scores}}</span> 
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['shops'],
		methods: {
			ck_goPage(path,id) {
				this.$router.push({path,query:{id}});
			}
		},
	}
</script>

<style scoped>
	.HomeRecommend {
		width: 21.25rem;
		border-bottom: 0.0625rem solid #EE4454;
		padding: 0.625rem 0;
		margin-bottom: 0.5625rem;
	}
	
	.Recommend-Title {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
	
	.Recommend-Title .left {
		font-size: 1rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.Recommend-Title .right {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		cursor: pointer;
	}
	
	.Recommend-item {
		padding: 0.625rem 0;
		border-bottom: 0.0625rem solid #F2F2F2;
		cursor: pointer;
	}
	
	.Recommend-item:hover .name {
		font-size: 0.925rem;
	}
	
	.Recommend-item .name {
		font-size: 0.875rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-right: 0.375rem;
		cursor: pointer;
	}
	
	.Recommend-item .item-img {
		width: 0.875rem;
		height: 0.875rem;
		margin-left: 0.125rem;
	}
	
	.Recommend-item .content {
		display: flex;
		align-items: center;
		margin-top: 0.1875rem;
		justify-content: space-between;
		border: none;
		height: auto;
		width: auto;
	}
	
	.Recommend-item .content-text {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		display: flex;
		align-items: center;
	}
	
	.Recommend-item .evaluate {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
</style>
