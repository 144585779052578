<template>
	<div class='PersonalCenter'>
		<div>
			<Header bgIndex='4' @dialogBoxIndex="dialogBoxIndex" />
		</div>

		<div class='SY-CONTENT'>
			<div style='width: 75rem;display: flex;'>
				<div class='leftMenu'>
					<div 
						style='display: flex;align-items: center;cursor: pointer;margin-top: 2.1875rem;' 
						v-for='(item,index) in menuList'
						:key='index'
						@click='ck_menu(index)'
					>
						<img v-if='item.active' style='width: 1.25rem;height: 1.25rem;margin-left: 2rem;' :src="item.Aimg" alt="">
						<img v-else style='width: 1.25rem;height: 1.25rem;margin-left: 2rem;' :src="item.img" alt="">
						<div :class="item.active?'text2':'text1'" style='margin-left: 1.25rem;'>{{item.text}}</div>
					</div>
				</div>

				<!-- 主页 -->
				<div v-if='menuValue == 0'>
					<PersonalHome @setMenuList='setMenuList'/>
				</div>

				<!-- 我的购物车 -->
				<!-- <div v-if='menuValue == 1'></div> -->

				<!-- 已买到的宝贝 -->
				<div v-if='menuValue == 2'>
					<PersonalIsBuy :isBuyMenuValue='isBuyMenuValue'/>
				</div>

				<!-- 购买过的店铺 -->
				<div v-if='menuValue == 3'>
					<PersonalDp />
				</div>

				<!-- 我的收藏 -->
				<div v-if='menuValue == 4'>
					<PersonalSc />
				</div>

				<!-- 我的优惠 -->
				<div v-if='menuValue == 5'>
					<PersonalWdyh />
				</div>

				<!-- 资金管理 -->
				<div v-if='menuValue == 6'>
					<PersonalJf />
				</div>

				<!-- 评价管理 -->
				<div v-if='menuValue == 7'>
					<PersonalPjgl />
				</div>

				<!-- 退款维权 -->
				<div v-if='menuValue == 8'>
					<PersonalTkwq />
				</div>

				<!-- 账户设置 -->
				<div v-if='menuValue == 9'>
					<PersonalZhsz />
				</div>
			</div>
		</div>

		<!-- 页脚 -->
		<div class='SY-CONTENT' style='background-color: #333333;'>
			<HomeFooter />
		</div>
		
		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Header from '../components/Header.vue';
	import PersonalHome from '../components/PersonalCenter/PersonalHome.vue';
	import PersonalIsBuy from '../components/PersonalCenter/PersonalIsBuy.vue';
	import PersonalDp from '../components/PersonalCenter/PersonalDp.vue';
	import PersonalJf from '../components/PersonalCenter/PersonalJf.vue';
	import PersonalSc from '../components/PersonalCenter/PersonalSc.vue';
	import PersonalWdyh from '../components/PersonalCenter/PersonalWdyh.vue';
	import PersonalPjgl from '../components/PersonalCenter/PersonalPjgl.vue';
	import PersonalTkwq from '../components/PersonalCenter/PersonalTkwq.vue';
	import PersonalZhsz from '../components/PersonalCenter/PersonalZhsz.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';

	import menuSy from '../assets/PersonalCenter/menu-sy.png'
	import menuSyA from '../assets/PersonalCenter/menu-sy-a.png'
	import menuGwc from '../assets/PersonalCenter/menu-gwc.png'
	import menuYmdbb from '../assets/PersonalCenter/menu-ymdbb.png'
	import menuYmdbbA from '../assets/PersonalCenter/menu-ymdbb-a.png'
	import menuDp from '../assets/PersonalCenter/menu-dp.png'
	import menuDpA from '../assets/PersonalCenter/menu-dp-a.png'
	import menuSc from '../assets/PersonalCenter/menu-sc.png'
	import menuScA from '../assets/PersonalCenter/menu-sc-a.png'
	import menuYhxx from '../assets/PersonalCenter/menu-yhxx.png'
	import menuYhxxA from '../assets/PersonalCenter/menu-yhxx-a.png'
	import menuJf from '../assets/PersonalCenter/menu-jf.png'
	import menuJfA from '../assets/PersonalCenter/menu-jf-a.png'
	import menuPj from '../assets/PersonalCenter/menu-pj.png'
	import menuPjA from '../assets/PersonalCenter/menu-pj-a.png'
	import menuWq from '../assets/PersonalCenter/menu-wq.png'
	import menuWqA from '../assets/PersonalCenter/menu-wq-a.png'
	import menuSz from '../assets/PersonalCenter/menu-sz.png'
	import menuSzA from '../assets/PersonalCenter/menu-sz-a.png'


	export default {
		components: {
			Header,
			PersonalHome,
			HomeFooter,
			PersonalIsBuy,
			PersonalDp,
			PersonalJf,
			PersonalSc,
			PersonalWdyh,
			PersonalPjgl,
			PersonalTkwq,
			PersonalZhsz
		},
		data() {
			return {
				menuList: [{
						text: '主页',
						img: menuSy,
						Aimg: menuSyA,
						active: true
					},
					{
						text: '我的购物车',
						img: menuGwc,
						Aimg: menuSyA,
						active: false
					},
					{
						text: '已买到的宝贝',
						img: menuYmdbb,
						Aimg: menuYmdbbA,
						active: false
					},
					{
						text: '购买过的店铺',
						img: menuDp,
						Aimg: menuDpA,
						active: false
					},
					{
						text: '我的收藏',
						img: menuSc,
						Aimg: menuScA,
						active: false
					},
					{
						text: '我的优惠',
						img: menuYhxx,
						Aimg: menuYhxxA,
						active: false
					},
					{
						text: '资金管理',
						img: menuJf,
						Aimg: menuJfA,
						active: false
					},
					{
						text: '评价管理',
						img: menuPj,
						Aimg: menuPjA,
						active: false
					},
					{
						text: '退款维权',
						img: menuWq,
						Aimg: menuWqA,
						active: false
					},
					{
						text: '账户设置',
						img: menuSz,
						Aimg: menuSzA,
						active: false
					}
				],
				menuValue: 0,
				isBuyMenuValue: 0, //已买到的宝贝
				isShowDialogBox:false,
			}
		},
		mounted() {
			this.getRouteValue();
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			ck_menu(index) {
				for (let i in this.menuList) {
					this.menuList[i].active = false;
				}
				this.menuList[index].active = true;
				this.menuValue = index;
				if(index == 1) {
					this.$router.push({path:'/ShoppingCart'});
				}
			},
			// 接受路由传值
			getRouteValue() {
				if (this.$route.query.type) {
					this.menuValue = this.$route.query.type;
					for (let i in this.menuList) {
						this.menuList[i].active = false;
					}
					this.menuList[this.menuValue].active = true;
				}
			},
			// 子传父的Menu参数
			setMenuList(data) {
				this.menuValue = data.parentMenuValue;
				this.ck_menu(data.parentMenuValue);
				this.isBuyMenuValue = data.isBuyMenuValue;
			}
		}
	}
</script>

<style scoped>
	.PersonalCenter {
		background: #F5F5F5;
	}

	.PersonalCenter .leftMenu {
		width: 16.5625rem;
		min-height: 73.4375rem;
		background-color: #FFFFFF;
		border: 1px solid #FFFFFF;
	}

	.PersonalCenter .text1 {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.PersonalCenter .text2 {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
	}
</style>
