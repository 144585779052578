<template>
	<div>
		<div>
			<Header bgIndex='1' @dialogBoxIndex="dialogBoxIndex" />
		</div>

		<div class='SY-CONTENT'>
			<div style='width: 75rem;height: 500px;'>
				<div class='item'>
					<div class='text1'>标题</div>
					<div>
						<el-input v-model="title" placeholder="请输入内容"></el-input>
					</div>
				</div>
				<div class='item'>
					<div class='text1'>封面图片</div>
					<div>
						
					</div>
				</div>
				<QuillEditor v-model="content"/>
			</div>
		</div>
		
		<div class='SY-CONTENT'>
			<div class='DIS-CENTER' style='width: 75rem;'>
				<div class='btn DIS-CENTER' @click="ck_btn">
					保存
				</div>
			</div>
		</div>
		
		<!-- 页尾文字 -->
		<div class='SY-CONTENT' style='margin-top:4rem;background-color: #FAFAFA;'>
			<HomeText />
		</div>

		<!-- 页脚 -->
		<div class='SY-CONTENT' style='background-color: #333333;'>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Header from '../components/Header.vue';
	import HomeText from '../components/Home/HomeText.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	import QuillEditor from '../components/quilleditor/QuillEditor.vue'

	export default {
		components: {
			Header,
			HomeText,
			HomeFooter,
			QuillEditor,
		},
		data() {
			return {
				content: '',
				title: '',
				isShowDialogBox:false,
			}
		},
		methods: {
			ck_btn() {
				console.log(this.content);
			},
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
		}
	}
</script>

<style scoped>
	.btn {
		width: 100px;
		height: 40px;
		background: #EE4454;
		border-radius: 2px;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
		margin-top: 1.25rem;
	}
	
	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.item {
		display: flex;
		align-items: center;
		margin-top: 1.875rem;
	}
</style>
