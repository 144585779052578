import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Api from  './request/api.js';
import animated from 'animate.css'
import HighchartsVue  from 'highcharts-vue'
import MChat from '../packages/index'      // 引入即时通讯
import DialogBoxIndex from './components/DialogBox/DialogBoxIndex.vue'

// 引入全局组件
// 测试vconsole插件
// import VConsole from 'vconsole';
// const vConsole = new VConsole();
Vue.use(animated) 
Vue.use(HighchartsVue)
Vue.use(MChat)
Vue.config.productionTip = false
// 挂在到vue的原型链上面去
Vue.prototype.axios = axios;
Vue.component('DialogBoxIndex',DialogBoxIndex)

Vue.use(ElementUI)
Vue.use(VueAxios, axios)
Vue.use(Api, Api)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
