<template>
	<div class='RegisteredFirst'>
		<div style='display: flex;align-items: center;'>
			<div :class="phoneStatus > 1 ? 'RegisteredFirst-input-errno' : 'RegisteredFirst-input'">
				<div style='margin-left: 1rem;width: 6.25rem;'>
					中国 +86
					<img style='width: 0.5rem;height: 0.5rem;' src="../../assets/images/arrow-bottom.png" alt="">
				</div>
				<div class='RegisteredFirst-line'></div>
				<el-input @focus="phineFocus('phone')" @blur="phoneBlur('phone')" placeholder="请输入手机号" v-model="phone">
				</el-input>
			</div>
			<img 
				v-if='phoneStatus == 1' 
				style='width: 1.5rem;height: 1.5rem;margin-left: 1.1875rem;' 
				src="../../assets/images/succeed.png"
				alt=""
			>
			<div v-else style='width: 1.5rem;height: 1.5rem;margin-left: 1.1875rem;'></div>
		</div>

		<div :class="phoneStatus > 1 ? 'errno-text' : 'correct-text'">
			该手机号已注册或有误
		</div>

		<div style='display: flex;align-items: center;'>
			<div :class="codeStatus > 1 ? 'RegisteredFirst-input-errno' : 'RegisteredFirst-input'">
				<div style='margin-left: 0.9375rem;width: 8.75rem;'>手机验证码</div>
				<el-input @focus="phineFocus('code')" @blur="phoneBlur('code')" placeholder="输入激活码" v-model="code"></el-input>
				<div 
					style='width: 8.75rem;margin-left: -1.625rem;cursor: pointer;'
					class='DIS-CENTER'
					@click='ck_code'
				>
					{{codeText}}
				</div>
			</div>
			<img 
				v-if='codeStatus == 1' 
				style='width: 1.5rem;height: 1.5rem;margin-left: 1.1875rem;' 
				src="../../assets/images/succeed.png"
				alt=""
			>
			<div v-else style='width: 1.5rem;height: 1.5rem;margin-left: 1.1875rem;'></div>
		</div>

		<div :class="codeStatus > 1 ? 'errno-text' : 'correct-text'">
			验证码有误或已超时，请重新发送
		</div>

		<div class='btn' @click='nextStep({num: 1})'>
			下一步
		</div>
	</div>
</template>

<script>
	import Api from  '../../request/api.js';
	
	export default {
		data() {
			return {
				phone: '',
				code: '',
				phoneStatus: 0, //0是正常，1是正确，2是错误
				codeStatus: 0,
				codeText: '获取激活码',
				timer: null,
			}
		},
		methods: {
			nextStep(num) {
				if(this.phoneStatus == 1 && this.codeStatus == 1) {
					const data = {
						phone: this.phone,
						code: this.code
					}
					this.axios.post(Api.checkPhoneCode,data).then(res=>{
						if(res.data.code === 0) {
							this.$store.commit('nextStep',num)
							this.$emit('getPhone',this.phone)
						} else {
							// 这里之后要进行修改
							// this.$message.error(res.data.msg);
							this.$store.commit('nextStep',num)
							this.$emit('getPhone',this.phone)
						}
					})
				} else {
					this.$message.warning('请输入正确的手机号或验证码')
				}
			},
			phineFocus(type) {
				if(type == 'phone') {
					this.phoneStatus = 0;
					return
				}
				if(type == 'code') {
					this.codeStatus = 0;
					return
				}
			},
			phoneBlur(type) {
				if(type == 'phone') {
					//手机号码是否有用
					if (!(/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.phone))) {
						this.phoneStatus = 2;
					} else {
						this.phoneStatus = 1;
					}
					return
				}
				if(type == 'code') {
					if(this.code.length < 4) {
						this.codeStatus = 2;
					} else {
						this.codeStatus = 1;
					}
					return
				}
			},
			ck_code() {
				if(this.codeText == '获取激活码') {
					//手机号码是否有用
					if (!(/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.phone))) {
						this.phoneStatus = 2;
						return
					} 
					//调用接口
					const data = {
						phone: this.phone
					}
					this.axios.post(Api.sendPhoneCode, data).then(res => {
						if (res.data.code === 200) {
							this.codeText = 60;
							this.timer = setInterval(()=> {
								if(this.codeText > 1) {
									this.codeText = this.codeText - 1;
								} else {
									this.codeText = '获取激活码';
									clearInterval(this.timer);
								}
							},1000)
						} else {
							this.$message.error(res.data.msg);
						}
					});
				}
			}
		}
	}
</script>

<style scoped>
	.RegisteredFirst-input {
		width: 21.25rem;
		display: flex;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		border: 1px solid #DDDDDD;
		height: 2.75rem;
		align-items: center;
	}

	.RegisteredFirst-input-errno {
		width: 21.25rem;
		display: flex;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		border: 1px solid #EE4454;
		height: 2.75rem;
		align-items: center;
	}

	.RegisteredFirst-line {
		height: 1.5625rem;
		border-left: 1px solid #DDDDDD;
		margin-left: 0.4375rem;
	}

	.RegisteredFirst>>>.el-input__inner {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		border: none;
		width: 9rem;
	}

	.errno-text {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
		margin: 0.3125rem 0;
	}

	.correct-text {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		margin: 0.3125rem 0;
	}

	.btn {
		width: 340px;
		height: 50px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		margin-top: 1.6875rem;
	}
</style>
