<template>
	<div class='HomeFirst'>
		<div class='title-content'>
			<HomeTitle 
				title='精选好书'
				text='更多精选'
				path='GoodBook'
			/>
		</div>
		
		<div style='display: flex;flex-wrap: wrap;'>
			<div
				:class="index == 0 || index%6 == 0 ? 'goodsItem1' : 'goodsItem2'" 
				v-for='(item,index) in homeChoicenessGoods'
				:key='index'
			>
				<GoodsListFirst :goodsItem='item'/>
			</div>
		</div>
	</div>
</template>

<script>
	import GoodsListFirst from '../components/GoodsListFirst.vue';
	import HomeTitle from '../components/Home-title.vue';
	
	export default {
		components: { GoodsListFirst, HomeTitle },
		props:['homeChoicenessGoods'],
		methods: {
			
		}
	}
</script>

<style scoped>
	.HomeFirst {
		margin-left: 2.3125rem;
	}
	
	.HomeFirst .title-content {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding-bottom: 0.5625rem;
		border-bottom: 2px solid #EE4454;
		margin-top: 1.75rem;
		width: 60.25rem;
	}
	
	.HomeFirst .goodsItem1 {
		margin-top: 2.1875rem;
		margin-left: 0.4375rem;
	}
	
	.HomeFirst .goodsItem2 {
		margin-top: 2.1875rem;
		margin-left: 0.9375rem;
	}
</style>
