<template>
	<div class='ApplyRefundThird'>
		<div class='titleBox'>
			<img 
				style='width: 2.25rem;height: 2.25rem;'
				src="../../assets/images/ApplyRefundThitd-title-img.png" 
				alt=""
			>
			<div class="title" style='margin-left: 0.9375rem;'>
				商家同意退货
			</div>
		</div>
		<div class='text1' style='margin-top: 0.9375rem;'>
			请您在 <span>4</span> 天 <span>0</span> 时 <span>27</span> 分 <span>5</span> 秒内退货并填写物流信息，预期将自动取消
		</div>
		<div class='title' style='font-size: 0.875rem;margin-top: 2.4375rem;'>
			寄送至：
		</div>
		<div class='addressBox' style='margin-top: 0.5625rem;'>
			<div class='text2'>
				收件人：小乔
			</div>
			<div class='text2' style='margin-top: 0.9375rem;'>
				电话：13309092588
			</div>
			<div class='text2' style='margin-top: 0.9375rem;'>
				收货地址：浙江省杭州市西湖区文新街道文一西路1188号
			</div>
		</div>
		<div class='item' style='margin-top: 1.625rem;'>
			<div class='text3' style='width: 4.375rem;display: flex;justify-content: flex-end;'>
				<span>*</span>物流公司
			</div>
			<div style='width: 21.25rem;margin-left: 2.1875rem;'>
				<el-select v-model="value" placeholder="请选择">
					<el-option
						v-for="item in options"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
					</el-option>
				</el-select>
			</div>
		</div>
		<div class='item' style='margin-top: 1.875rem;'>
			<div class='text3' style='width: 4.375rem;display: flex;justify-content: flex-end;'>
				<span>*</span>运单号
			</div>
			<div style='width: 21.25rem;margin-left: 2.125rem;'>
				<el-input v-model="input" placeholder="请输入运单号"></el-input>
			</div>
		</div>
		<div class='item2' style='margin-top: 1.875rem;'>
			<div class='text3' style='width: 4.375rem;display: flex;justify-content: flex-end;'>
				上传图片
			</div>
			<img 
				style='width: 6.25rem;height: 6.25rem;margin-left: 2.25rem;'
				src="../../assets/images/sczj.png" 
				alt=""
			>
		</div>
		<div 
			class='btn DIS-CENTER' 
			style='margin-left: 104px;margin-top: 2.25rem;margin-bottom: 10.75rem;'
		>
			提交物流信息
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				options: [{
					value: '选项1',
					label: '黄金糕'
				}, {
					value: '选项2',
					label: '双皮奶'
				}, {
					value: '选项3',
					label: '蚵仔煎'
				}, {
					value: '选项4',
					label: '龙须面'
				}, {
					value: '选项5',
					label: '北京烤鸭'
				}],
				value: '',
				input: '',
			}
		}
	}
</script>

<style scoped>
	.ApplyRefundThird {
		
	}
	
	.titleBox {
		display: flex;
		align-items: center;
	}
	
	.title {
		font-size: 28px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
	}
	
	.text1 span {
		color: #EE4454;
	}
	
	.addressBox {
		width: 429px;
		height: 112px;
		background: #FAFAFA;
		padding-left: 1.875rem;
		padding-top: 1.25rem;
	}
	
	.text2 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
	}
	
	.item {
		display: flex;
		align-items: center;
	}
	
	.text3 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.text3 span {
		color: #EE4454;
	}
	
	
	.item>>>.el-input__inner{
		width: 340px;
		height: 44px;
		border: 1px solid #DDDDDD;    
	}
	
	.item2 {
		display: flex;
	}
	
	.btn {
		width: 174px;
		height: 50px;
		background: #EE4454;
		border-radius: 4px;
		cursor: pointer;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}
</style>
