<template>
	<div class='HelpCenter'>
		<!-- 导航栏 -->
		<div class='SY-CONTENT headerBox'>
			<div class='header'>
				<img 
					class='logo' 
					src="../assets/logo/logo3.png" 
					alt=""
					@click='ck_goPage("/")'
				>
				<div class='title' style='margin-left: 1.25rem;'>
					帮助中心
				</div>
			</div>
		</div>
	
		<!-- 内容区 -->
		<div class='SY-CONTENT'>
			<div class='contentBox'>
				<!-- 左边 -->
				<div class='left'>
					<div
						v-for='(item,index) in menuList'
						:key='index'
					>
						<div
							class='item'
							@click='ck_menu(index)'
						>
							<div class='text' style='margin-left: 2.5rem;'>
								{{item.text}}
							</div>
							<div v-if='item.child'>
								<img
									v-if='item.active'
									style='width: 0.5625rem;height: 0.5625rem;margin-right: 1.25rem;'
									src="../assets/images/arrow_up@2x.png" 
									alt=""
								>
								<img 
									v-else
									style='width: 0.5625rem;height: 0.5625rem;margin-right: 1.25rem;'
									src="../assets/images/arrow-bottom.png" 
									alt=""
								>
							</div>
						</div>
						<div 
							class='child' 
							v-show='item.active'
							v-for='(childItem,childIndex) in item.child'
							:key='childIndex'
							@click='ck_childMenu(index,childIndex)'
						>
							<div class='text childBox1' v-if='childItem.active'>
								<div style='margin-left: 3.5rem;'>
									{{childItem.text}}
								</div>
							</div>
							<div class='text childBox' v-else>
								<div style='margin-left: 3.8125rem;'>
									{{childItem.text}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 右边 -->
				<div class='right'>
					<div 
						class='title'
						style='width: 100%;height: 830px;overflow-y: auto;'
						v-html='contentText'
					>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 页脚 -->
		<div class='SY-CONTENT' style='background-color: #333333;'>
			<HomeFooter />
		</div>
	</div>
</template>

<script>
	import Api from '../request/api.js';
	
	import HomeFooter from '../components/Home/HomeFooter.vue';
	import { HELPCENTERMENU } from '../assets/data/helpCenter-menu.js';
	
	export default {
		components: {
			HomeFooter
		},
		data() {
			return {
				menuList: HELPCENTERMENU,
				contentText: '',
				articleId: 22,
			}
		},
		created() {
			if(this.$route.query.id) {
				if(this.$route.query.id == 1) {
					this.ck_childMenu(2,0)
					return
				}
				if(this.$route.query.id == 2) {
					this.ck_menu(3)
					return
				}
				if(this.$route.query.id == 3) {
					this.ck_menu(5)
					return
				}
				if(this.$route.query.id == 4) {
					this.ck_childMenu(1,0)
					return
				}
				if(this.$route.query.id == 5) {
					this.ck_childMenu(0,1)
					return
				}
				if(this.$route.query.id == 6) {
					this.ck_menu(4)
					return
				}
			}
			
			this.ck_childMenu(0,0)
		},
		methods: {
			ck_menu(index) {
				for(let i in this.menuList) {
					this.menuList[i].active = false;
				}
				this.menuList[index].active = !this.menuList[index].active
				if(index == 3) { // 投诉维权
					this.articleId = 20;
				}
				if(index == 4) { // 信用评价（暂无）
					this.articleId = 22;
				}
				if(index == 5) { // 常见问题
					this.articleId = 25;
				}
				if(index == 4) { // 信用评价
					this.articleId = 31;
				}
				this.initData(this.articleId);
			},
			ck_childMenu(index,childIndex) {
				for(let i in this.menuList) {
					this.menuList[i].active = false;
					for(let j in this.menuList[i].child) {
						this.menuList[i].child[j].active = false;
					}
				}
				this.menuList[index].active = true;
				this.menuList[index].child[childIndex].active = true;
				if(index == 0 && childIndex == 0) {  // 注册账户
					this.articleId = 22;
				}
				if(index == 0 && childIndex == 1) { // 购买流程
					this.articleId = 23;
				}
				if(index == 1 && childIndex == 0) { // 入驻流程
					this.articleId = 24;
				}
				if(index == 2 && childIndex == 0) { // 支付流程
					this.articleId = 21;
				}
				if(index == 2 && childIndex == 1) { // 资金管理（暂无）
					this.articleId = 22;
				}
				if(index == 1 && childIndex == 1) { // 商品上架（暂无）
					this.articleId = 30;
				}
				this.initData(this.articleId);
			},
			ck_goPage(path) {
				this.$router.push({path})
			},
			initData(articleId) {
				const data = {
					articleId
				}
				this.axios.post(Api.getArticle,data).then(res=>{
					if(res.data.code == 200) {
						this.contentText = res.data.data.articleContent
					} else {
						this.$message.error(res.data.msg)
					}
				})
			}
		}
	}
</script>

<style scoped>
	.HelpCenter {
		background-color: #F5F5F5;
	}
	
	.header {
		width: 75rem;
		height: 3.75rem;
		display: flex;
		align-items: center;
	}
	
	.headerBox {
		background: #FFFFFF;
		box-shadow: 0px 2px 14px 0px rgba(216, 216, 216, 0.5);
	}
	
	.logo {
		width: 137px;
		height: 50px;
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		cursor: pointer;
	}
	
	.title {
		font-size: 20px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.contentBox {
		display: flex;
		justify-content: space-between;
		margin-top: 0.625rem;
		width: 75rem;
	}
	
	.contentBox .left {
		width: 15.3125rem;
		background-color: #FFFFFF;
	}
	
	.item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		padding: 0.875rem 0;
	}
	
	.text {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.right {
		width: 59rem;
		height: 830px;
		margin-left: 0.6875rem;
		background-color: #FFFFFF;
	}
	
	.childBox {
		padding: 0.9375rem 0;
		cursor: pointer;
	}
	
	.childBox1 {
		padding: 0.9375rem 0;
		border-left: 5px solid #EE4454;
		cursor: pointer;
		background-color: #FAFAFA;
	}
</style>
