<template>
  <div class="GoodsDetailsHeader">
    <div class="DIS-CENTER">
      <img
        style="
          width: 0.875rem;
          height: 0.875rem;
          margin-right: 0.1875rem;
          cursor: pointer;
        "
        src="../assets/images/goodsDetail-home.png"
        alt=""
      />
      <div class="text" @click="goPage('/')">首页</div>
      <div class="text" v-if="isSeller == 1" @click="ck_open">卖家中心</div>
      <div class="text" @click="ck_openQQ">客服</div>
      <!-- <div class='text'>手机版</div> -->
    </div>

    <!-- 商品页面 -->
    <div class="DIS-CENTER">
      <!-- <div style='width: 1.875rem;height: 1.875rem;background-color: pink;margin-right: 1.25rem;'></div> -->
      <img
        style="
          width: 1.875rem;
          height: 1.875rem;
          margin-right: 1.25rem;
          border-radius: 32px;
        "
        :src="userHeader"
        alt=""
      />
      <div class="text" @click="goPage('/ShoppingCart')">购物车</div>
      <div class="text" @click="goPage('/PersonalCenter')">个人中心</div>
      <div class="im-messageBox">
        <div class="text" @click="ck_dialogBox">消息</div>
        <div class="im-messageBox-num" v-if="unreadMessage">
          {{ unreadMessageNum }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Api from "../request/api.js";
export default {
  computed: mapState(["AllNum"]),
  data() {
    return {
      userHeader: "",
      isSeller: "",
      unreadMessageNum: "",
      unreadMessage: false,
    };
  },
  created() {
    this.userHeader = JSON.parse(localStorage.getItem("userInfo")).userPhoto;
    this.seacthSeller();
  },
  watch: {
    AllNum: {
      deep: true,
      handler(newMsg) {
        if (newMsg == 0) {
          this.unreadMessage = false;
        } else {
          this.unreadMessage = true;
        }
        if (newMsg > 99) {
          this.unreadMessageNum = "...";
        } else {
          this.unreadMessageNum = newMsg;
        }
      },
    },
  },
  methods: {
    // 打开对话框
    ck_dialogBox() {
      this.$emit("dialogBoxIndex", true);
    },
    // 查询用户店铺
    seacthSeller() {
      const data = {
        loginid: localStorage.getItem("shuying-token"),
      };
      this.axios.post(Api.getUserShop, data).then((res) => {
        if (res.data.code == 200) {
          this.isSeller = res.data.data.hasShop;
        }
      });
    },
    goPage(path) {
      this.$router.push({ path });
    },

    // 打开店铺客服
    ck_openQQ() {
      window.open("tencent://message/?uin=3633750010");
    },
    ck_open() {
      window.open("http://admin.showwins.com/shop");
    },
  },
};
</script>

<style scoped>
.GoodsDetailsHeader {
  width: 75rem;
  height: 3.4375rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.GoodsDetailsHeader .text {
  font-size: 0.875rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  margin-right: 1.5625rem;
  cursor: pointer;
}
</style>
