<template>
	<div style='width: 23.9375rem;'>
		<div class='RegisteredSecond'>
			<div class='RegisteredSecond-input'>
				<div class='title'>账户名称</div>
				<el-input placeholder="您的登录账户名" v-model="username"></el-input>
			</div>
			<div class='RegisteredSecond-input'>
				<div class='title'>设置密码</div>
				<el-input placeholder="建议使用两种以上字符组合" v-model="password"></el-input>
			</div>
			<div class='RegisteredSecond-input'>
				<div class='title'>确认密码</div>
				<el-input placeholder="请再次确认密码" v-model="confirmPassword"></el-input>
			</div>
			
			<div class='btn' @click='nextStep({num: 2})'>
				下一步
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				username: '',
				password: '',
				confirmPassword: '',
			}
		},
		methods: {
			nextStep(num) {
				if(this.username.length<1) {
					this.$message.warning('账户名称不能为空');
					return;
				}
				if(this.password.length<6) {
					this.$message.warning('账户密码不能低于6位');
					return;
				}
				if(this.confirmPassword.length<6) {
					this.$message.warning('请输入确认密码');
					return;
				}
				if(this.password != this.confirmPassword) {
					this.$message.warning('两次输入的密码不一致，请检查后重新输入');
					return;
				}
				this.$store.commit('nextStep',num);
				// 参数传给父级
				const data = {
					username: this.username,
					password: this.password
				}
				this.$emit('getLoginData',data);
			},
		}
	}
</script>

<style scoped>
	.RegisteredSecond-input {
		width: 21.25rem;
		display: flex;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		border: 1px solid #DDDDDD;
		height: 2.75rem;
		align-items: center;
		margin-bottom: 1.875rem;
	}
	
	.RegisteredSecond>>>.el-input__inner {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		border: none;
		width: 12rem;
	}
	
	.RegisteredSecond .title {
		margin-left: 1rem;
		width: 75px;
	}
	
	.btn {
		width: 340px;
		height: 50px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		margin-top: 1.6875rem;
	}
</style>
