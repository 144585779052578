<template>
	<div class='PersonalPhoneAvaliable'>
		<div class='itemBox' style='margin-top: 2.625rem;'>
			<div class='text1'>
				<span>*</span>证件类型
			</div>
			<div class='inputBox'>
				<el-select v-model="value" placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.label">
					</el-option>
				</el-select>
			</div>
		</div>
		<div class='itemBox' style='margin-top: 1.875rem;'>
			<div class='text1'>
				<span>*</span>身份证号
			</div>
			<div class='inputBox'>
				<el-input v-model="idNumber" placeholder="请输入身份证号"></el-input>
			</div>
		</div>
		<div class='text2' style='margin: 25px 0;'>
			（上传图片支出持jpg、png、jpeg格式，大小不超过10M)
		</div>
		<div class='itemBox' style='align-items: flex-start;'>
			<div class='text1' style='margin-top: 0.5rem;'>
				<span>*</span>正面人像
			</div>
			<div style="position: relative;">
				<img style='width: 10.3125rem;height: 6.25rem;margin-left: 33px;' alt="" :src='userHeader1'>
				<label class='upload DIS-CENTER' for="upload" accept="image/png,image/jpeg">
					上传正面人像
				</label>
			</div>
			<input id='upload' type="file" style='display: none;' @change="ce_upload">
		</div>
		<div class='itemBox' style='align-items: flex-start;margin-top: 1.875rem;'>
			<div class='text1' style='margin-top: 0.5rem;'>
				<span>*</span>反面国徽
			</div>
			<div style="position: relative;">
				<img style='width: 10.3125rem;height: 6.25rem;margin-left: 33px;' alt="" :src='userHeader2'>
				<label class='upload DIS-CENTER' for="upload1" accept="image/png,image/jpeg">
					上传反面国徽
				</label>
			</div>
			<input id='upload1' type="file" style='display: none;' @change="ce_upload2">
		</div>
		<div class='itemBox' style='align-items: flex-start;margin-top: 1.875rem;'>
			<div class='text1' style='margin-top: 0.5rem;'>
				<span>*</span>手持证件
			</div>
			<div style="position: relative;">
				<img style='width: 100px;height: 6.25rem;margin-left: 33px;' :src="userHeader3" alt="">
				<label class='upload DIS-CENTER' style="width: 100px;" for="upload3" accept="image/png,image/jpeg">
					上传手持证件
				</label>
			</div>
			<input id='upload3' type="file" style='display: none;' @change="ce_upload3">
		</div>
		<div class='itemBox' style='margin-top: 1.875rem;'>
			<div class='text1'>
				<span>*</span>新手机号
			</div>
			<div class='inputBox'>
				<el-input v-model="newPhone" placeholder="请输入身份证号"></el-input>
			</div>
		</div>
		<div class='itemBox' style='margin-top: 1.875rem;'>
			<div class='text1'>
				备注
			</div>
			<div class='inputBox' style='border: none;height: 6.25rem;'>
				<el-input type="textarea" resize='none' :rows="4" placeholder="请输入内容">
				</el-input>
			</div>
		</div>
		<div class='btn DIS-CENTER' @click="ck_submit">
			提交
		</div>
	</div>
</template>

<script>
	import IDTYPE from './idType.js'
	import Api from '../../request/api.js';
	export default {
		data() {
			return {
				options: IDTYPE.IDTYPE,
				value: '',
				idNumber: '',
				newPhone: '',
				userHeader1: '',
				userHeaderUpdata1: '', // 上传服务器 不带域名
				userHeader2: '',
				userHeaderUpdata2: '', // 上传服务器 不带域名
				userHeader3:'',
				userHeaderUpdata3:'',
			}
		},
		mounted() { 
			this.userHeader1=require('../../assets/images/sfz-zheng.png')
			this.userHeader2=require('../../assets/images/sfz-fan.png')
			this.userHeader3=require('../../assets/images/sczj.png')
		},
		methods: {
			ck_submit() {
				if (this.value == '' || this.idNumber == '' || this.newPhone == '' || this.userHeaderUpdata1=='' || this.userHeaderUpdata2==''|| this.userHeaderUpdata3=='') {
					this.$message.warning('请填写内容')
					return;
				}
				// 修改手机号提交
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					type:1,
					newPhone:this.newPhone,
					legalCertificateType: this.value,  // 身份证类型
					legalCertificate: this.idNumber,  // 身份证号
					legalCertificateImg: this.userHeaderUpdata1, // 正面
					legalCertificateImg_b: this.userHeaderUpdata2,  //反面
					handCardImg: this.userHeaderUpdata3,   // 手持
				}
				
				// 错误返回 code 200
				this.axios.post(Api.editPhone, data).then(res => {
					if (res.data.code == 200) {
						this.$message.success(res.data.msg);
						this.value=''
						this.idNumber=''
						this.newPhone=''
						this.userHeader1=require('../../assets/images/sfz-zheng.png')
						this.userHeader2=require('../../assets/images/sfz-fan.png')
						this.userHeader3=require('../../assets/images/sczj.png')
					}else{
						this.$message.error(res.data.msg);
					}
				})
			},
			getUpload(value, type) {
				const file = value.srcElement.files[0];
				if (!file) {
					return
				}
				let data = new FormData();
				data.append('file', file);
				this.axios.post(
					Api.uploadFile,
					data,
				).then(res => {
					if (res.data.code == 200) {
						if (type == 1) {
							this.userHeader1 = Api.URL + '/' + res.data.data.file_path;
							this.userHeaderUpdata1 = res.data.data.file_path;
						} else if (type == 2) {
							this.userHeader2 = Api.URL + '/' + res.data.data.file_path;
							this.userHeaderUpdata2 =res.data.data.file_path;
						} else{
							this.userHeader3 = Api.URL + '/' + res.data.data.file_path;
							this.userHeaderUpdata3 =res.data.data.file_path;
						}
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			ce_upload(value) {
				this.getUpload(value, 1)
			},
			ce_upload2(value) {
				this.getUpload(value, 2)
			},
			ce_upload3(value){
				this.getUpload(value,3)
			}
		}
	}
</script>

<style scoped>
	.PersonalPhoneAvaliable {}

	.itemBox {
		display: flex;
		align-items: center;
	}

	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		width: 4.375rem;
		display: flex;
		justify-content: flex-end;
	}

	.text1 span {
		color: #EE4454;
	}

	.inputBox {
		width: 340px;
		height: 44px;
		border: 1px solid #DDDDDD;
		margin-left: 1.5625rem;
	}

	.inputBox>>>.el-input__inner {
		border: none;
		width: 340px;
		height: 44px;
	}

	.text2 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
	}

	.btn {
		width: 340px;
		height: 50px;
		background: #EE4454;
		border-radius: 4px;
		cursor: pointer;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		margin-top: 1.875rem;
		margin-left: 90px;
	}

	img {
		cursor: pointer;
	}

	.upload {
		width: 10.3125rem;
		height: 6.25rem;
		margin-left: 33px;
		position: absolute;
		top: 0;
		left: 0;
		background: #000000;
		opacity: 0;
		border-radius: 10px;
		cursor: pointer;
		font-size: 12px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FFFFFF;
	}

	.upload:hover {
		opacity: 0.5;
	}
</style>
