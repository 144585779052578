<template>
	<div class='SubmitOrder'>
		<div class='SY-CONTENT' style='background-color: #F2F2F2;'>
			<GoodsDetailsHeader @dialogBoxIndex="dialogBoxIndex" />
		</div>
		
		<div class='SY-CONTENT' style='margin-top: 1.375rem;'>
			<div style='width: 75rem;'>
				<img class='logo' src="../assets/logo/logo2.png" alt="">
			</div>
		</div>
		
		<div class='SY-CONTENT' style='margin-top: 1.4375rem;'>
			<SelectAddress :addressList='addressList'/>
		</div>
		
		<div class='SY-CONTENT' style='margin-top: 3.25rem;'>
			<OrderInfo :tableList='tableList' :addressValue='addressValue' :goodsTotalMoney='goodsTotalMoney'/>
		</div>
		
		<!-- 页尾文字 -->
		<div 
			class='SY-CONTENT'
			style='margin-top: 2.5625rem;background-color: #FAFAFA;'
		>
			<HomeText />
		</div>
		
		<!-- 页脚 -->
		<div 
			class='SY-CONTENT'
			style='background-color: #333333;'
		>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Api from '../request/api.js';
	
	import GoodsDetailsHeader from '../components/GoodsDetailsHeader.vue';
	import SelectAddress from '../components/submitOrder/SelectAddress.vue';
	import OrderInfo from '../components/submitOrder/OrderInfo.vue';
	import HomeText from '../components/Home/HomeText.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	
	export default {
		components: { 
			GoodsDetailsHeader, 
			SelectAddress, 
			OrderInfo,
			HomeText,
			HomeFooter
		},
		data() {
			return {
				addressList: [], // 地址列表
				orderList: {}, // 订单信息
				tableList: [], // 商品列表数据
				addressData: [], // 寄出去的地址
				addressValue: [], // 用户选中的地址信息
				goodsTotalMoney: '', // 实际付款
				isShowDialogBox:false,
			}
		},
		created() {
			this.initData();
		},
		methods: {
			initData() {
				this.getMyAddress()
				this.getOrder()
			},
			getMyAddress() {
				const data = {
					loginid: localStorage.getItem('shuying-token')
				}
				this.axios.post(Api.myAddress,data).then(res=>{
					if(res.data.code==200) {
						this.addressList = res.data.data;
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			getOrder() {
				const data = {
					loginid: localStorage.getItem('shuying-token')
				}
				this.axios.post(Api.settlement,data).then(res=>{
					if(res.data.code == 200) {
						this.tableList = res.data.data.carts.carts;
						this.addressValue = res.data.data.userAddress;
						this.goodsTotalMoney = res.data.data.carts.goodsTotalMoney;
						for(let i in this.tableList) {
							for(let j in this.tableList[i].list) {
								this.tableList[i].list[j].goodsImg = `${Api.URL}/${this.tableList[i].list[j].goodsImg}`;
							}
						}
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			dialogBoxIndex(data){
				this.isShowDialogBox = data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
		}
	}
</script>

<style scoped>
	.logo {
		width: 133px;
		height: 60px;
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
</style>
