<template>
	<div class='RefereesDetails'>
		<div>
			<Header bgIndex='1' @dialogBoxIndex="dialogBoxIndex" />
		</div>
		<!-- 推荐人信息 -->

		<!-- 内容区 -->
		<div class='SY-CONTENT' style='flex-direction: column;align-items: center;'>
			<div class='personalItem'>
				<div class='top'>
					<img style='width: 5rem;height: 5rem;border-radius: 50%;' :src="userImg" alt="">
					<div class='text1' style='margin-top: 1rem;'>{{user_name}}</div>
					<div class='text2' style='margin-top: 0.5rem;'>一个喜欢阅读、乐观向上的90后宝妈~</div>
				</div>
				<div class='bottom'>
					<div style='width: 103px'></div>
					<div style='display: flex;align-items: center;justify-content: space-between;width: 25rem;'>
						<div class='bottomItem' v-for="(item,index) in userInfoList" :key='index'>
							<div class='text1'>{{item.num}}</div>
							<div class='text2'>{{item.text}}</div>
						</div>
					</div>
					
					<div style="width: 72px;margin-right: 1.9375rem;">
						<div v-if="userInfo.userId!=userId">
							<div v-if='!Focus' class='btn DIS-CENTER' @click='Focus = true'>关注</div>
							<div v-else class='btn DIS-CENTER' @click='Focus = false'>取消关注</div>
						</div>
					</div>
				</div>
			</div>

			<div class='contentItem'>
				<div class='contentTitleBox'>
					TA的推荐 <span>(58)</span>
				</div>

				<div class='goodList-Content'>
					<div :class="index==0 || index%5==0 ? 'goodList-item1' : 'goodList-item2'"
						v-for='(item,index) in 10' :key='index'>
						<div @click='ck_goPage("/GoodsDetails")'>
							<img class='goods-img' src="../assets/images/homethirddemo.png" alt="">
							<div class='text'>
								一个“丧失为人资格”少年的心灵独白，一个对村上春树影响至深的绝…
							</div>
						</div>
						<div class='user-box'>
							<div class='DIS-CENTER' @click='ck_goPage("/RefereesDetails")'>
								<img class='user-img' src="../assets/images/headicon.png" alt="">
								<div class='username'>曾阿牛</div>
							</div>
							<div class='DIS-CENTER'>
								<img class='good-img' src="../assets/images/like.png" alt="">
								<div class='num'>936</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 页脚 -->
		<div class='SY-CONTENT' style='background-color: #333333;margin-top: 4.5rem;'>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Header from '../components/Header.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	// import Api from '../request/api.js'
	export default {
		components: {
			Header,
			HomeFooter
		},
		data() {
			return {
				Focus: false,
				userInfoList: [{
						num: '0',
						text: '关注'
					},
					{
						num: '0',
						text: '粉丝'
					},
					{
						num: '0',
						text: '点赞'
					},
				],
				user_name: '',
				userImg: '',
				userInfo:'',
				userId:'',
				isShowDialogBox:false,
			}
		},
		created() {
			this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
			// this.initData()
		},
		mounted() { // 处理发布 数据
			for (let i in this.userInfoList) {
				if (this.userInfoList[i].num > 1000 && this.userInfoList[i].num < 10000) {
					this.userInfoList[i].num = this.userInfoList[i].num / 1000 + 'k';
				} else if (this.userInfoList[i].num > 10000) {
					this.userInfoList[i].num = this.userInfoList[i].num / 10000 + 'w';
				}
			}
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			ck_goPage(path) {
				this.$router.push({
					path
				});
			},
			
			// 应该是推荐人信息
			// initData() {
			// 	const data = {
			// 		loginid: localStorage.getItem("shuying-token"),
			// 	};
			// 	this.axios.post(Api.authorIndex, data).then((res) => {
			// 		if (res.data.code == 200) {
			// 			console.log(res.data.data)
			// 			this.userId=res.data.data.userInfo.userId
			// 			this.user_name = res.data.data.userInfo.userName
			// 			this.userImg = Api.URL + res.data.data.userInfo.userPhoto
			// 			this.userInfoList[0].num = res.data.data.myFollowNum
			// 			this.userInfoList[1].num = res.data.data.myFansNum
			// 			this.userInfoList[2].num = res.data.data.zanNum
			// 		} else {
			// 			this.$message.error(res.data.msg);
			// 		}
			// 	});
			// },

		}
	}
</script>

<style scoped>
	.personalItem {
		width: 1200px;
		height: 270px;
		border: 1px solid #E6E6E6;
		margin-top: 1.25rem;
	}

	.top {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 75rem;
		margin-top: 1.75rem;
	}

	.text1 {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.text2 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}

	.bottom {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 1.25rem;
	}

	.btn {
		width: 72px;
		height: 28px;
		border-radius: 1px;
		border: 1px solid #E6E6E6;
		cursor: pointer;
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-right: 1.9375rem;
	}

	.bottomItem {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.contentItem {
		margin-top: 2.25rem;
	}

	.contentTitleBox {
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		width: 75rem;
		padding-bottom: 0.625rem;
		border-bottom: 2px solid #EE4454;
	}

	.contentTitleBox span {
		font-size: 1.125rem;
		font-weight: 400;
	}

	.goodList-Content {
		display: flex;
		width: 75rem;
		flex-wrap: wrap;
	}

	.goodList-Content .goodList-item1 {
		width: 13.875rem;
		height: 25rem;
		background: #FFFFFF;
		border: 1px solid #E6E6E6;
		margin-top: 1.375rem;
		cursor: pointer;
	}

	.goodList-Content .goodList-item2 {
		width: 13.875rem;
		height: 25rem;
		background: #FFFFFF;
		border: 1px solid #E6E6E6;
		margin-left: 1.25rem;
		margin-top: 1.375rem;
		cursor: pointer;
	}

	.goodList-Content .text {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		padding: 0 0.8125rem;
		margin: 0.5625rem 0;
	}

	.goodList-Content .goods-img {
		width: 14rem;
		height: 18.75rem;
	}

	.goodList-Content .user-box {
		display: flex;
		justify-content: space-between;
		padding: 0 0.8125rem;
	}

	.goodList-Content .user-img {
		width: 1.875rem;
		height: 1.875rem;
		border-radius: 50%;
	}

	.goodList-Content .username {
		font-size: 0.75rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-left: 0.625rem;
	}

	.goodList-Content .good-img {
		width: 1rem;
		height: 1rem;
		cursor: pointer;
	}

	.goodList-Content .num {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-left: 0.1875rem;
	}
</style>
