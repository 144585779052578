<template>
	<div class='MineAddress'>
		<div>
			<Header bgIndex='4' @dialogBoxIndex="dialogBoxIndex" />
		</div>

		<div class='SY-CONTENT'>
			<div style='width: 75rem;background-color: #ffffff;padding-top: 2.9375rem;min-height: 73.4375rem;'>
				<div class='Bread' v-if='breadCrumbsType=="SubmitOrder"'>
					<span style='cursor: pointer;' @click='ck_goPage("/SubmitOrder")'>返回订单</span>
					<span style='margin: 0 1.5625rem;'>/</span>
					<span class='title'>我的收货地址</span>
				</div>
				<div class='Bread' v-else>
					<span style='cursor: pointer;' @click='ck_goPage("/PersonalCenter")'>主页</span>
					<span style='margin: 0 1.5625rem;'>/</span>
					<span class='title'>我的收货地址</span>
				</div>
				<div class='tableTitle'>
					<div class='title'>
						全部地址列表
					</div>
					<img 
						style='width: 6.8125rem;height: 2.25rem;cursor: pointer;margin-right: 3.75rem;' 
						src="../assets/PersonalCenter/newAddress-img.png"
						alt=""
						@click='ck_addressBtn("add")'
					>
				</div>

				<!-- 表头 -->
				<div class='tableHeader'>
					<div class='title1' style='margin-left: 4.3125rem;'>收货人</div>
					<div class='title1' style='margin-left: 6.1875rem;'>所在地区</div>
					<div class='title1' style='margin-left: 8.75rem;'>详细地址</div>
					<div class='title1' style='margin-left: 10.6875rem;'>邮编</div>
					<div class='title1' style='margin-left: 6.625rem;'>手机</div>
					<div class='title1' style='margin-left: 9.125rem;'>操作</div>
				</div>

				<!-- 表格内容 -->
				<div class='tableItem' v-for='(item,index) in tableData' :key='index'>
					<div class='text1' style='width: 5.5rem;margin-left: 4.375rem;'>
						{{item.userName}}
					</div>
					<div class='text1' style='width: 9.375rem;margin-left: 2.8125rem;'>
						{{item.areaName}}{{item.areaName1}}
					</div>
					<div class='text1' style='margin-left: 2.3125rem;width: 200px;'>
						{{item.userAddress}}
					</div>
					<div class='text1' style='margin-left: 20px;'>
						{{item.postcode}}
					</div>
					<div class='text1' style='margin-left: 5.8125rem;'>
						{{item.userPhone}}
					</div>
					<div style='display: flex;align-items: center;margin-left: 6.3125rem;'>
						<div class='text1' style='cursor: pointer;' @click='ck_addressBtn("edit",item)'>
							修改
						</div>
						<div class='text1' style='cursor: pointer;margin-left: 1rem;' @click='ck_tableStatus(item,"del")'>
							删除
						</div>
						<!-- isDefault  0为默认消息 -->
						<img 
							v-if='item.isDefault == 1' 
							style='width: 2.5rem;height: 1.25rem;margin-left: 1.25rem;' 
							src="../assets/PersonalCenter/table-mr.png"
							alt=""
						>
						<div v-else class='text1' style='cursor: pointer;margin-left: 1.25rem;' @click='ck_tableStatus(item.addressId,"default")'>
							设为默认
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 弹窗和遮罩 -->
		<div class='mask' v-if='showModel'></div>
		<div class='model' v-if='showModel'>
			<div style='display: flex;align-items: center;justify-content: space-between;margin-top: 1.3125rem;'>
				<div class='title' style='margin-left: 3.9375rem;margin-top: 0.625rem;'>
					{{addressType=='add'?'新增':'修改'}}收货地址
				</div>
				<img 
					style='width: 1.125rem;height: 1.125rem;margin-right: 1.125rem;cursor: pointer;' 
					src="../assets/images/close@2x.png"
					alt=""
					@click='showModel = false'
				>
			</div>

			<div class='modelItem' style='margin-top: 2.6875rem;align-items: flex-start'>
				<div style='display: flex;width: 4.875rem;justify-content: flex-end;margin-top: 1rem;' class='modelText'>
					<span style='color: #EE4656;'>*</span>
					收获地址
				</div>
				<div style='margin-left: 1.8125rem;width: 24.375rem;'>
					<!-- ------------------ 获取地址 ---------------- -->
					<el-select v-model="provinceValue" placeholder="请选择省份" @change='getCity'>
						<el-option 
							v-for="item in provinceOptions" 
							:key="item.areaId" 
							:label="item.areaName" 
							:value="item.areaId"
						>
						</el-option>
					</el-select>
					<el-select v-model="cityValue" placeholder="请选择市" style='margin: 10px 0;' @change='getArea'>
						<el-option 
							v-for="item in cityOptions" 
							:key="item.areaId" 
							:label="item.areaName" 
							:value="item.areaId"
						>
						</el-option>
					</el-select>
					<el-select v-model="areaValue" placeholder="请选择区" @change='getAddress'>
						<el-option 
							v-for="item in areaOptions" 
							:key="item.areaId" 
							:label="item.areaName" 
							:value="item.areaId"
						>
						</el-option>
					</el-select>
					<!-- ------------------------------------------------- -->
				</div>
			</div>
			<div class='modelItem' style='margin-top: 1.875rem;'>
				<div style='display: flex;width: 4.875rem;justify-content: flex-end;' class='modelText'>
					<span style='color: #EE4656;'>*</span>
					详细地址
				</div>
				<div style='margin-left: 1.8125rem;'>
					<el-input v-model="userAddress" placeholder="请输入详细地址"></el-input>
				</div>
			</div>
			<div class='modelItem' style='margin-top: 1.875rem;'>
				<div style='display: flex;width: 4.875rem;justify-content: flex-end;' class='modelText'>
					<span style='color: #EE4656;'>*</span>
					收货人姓名
				</div>
				<div style='margin-left: 1.8125rem;'>
					<el-input v-model="userName" placeholder="请输入收货人姓名"></el-input>
				</div>
			</div>
			<div class='modelItem' style='margin-top: 1.875rem;'>
				<div style='display: flex;width: 4.875rem;justify-content: flex-end;' class='modelText'>
					<span style='color: #EE4656;'>*</span>
					手机号
				</div>
				<div style='margin-left: 1.8125rem;'>
					<el-input v-model="userPhone" placeholder="请输入手机号"></el-input>
				</div>
			</div>
			<div class='modelItem' style='margin-top: 1.875rem;'>
				<div style='display: flex;width: 4.875rem;justify-content: flex-end;' class='modelText'>
					<span style='color: #EE4656;'>*</span>
					邮编
				</div>
				<div style='margin-left: 1.8125rem;'>
					<el-input v-model="postcode" placeholder="请输入邮编"></el-input>
				</div>
			</div>
			<div 
				class='modelText' 
				style='display: flex;align-items: center;cursor: pointer;margin-left: 9.9375rem;margin-top: 1.125rem;'
				@click='ck_setIsDefault'
			>
				<img
					v-if='isDefault==1'
					style='width: 0.875rem;height: 0.875rem;margin-right: 0.5625rem;'
					src="../assets/images/choice-active.png" alt=""
				>
				<img 
					v-else
					style='width: 0.875rem;height: 0.875rem;margin-right: 0.5625rem;'
					src="../assets/images/choice.png" alt=""
				>
				设为默认
			</div>
			<div 
				class='modelBtn DIS-CENTER' style='margin-left: 9.875rem;margin-top: 1.3125rem;'
				@click='ck_saveAddress'
			>
				保存
			</div>
		</div>

		<!-- 页脚 -->
		<div class='SY-CONTENT' style='background-color: #333333;'>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Api from '../request/api.js';
	
	import Header from '../components/Header.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';

	export default {
		components: {
			Header,
			HomeFooter
		},
		data() {
			return {
				tableData: [],
				/*----------- 获取地址 ----------*/
				provinceOptions: [], // 省的数组
				provinceValue: '', // 省的数据
				provinceText: '',
				cityOptions: [], // 市的数组
				cityValue: '', // 市的数据
				cityText: '',
				areaOptions: [], // 区的数组
				areaValue: '', // 区的数据
				areaText: '',
				/*----------------------------*/
				userAddress: '', // 详细地址
				userName: '', // 收货人姓名
				userPhone: '', // 手机号
				postcode: '', // 邮编
				isDefault: 1, // 是否默认
				value: '',
				input: '',
				showModel: false, //模态框的显示
				addressType: 'add', // 判断用户是新增还是修改
				addressId: '', // 修改的时候需要传的id
				breadCrumbsType: '',
				isShowDialogBox:false,
			}
		},
		created() {
			this.initData();
		},
		mounted() {
			if(this.$route.query) {
				this.breadCrumbsType = this.$route.query.type;
			}
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			initData() {
				const data = {
					loginid: localStorage.getItem('shuying-token')
				}
				this.axios.post(Api.myAddress,data).then(res=>{
					console.log(res.data.data,'默认数据获取')
					if(res.data.code == 200) {
						this.tableData = res.data.data;
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			ck_goPage(path) {
				this.$router.push({path});
			},
			ck_tableStatus(item,type) {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					id: item.addressId
				}
				console.log(data)
				let interfaceUrl = Api.setDefault;
				if(type == 'del') {
					interfaceUrl = Api.delAddress;
				}
				console.log(interfaceUrl)
				this.axios.post(interfaceUrl,data).then(res=>{
					if(res.data.code == 200) {
						this.$message.success(res.data.msg)
						this.initData();
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			ck_setIsDefault() {
				if(this.isDefault == 1) {
					this.isDefault = 0;
					return 
				}
				this.isDefault = 1;
			},
			/*--------------- 获取地址 ----------------*/
			initAddressData(parentId,type) {
				const data = {
					parentId
				}
				this.axios.post(Api.getArea, data).then(res => {
					if (res.data.code == 200) {
						// this.provinceOptions = res.data.data
						if(type=='city') {
							this.cityOptions = res.data.data;
						} else if(type=='area') {
							this.areaOptions = res.data.data;
						} else {
							this.provinceOptions = res.data.data;
						}
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			getCity(value) {
				this.initAddressData(value,'city');
				this.provinceText = value ? this.provinceOptions.find(ele => ele.areaId === value).areaName : ''
			},
			getArea(value) {
				this.initAddressData(value,'area');
				this.cityText = value ? this.cityOptions.find(ele => ele.areaId === value).areaName : ''
			},
			getAddress(value) {
				this.initAddressData(value,'area');
				this.areaText = value ? this.areaOptions.find(ele => ele.areaId === value).areaName : ''
			},
			/*------------------------------------------------*/
			// 新增或修改收货地址按钮
			ck_addressBtn(type,item) {
				this.addressType = type; // 判断用户是新增还是修改
				this.initAddressData();
				if(type == 'edit') {
					this.userName = item.userName;
					this.userPhone = item.userPhone;
					this.userAddress = item.userAddress;
					this.postcode = item.postcode;
					this.isDefault = item.isDefault;
					this.addressId = item.addressId;
					// 目前收货地址是没复现的，要想办法复现才行
				}
				this.showModel = true;
			},
			// 新增收货地址
			ck_saveAddress() {
				let interfaceUrl = '';
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					userName: this.userName,
					userPhone: this.userPhone,
					userAddress: this.userAddress,
					postcode: this.postcode,
					isDefault: this.isDefault,
					areaId: this.areaValue
				}
				if(this.addressType == 'add') {
					interfaceUrl = Api.addAddress
				} else {
					data.addressId = this.addressId;
					interfaceUrl = Api.toEdit
				}
				this.axios.post(interfaceUrl,data).then(res=>{
					if(res.data.code == 200) {
						this.showModel = false;
						this.userName = '';
						this.userPhone = '';
						this.userAddress = '';
						this.postcode = '';
						this.isDefault = 1;
						this.provinceOptions = []; // 省的数组
						this.provinceValue = ''; // 省的数据
						this.provinceText = '';
						this.cityOptions = []; // 市的数组
						this.cityValue = ''; // 市的数据
						this.cityText = '';
						this.areaOptions = []; // 区的数组
						this.areaValue = ''; // 区的数据
						this.areaText = '';
						this.$message.success(res.data.msg);
						this.initData();
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			// // 修改收货地址
			// editAddress() {
			// 	const data = {
			// 		loginid: localStorage.getItem('shuying-tokne'),
			// 		user
			// 	}
			// }
		}
	}
</script>

<style scoped>
	.Bread {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-left: 3.125rem;
	}

	.title {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.tableTitle {
		margin-left: 3.125rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 3.625rem;
	}

	.tableHeader {
		margin-left: 3.125rem;
		width: 68.0625rem;
		height: 2.75rem;
		display: flex;
		align-items: center;
		background: #FAFAFA;
		margin-top: 1.5625rem;
		border: 1px solid #E6E6E6;
	}

	.title1 {
		font-size: 12px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.tableItem {
		height: 5.3125rem;
		width: 68.0625rem;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #E6E6E6;
		margin-left: 3.125rem;
	}

	.text1 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.mask {
		width: 100%;
		min-height: 89.6rem;
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.2);
		z-index: 6;
	}

	.model {
		width: 37.5rem;
		height: 43rem;
		background-color: #FFFFFF;
		position: fixed;
		z-index: 9;
		top: 50%;
		margin-top: -18.75rem;
		left: 50%;
		margin-left: -18.75rem;
	}

	.modelItem {
		display: flex;
		align-items: center;
		margin-left: 3.25rem;
	}

	.modelText {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.MineAddress>>>.el-input__inner{
		width: 340px;
		height: 44px;
		border: 1px solid #DDDDDD;
		border-radius: 0;
	}
	
	.modelBtn {
		width: 120px;
		height: 44px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
	}
</style>
