<template>
	<div class='HomeThird'>
		<HomeTitle title='达人推荐' text='更多推荐' path='Intelligent' />

		<div style='display: flex;'>
			<div class='goodList-Content'>
				<div :class="index==0 || index%3==0 ? 'goodList-item1' : 'goodList-item2'"
					v-for='(item,index) in goodList.slice(0,6)' :key='index' @click="ck_goDetail(item.id)" style="cursor: pointer;">
					<div>
						<img class='goods-img' :src="item.cover_image" alt="">
						<div class='text'
							style="height: 35px; text-overflow: -o-ellipsis-lastline;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;line-clamp: 2;-webkit-box-orient: vertical;">
							{{item.title}}
						</div>
					</div>
					<div class='user-box'>
						<div class='DIS-CENTER' @click='ck_goPage("/RefereesDetails")'>
							<img class='user-img' :src="imgUrl+item.userPhoto" alt="">
							<div class='username'>{{item.userName}}</div>
						</div>
						<div class='DIS-CENTER'>
							<img class='good-img' src="../../assets/images/like.png" alt="">
							<div class='num'>{{item.zan_num}}</div>
						</div>
					</div>
				</div>
			</div>

			<div class='ranking'>
				<div style='display: flex;'>
					<div class='title'>
						排行榜
					</div>
					<div class='menu'>
						<div style='margin-right: 1.5rem;cursor: pointer;' v-for='(item1,index1) in menuList'
							:key='index1' @click='ck_menu(index1)'>
							<div class='menu-item'>
								<div :class="item1.active ? 'menu-text1' : 'menu-text2'">{{item1.text}}</div>
								<div class='line' v-if='item1.active'></div>
							</div>
						</div>
					</div>
				</div>
				
				<!-- 达人榜 intelligentList   最少显示15条 数据-->
				<div v-show='rankValue == 0' class='data' v-for='(item2,index2) in intelligentList' :key='index2'
					@click='ck_goPage("/RefereesDetails",item2.userId)'>
					<div style='display: flex;align-items: center;'>
						<img v-if='index2 == 0' style='width: 1.75rem;height: 1.25rem;'
							src="../../assets/images/rankingFrist.png" alt="">
						<img v-if='index2 == 1' style='width: 1.75rem;height: 1.25rem;'
							src="../../assets/images/rankingSecond.png" alt="">
						<img v-if='index2 == 2' style='width: 1.75rem;height: 1.25rem;'
							src="../../assets/images/rankingThird.png" alt="">
						<div class='index DIS-CENTER' v-if='index2 > 2'>
							{{index2 +1}}
						</div>
						
						<img class='user-img' :src="imgUrl+item2.userPhoto" alt="">
						
						<div class='username'>{{item2.loginName}}</div>
					</div>
					<div class='text'>推荐成交2180笔</div>
				</div>
				
				<!-- 热书榜 hotBookList -->
				<div v-show='rankValue == 1' class='data' :style='index3==0?"margin-top:17px":"margin-top: 0.5rem;"' v-for='(item3,index3) in hotBookList' :key="index3+ '-only'">
					<div style='display: flex;align-items: center;cursor: pointer;' @click="ck_goGoodPage('/IntelligentRecommend',item3.id)">
						<div>
							<img v-if='index3 == 0' style='width: 1.375rem;height: 1.375rem;'
								src="../../assets/images/Rank-01.png" alt="">
							<img v-if='index3 == 1' style='width: 1.375rem;height: 1.375rem;'
								src="../../assets/images/Rank-02.png" alt="">
							<img v-if='index3 == 2' style='width: 1.375rem;height: 1.375rem;'
								src="../../assets/images/Rank-03.png" alt="">
							<div v-if='index3>2'
								style='width: 1.375rem;height: 1.375rem;background-color: #E6E6E6;border-radius: 50%;'
								class='DIS-CENTER text2'>
								{{index3+1}}
							</div>
						</div>
						<img style='width: 3.75rem;height: 3.75rem;border: 1px solid #E6E6E6;margin-left: 1.375rem;'
							:src="item3.cover_image" alt="">
						<div class='text1' style='margin-left: 1.0625rem;'>
							<div style='width: 18.4375rem'>
								{{item3.title}}
							</div>
							<div style='display: flex;align-items: center;margin-top: 0.4375rem;'>
								<img style='width: 1.0625rem;height: 0.9375rem;' src="../../assets/images/like.png"
									alt="">
									<!-- 点赞 数量-->
								<div class='text1' style='margin-left: 0.5rem;'>{{item3.zan_num}}</div>
							</div>
						</div>
					</div>
				</div>
				
				<div style="margin-top: 20px;">
					<HomeTitle title='' text='更多' path='IntelligentRank' />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import HomeTitle from '../components/Home-title.vue'
	import Api from '../../request/api.js';
	export default {
		components: {
			HomeTitle
		},
		data() {
			return {
				menuList: [{
						text: '达人榜',
						active: false
					},
					{
						text: '热书榜',
						active: true
					}
				],
				goodList: [],
				intelligentList:[],
				hotBookList:[],
				rankValue: 1,    //0达人榜 1热书榜        更改默认 默认0
				imgUrl:Api.URL,
			}
		},
		created() {
			this.initData()
			this.intelligentInit()
			this.hotBookListInit()
		},
		methods: {
			ck_goGoodPage(path,id) {
				this.$router.push({path,query:{id}});
			},
			initData() {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					page: 1,
				}
				this.axios.post(Api.authorRecommend, data).then(res => {
					if (res.data.code == 200) {
						this.goodList = res.data.data
					} else {
						this.$message.error(res.data.msg)
					}
				});
			},
			
			// 达人榜 空数据
			intelligentInit(){
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					page:1,
				}
				this.axios.post(Api.authorRank, data).then(res => {
					if (res.data.code == 200) {
						// this.intelligentList=res.data.data
						this.intelligentList=[]
					} else {
						this.$message.error(res.data.msg)
					}
				});
			},
			
			// 热书榜  hotGoodsRank
			hotBookListInit(){
				const data = {
					loginid: localStorage.getItem('shuying-token'),
				}
				this.axios.post(Api.hotGoodsRank, data).then(res => {
					if (res.data.code == 200) {
						this.hotBookList=res.data.data
					} else {
						this.$message.error(res.data.msg)
					}
				});
			},
			ck_menu(index) {
				for (let i in this.menuList) {
					this.menuList[i].active = false;
				}
				this.menuList[index].active = true;
				this.rankValue = index;
			},
			ck_goPage(path) {
				this.$router.push({
					path
				})
			},
			ck_goDetail(id) {
				this.$router.push({
					path: '/IntelligentRecommend?id='+id
				})
			}
		}
	}
</script>

<style scoped>
	.HomeThird {
		width: 75rem;
	}

	.goodList-Content {
		display: flex;
		width: 44.5rem;
		flex-wrap: wrap;
	}

	.goodList-item1:hover .text {
		color: #EE4454;
		cursor: pointer;
	}

	.goodList-item2:hover .text {
		color: #EE4454;
		cursor: pointer;
	}

	.goodList-Content .goodList-item1 {
		width: 13.875rem;
		height: 25rem;
		background: #FFFFFF;
		border: 1px solid #E6E6E6;
		margin-top: 1.375rem;
	}

	.goodList-Content .goodList-item2 {
		width: 13.875rem;
		height: 25rem;
		background: #FFFFFF;
		border: 1px solid #E6E6E6;
		margin-left: 1.25rem;
		margin-top: 1.375rem;
	}

	.goodList-Content .text {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		padding: 0 0.8125rem;
		margin: 0.5625rem 0;
	}

	.goodList-Content .goods-img {
		width: 14rem;
		height: 18.75rem;
	}

	.goodList-Content .user-box {
		display: flex;
		justify-content: space-between;
		padding: 0 0.8125rem;
	}

	.goodList-Content .user-img {
		width: 1.875rem;
		height: 1.875rem;
		border-radius: 50%;
	}

	.goodList-Content .username {
		font-size: 0.75rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-left: 0.625rem;
	}

	.goodList-Content .good-img {
		width: 1rem;
		height: 1rem;
		cursor: pointer;
	}

	.goodList-Content .num {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-left: 0.1875rem;
	}

	.ranking {
		width: 26.125rem;
		margin-left: 4.375rem;
		margin-top: 1.375rem;
	}

	.ranking .title {
		font-size: 1rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.ranking .menu {
		margin-left: 2rem;
		display: flex;
	}

	.ranking .menu .menu-item {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.ranking .menu .menu-text1 {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
	}

	.ranking .menu .menu-text2 {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.ranking .menu .line {
		width: 0.875rem;
		height: 0.1875rem;
		background: #EE4454;
		margin-top: 0.125rem;
	}

	.ranking .data {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 1.25rem;
	}

	.ranking .data .index {
		font-size: 0.875rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		width: 1.75rem;
	}

	.ranking .data .user-img {
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 50%;
		margin: 0 0.625rem;
	}

	.ranking .data .username {
		font-size: 0.875rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.ranking .data .text {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}

	.text1 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.text2 {
		font-size: 12px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
</style>
