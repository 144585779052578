<template>
	<div>
		<div>
			<Header bgIndex='1' @dialogBoxIndex="dialogBoxIndex" />
		</div>

		<div class='SY-CONTENT'>
			<!-- <HomeMenu :menuList='menuList'/> -->
			<div style='width: 75rem;display: flex;justify-content: space-between;align-items: center;'>
				<H2>达人推荐</H2>
				<!-- <div class='btn-tj DIS-CENTER' @click='ck_goPage("/RichText")'>
					写推荐卡
				</div> -->
			</div>
		</div>

		<!-- 内容区 -->
		<div class='SY-CONTENT'>
			<div class='Inter-content'>
				<div style='width: 44.5rem;height: 25rem;margin-top: 1.25rem;'>
					<el-carousel height="400px">
						<el-carousel-item v-for="item in 4" :key="item">
							<div style='background-color: pink;width: 100%;height: 100%;'></div>
						</el-carousel-item>
					</el-carousel>
				</div>
				<div :class="(index-2)%5==0 ? 'item1 item' : 'item2 item'" v-for='(item,index) in goodList' :key='index'
					@click="ck_goPage('/IntelligentRecommend',item.id)">
					<img class='item-img' :src="item.cover_image" alt="">
					<div class='title'>{{item.title}}</div>
					<div class='text-box'>
						<div class='DIS-CENTER'>
							<img style='width: 1.75rem;height: 1.75rem;border-radius: 32px;'
								:src="imgUrl+item.userPhoto" alt="">
							<div class='username'>{{item.userName}}</div>
						</div>
						<div class='DIS-CENTER'>
							<img style='width: 1rem;height: 1rem;cursor: pointer;' src="../assets/images/like.png"
								alt="">
							<div class='num'>{{item.zan_num}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class='DIS-CENTER SY-CONTENT' v-if="goodList.length>20 && isCheckMore">
			<div class='look DIS-CENTER' @click='ck_look'>
				查看更多
			</div>
		</div>

		<!-- 页尾文字 -->
		<div class='SY-CONTENT' style='margin-top: 8.5rem;background-color: #FAFAFA;'>
			<HomeText />
		</div>

		<!-- 页脚 -->
		<div class='SY-CONTENT' style='background-color: #333333;'>
			<HomeFooter />
		</div>
		
		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Header from '../components/Header.vue';
	// import HomeMenu from '../components/Home/HomeMenu.vue';
	import HomeText from '../components/Home/HomeText.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';

	import {
		INTEMENU
	} from '../assets/data/Inte-data.js';
	import Api from '../request/api.js';
	export default {
		components: {
			Header,
			HomeText,
			HomeFooter
		},
		data() {
			return {
				menuList: INTEMENU,
				pageNum: 2,
				goodList: [],
				imgUrl: Api.URL,
				isCheckMore:true,
				isShowDialogBox:false,
			}
		},
		created() {
			this.initData(1)
		},
		mounted() {
			this.initData(2)
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			initData(num) {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					page: num,
				}
				this.axios.post(Api.authorRecommend, data).then(res => {
					if (res.data.code == 200) {
						for (var i in res.data.data) {
							this.goodList.push(res.data.data[i])
						}
						if(res.data.data.length<17){
							this.isCheckMore=false
						}
					} else {
						this.$message.error(res.data.msg)
					}
				});
			},

			// 查看更多
			ck_look() {
				this.pageNum = this.pageNum + 1;
				this.initData(this.pageNum)
			},
			ck_goPage(path, id) {
				this.$router.push({
					path,
					query: {
						id
					}
				})
			}
		}
	}
</script>

<style scoped>
	.Inter-content {
		display: flex;
		flex-wrap: wrap;
		width: 75rem;
	}

	.Inter-content .item2 {
		width: 13.875rem;
		height: 25rem;
		margin-left: 1.1875rem;
		background: #FFFFFF;
		border: 1px solid #E6E6E6;
		margin-top: 1.25rem;
	}

	.Inter-content .item1 {
		width: 13.875rem;
		height: 25rem;
		background: #FFFFFF;
		border: 1px solid #E6E6E6;
		margin-top: 1.25rem;
	}

	.item .item-img {
		width: 13.875rem;
		height: 18.75rem;
		cursor: pointer;
	}

	.item .title {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		padding: 0 0.75rem;
		margin-top: 0.5625rem;
		cursor: pointer;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.item .text-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 0.75rem;
		margin-top: 0.9375rem;
	}

	.text-box .username {
		font-size: 0.75rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-left: 0.75rem;
	}

	.text-box .num {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-left: 0.5625rem;
	}

	.look {
		width: 7.5rem;
		height: 2.25rem;
		border: 0.0625rem solid #E6E6E6;
		font-size: 0.875rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
		margin-top: 3.125rem;
	}

	.btn-tj {
		width: 100px;
		height: 40px;
		background: #EE4454;
		border-radius: 2px;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
	}
</style>
