<template>
	<div class='PersonalPjgl'>
		<!-- 头部菜单 -->
		<div style='display: flex;align-items: center'>
			<div class='headerBox'></div>
			<div class='headerTitle'>我的评价</div>
		</div>
	
		<!-- 表头 -->
		<div class='tableHeader'>
			<div class='text2 DIS-CENTER' style='margin-left: 3.125rem;cursor: pointer;'>
				全部评论
				<!-- <img 
					style='width: 0.5625rem;height: 0.5625rem;margin-left: 0.125rem;' 
					src="../../assets/images/arrow-bottom.png" alt=""
				> -->
			</div>
			<div class='text2' style='margin-left: 48px;'>订单号</div>
			<div class='text2' style='margin-left: 138px;'>商品信息</div>
			<div class='text2' style='margin-left: 168px;'>评价内容</div>
		</div>
	
		<!-- 表格内容 -->
		<div 
			v-show='total > 0'
			class='tableContent'
			v-for='(item,index) in tableData'
			:key='index'
		>
			<img 
				v-if='item.score == "best"'
				style='width: 1.875rem;height: 1.875rem;margin-left: 4.125rem;'
				src="../../assets/PersonalCenter/haoping.png" alt=""
			>
			<img
				v-if='item.score == "good"'
				style='width: 1.875rem;height: 1.875rem;margin-left: 4.125rem;'
				src="../../assets/PersonalCenter/zhongping.png" alt=""
			>
			<img
				v-if='item.score == "bad"'
				style='width: 1.875rem;height: 1.875rem;margin-left: 4.125rem;'
				src="../../assets/PersonalCenter/chaping.png" alt=""
			>
			
			<div class='text3' style='margin-left: 3.0625rem;width: 8.125rem;'>
				{{item.orderNo}}
			</div>
			<div class='text3' style='width: 8.5rem;margin-left: 2.8125rem;'>
				{{item.goodsName}}
			</div>
			<div style='margin-left: 4.875rem;'>
				<div class='text3' style='width: 22rem;'>
					{{item.content}}
				</div>
				<div class='text4' style='margin-top: 0.875rem;'>
					{{item.replyTime}}
				</div>
			</div>
		</div>
		
		<div class='sorter' v-if='total > 0'>
			<el-pagination
				background
				layout="pager"
				:total="total"
				@current-change='ck_setPage'
			>
			</el-pagination>
		</div>
		
		<div v-if='total < 1'>
			<NoneItem title='您还没有购买过商品哦~' text='请快去选购您心仪的商品吧'/>
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	
	import NoneItem from '../NoneItem.vue';
	
	export default {
		components: { NoneItem },
		data() {
			return {
				tableData: [], // 列表数据
				total: 0, // 列表总数据
			}
		},
		created() {
			this.initData(1)
		},
		methods: {
			initData(num) {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					page: num,
				}
				this.axios.post(Api.myAppraise,data).then(res=>{
					if(res.data.code == 200) {
						this.total = res.data.data.count;
						this.tableData = res.data.data.data;
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			ck_setPage(value) {
				this.initData(value)
			}
		}
	}
</script>

<style scoped>
	.PersonalPjgl {
		padding-top: 1.3125rem;
		width: 55.75rem;
		padding-left: 1.4375rem;
		background-color: #FFFFFF;
		min-height: 73.4375rem;
		padding-right: 1.25rem;
	}
	
	.tableHeader {
		width: 891px;
		height: 44px;
		background: #FAFAFA;
		border: 1px solid #E6E6E6;
		margin-top: 1.25rem;
		display: flex;
		align-items: center;
	}
	
	.text2 {
		font-size: 12px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.tableContent {
		padding: 1.6875rem 0 2.125rem;
		width: 55.6875rem;
		border-bottom: 1px solid #E6E6E6;
		display: flex;
		align-items: center;
	}
	
	.text3 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.text4 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
	
	.sorter {
		display: flex;
		justify-content: flex-end;
		margin-top: 1.875rem;
	}
	
	.sorter>>>.el-pager li {
		background-color: transparent !important;
		color: #333333 !important;
		border: 1px solid #E6E6E6;
		width: 36px;
		height: 36px;
		line-height: 2.25rem;
	}
	
	.sorter>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color:  #333333 !important;
		color: white!important;
	}
	
	.headerBox {
		width: 0.375rem;
		height: 1.25rem;
		background-color: #EE4454;
	}
	
	.headerTitle {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-left: 1.125rem;
	}
</style>
