<template>
	<div class='login-right'>
		<div class='login-img'>
			<img style='width: 3.125rem;height: 3.125rem;margin: 0.3125rem;cursor: pointer;'
				src="../../assets/images/erweima.png" alt="">
		</div>
		<div class='login-title' v-if='loginData.type==1'>
			<div class='left'>
				密码登录
			</div>
			<div class='right' @click='loginData.type = 2'>
				验证码登录
			</div>
		</div>

		<div class='login-title' v-else>
			<div class='left'>
				短信登录
			</div>
			<div class='right' @click='loginData.type = 1'>
				密码登录
			</div>
		</div>

		<img v-if='errno' style='width: 21.25rem;height: 1.875rem;margin-top: 0.4375rem;margin-bottom: -0.4375rem;'
			src="../../assets/images/password-error.png" alt="">

		<div v-if='loginData.type == 1'>
			<div style='margin-top: 1.375rem;border: 1px solid #DDDDDD;'>
				<el-input v-model="loginData.username" placeholder="用户名/手机号/邮箱" prefix-icon='el-icon-user'></el-input>
			</div>
			<div style='margin-top: 1.875rem;border: 1px solid #DDDDDD;'>
				<el-input v-model="loginData.password" placeholder="密码" prefix-icon='el-icon-lock'></el-input>
			</div>
		</div>

		<div v-else>
			<div style='margin-top: 1.375rem;border: 1px solid #DDDDDD;'>
				<el-input v-model="loginData.username" placeholder="手机号" prefix-icon='el-icon-user'></el-input>
			</div>
			<div class='Login-code' style=''>
				<el-input style='width: 200px;' v-model="code" placeholder="验证码" prefix-icon='el-icon-lock'></el-input>
				<div class='getCodeText' @click="ck_getCode">{{codeText}}</div>
			</div>
		</div>


		<div class='register'>
			<div @click='ck_registe({num: 0})'>免费注册</div>
			<div v-if='loginData.type==1' @click='ck_goPage("/FindPassword")'>忘记密码</div>
		</div>

		<div class='btn DIS-CENTER' @click='ck_login'>
			登录
		</div>

		<div class='thirdparty'>
			<div>
				第三方登录
			</div>
			<img style='width: 1.875rem;height: 1.875rem;margin-left: 1.3125rem;' src="../../assets/images/login-WX.png"
				alt="">
			<img style='width: 1.875rem;height: 1.875rem;margin: 0 1.4375rem;' src="../../assets/images/login-WB.png"
				alt="">
			<img style='width: 1.875rem;height: 1.875rem;' src="../../assets/images/login-QQ.png" alt="">
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';

	export default {
		data() {
			return {
				loginData: {
					type: 1,
					username: '',
					password: '',
				},

				errno: false,
				phone: '',
				code: '',
				codeText: '获取验证码'
			}
		},
		methods: {
			// 获取验证码
			ck_getCode() {
				const telStr =
					/^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/;
				if (!(telStr.test(this.loginData.username))) {
					this.$message.warning('请输入正确的手机号')
					return
				}
				if (this.codeText == '获取验证码') {
					const data = {
						phone: this.loginData.username
					}
					this.axios.post(Api.sendPhoneCode, data).then(res => {
						if (res.data.code == 200) {
							this.$message.success('验证码已发送')
						} else {
							this.$message.success('验证码发送失败')
						}
					})
					this.codeText = 60;
					this.timer = setInterval(() => {
						this.codeText = this.codeText - 1;
						if (this.codeText < 1) {
							window.clearInterval(this.timer);
							this.codeText = '获取验证码'
						}
					}, 1000)
				}
			},
			ck_registe(num) {
				this.$store.commit('nextStep', num)
				this.$router.push({
					path: '/RegistePhoner'
				})
				this.$store.commit('editMailboxActive', {
					isActive: true
				})
			},

			ck_goPage(path) {
				this.$router.push({
					path
				})
			},
			
			// 密码登录 验证码登录
			ck_login() { 
				if (this.loginData.username == '') {
					this.$message.warning('请输入用户名/手机号/邮箱')
					return
				}
				var data;

				if (this.loginData.type == 1) {
					if (this.loginData.password == '') {
						this.$message.warning('请输入密码')
						return
					}
					data = {
						type: 1,
						username: this.loginData.username,
						password: this.loginData.password
					}
				} else {
					data = {
						type: 2,
						phone: this.loginData.username,
						code: this.code
					}
				}
				this.axios.post(Api.login, data).then(res => {
					if (res.data.code == 200) {
						localStorage.setItem("shuying-token", res.data.data);
						this.getUserInfo(res.data.data);
						// this.ck_goPage('/');

					} else {
						this.errno = true;
					}
				})
			},
			// 获取用户信息
			getUserInfo(loginid) {
				const data = {
					loginid
				}
				this.axios.post(Api.getUserInfo, data).then(res => {
					if (res.data.code == 200) {
						localStorage.setItem('userInfo', JSON.stringify(res.data.data));
						this.$emit('signIn', true);   
						this.ck_goPage('/');
					} else {
						this.$message.error(res.data.msg)
					}
				})
			}
		}
	}
</script>

<style scoped>
	.login-right {
		background: #FFFFFF;
		width: 21.25rem;
		position: relative;
		padding: 0 2.5rem;
		padding-top: 2.7rem;
		padding-bottom: 2rem;
	}

	.login-right .login-img {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		position: absolute;
		top: 0;
		left: 0;
	}

	.login-title {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
	}

	.login-title .left {
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.login-title .right {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		cursor: pointer;
	}

	.login-right>>>.el-input__inner {
		background-color: #F5F5F5;
		border: none;
	}

	.register {
		width: 21.25rem;
		display: flex;
		justify-content: space-between;
		margin-top: 0.4375rem;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}

	.register div {
		cursor: pointer;
	}

	.btn {
		width: 340px;
		height: 50px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FFFFFF;
		cursor: pointer;
		margin-top: 2.25rem;
	}

	.thirdparty {
		display: flex;
		align-items: center;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-top: 2.1875rem;
	}

	.thirdparty img {
		cursor: pointer;
	}

	.Login-code {
		margin-top: 1.875rem;
		border: 1px solid #DDDDDD;
		background-color: #F5F5F5;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.getCodeText {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-right: 1rem;
		cursor: pointer;
	}
</style>
