<template>
	<div class='phoneFindPass'>
		<FindPasswordTitle text='手机验证' />
		<div class='itemBox' style='margin-top: 2rem;'>
			<div class='text1' style='margin-left: 16px;width: 3.5rem;'>手机号</div>
			<div style='margin-left: 20px;'>
				<el-input :placeholder="userInfo.userPhone" :disabled="true"></el-input>
			</div>
		</div>
		<div class='itemBox1 itemBox' style='margin-top: 1.625rem;'>
			<div style='display: flex;align-items: center;'>
				<div class='text1' style='margin-left: 16px;width: 3.5rem;'>验证码</div>
				<div style='margin-left: 20px;'>
					<el-input v-model="code" placeholder="请输入验证码"></el-input>
				</div>
			</div>
			<div class='text1 codeText DIS-CENTER' @click='ck_getCode'>
				{{codeText}}
			</div>
		</div>
		<div class='itemBox' style='margin-top: 1.625rem;'>
			<div class='text1' style='margin-left: 16px;width: 3.5rem;'>设置密码</div>
			<div style='margin-left: 20px;'>
				<el-input v-model="newPass" placeholder="请设置新的密码" show-password></el-input>
			</div>
		</div>
		<div class='itemBox' style='margin-top: 1.625rem;'>
			<div class='text1' style='margin-left: 16px;width: 3.5rem;'>重复密码</div>
			<div style='margin-left: 20px;'>
				<el-input v-model="newPass2" placeholder="请再次确认密码" show-password></el-input>
			</div>
		</div>
		<div class='btn DIS-CENTER' @click='ck_findPass'>
			提交
		</div>
		<!-- <div class='btnBox'>
			<div @click='ck_Switch(1)'>邮箱验证码</div>
			<div @click='ck_Switch(2)'>密保验证</div>
		</div> -->
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	import FindPasswordTitle from './FindPasswordTitle.vue';

	export default {
		components: {
			FindPasswordTitle,
		},
		data() {
			return {
				code: '',
				newPass: '',
				newPass2: '',
				codeText: '获取验证码',
				timer: null,
				userInfo:{},
			}
		},
		mounted() {
			this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
		},
		methods: {
			ck_getCode() {
				if(this.codeText == '获取验证码') {
					const data = {
						phone: this.userInfo.userPhone
					}
					this.axios.post(Api.sendPhoneCode,data).then(res=>{
						if(res.data.code == 200) {
							this.$message.success('验证码已发送')
						} else {
							this.$message.success('验证码发送失败')
						}
					})
					this.codeText = 60;
					this.timer = setInterval(()=>{
						this.codeText = this.codeText - 1;
						if(this.codeText < 1) {
							window.clearInterval(this.timer);
							this.codeText = '获取验证码'
						}
					},1000)
				}
			},
			ck_Switch(value) {
				this.$emit('ckSwitch',value)
			},
			// 重置密码
			ck_findPass() {
				if(!(/^\d{4}$/.test(this.code))) {
					this.$message.warning('输入的验证码有误')
					return
				}
				if(this.newPass.length<6) {
					this.$message.warning('密码不能小于6位数')
					return
				}
				if(this.newPass != this.newPass2) {
					this.$message.warning('二次输入的新密码不一致，请重新输入')
					return
				}
				
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					phone: this.userInfo.userPhone,
					code: this.code,
					newPass: this.newPass,
				}
				this.axios.post(Api.resetPwd,data).then(res=>{
					if(res.data.code == 200) {
						this.$message.success('重置密码成功，请重新登录');
						this.$router.push({path:"/Login"})
					} else {
						this.$message.error(res.data.msg);
					}
				})
			}
		}
	}
</script>

<style scoped>
	.phoneFindPass {
		width: 21.25rem;
	}

	.itemBox {
		width: 21.25rem;
		height: 2.75rem;
		border: 1px solid #DDDDDD;
		display: flex;
		align-items: center;
	}
	
	.itemBox1 {
		justify-content: space-between;
	}

	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.itemBox>>>.el-input__inner {
		border: none;
		height: 2.75rem;
		width: 15.5rem;
	}
	
	.itemBox1>>>.el-input__inner {
		border: none;
		height: 2.75rem;
		width: 7rem;
	}
	
	.codeText {
		margin-left: 16px;
		width: 4.375rem;
		cursor: pointer;
		margin-right: 0.875rem;
	}
	
	.btn {
		width: 340px;
		height: 50px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
		margin-top: 1.9375rem;
	}
	
	.btnBox {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
		margin-top: 1.25rem;
	}
	
	.btnBox div {
		cursor: pointer;
	}
</style>
