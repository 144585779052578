<template>
	<div>
		<div 
			:class="index == homeClassify.length - 1 ? 'classify2 classify' : 'classify1 classify'"
			v-for='(item,index) in homeClassify'
			:key='index'
		>
			<div class='title'>
				{{item.title}}
			</div>
			<div>
				<div
					class="text"
					v-for='(item1,index1) in item.text'
					:key='index1'
				>
					<div 
						class='word'
						v-show='index2<2'
						v-for='(item2,index2) in item1.list'
						:key='index2'
						@click='ck_goPage("/AccurateSearch",item2.catId)'
					>
						<div
							class='slash'
							v-if='index2 != 0'
						>
							/
						</div>
						{{ item2.catName }}
					</div>
				</div>
			</div>
		</div>
		
		<div class='advertising'>
			<img 
				style='width: 100%;cursor: pointer;' 
				@click='ck_goPage("/Advertising")'
				:src="advertising.adFile" 
				alt=""
			>
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	
	export default {
		props: ['homeClassify'],
		data() {
			return {
				advertising: '',
			}
		},
		created() {
			this.axios.post(Api.getAdImg,{adPositionId: 291}).then(res=>{
				if(res.data.code == 200) {
					this.advertising = res.data.data[0];
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		methods: {
			ck_goPage(path,value) {
				this.$router.push({path,query:{catId:value}})
			}
		}
	}
</script>

<style scoped>
	.classify1 {
		width: 12.5rem;
		margin-bottom: 1.125rem;
	}
	
	.classify2 {
		width: 12.5rem;
	}
	
	.classify .title {
		width: 10.9375rem;
		height: 2.5rem;
		background: #EE4454;
		font-size: 1rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		padding-left: 1.5625rem;
	}
	
	.classify .text {
		display: flex;
		align-items: center;
		width: 10.8125rem;
		height: 2.5rem;
		border: 0.0625rem solid #E6E6E6;
		padding-left: 1.5625rem;
		font-size: 0.875rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
		border-top: none;
	}
	
	.classify .text .word {
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	
	.classify .text .word .slash {
		font-size: 0.625rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin: 0 0.25rem;
	}
	
	.classify .text .word:hover {
		color: #EE4454;
		font-size: 0.925rem
	}
	
	.advertising {
		width: 12.5rem;
		height: 17.5rem;
		background-color: #D5DCE7;
		margin-top: 1rem;
	}
</style>
