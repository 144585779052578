<template>
	<div class="EditMessageThird">
		<div class='text1'>
			邮件已送达 <span style='color: #EE4454;'>Zhang***@163.com{{setEmail}}</span>
		</div>
		<div class='text2' style='margin-top: 0.75rem;'>
			请您登录邮箱，点击验证链接完成修改，链接在24小时内有效。
		</div>
		<div class='btnBox'>
			<div class='btn DIS-CENTER'>请查收邮件</div>
			<div class='text2' style='color: #EE4454;cursor: pointer;'>没有收到邮件？</div>
		</div>
	</div>
</template>

<script>
	export default{
		props:['setEmail'],
	}
</script>

<style scoped>
	.EditMessageThird {
		
	}
	
	.text1 {
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.text2 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
	
	.btnBox {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 2.5rem;
	}
	
	.btn {
		width: 240px;
		height: 50px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
	}
</style>
