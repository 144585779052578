<template>
	<div>
		<div>
			<Header bgIndex='1' @dialogBoxIndex="dialogBoxIndex" />
		</div>

		<!-- 内容区 -->
		<div class='SY-CONTENT'>
			<div class='IR-content'>
				<!-- 点赞 评论 收藏 分享-->
				<HRContent :goods_info='goods_info' :author_info='author_info' :detail='detail' :comment='comment' :is_zan='is_zan' :is_collect='is_collect' @setInitData='setInitData' />
				<!-- 关注取关功能 相似推荐  推荐人信息 -->
				<HRRight :author_id='author_id' :author_info='author_info' @refreshAuthorArticleDetail='refreshAuthorArticleDetail' />
			</div>
		</div>

		<!-- 页尾文字 -->
		<div class='SY-CONTENT' style='margin-top: 8.5rem;background-color: #FAFAFA;'>
			<HomeText />
		</div>

		<!-- 页脚 -->
		<div class='SY-CONTENT' style='background-color: #333333;'>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Header from '../components/Header.vue';
	import HomeText from '../components/Home/HomeText.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	import HRContent from '../components/IntelligentRecommend/HRContent.vue';
	import HRRight from '../components/IntelligentRecommend/HRRight.vue';
	import Api from '../request/api.js';
	export default {
		data() {
			return {
				goods_info:'',
				author_info:'',
				detail:'',
				is_zan:'',
				is_collect:'',
				comment:[],
				author_id:'',
				isShowDialogBox:false,
			}
		},
		components: {
			Header,
			HomeText,
			HomeFooter,
			HRContent,
			HRRight
		},
		created() {
			this.initData()
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			setInitData(data){
				if(data){
					this.initData()
				}
			},
			refreshAuthorArticleDetail(data){
				if(data){
					this.initData()
				}
			},
			initData() {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					id: this.$route.query.id,
				}
				this.axios.post(Api.authorArticleDetail, data).then(res => {
					if (res.data.code == 200) {
						this.goods_info=res.data.data.goods_info
						this.author_info=res.data.data.author_info
						this.detail=res.data.data.detail
						this.is_zan=res.data.data.is_zan
						this.is_collect=res.data.data.is_collect
						this.comment=res.data.data.comment
						this.author_id=res.data.data.detail.author_id
					} else {
						this.$message.error(res.data.msg)
					}
				});
			}
		}
	}
</script>

<style scoped>
	.IR-content {
		margin-top: 2.4375rem;
		display: flex;
		/* justify-content: space-between; */
		/* width: 64.0625rem; */
		padding: 0 2.1875rem 0 8.125rem;
		justify-content: center;
	}
</style>
