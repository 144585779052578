<template>
	<div class='PersonalRank'>
		<div 
			class='item' v-for='(item,index) in intelligentList' :key='index'
			@click='ck_goPage("/RefereesDetails")'>
			<div style='display: flex;align-items: center'>
				<div>
					<img v-if='index == 0' class='img1' src="../../assets/images/Rank-01.png" alt="">
					<img v-if='index == 1' class='img1' src="../../assets/images/Rank-02.png" alt="">
					<img v-if='index == 2' class='img1' src="../../assets/images/Rank-03.png" alt="">
					<div v-if='index > 2' class='indexNum DIS-CENTER'>
						{{index + 1}}
					</div>
				</div>
				<img class='img2' src="../../assets/images/headicon.png" alt="">
				<div style='margin-left: 1.25rem;'>
					<div class='text1'>{{item.loginName}}</div>
					<div class='text2' style='margin-top: 0.4375rem;'>推荐成交2180笔</div>
				</div>
			</div>
			<div style='display: flex;align-items: center'>
				<div class='text2' style='color: #333333;' v-if="!item.articles">
					TA的推荐：
				</div>
				<div v-for='(item1,index1) in item.articles' :key='index1'>
					<img class='img3' src="../../assets/images/homethirddemo.png" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js'
	export default {
		data(){
			return{
				intelligentList:[],
			}
		},
		created() {
			this.initData()
		},
		methods: {
			ck_goPage(path) {
				this.$router.push({path});
			},
			initData(){
				const data = {
					loginid: localStorage.getItem('shuying-token'),
				}
				this.axios.post(Api.authorRank, data).then(res => {
					if (res.data.code == 200) {
						this.intelligentList=res.data.data
					} else {
						this.$message.error(res.data.msg)
					}
				})
			}
		}
	}
</script>

<style scoped>
	.PersonalRank {
		width: 75rem;
	}
	
	.item {
		width: 75rem;
		height: 8.4375rem;
		border-bottom: 1px solid #E6E6E6;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
	}
	
	.img1 {
		width: 2.75rem;
		height: 2.75rem;
		margin-left: 1.0625rem;
	}
	
	.img2 {
		width: 3.125rem;
		height: 3.125rem;
		margin-left: 2.75rem;
	}

	.text1 {
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.text2 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
	
	.img3 {
		width: 80px;
		height: 80px;
		border: 1px solid #E6E6E6;
		margin-left: 1.875rem;
	}
	
	.indexNum {
		width: 2.75rem;
		height: 2.75rem;
		border-radius: 50%;
		background: #E6E6E6;
		margin-left: 1rem;
	}
</style>
