<template>
  <div>
    <!-- 注册头部 -->
    <LoginHeader passWordType="0" />

    <!-- 内容区 -->
    <div
      class="SY-CONTENT"
      style="background-color: #ee4454; height: 47.5rem; margin-bottom: -135px"
    >
      <div class="login-content">
        <!-- 左边图片 -->
        <img
          style="width: 42.5rem; height: 39.375rem; margin-top: 7.875rem"
          src="../../assets/images/login-img.png"
          alt=""
        />
        <!-- 右边登入 -->
        <div style="margin-top: 10.625rem">
          <LoginContent @signIn="signIn" />
        </div>
      </div>
    </div>

    <!-- 页尾文字 -->
    <div
      class="SY-CONTENT"
      style="margin-top: 8.5rem; background-color: #fafafa"
    >
      <HomeText />
    </div>

    <!-- 页脚 -->
    <div class="SY-CONTENT" style="background-color: #333333">
      <HomeFooter />
    </div>
  </div>
</template>

<script>
import LoginHeader from "../../components/LoginHeader.vue";
import HomeText from "../../components/Home/HomeText.vue";
import HomeFooter from "../../components/Home/HomeFooter.vue";
import LoginContent from "../../components/login/LoginContent.vue";

export default {
  inject: ["reload"],
  components: { LoginHeader, HomeText, HomeFooter, LoginContent },
  methods: {
    signIn(data) {
      if (data) {
		this.reload()
      }
    },
  },
};
</script>

<style scoped>
.login-content {
  width: 75rem;
  display: flex;
  justify-content: space-between;
  height: 100%;
}
</style>
