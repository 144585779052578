<template>
  <div>
    <div class="PublishTwWrap">
      <div class="PublishTwWrap_leftBox">
        <div class="PublishTwWrap_leftBox_content">
          <div class="leftBox_content_title">
            <el-input
              type="text"
              placeholder="请输入文章标题(5~30字)"
              v-model="title"
              class="leftBox_content_titlepC"
            ></el-input>
          </div>

          <div class="leftBox_content_textarea">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入正文..."
              v-model="textarea"
              resize="none"
            >
            </el-input>
          </div>

          <div class="leftBox_content_menu">
            <div class="leftBox_content_menuAdd">
              <div class="leftBox_content_menuAddText1">*</div>
              <div class="leftBox_content_menuAddText2">添加图片</div>
              <div class="leftBox_content_menuAddText3">
                (最多5张，默认第一张为封面，建议图片比例 3:4 ，支持jpg、png格式)
              </div>
            </div>
            <div style="display: flex">
              <!-- 图片组 -->
              <div v-for="(item, index) in imageList" :key="index">
                <img
                  :src="item"
                  style="
                    width: 180px;
                    height: 180px;
                    cursor: pointer;
                    margin-right: 20px;
                  "
                />
                <div
                  class="leftBox_content_menu_bgClose"
                  @click="ck_delImage(index)"
                ></div>
              </div>

              <div style="position: relative">
                <img
                  src="../../../assets/images/sczj.png"
                  style="width: 180px; height: 180px; cursor: pointer"
                />
                <label
                  class="upload DIS-CENTER"
                  for="upload"
                  accept="image/png,image/jpeg"
                >
                  添加图片
                </label>
                <input
                  id="upload"
                  type="file"
                  style="display: none"
                  @change="ce_upload"
                />
              </div>
            </div>

            <div style="display: flex; flex-direction: column">
              <div class="leftBox_content_menuAdd" style="padding-top: 34px">
                <div class="leftBox_content_menuAddText1">*</div>
                <div class="leftBox_content_menuAddText2">添加商品</div>
              </div>

              <div>
                <el-select
                  v-el-select-loadmore="handleLoadMore"
                  v-model="optionsVal"
                  placeholder="请选择"
                  filterable
                  :filter-method="dataFilter"
                  style="width: 349px"
                  :popper-append-to-body="false"
                >
                  <el-option
                    v-for="item in shopList"
                    :key="item.goodsId"
                    :label="item.goodsName"
                    :value="item.goodsId"
                    id="PublishTwWrap_select"
                    style="width: 353px"
                  >
                    <div class="selectBg">
                      <div class="selectBg_title">{{ item.goodsName }}</div>
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          margin-top: 6px;
                        "
                      >
                        <div style="display: flex; align-items: center">
                          <img
                            style="
                              width: 16px;
                              height: 16px;
                              border-radius: 16px;
                            "
                            :src="item.shopInfo.shopImg"
                          />
                          <div class="selectBg_contentLeft_text">
                            {{ item.shopInfo.shopName }}
                          </div>
                        </div>

                        <div class="selectBg_contentRight">
                          <div
                            style="
                              font-size: 10px !important;
                              font-family: PingFangSC-Semibold, PingFang SC;
                              font-weight: 600;
                              color: #ee4454;
                              display: flex;
                              align-items: flex-end;
                            "
                          >
                            ￥
                          </div>
                          <div
                            style="
                              font-size: 18px;
                              font-weight: 600;
                              color: #ee4454;
                              font-family: PingFangSC-Semibold, PingFang SC;
                            "
                          >
                            {{ item.shopPrice }}
                          </div>
                          <div
                            style="
                              margin-left: 5px;
                              font-size: 10px !important;
                              font-family: PingFangSC-Regular, PingFang SC;
                              font-weight: 400;
                              color: #9a9a9a;
                            "
                          >
                            起
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-option>
                </el-select>
              </div>
              <!-- <div style="height: 100px;" v-if="isSelectHeight"></div> -->
              <div style="display: flex">
                <div
                  class="leftBox_content_menuBtn leftBox_content_menuBtn1"
                  v-for="(item, indexs) in btnList"
                  :key="indexs"
                  @click="ck_goPage(item.text)"
                >
                  {{ item.text }}
                </div>
                <div
                  v-if="isTimeInterval"
                  class="leftBox_content_menuBtn leftBox_content_menuBtn1"
                  @click="cancelPublish"
                >
                  取消发布
                </div>
                <div
                  v-if="isTimeInterval"
                  style="margin-right: 20px; margin-top: 45px"
                  class="pickerBox"
                >
                  <el-date-picker
                    v-model="timeInterval"
                    type="date"
                    placeholder=""
                  ></el-date-picker>
                </div>
                <div
                  class="leftBox_content_menuBtn leftBox_content_menuBtn2"
                  @click="ck_publish"
                >
                  发布
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="PublishTwWrap_rightBox">
        <PublishValueHelper />
      </div>
    </div>

    <div class="previewWrap">
      <el-dialog :visible.sync="isPreview" width="50%">
        <div style="display: flex; flex-direction: column">
          <div class="previewWrap_title">{{ title }}</div>
          <div class="previewWrap_time">发布于 {{ DateTrans(tmp) }}</div>
        </div>
        <div class="previewWrap_banner">
          <img
            class="previewWrap_banner_arrow"
            src="../../../assets/images/IR-left.png"
            alt=""
          />
          <img class="previewWrap_banner_product" :src="coverImg" alt="" />
          <img
            class="previewWrap_banner_arrow"
            src="../../../assets/images/IR-right.png"
            alt=""
          />
        </div>
        <div class="previewWrap_bannerList-box">
          <div class="previewWrap_bannerList">
            <img
              v-for="(item, index) in imageList"
              :key="index"
              :class="index == 0 ? 'previewWrap_img1' : 'previewWrap_img2'"
              :src="item"
            />
          </div>
        </div>
        <div class="previewWrap_content">
          <div class="previewWrap_content_item" style="white-space: pre-wrap">
            {{ textarea }}
          </div>
          <div
            class="previewWrap_dialog-footer"
            style="display: flex; justify-content: flex-end"
          >
            <el-button @click="isPreview = false">取消</el-button>
            <el-button type="primary" @click="isPreview = false"
              >确认发布</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Api from "../../../request/api.js";
// import addImg from '../../../assets/images/sczj.png';
import PublishValueHelper from "./PublishValueHelper.vue";
import leftImg from "../../../assets/images/IR-left.png";
import rightImg from "../../../assets/images/IR-right.png";
import { dateTrans } from "../../../utils/util.js";
export default {
  data() {
    return {
      DateTrans: dateTrans,
      title: "",
      textarea: "",
      // addImg: addImg,
      leftImg: leftImg,
      rightImg: rightImg,
      // cities: [{
      // 		value: '1',
      // 		label: '瑜伽之父传承人经典力作，瑜伽不断适应现代人的练习法则…',
      // 		title: '四叶草儿书店',
      // 		price: '59',
      // 	}, {
      // 		value: '2',
      // 		label: '小明',
      // 		title: '四叶草儿书店',
      // 		price: '59',
      // 	},
      // 	{
      // 		value: '3',
      // 		label: '小红瑜伽不断适应现代人的练习法则…',
      // 		title: '四叶草儿书店',
      // 		price: '59',
      // 	}, {
      // 		value: '4',
      // 		label: '小白瑜伽不断适应现代人的练习法则…',
      // 		title: '四叶草儿书店',
      // 		price: '59',
      // 	},
      // 	{
      // 		value: '5',
      // 		label: '小张瑜伽不断适应现代人的练习法则…',
      // 		title: '四叶草儿书店',
      // 		price: '59',
      // 	}, {
      // 		value: '6',
      // 		label: '小王瑜伽不断适应现代人的练习法则…',
      // 		title: '四叶草儿书店',
      // 		price: '59',
      // 	}
      // ],

      // citiesNew: [{
      // 		value: '1',
      // 		label: '瑜伽之父传承人经典力作，瑜伽不断适应现代人的练习法则…',
      // 		title: '四叶草儿书店',
      // 		price: '59',
      // 	}, {
      // 		value: '2',
      // 		label: '小明',
      // 		title: '四叶草儿书店',
      // 		price: '59',
      // 	},
      // 	{
      // 		value: '3',
      // 		label: '小红瑜伽不断适应现代人的练习法则…',
      // 		title: '四叶草儿书店',
      // 		price: '59',
      // 	}, {
      // 		value: '4',
      // 		label: '小白瑜伽不断适应现代人的练习法则…',
      // 		title: '四叶草儿书店',
      // 		price: '59',
      // 	},
      // 	{
      // 		value: '5',
      // 		label: '小张瑜伽不断适应现代人的练习法则…',
      // 		title: '四叶草儿书店',
      // 		price: '59',
      // 	}, {
      // 		value: '6',
      // 		label: '小王瑜伽不断适应现代人的练习法则…',
      // 		title: '四叶草儿书店',
      // 		price: '59',
      // 	}
      // ],
      optionsVal: "",
      isSelectHeight: false,
      btnList: [
        {
          text: "预览",
        },
        // {
        // 	text: '存草稿',
        // },
        // {
        // 	text: '定时发布',
        // }
      ],
      timeInterval: "",
      isTimeInterval: false,
      isPreview: false,
      tmp: "",
      coverImg: "",
      imageList: [],
      shopList: [],
      formData: {
        pageIndex: 1,
        pageSize: 20,
      },
    };
  },
  components: {
    PublishValueHelper,
  },
  mounted() {
    this.getShopData(this.formData.pageIndex);
  },
  directives: {
    "el-select-loadmore": {
      bind(el, binding) {
        // 获取element-ui定义好的scroll盒子
        const SELECTWRAP_DOM = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
        );
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          const condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight + 20;
          if (condition) {
            binding.value();
            //调用handleLoadMore
          }
        });
      },
    },
  },
  methods: {
    ck_delImage(index) {
      this.imageList.splice(index, 1);
    },
    ck_goPage(text) {
      if (text == "定时发布") {
        this.isTimeInterval = true;
        this.btnList = [
          {
            text: "预览",
          },
          {
            text: "存草稿",
          },
        ];
      }

      if (text == "预览") {
        if (this.title == "" || this.textarea == "" || this.coverImg == "") {
          this.$message.error("不能为空");
          return;
        }
        var tmp = Date.parse(new Date()).toString();
        this.tmp = tmp.substr(0, 10);
        this.isPreview = true;
      }
    },
    cancelPublish() {
      this.isTimeInterval = false;
      this.btnList = [
        {
          text: "预览",
        },
        // {
        // 	text: '存草稿',
        // },
        // {
        // 	text: '定时发布',
        // }
      ];
    },
    ck_publish() {
      // 判断this.isTimeInterval是否为true   this.isTimeInterval=true才有定时发布
      // this.timeInterval  定时发布时间

      const data = {
        loginid: localStorage.getItem("shuying-token"),
        type: 0, //文章
        title: this.audioTitle,
        goods_id: this.optionsVal,
        cover_image: this.coverUrl,
        audio: this.audioUrl, // 上传音频地址
        content: this.textarea,
      };

      this.axios.post(Api.uploadArticle, data).then((res) => {
        if (res.data.code == 200) {
          this.$emit("publist", 1);
          this.$emit("publistTitle", this.title);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    handleLoadMore() {
      this.formData.pageIndex++;
      this.getShopData(this.formData);
    },
    getShopData(v) {
      const data = {
        loginid: localStorage.getItem("shuying-token"),
        page: v.pageIndex,
      };
      this.axios.post(Api.bestGoodsRecommend, data).then((res) => {
        if (res.data.code == 200) {
          for (var i in res.data.data) {
            this.shopList.push(res.data.data[i]);
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // select 自定义搜索
    dataFilter(val) {
      this.value = val;
      if (val) {
        //val存在
        this.cities = this.shopList.filter((item) => {
          if (
            !!~item.label.indexOf(val) ||
            !!~item.label.toUpperCase().indexOf(val.toUpperCase())
          ) {
            return true;
          }
        });
      } else {
        //val为空时，还原数组
        this.cities = this.citiesNew;
      }
    },

    // 上传图片
    ce_upload(value) {
      if (this.imageList.length == 5) {
        this.$message.error("最多可以添加5张");
        return;
      }
      const file = value.srcElement.files[0];
      if (!file) {
        return;
      }
      let data = new FormData();
      data.append("file", file);
      this.axios.post(Api.uploadFile, data).then((res) => {
        if (res.data.code == 200) {
          // this.userHeader1 = res.data.data.file_path; // 传递给后台的数据
          this.coverImg = Api.URL + "/" + res.data.data.file_path;
          this.imageList.push(Api.URL + "/" + res.data.data.file_path);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style>
.leftBox_content_menu_bgClose {
  position: absolute;
  background: url(../../../assets/images/deleteImage.png) no-repeat;
  width: 20px;
  height: 20px;
  margin-top: -190px;
  margin-left: 170px;
}

.previewWrap .el-dialog__header {
  display: none;
}

.previewWrap .el-dialog__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 35px 197px 0 !important;
}

.previewWrap .el-dialog {
  margin-top: 5vh !important;
}

.previewWrap_title {
  font-size: 1.75rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  display: flex;
  flex-wrap: wrap;
  line-height: 34px;
}

.previewWrap_time {
  font-size: 0.875rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin-top: 0.5rem;
}

.previewWrap_banner {
  width: 33.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.875rem;
}

.previewWrap_banner_arrow {
  width: 3.125rem;
  height: 3.125rem;
  cursor: pointer;
}

.previewWrap_banner_product {
  width: 22.5rem;
  height: 30rem;
  border: 1px solid #e6e6e6;
}

.previewWrap_bannerList-box {
  width: 33.25rem;
  display: flex;
  justify-content: center;
  margin-top: 1.3125rem;
}

.previewWrap_bannerList {
  width: 22.5rem;
}

.previewWrap_img1 {
  width: 3.75rem;
  height: 3.75rem;
  cursor: pointer;
}

.previewWrap_img2 {
  width: 3.75rem;
  height: 3.75rem;
  margin-left: 10px;
  cursor: pointer;
}

.previewWrap_content {
  margin-top: 1.1875rem;
  padding-bottom: 0.8125rem;
}

.previewWrap_content_item {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.previewWrap_dialog-footer .el-button {
  padding: 6px 14px !important;
  line-height: inherit !important;
  font-size: 13px !important;
  margin-top: 50px;
  margin-bottom: 53px;
}

.previewWrap_dialog-footer .el-button--primary {
  background-color: #ee4454 !important;
  font-family: PingFangSC-Regular, PingFang SC !important;
  font-weight: 400 !important;
  color: #ffffff !important;
  border: none;
}

.PublishTwWrap {
  width: 100%;
  display: flex;
}

.PublishTwWrap .PublishTwWrap_leftBox {
  background-color: #ffffff;
  margin-right: 38px;
  width: 80%;
}

.PublishTwWrap_leftBox_content {
  padding-left: 44px;
  padding-right: 42px;
}

.leftBox_content_title {
  padding-top: 39px;
  padding-bottom: 26px;
  border-bottom: 1px solid #e6e6e6;
}

.leftBox_content_titlepC {
  font-size: 24px !important;
  font-family: PingFangSC-Semibold, PingFang SC !important;
  font-weight: 600 !important;
  color: #333333 !important;
}

.leftBox_content_title .el-input__inner {
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: inherit;
  color: #333333 !important;
  line-height: 0 !important;
  font-weight: 600 !important;
}

.leftBox_content_title input::-webkit-input-placeholder {
  color: #333333;
  font-size: 24px !important;
  font-family: PingFangSC-Semibold, PingFang SC !important;
  font-weight: 600 !important;
}

.leftBox_content_title input::-moz-input-placeholder {
  color: #333333;
  font-size: 24px !important;
  font-family: PingFangSC-Semibold, PingFang SC !important;
  font-weight: 600 !important;
}

.leftBox_content_title input::-ms-input-placeholder {
  color: #333333;
  font-size: 24px !important;
  font-family: PingFangSC-Semibold, PingFang SC !important;
  font-weight: 600 !important;
}

.leftBox_content_textarea {
  height: 369px;
  border-bottom: 1px solid #e6e6e6;
  padding-top: 22px;
}

.leftBox_content_textarea .el-textarea__inner {
  padding: 0;
  border: none;
  height: 300px;
}

.leftBox_content_textarea textarea::-webkit-input-placeholder {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

.leftBox_content_textarea textarea::-moz-input-placeholder {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

.leftBox_content_textarea textarea::-ms-input-placeholder {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

.leftBox_content_menu {
  padding-bottom: 67px;
}

.leftBox_content_menuAdd {
  display: flex;
  padding-top: 25px;
  padding-bottom: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
}

.leftBox_content_menuAddText1 {
  color: #ff3a3a;
}

.leftBox_content_menuAddText2 {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}

.leftBox_content_menuAddText3 {
  font-size: 12px;
  color: #999999;
  align-items: center;
  display: flex;
}

.leftBox_content_menuBtn {
  padding-top: 6px;
  padding-bottom: 6px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  border-radius: 2px;
  cursor: pointer;
}

.leftBox_content_menuBtn1 {
  border: 1px solid #e6e6e6;
  margin-right: 10px;
  color: #333333;
}

.leftBox_content_menuBtn2 {
  color: #ffffff;
  background-color: #ee4454;
}

.leftBox_content_menu .upload {
  width: 180px;
  height: 180px;
  position: absolute;
  top: 0;
  left: 0;
  background: #000000;
  opacity: 0;
  cursor: pointer;
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}

.leftBox_content_menu .upload:hover {
  opacity: 0.5;
}

.PublishTwWrap .PublishTwWrap_rightBox {
  background-color: #ffffff;
  width: 20%;
  height: 300px;
}

/* 添加商品 样式 */
.selectBg {
  width: 300px !important;
  border-radius: 8px !important;
  border: 1px solid #f5f5f5 !important;
  padding: 13px 12px 13px 16px !important;
}

#PublishTwWrap_select {
  padding: 0 14px 10px !important;
  line-height: 20px;
  height: auto !important;
  display: flex !important;
  flex-wrap: wrap !important;
  white-space: inherit !important;
}

.selectBg_title {
  font-size: 14px !important;
  font-family: PingFangSC-Regular, PingFang SC !important;
  font-weight: 400 !important;
  color: #333333 !important;
}

.selectBg_contentLeft_text {
  margin-left: 6px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.selectBg_contentRight {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.pickerBox .el-input__inner {
  height: 31px !important;
  line-height: 31px !important;
}

.pickerBox .el-input__icon {
  line-height: 32px;
}
</style>
