<template>
	<!-- 首页 -->
	<div class="PublishIndexWrap">
		<div>
			<div class="PublishIndexWrap_title">粉丝概览</div>
			<!-- 组件 -->
			<div class="PublishIndexWrap_listBox">
				<div class="PublishIndexWrap_wrapBox" v-for="(item,index) in dataList" :key="index"
					:style="index=3?'margin-top:1.25rem;':''">
					<div class="PublishIndexWrap_itemBox">
						<div class="PublishIndexWrap_itemBox_title">{{item.title}}</div>
						<div class="PublishIndexWrap_itemBox_num">{{item.num}}</div>
						<div class="PublishIndexWrap_itemBox_dayContent">
							<div class="PublishIndexWrap_itemBox_dayContent_text">{{item.dayTitle}}</div>
							<div class="PublishIndexWrap_itemBox_dayContent_num">{{item.dayNum}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div style="margin-top: 3.25rem;">
			<div class="PublishIndexWrap_course_header">
				<div class="PublishIndexWrap_title">粉丝活跃度</div>
			</div>
			<div class="PublishIndexWrap_course" style="height: 15.125rem;">
				<!-- 内容 -->
				<div class="DIS-CENTER PublishIndexWrap_noneText">暂无数据</div>
			</div>

			<div class="PublishIndexWrap_course_header">
				<div class="PublishIndexWrap_title">关注趋势</div>
			</div>

			<div class="highchartsBox">
				<highcharts :options="chartOptions"></highcharts>
			</div>

		</div>


	</div>
</template>

<script>
	import Api from '../../request/api.js'
	export default {
		data() {
			return {
				dataList: [{
						title: '粉丝总数',
						num: '0',
						dayTitle: '近30日:',
						dayNum: 0,
					},
					{
						title: '活跃粉丝数',
						num: '0',
						dayTitle: '近30日:',
						dayNum: 0,
					},
					{
						title: '下单粉丝数',
						num: '0',
						dayTitle: '近30日:',
						dayNum: 0,
					},
				],
				courseList: [{
						img: '',
						text: '',
						headPhoto: '',
						forTitle: '',
					},
					{
						img: '',
						text: '',
						headPhoto: '',
						forTitle: '',
					},
					{
						img: '',
						text: '',
						headPhoto: '',
						forTitle: '',
					},
					{
						img: '',
						text: '',
						headPhoto: '',
						forTitle: '',
					}, {
						img: '',
						text: '',
						headPhoto: '',
						forTitle: '',
					},
				],
				chartOptions: {},

			}
		},
		mounted() {
			this.chartOptions = {
				chart: {
					type: 'line'
				},
				credits:{
					enabled:false
				},
				title: {
					text: null    // 标题
				},
				subtitle: {
					text: null   //小标题
				},
				xAxis: {
					categories: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
				},
				yAxis: {
					title: {
						text: null  // 左侧单位标题
					}
				},
				plotOptions: {
					line: {
						dataLabels: {
							// 开启数据标签
							enabled: false
						},
						// 关闭鼠标跟踪，对应的提示框、点击事件会失效
						enableMouseTracking: true
					}
				},
				series: [{
					name: '关注趋势',
					// data: [7.0, 6.9, 9.5, 14.5, 18.4, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6],
					data:[],
					showInLegend:false,  //隐藏底部的选择按钮
				}, 
				// {
				// 	name: '伦敦',
				// 	data: [3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0, 16.6, 14.2, 10.3, 6.6, 4.8],
				// 	showInLegend:false,
				// },
				]
			},
			
			this.initData()
		},
		methods:{
			initData(){
				const data = {
					loginid: localStorage.getItem('shuying-token'),
				}
				this.axios.post(Api.fansManage,data).then(res=>{
					if(res.data.code == 200) {
						this.dataList[0].num=res.data.data.fans_count
						this.dataList[0].dayNum=res.data.data.month_fans_count
						this.dataList[1].num=res.data.data.active_fans_count
						this.dataList[1].dayNum=res.data.data.month_active_fans_count
						this.dataList[2].num=res.data.data.place_order_count
						this.dataList[2].dayNum=res.data.data.month_place_order_count
					} else {
						this.$message.warning(res.data.msg);
					}
				})
			}
		}
	}
</script>

<style>
	.PublishIndexWrap {
		margin-top: 1.5rem;
	}

	.PublishIndexWrap_title {
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-left: 1.875rem;
	}

	/* 组件 */
	.PublishIndexWrap_listBox {
		margin: 1.1875rem 2.3125rem 0 1.9375rem;
		display: flex;
		flex-wrap: wrap;
	}

	.PublishIndexWrap_wrapBox {
		width: 31.25rem;
	}

	.PublishIndexWrap_itemBox {
		padding: 1.875rem 0 1.9375rem 2.125rem;
		background: #FBFBFB;
		display: flex;
		flex-direction: column;
		margin-right: 1.3125rem;
	}

	.PublishIndexWrap_itemBox_title {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-bottom: 0.5rem;
	}

	.PublishIndexWrap_itemBox_num {
		font-size: 35px;
		font-family: AlibabaPuHuiTiB;
		color: #333333;
		font-weight: 600;
		margin-bottom: 0.8125rem;
	}

	.PublishIndexWrap_itemBox_dayContent {
		display: flex;
	}

	.PublishIndexWrap_itemBox_dayContent_text {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.PublishIndexWrap_itemBox_dayContent_num {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
		margin-left: 0.9375rem;
	}

	/* 组件 */

	.PublishIndexWrap_course_header {
		display: flex;
		justify-content: space-between;
		margin-right: 2.1875rem;
		align-items: center;
	}

	.PublishIndexWrap_course {
		margin-top: 1.25rem;
	}

	.PublishIndexWrap_noneText {
		font-size: 24px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #C4C4C4;
		padding-top: 4.25rem;
	}

	.highchartsBox {
		margin: 30px 67px 60px 35px;
	}
</style>
