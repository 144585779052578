<template>
	<div>
		<div class='GoodsListItem1' @click='ck_goPage("/GoodsDetails",goodList.goodsId)'>
			<img 
				style='width: 11.875rem;height: 11.875rem;'
				:src="goodList.goodsImg" 
				alt=""
			>
			<div style='margin-left: 1.0625rem;display: flex;flex-direction: column;justify-content: space-around;'>
				<div class='titleBox'>
					{{goodList.goodsName}}
				</div>
				<div class='nameText'>
					{{goodList.goodsTips}}
				</div>
				<div class='priceText'>
					￥{{goodList.shopPrice}} <span style='font-size: 0.875rem;color: #999999;'>起</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['goodList'],
		methods: {
			ck_goPage(path,id) {
				this.$router.push({path,query:{id}})
			}
		}
	}
</script>

<style scoped>
	.GoodsListItem1 {
		display: flex;
		cursor: pointer;
	}
	
	.titleBox {
		width: 14.6875rem;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.nameText {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
	
	.priceText {
		font-size: 24px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
	}
</style>
