<template>
	<div class='PersonalSc'>
		<!-- 头部 -->
		<div class='SC-header'>
			<div class='DIS-CENTER'>
				<div 
					class='DIS-CENTER' 
					style='flex-direction: column;cursor: pointer;margin-right: 3.125rem;'
					v-for='(item,index) in menuList'
					:key='index'
					@click='ck_menu(index)'
				>
					<div 
						class='text1'
						:style="item.active? '': 'color: #333333'"
					>
						{{item.text}}
					</div>
					<div 
						class='headerLine'
						:style="item.active? '': 'background: #ffffff'"
					></div>
				</div>
			</div>
			<div class='DIS-CENTER' style='margin-right: 2.75rem;'>
				<div>
					<el-input v-model="input" placeholder="请输入收藏的商品或店铺名"></el-input>
				</div>
				<div class='headerSearch DIS-CENTER'>搜索</div>
			</div>
		</div>
	
		<!-- 内容 -->
		<!-- 商品收藏 -->
		<div v-if='showPage == 0'>
			<div
				v-if='goodsList.length > 0'
				style='display: flex;flex-wrap: wrap;align-items: center'
			>
				<div 
					class='contentItem'
					v-for='(item,index) in goodsList'
					:key='index'
				>
					<img 
						style='width: 7.375rem;height: 11.375rem;'
						src="../../assets/images/homethirddemo.png" alt=""
					>
					<div class='title' style='width: 12.3125rem;'>
						{{item.goodsName}}
					</div>
					<div class='contentBtnBox'>
						<div class='text2'>
							<span>￥</span>
							<span style='font-size: 1.25rem;'>{{item.shopPrice}}</span>
							<span class='text3' style='margin-left: 0.3125rem;'>起</span>
						</div>
						<div class='btn DIS-CENTER' @click='ck_followGoods(index)'>取消收藏</div>
					</div>
				</div>
			</div>
			<div
				class='noneItem'
				v-else
			>
				<img style='width: 26.25rem;height: 16.25rem;' src="../../assets/PersonalCenter/none1.png" alt="">
				<div class='noneText1' style='margin-top: 2.5rem;'>
					您的收藏为空~
				</div>
				<div class='noneText2' style='margin-top: 0.6875rem;'>
					去随便逛逛吧，看看有没有喜欢的
				</div>
			</div>
		</div>
		<!-- 店铺收藏 -->
		<div v-if='showPage == 1'>
			<div
				v-show='shopsList.length > 0'
				class='contentItem2'
				v-for='(item,index) in shopsList'
				:key='index'
			>
				<div style='display: flex;align-items: center'>
					<img
						style='width: 3.125rem;height: 3.125rem;'
						src="../../assets/images/headicon.png" alt=""
					>
					<div style='margin-left: 1.0625rem;'>
						<div 
							style='width: 5.625rem;overflow: hidden;'
							class='text5'
						>
							四叶草儿书店
						</div>
						<img 
							style='width: 0.75rem;height: 0.75rem;cursor: pointer;' 
							src="../../assets/PersonalCenter/contact-kf.png" alt=""
						>
					</div>
					<div style='margin-left: 2.25rem;'>
						<img 
							style='width: 5rem;height: 5rem;border: 1px solid #E6E6E6;'
							src="../../assets/images/homethirddemo.png" alt=""
						>
					</div>
				</div>
				<div style='display: flex;align-items: center;'>
					<div class='btn1 DIS-CENTER'>取消收藏</div>
				</div>
			</div>
			
			<div v-if='shopsList.length < 1'>
				<NoneItem title='您还没有收藏店铺呢~' text='赶紧去收藏自己心仪的店铺吧'/>
			</div>
			
			<!-- <div class='sorter'>
				<el-pagination
					background
					layout="pager"
					:total="1000">
				</el-pagination>
			</div> -->
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	
	import NoneItem from '../NoneItem.vue';
	
	export default {
		components: { NoneItem },
		data() {
			return {
				input: '',
				menuList: [
					{
						text: '商品收藏',
						active: true
					},
					{
						text: '店铺收藏',
						active: false
					},
				],
				showPage: 0,
				goodsList: [], //收藏的商品列表
				shopsList: [], //收藏的店铺列表
			}
		},
		created() {
			this.initData();
		},
		methods: {
			initData() {
				let type = 1;
				if(this.showPage == 1) {
					type = 2;
				}
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					type,
					pagesize: 2,
				}
				this.axios.post(Api.myCollect,data).then(res=>{
					if(res.data.code == 200) {
						if(this.showPage == 0) {
							this.goodsList = res.data.data;
							return 
						} 
						if(this.showPage == 1) {
							this.shopsList = res.data.data;
							return 
						} 
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			ck_menu(index) {
				for(let i in this.menuList) {
					this.menuList[i].active = false;
				}
				this.menuList[index].active = true;
				this.showPage = index;
				this.initData();
			},
			ck_followGoods(index) {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					id: this.goodsList[index].goodsId,
					goodsId: this.goodsList[index].goodsId,
					type: 2,
				}
				this.axios.post(Api.followGoods,data).then(res=>{
					if(res.data.code == 200) {
						this.$message.success(res.data.msg);
						this.initData();
					} else {
						this.$message.error(res.data.msg)
					}
				})
			}
		}
	}
</script>

<style scoped>
	.PersonalSc {
		width: 55.8125rem;
		min-height: 73.4375rem;
		background-color: #FFFFFF;
		padding-top: 2.125rem;
		padding-left: 2.625rem;
	}
	
	.SC-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	
	.text1 {
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #EE4454;
	}
	
	.headerLine {
		width: 28px;
		height: 4px;
		background: #EE4454;
		margin-top: 0.375rem;
	}
	
	.headerSearch {
		width: 60px;
		height: 28px;
		background: #EFEFEF;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
	}
	
	.PersonalSc>>>.el-input__inner{
		border-radius: 0;
		width: 194px;
		height: 28px;
		border: 1px solid #EEEEEE;
		font-size: 0.75rem
	} 
	
	.contentItem {
		padding: 1rem 0.9375rem 1.125rem;
		border: 1px solid #E6E6E6;
		margin-top: 2.5rem;
		margin-right: 1.25rem;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	
	.title {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.contentBtnBox {
		width: 12.3125rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 0.8125rem;
	}
	
	.btn {
		width: 70px;
		height: 30px;
		border-radius: 2px;
		border: 1px solid #E6E6E6;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
	}
	
	.text2 {
		font-size: 13px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.text3 {
		font-size: 11px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
	
	.noneText1 {
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.noneText2 {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
	
	.noneItem {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 3.75rem;
	}
	
	.contentItem2 {
		width: 54.75rem;
		border-bottom: 1px solid #E6E6E6;
		padding: 28px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.text5 {
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.btn1 {
		width: 70px;
		height: 28px;
		border: 1px solid #E6E6E6;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
		color: #333333;
	}
	
	.btn2 {
		width: 70px;
		height: 28px;
		border: 1px solid #E6E6E6;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
	}
	
	.sorter {
		display: flex;
		justify-content: flex-end;
		margin-top: 1.875rem;
	}
	
	.sorter>>>.el-pager li {
		background-color: transparent !important;
		color: #333333 !important;
		border: 1px solid #E6E6E6;
		width: 36px;
		height: 36px;
		line-height: 2.25rem;
	}
	
	.sorter>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color:  #333333 !important;
		color: white!important;
	}
</style>
