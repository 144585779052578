<template>
  <div>
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
import Api from "./request/api.js";
import { ws } from "./websocket.js";
export default {
  provide() {
    return {
      reload: this.reload,
      ws: ws,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  created() {
    if (localStorage.getItem("shuying-token")) {
      this.wsWebSocket();
    }
  },
  watch: {
    isRouterAlive(data) {
      if (!data) {
        location.reload();
      }
    },
  },
  methods: {
    getOderChatNum() {
      const data = {
        loginid: localStorage.getItem("shuying-token"),
      };
      this.axios.post(Api.getNotReadMsgNum, data).then((res) => {
        if (res.data.code == 200) {
          this.$store.commit("SetOrderNum", res.data.data.order_msg_num);
          this.$store.commit("SetChatNum", res.data.data.chat_msg_num);
          this.$store.commit(
            "SetAllNum",
            res.data.data.chat_msg_num + res.data.data.order_msg_num
          );
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    wsWebSocket() {
      // 连接
      ws.onopen = function (e) {
        console.log("WebSocket已连接!");
        let alwaysSend = {
          op_type: 101,
          uid: JSON.parse(localStorage.getItem("userInfo")).userId,
        };
        ws.send(JSON.stringify(alwaysSend));
      };

      // 接收消息
      var _this = this;
      ws.onmessage = function (evt) {
        let data = JSON.parse(evt.data);
        // console.log(data, "数据已接收...");
        _this.getOderChatNum();

        if (typeof data.content !== "undefined") {
          _this.$store.commit("SetMessages", data); // store存储 对话框最新收发数据
        } else {
          _this.$store.commit("SetUser", data.userinfo.userId); // store存储 接收成功存储一份用户数据
        }
      };

      // 关闭 websocket
      ws.onclose = function () {
        console.log("连接已关闭...");
      };
    },

    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },

    // 点击发送消息
    // handleSendBtnClick() {
    //   const msg = this.message;

    //   if (!msg.trim().length) {
    //     return;
    //   }

    //   ws.send(
    //     JSON.stringify({
    //       id: new Date().getTime(),
    //       user: this.username,
    //       dateTime: new Date().getTime(),
    //       msg: this.message,
    //     })
    //   );
    //   this.message = "";
    // },
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
}

.DIS-CENTER {
  display: flex;
  justify-content: center;
  align-items: center;
}

input::-webkit-input-placeholder {
  font-size: 0.75rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #bfbfbf;
}

.SY-CONTENT {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ziying {
  color: #ffffff;
  background-color: #ee4454;
  padding: 0 5px;
}

.ershou {
  background: rgb(207, 137, 25);
  color: #ffffff;
  padding: 0 5px;
}

.SY-DIALOGWRAP {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 25%;
  left: 30%;
  z-index: 9;
}

.isShowDialogShade {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 9;
}

.im-messageBox {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.im-messageBox-num {
  width: 18px;
  height: 18px;
  background: #ee4454;
  border-radius: 50%;
  font-size: 10px !important;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  position: absolute;
  top: -6px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

// 全局组件样式
</style>
