<template>
	<div>
		<div v-if='homeNewBooks' class='HomeSecond'>
			<HomeTitle 
				title='新书上架'
				text='更多新书'
				path='NewBook'
			/>
			
			<div style='display: flex;'>
				<div
					:class="index == 0 ? 'goodsList-second1 goodsList-second' : 'goodsList-second2 goodsList-second'"
					v-for='(item,index) in homeNewBooks'
					:key='index'
				>
					<img 
						style='width: 14rem;height: 14rem;'
						:src="item.goodsImg" 
						alt=""
					>
					<div class='title'>
						<span class='ziying' v-if='item.isSelfSale == 1'>自营</span>
						<span class='ershou' v-if='item.isSecondhandBook == 1'>二手</span>
						{{item.goodsName}}
					</div>
					<div class='introduce'>
						<div class='money-box'>
							<div>￥</div>
							<div style='font-size: 1.125rem;'>{{item.shopPrice}}</div>
							<div class='text'>起</div>
						</div>
						<div class='btn DIS-CENTER' @click='ck_goPage("/GoodsDetails",item.goodsId)'>立即购买</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import HomeTitle from '../components/Home-title.vue'
	export default {
		components: { HomeTitle },
		props: ['homeNewBooks'],
		methods: {
			ck_goPage(path,id) {
				this.$router.push({path,query:{id}})
			}
		}
	}
</script>

<style scoped>
	.HomeSecond {
		width: 75rem;
		cursor: pointer;
	}
	
	.HomeSecond .goodsList-second1 {
		margin-top: 1.25rem;
		width: 14rem;
		height: 20rem;
		border: 1px solid #E6E6E6;
		background: #FFFFFF;
	}
	
	.HomeSecond .goodsList-second2 {
		margin-top: 1.25rem;
		width: 14rem;
		height: 20rem;
		border: 1px solid #E6E6E6;
		background: #FFFFFF;
		margin-left: 1.25rem;
	}
	
	/* .HomeSecond .goodsList-second:hover .title {
		color: #EE4454;
	} */
	
	.HomeSecond .goodsList-second .title {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		padding: 0 0.5625rem;
		width: 12.875rem;
		height: 2.25rem;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	
	.HomeSecond .goodsList-second .money-box {
		display: flex;
		font-size: 0.8125rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		align-items: center;
	}
	
	.HomeSecond .goodsList-second .money-box .text {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-left: 0.1875rem;
	}
	
	.HomeSecond .goodsList-second .btn {
		width: 4.375rem;
		height: 1.875rem;
		background: #EE4454;
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
		margin-top: 0; 
	}
	
	.HomeSecond .goodsList-second:hover .btn {
		animation: pulse; 
		animation-duration: 1s;
	}
	
	.HomeSecond .goodsList-second .introduce {
		display: flex;
		justify-content: space-between;
		padding: 0 0.8125rem;
		margin-top: 0.75rem;
		align-items: center;
	}
</style>
