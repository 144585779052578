export function latelyDate(time) {
	if (!time) {
		return
	}
	let oldDate = new Date(time)
	let newDate = new Date()
	let dayNum = "";
	let getTime = (newDate.getTime() - oldDate.getTime()) / 1000;
	if (getTime < 60 * 5) {
		dayNum = "刚刚";
	} else if (getTime >= 60 * 5 && getTime < 60 * 60) {
		dayNum = parseInt(getTime / 60) + "分钟前";
	} else if (getTime >= 3600 && getTime < 3600 * 24) {
		dayNum = parseInt(getTime / 3600) + "小时前";
	} else if (getTime >= 3600 * 24 && getTime < 3600 * 24 * 30) {
		dayNum = parseInt(getTime / 3600 / 24) + "天前";
	} else if (getTime >= 3600 * 24 * 30 && getTime < 3600 * 24 * 30 * 12) {
		dayNum = parseInt(getTime / 3600 / 24 / 30) + "个月前";
	} else if (time >= 3600 * 24 * 30 * 12) {
		dayNum = parseInt(getTime / 3600 / 24 / 30 / 12) + "年前";
	}
	let year = oldDate.getFullYear();
	let month = oldDate.getMonth() + 1;
	let day = oldDate.getDate();
	let hour = oldDate.getHours();
	let minute = oldDate.getMinutes();
	let second = oldDate.getSeconds();
	if (dayNum) {
		return dayNum;
	} else {
		return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
	}
}

export function newDate(date) {
	// let _date = new Date(parseInt(date));//13位
	let _date = new Date(parseInt(date) * 1000); //10位
	let y = _date.getFullYear();
	let m = _date.getMonth() + 1;
	m = m < 10 ? ('0' + m) : m;
	let d = _date.getDate();
	d = d < 10 ? ('0' + d) : d;
	let h = _date.getHours();
	h = h < 10 ? ('0' + h) : h;
	let minute = _date.getMinutes();
	let second = _date.getSeconds();
	minute = minute < 10 ? ('0' + minute) : minute;
	second = second < 10 ? ('0' + second) : second;
	let dates = y + '-' + m + '-' + d + ' ' + '' + h + ':' + minute + ':' + second; //年月日时分秒
	return latelyDate(dates)
};