<template>
	<div class='ShopHome'>
		<div class='SY-CONTENT' style='background-color: #f2f2f2;'>
			<GoodsDetailsHeader @dialogBoxIndex="dialogBoxIndex" />
		</div>
		
		<div class='SY-CONTENT' style='margin-top: 1.25rem;'>
			<GoodsSearch />
		</div>
		
		<div class='SY-CONTENT'>
			<div class='zhaopai DIS-CENTER'>
				店铺招牌
			</div>
		</div>
		
		<div class='SY-CONTENT'>
			<div class='Details-menu'>
				<div class='text' v-for='(item,index) in menuList' :key='index'>
					{{item.catName}}
				</div>
			</div>
		</div>
		
		<!-- 内容区 -->
		<div class='SY-CONTENT'>
			<div style='display: flex;width: 75rem;margin-top: 1.125rem;'>
				<!-- 左侧 -->
				<div class='ConrentLeft'>
					<ContentRightBox :shopInfo='shopInfo' @getTalkeShopId="getTalkeShopId"/>
					<div 
						class='title'
						style='margin-top: 1.3125rem;border-bottom: 1px solid #EE4454;padding-bottom: 0.75rem;'
					>
						店铺公告
					</div>
					<div class='notice'>
						<div class='noticeItem'>
							{{shopInfo.shopNotice?shopInfo.shopNotice:'暂无公告'}}
						</div>
					</div>
					<div
						class='title'
						style='margin-top: 1.3125rem;border-bottom: 1px solid #EE4454;padding-bottom: 0.75rem;'
					>
						商品分类
					</div>
					<div class='leftMenu'>
						<div 
							class='leftMenuItem'
							:style='index==0?"":"border-top:1px solid #F5F5F5"'
							v-for='(item,index) in menuList'
							:key='index'
						>
							{{item.catName}}
						</div>
					</div>
				</div>
				<!-- 右侧 -->
				<div style='margin-left: 1.5rem;'>
					<div class='titleBox'>
						<div class='title1'>
							店主推荐
						</div>
						<div class='DIS-CENTER' style='cursor: pointer;'>
							<div class=text1>更多</div>
							<img 
								style='width: 0.875rem;height: 0.875rem;margin-left: 0.625rem;' 
								src="../assets/images/arrow_right.png" alt=""
							>
						</div>
					</div>
					
					<div style='width: 59.375rem;display: flex;flex-wrap: wrap;'>
						<div
							class='goodItem1'
							:style='index==0 || index%2 ==0 ? "":"margin-left: 40px"'
							v-for='(item,index) in shopInfo.recommend_goods' 
							:key='index'
						>
							<GoodListItem1 :goodList='item'/>
						</div>
					</div>
					
					<div class='titleBox' style='margin-top: 3.125rem;'>
						<div class='title1'>
							珍藏好书
						</div>
						<div class='DIS-CENTER' style='cursor: pointer;'>
							<div class=text1>更多</div>
							<img 
								style='width: 0.875rem;height: 0.875rem;margin-left: 0.625rem;' 
								src="../assets/images/arrow_right.png" alt=""
							>
						</div>
					</div>
					
					<div style='display: flex;flex-wrap: wrap;width: 59.375rem;'>
						<div
							class='goodItem2'
							:style='index==0 || index%6==0? "":"margin-left: 15px"'
							v-for='(item,index) in shopInfo.best_goods'
							:key='index'
						>
							<GoodsListFirst :goodsItem='item'/>
						</div>
					</div>
					
					<div class='titleBox' style='margin-top: 3.125rem;'>
						<div class='title1'>
							新书上市
						</div>
						<div class='DIS-CENTER' style='cursor: pointer;'>
							<div class=text1>更多</div>
							<img 
								style='width: 0.875rem;height: 0.875rem;margin-left: 0.625rem;' 
								src="../assets/images/arrow_right.png" alt=""
							>
						</div>
					</div>
					
					<div style='display: flex;flex-wrap: wrap;width: 59.375rem;'>
						<div
							class='goodItem2'
							:style='index==0 || index%6==0? "":"margin-left: 15px"'
							v-for='(item,index) in shopInfo.new_goods'
							:key='index'
						>
							<GoodsListFirst :goodsItem='item'/>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 页尾文字 -->
		<div 
			class='SY-CONTENT'
			style='margin-top: 8.5rem;background-color: #FAFAFA;'
		>
			<HomeText />
		</div>
		
		<!-- 页脚 -->
		<div 
			class='SY-CONTENT'
			style='background-color: #333333;'
		>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="getShopId"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Api from '../request/api.js';
	
	import GoodsDetailsHeader from '../components/GoodsDetailsHeader.vue';
	import GoodsSearch from '../components/GoodsSearch.vue';
	import HomeText from '../components/Home/HomeText.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	import ContentRightBox from '../components/goodDetails/ContentRightBox.vue';
	import GoodListItem1 from '../components/shopHome/GoodListItem1.vue';
	import GoodsListFirst from '../components/components/GoodsListFirst.vue';
	
	export default {
		components: {
			GoodsDetailsHeader,
			GoodsSearch,
			HomeText,
			HomeFooter,
			ContentRightBox,
			GoodListItem1,
			GoodsListFirst
		},
		data() {
			return {
				menuList: [],
				shopInfo: {}, // 店铺详情
				isShowDialogBox:false,
				getShopId:"",
			}
		},
		created() {
			this.initData();
		},
		methods: {
			initData() {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					shopId: this.$route.query.id,
				}
				this.axios.post(Api.shopIndex,data).then(res=>{
					if(res.data.code == 200) {
						this.menuList = res.data.data.shop_info.category;
						this.shopInfo = res.data.data.shop_info;
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			dialogBoxIndex(data){
				this.getShopId=false
				this.isShowDialogBox = data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			getTalkeShopId(data){
				if(data){
					this.getShopId=data
					this.isShowDialogBox=true
				}
			},
		}
	}
</script>

<style scoped>
	.zhaopai {
		width: 75rem;
		height: 5rem;
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		background: #E5F4F9;
		margin-top: 1.25rem;
	}
	
	.Details-menu {
		width: 73rem;
		padding-left: 1.875rem;
		height: 2.75rem;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		display: flex;
		align-items: center;
		background: #FAFAFA;
		border: 1px solid #E6E6E6;
	}
	
	.text {
		margin-right: 2.5rem;
		cursor: pointer;
	}
	
	.ConrentLeft {
		width: 14rem;
	}
	
	.title {
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.notice {
		width: 14rem;
		display: flex;
		justify-content: center;
		border: 1px solid #E6E6E6;
		margin-top: 1.25rem;
	}
	
	.noticeItem {
		width: 11.875rem;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		padding: 1.0625rem 0;
	}
	
	.leftMenu {
		margin-top: 1.125rem;
		width: 221px;
		background: #FFFFFF;
		border: 1px solid #E6E6E6;
	}
	
	.leftMenuItem {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		padding: 0.625rem 0 0.625rem 1.5rem;
		cursor: pointer;
	}
	
	.titleBox {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		border-bottom: 2px solid #EE4454;
		padding-bottom: 0.375rem;
		width: 59.375rem;
	}
	
	.title1 {
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.text1 {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
	
	.goodItem1 {
		margin-top: 2.1875rem;
	}
	
	.goodItem2 {
		margin-top: 2.1875rem;
	}
</style>
