<template>
	<div class='EmailFindPassword'>
		<div>2</div>
		<div v-if='step == 0'>
			<FindPasswordTitle text='邮箱验证' />
			<div class='itemBox' style='margin-top: 2rem;'>
				<div class='text1' style='margin-left: 16px;width: 3.5rem;'>邮箱</div>
				<div style='margin-left: 20px;'>
					<el-input v-model="input" placeholder="请输入您的邮箱"></el-input>
				</div>
			</div>
			<div class='btn DIS-CENTER' @click='step = 1'>
				确定
			</div>
			<div class='btnBox'>
				<div @click='ck_Switch(0)'>手机验证</div>
				<div @click='ck_Switch(2)'>密保验证</div>
			</div>
		</div>
		
		<div v-if='step == 1'>
			<div class='text2'>
				邮件已送达 <span>gos1818@163.com</span>
			</div>
			<div class='text3' style='margin-top: 0.75rem;'>
				请您登录邮箱，点击链接完成身份验证，链接在24小时内有效。
			</div>
			<div class='btnBox' style='margin-top: 2.5rem;'>
				<div class='btn2 DIS-CENTER'>
					请查收邮件
				</div>
				<div class='text4'>
					没有收到邮件？
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import FindPasswordTitle from './FindPasswordTitle.vue';
	
	export default {
		components: { FindPasswordTitle },
		data() {
			return {
				input: '',
				step: 0,
			}
		},
		methods: {
			ck_Switch(value) {
				this.$emit('ckSwitch',value)
			}
		}
	}
</script>

<style scoped>
	.itemBox {
		width: 21.25rem;
		height: 2.75rem;
		border: 1px solid #DDDDDD;
		display: flex;
		align-items: center;
	}
	
	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.itemBox>>>.el-input__inner {
		border: none;
		height: 2.75rem;
		width: 15.5rem;
	}
	
	.btn {
		width: 340px;
		height: 50px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
		margin-top: 1.9375rem;
	}
	
	.btnBox {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
		margin-top: 1.25rem;
	}
	
	.btnBox div {
		cursor: pointer;
	}
	
	.text2 {
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.text2 span {
		color: #ee4454;
	} 
	
	.text3 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
	
	.btn2 {
		width: 240px;
		height: 50px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}
	
	.text4 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
	}
</style>
