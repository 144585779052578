<template>
	<div class='HomeFive'>
		<HomeTitle 
			:title='title'
			:text='text'
			:path='path'
		/>
		
		<div class='HomeFive-content'>
			<div 
				:class="index==0 || index%5==0 ? 'item2' : 'item1'"
				v-for='(item,index) in goodsList' 
				:key='index'
			>
				<GoodsListFirst :goodsItem='item'/>
			</div>
		</div>
	</div>
</template>

<script>
	import HomeTitle from '../components/Home-title.vue';
	import GoodsListFirst from '../components/GoodsListFirst.vue';
	
	export default {
		components: { HomeTitle, GoodsListFirst },
		props: [ 'title', 'text', 'path', 'goodsList' ]
	}
</script>

<style scoped>
	.HomeFive {
		width: 50.5rem;
	}
	
	.HomeFive .HomeFive-content {
		display: flex;
		flex-wrap: wrap;
	}
	
	.HomeFive .HomeFive-content .item1 {
		margin-left: 1.6875rem;
		margin-top: 2rem;
	}
	.HomeFive .HomeFive-content .item2 {
		margin-top: 2rem;
	}
</style>
