<template>
	<div>
		<div class="PublishContentplgl_title">评论管理</div>
		<div class="PublishContentplgl_wrapContent">
			<div class="PublishContentplgl_wrap" v-for="(item,index) in testList1" :key='index' @scroll="onScroll">
				<div v-show="isCheckMore?index<9999999999999999999:index<3">
					<div class="PublishContentplgl_box" style="border-bottom: 1px solid #E5E5E5;">
						<div class="PublishContentplgl_boxLeft">
							<div class="topUserInfo">
								<img :src="userInfo.userPhoto" class="topUserInfo_img" />
								<div class="topUserInfo_name">{{userInfo.loginName}}</div>
							</div>
							<div class="bottomContent">
								<div class="bottomContent_creatTime">{{item.create_time}}</div>
								<div class="bottomContent_text">{{item.content}}</div>
								<div class="bottomContent_options">
									<div class="bottomContent_optionsItem" @click="ck_checkFollow(0,index,item.id,item.article_id,item.is_zan)">
										<img src="../../assets/Publish/dz.png" class="bottomContent_img" />
										<div class="bottomContent_text1">{{item.zan_num}}</div>
									</div>

									<div class="bottomContent_optionsItem" @click="ck_checkFollow(1,index,item.id,item.article_id,item.is_zan)">
										<img src="../../assets/Publish/pl.png" class="bottomContent_img" />
										<div class="bottomContent_text2">回复</div>
									</div>

									<div class="bottomContent_optionsItem" @click="ck_checkFollow(2,index,item.id,item.article_id,item.is_zan)">
										<img src="../../assets/Publish/sc.png" class="bottomContent_img" />
										<div class="bottomContent_text2">删除</div>
									</div>
								</div>

								<!-- 二级评论 commit -->
								<div class="PublishContentplgl_commitBox" v-if="item.isCommit">
									<el-input v-model="commitVal" :placeholder="'回复：'+commitValPla"
										style="width: 516px;height: 47px;"></el-input>
									<div class="PublishContentplgl_commitBox_btn">
										<div class="PublishContentplgl_commitBox_btnStyle btnStyle1"
											@click="item.isCommit=false">取消
										</div>
										<div class="PublishContentplgl_commitBox_btnStyle btnStyle2"
											@click="ck_send(index,item.article_id,item.id)">发送</div>
									</div>
								</div>
								<!-- 回复 reply  -->
								<div class="PublishContentplgl_replyBox">
									<div class="PublishContentplgl_replyItem" v-for="(item1,index1) in item.child"
										:key='index1'>
										<img class="replyItem_img" :src="userInfo.userPhoto" />
										<div class="replyItem_text">{{userInfo.loginName}}</div>
										<div class="replyItem_text1" style="margin-left: 8px;margin-right: 8px;">回复
										</div>
										<div class="replyItem_text">{{userInfo.loginName}}：</div>
										<div class="replyItem_text1">{{item1.content}}</div>
									</div>
								</div>
							</div>
						</div>
						<img :src="item.cover_image" class="PublishContentplgl_boxRight" />
					</div>
				</div>
			</div>
		</div>
		<div class="DIS-CENTER checkMore_plgl" @click="ck_checkMore" v-if="!isCheckMore && testList1.length>3">查看更多</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js'
	export default {
		props: ['newCheckScorll'],
		data() {
			return {
				userInfo: {},
				zan_num: 0,
				commitVal: '',
				commitValPla: 'Carterina',
				isCheckMore: false,
				testList1: [],
				status: 1,
				docHeight: '',
				winHeight: '',
				checkScorll: true,
			}
		},
		mounted() {
			this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
			this.initData()
			if (this.checkScorll && this.$props.newCheckScorll) {
				this.$nextTick(function() {
					window.addEventListener('scroll', this.onScroll);
				})
			} else {
				// console.log('checkScorll 为false的情况')
			}
		},
		beforeDestroy() {
			this.$emit('checkScorll', false)
			this.checkScorll = false
		},
		methods: {
			initData() {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					page: this.status,
				}
				this.axios.post(Api.commentManage, data).then(res => {
					if (res.data.code == 200) {
						for (let i in res.data.data) {
							this.testList1.push(res.data.data[i])
						}

						for (let i in this.testList1) {
							this.$set(this.testList1[i], 'isCommit', false)
						}
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			
			// 发送 评论
			ck_send(index,article_id,pid) {
				if(this.commitVal==''){
					this.$message.error('评论内容不能为空')
					return;
				}
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					article_id: article_id,
					content: this.commitVal,
					pid: pid,
				}
				this.axios.post(Api.sendComment, data).then(res => {
					if (res.data.code == 200) {
						this.$message.success(res.data.msg)
						this.status=1
						this.testList1=[]
						this.initData()
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},

			ck_checkFollow(type, index, delIndex,id,is_zan) {
				this.commitVal = ''
				if (type == 1) {
					this.testList1[index].isCommit = true
					for (let i in this.testList1) {
						this.testList1[i].isCommit = false
					}
					this.testList1[index].isCommit = true
				} else {
					for (let i in this.testList1) {
						this.testList1[i].isCommit = false
					}
					this.commitVal = ''
					if (type == 0) {
						this.clickZan(id,is_zan)
					}
					if (type == 2) {
						this.delComment(delIndex)
					}
				}
			},

			delComment(delIndex) {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					commentId: delIndex,
				}
				this.axios.post(Api.delComment, data).then(res => {
					if (res.data.code == 200) {
						this.$message.success('删除成功')
						this.status=1
						this.testList1=[]
						this.initData()
						// this.testList1=this.testList1.filter(i=>i.id != delIndex)
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			
			clickZan(id,is_zan) {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					article_id:id,
					type: is_zan,
				}
				this.axios.post(Api.clickZan, data).then(res => {
					if (res.data.code == 200) {
						this.testList1=[]
						this.initData()
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},

			ck_checkMore() {
				this.isCheckMore = true
				for (let i in this.testList1) {
					this.testList1[i].isCommit = false
				}
				this.commitVal = ''
			},

			// 获取滚动条当前的位置
			getScrollTop() {
				let scrollTop = 0
				if (document.documentElement && document.documentElement.scrollTop) {
					scrollTop = document.documentElement.scrollTop
				} else if (document.body) {
					scrollTop = document.body.scrollTop
				}
				return scrollTop
			},

			// 获取当前可视范围的高度
			getClientHeight() {
				let clientHeight = 0
				if (document.body.clientHeight && document.documentElement.clientHeight) {
					clientHeight = Math.min(document.body.clientHeight, document.documentElement.clientHeight)
				} else {
					clientHeight = Math.max(document.body.clientHeight, document.documentElement.clientHeight)
				}
				return clientHeight
			},

			// 获取文档完整的高度
			getScrollHeight() {
				return Math.max(document.body.scrollHeight, document.documentElement.scrollHeight)
			},

			onScroll() {
				if (this.getScrollHeight() - this.getClientHeight() - this.getScrollTop() <= 0) {
					if (this.checkScorll && this.$props.newCheckScorll) {
						if (this.testList1.length == 10) {
							this.status++
							this.initData()
						}
					}
				}
			}
		}
	}
</script>

<style>
	.PublishContentplgl_title {
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-left: 29px;
		margin-top: 24px;
	}

	.PublishContentplgl_wrap {
		margin-left: 31px;
		margin-right: 58px;
	}

	.PublishContentplgl_box {
		padding-top: 21px;
		padding-bottom: 20px;
		display: flex;
		justify-content: space-between;
	}

	.PublishContentplgl_boxLeft {
		display: flex;
		flex-direction: column;
	}

	.PublishContentplgl_boxRight {
		width: 80px;
		height: 80px;
	}

	.topUserInfo {
		display: flex;
		align-items: center;
	}

	.topUserInfo_img {
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}

	.topUserInfo_name {
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-left: 14px;
	}

	.bottomContent {
		margin-top: 7px;
		margin-left: 44px;
	}

	.bottomContent_creatTime {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}

	.bottomContent_text {
		margin-top: 10px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.bottomContent_options {
		display: flex;
		justify-content: flex-start;
		margin-top: 8px;
		align-items: center;
	}

	.bottomContent_optionsItem {
		display: flex;
		margin-right: 29px;
		align-items: center;
		cursor: pointer;
	}

	.bottomContent_img {
		width: 20px;
		height: 20px;
	}

	.bottomContent_text1 {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-left: 4px;
	}

	.bottomContent_text2 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-left: 7px;
	}

	.PublishContentplgl_commitBox {
		margin-top: 18px;
	}

	.PublishContentplgl_replyBox {
		margin-top: 18px;
	}

	.PublishContentplgl_commitBox .el-input__inner {
		background-color: #F5F5F5;
	}

	.PublishContentplgl_commitBox_btn {
		display: flex;
		margin-top: 16px;
	}

	.PublishContentplgl_commitBox_btnStyle {
		padding: 6px 27px;
		font-size: 13px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		cursor: pointer;
	}

	.btnStyle1 {
		background-color: #FFFFFF;
		border: 1px solid #E6E6E6;
		margin-right: 20px;
		color: #333333;
	}

	.btnStyle2 {
		background-color: #EE4454;
		color: #FFFFFF;
	}

	.PublishContentplgl_replyItem {
		display: flex;
		align-items: center;
		margin-bottom: 5px;
	}

	.replyItem_img {
		width: 30px;
		height: 30px;
		margin-right: 12px;
		border-radius: 50%;
	}

	.replyItem_text {
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.replyItem_text1 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}

	.checkMore_plgl {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-top: 47px;
		margin-bottom: 50px;
		cursor: pointer;
	}
</style>
