<template>
  <!-- 音频视频 -->
  <div>
    <div class="PublishVideoAudio">
      <div class="PublishVideoAudioWrap">
        <div
          class="DIS-CENTER PublishVideoAudioWrap_upload"
          style="position: relative; display: flex; flex-direction: column"
        >
          <!-- 视频  -->
          <div v-if="PublishMenuType == 2">
            <el-upload
              drag
              :action="uploadUrl"
              multiple
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :on-progress="uploadVideoProcess"
              :on-success="handleAvatarSuccess"
            >
              <img
                src="../../../assets/Publish/uploadContent.png"
                style="width: 100%; height: 100%"
              />
              <img
                src="../../../assets/Publish/upload.png"
                style="
                  width: 275px;
                  height: 60px;
                  position: absolute;
                  margin-left: -437px;
                  margin-top: 184px;
                "
              />
            </el-upload>

            <div
              style="
                display: flex;
                margin-top: 27px;
                margin-bottom: 14px;
                justify-content: center;
              "
            >
              <div class="PublishVideoAudioWrap_upload_text1">
                推荐格式：mp4、flv、avi
              </div>
              <div style="width: 15px"></div>
              <div class="PublishVideoAudioWrap_upload_text1">
                视频大小不超过2G
              </div>
            </div>
            <div style="display: flex; justify-content: center">
              <div class="PublishVideoAudioWrap_upload_text1">
                上传视频，即表示您已同意
              </div>
              <div class="PublishVideoAudioWrap_upload_text2">
                《书营网使用协议》
              </div>
              <div class="PublishVideoAudioWrap_upload_text1">与</div>
              <div class="PublishVideoAudioWrap_upload_text2">
                《书营创作公约》
              </div>
              <div class="PublishVideoAudioWrap_upload_text1">
                ，请勿上传色情，反动等违法视频
              </div>
            </div>
          </div>

          <!-- 音频 -->
          <div v-if="PublishMenuType == 1">
            <el-upload
              drag
              :action="uploadUrl"
              :multiple="false"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :on-progress="uploadVideoProcess"
              :on-success="handleAvatarSuccess"
            >
              <img
                src="../../../assets/Publish/uploadContentAudio.png"
                style="width: 100%; height: 100%"
              />
              <img
                src="../../../assets/Publish/uploadAudio.png"
                style="
                  width: 275px;
                  height: 60px;
                  position: absolute;
                  margin-left: -437px;
                  margin-top: 184px;
                "
              />
            </el-upload>

            <div
              style="
                display: flex;
                margin-top: 27px;
                margin-bottom: 14px;
                justify-content: center;
              "
            >
              <div class="PublishVideoAudioWrap_upload_text1">
                推荐格式：mp3、wma、flac
              </div>
              <div style="width: 15px"></div>
              <div class="PublishVideoAudioWrap_upload_text1">
                音频大小不超过200M
              </div>
            </div>
            <div style="display: flex; justify-content: center">
              <div class="PublishVideoAudioWrap_upload_text1">
                上传音频，即表示您已同意
              </div>
              <div class="PublishVideoAudioWrap_upload_text2">
                《书营网使用协议》
              </div>
              <div class="PublishVideoAudioWrap_upload_text1">与</div>
              <div class="PublishVideoAudioWrap_upload_text2">
                《书营创作公约》
              </div>
              <div class="PublishVideoAudioWrap_upload_text1">，请阅读</div>
              <div class="PublishVideoAudioWrap_upload_text2">
                《音频投稿规范》
              </div>
            </div>
          </div>

          <el-dialog :visible.sync="dialogVisible" width="500px"></el-dialog>
        </div>
      </div>
    </div>
    <div
      class="DIS-CENTER PublishVideoAudioWrap_upload_progressWrap"
      v-if="dialogVisible"
    >
      <!-- 上传进度 -->
      <div class="PublishVideoAudioWrap_upload_progress">
        <div
          class="PublishVideoAudioWrap_upload_progress_title"
          style="margin-bottom: 45px"
        >
          {{ PublishMenuType == 1 ? uploadDialogTitle1 : uploadDialogTitle }}
        </div>
        <el-progress
          :color="uploadProgressColor"
          :stroke-width="10"
          :percentage="parseFloat(percentage)"
        >
        </el-progress>

        <div
          style="
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
            margin-bottom: 37px;
          "
        >
          <div>{{ percentage }}%</div>
          <div>{{ videoSizeUpNum }}/{{ videoSizeNum }}M</div>
        </div>
      </div>

      <!-- 发布内容 -->
      <div
        class="PublishVideoAudioWrap_upload_progress"
        style="margin-top: 28px"
      >
        <div
          class="PublishVideoAudioWrap_upload_progress_title"
          style="margin-bottom: 29px"
          v-if="PublishMenuType == 1"
        >
          请输入音频标题
        </div>
        <div
          class="PublishVideoAudioWrap_upload_progress_title"
          style="margin-bottom: 29px"
          v-if="PublishMenuType == 2"
        >
          请输入视频标题
        </div>
        <el-input
          type="text"
          placeholder="请输入音频标题(5~30字)"
          v-model="audioTitle"
          v-if="PublishMenuType == 1"
        >
        </el-input>
        <el-input
          type="text"
          placeholder="请输入视频标题(5~30字)"
          v-model="videoTitle"
          v-if="PublishMenuType == 2"
        >
        </el-input>

        <div style="display: flex; align-items: flex-end; margin-top: 24px">
          <div style="color: #ff3a3a">*</div>
          <div style="font-size: 16px; font-weight: 600; color: #333333">
            添加封面
          </div>
          <div
            style="
              font-size: 12px;
              font-weight: 400;
              color: #999999;
              font-family: PingFangSC-Semibold, PingFang SC;
            "
          >
            (建议图片比例 3:4 ，支持jpg、png格式)
          </div>
        </div>

        <div style="display: flex; margin-top: 24px">
          <div style="position: relative">
            <img
              :src="addImageBG"
              style="width: 180px; height: 180px; cursor: pointer"
            />
            <label
              class="PublishVideoAudio_upload DIS-CENTER"
              for="upload"
              accept="image/png,image/jpeg"
            >
              添加图片
            </label>
            <input
              id="upload"
              type="file"
              style="display: none"
              @change="ce_upload"
            />
          </div>
        </div>

        <div style="display: flex; margin-top: 26px">
          <div style="color: #ff3a3a">*</div>
          <div style="font-size: 16px; font-weight: 600; color: #333333">
            添加商品
          </div>
        </div>

        <div style="margin-top: 14px">
          <el-select
            popper-class="matterNameClass"
            v-el-select-loadmore="handleLoadMore"
            v-model="optionsVal"
            placeholder="请选择"
            filterable
            :filter-method="dataFilter"
            style="width: 349px"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in shopList"
              :key="item.goodsId"
              :label="item.goodsName"
              :value="item.goodsId"
              id="PublishTwWrap_select"
              style="width: 353px"
            >
              <div class="selectBg">
                <div class="selectBg_title">{{ item.goodsName }}</div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    margin-top: 6px;
                  "
                >
                  <div style="display: flex; align-items: center">
                    <img
                      :src="item.shopInfo.shopImg"
                      style="
                        width: 16px;
                        height: 16px;
                        border-radius: 16px;
                        background-color: pink;
                      "
                    />
                    <div class="selectBg_contentLeft_text">
                      {{ item.shopInfo.shopName }}
                    </div>
                  </div>

                  <div class="selectBg_contentRight">
                    <div
                      style="
                        font-size: 10px !important;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #ee4454;
                        display: flex;
                        align-items: flex-end;
                      "
                    >
                      ￥
                    </div>
                    <div
                      style="
                        font-size: 18px;
                        font-weight: 600;
                        color: #ee4454;
                        font-family: PingFangSC-Semibold, PingFang SC;
                      "
                    >
                      {{ item.marketPrice }}
                    </div>
                    <div
                      style="
                        margin-left: 5px;
                        font-size: 10px !important;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #9a9a9a;
                      "
                    >
                      起
                    </div>
                  </div>
                </div>
              </div>
            </el-option>
          </el-select>
        </div>

        <div style="display: flex; justify-content: flex-end">
          <div
            class="leftBox_content_menuBtn leftBox_content_menuBtn1"
            @click="dialogVisible = false"
          >
            取消
          </div>
          <div
            class="leftBox_content_menuBtn leftBox_content_menuBtn2"
            @click="ck_publist"
          >
            发布
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../../../request/api.js";
import addImage from "../../../assets/images/sczj.png";
export default {
  props: ["PublishMenuType"],
  data() {
    return {
      uploadUrl: Api.uploadFile,
      dialogVisible: false,
      dialogVisibles: false,
      uploadDialogTitle: "视频上传中…",
      uploadDialogTitle1: "音频上传中…",
      uploadProgressColor: "#EE4454",
      percentage: 0,
      videoSizeNum: 0,
      videoSizeUpNum: 0,
      videoTitle: "",
      audioTitle: "",
      videoUrl: "",
      audioUrl: "",
      addImageBG: addImage,
      optionsVal: "",
      formData: {
        pageIndex: 1,
        pageSize: 20,
      },
      shopList: [],
      coverUrl: "", //封面地址
    };
  },
  mounted() {
    this.getShopData(this.formData.pageIndex);
  },
  directives: {
    "el-select-loadmore": {
      bind(el, binding) {
        // 获取element-ui定义好的scroll盒子
        const SELECTWRAP_DOM = el.querySelector(
          ".el-select-dropdown.matterNameClass .el-select-dropdown__wrap"
        );
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          const condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight + 20;
          if (condition) {
            binding.value();
            //调用handleLoadMore
          }
        });
      },
    },
  },
  methods: {
    ce_upload(value) {
      const file = value.srcElement.files[0];
      if (!file) {
        return;
      }
      let data = new FormData();
      data.append("file", file);
      this.axios.post(Api.uploadFile, data).then((res) => {
        if (res.data.code == 200) {
          // this.userHeader1 = res.data.data.file_path; // 传递给后台的数据
          this.coverImg = Api.URL + "/" + res.data.data.file_path;
          this.addImageBG = Api.URL + "/" + res.data.data.file_path;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleLoadMore() {
      this.formData.pageIndex++;
      this.getShopData(this.formData);
    },
    getShopData(v) {
      const data = {
        loginid: localStorage.getItem("shuying-token"),
        page: v.pageIndex,
      };
      this.axios.post(Api.bestGoodsRecommend, data).then((res) => {
        if (res.data.code == 200) {
          for (var i in res.data.data) {
            this.shopList.push(res.data.data[i]);
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // 发布
    ck_publist() {
      var data;
      if (this.$props.PublishMenuType == 1) {
        data = {
          loginid: localStorage.getItem("shuying-token"),
          type: 1,
          title: this.audioTitle,
          goods_id: this.optionsVal,
          cover_image: this.coverUrl,
          audio: this.audioUrl, // 上传音频地址
        };
      } else {
        data = {
          loginid: localStorage.getItem("shuying-token"),
          type: 2,
          title: this.videoTitle,
          goods_id: this.optionsVal,
          cover_image: this.coverUrl,
          video: this.videoUrl,
        };
      }

      this.axios.post(Api.uploadArticle, data).then((res) => {
        if (res.data.code == 200) {
          if (this.$props.PublishMenuType == 1) {
            this.$emit("publist", 2);
            this.$emit("publistTitle", this.audioTitle);
          } else {
            this.$emit("publist", 3);
            this.$emit("publistTitle", this.videoTitle);
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // select 自定义搜索
    dataFilter(val) {
      this.value = val;
      if (val) {
        //val存在
        this.cities = this.citiesNew.filter((item) => {
          if (
            !!~item.label.indexOf(val) ||
            !!~item.label.toUpperCase().indexOf(val.toUpperCase())
          ) {
            return true;
          }
        });
      } else {
        //val为空时，还原数组
        this.cities = this.citiesNew;
      }
    },
    // 进度条
    uploadVideoProcess(event, file) {
      this.dialogVisible = true;
      this.dialogVisibles = true;
      this.videoSizeUpNum = parseInt(
        file.percentage.toFixed(0) * (this.videoSizeNum / 100)
      );
      this.percentage = file.percentage.toFixed(0);
    },
    // 上传成功
    handleAvatarSuccess(res) {
      this.percentage = 100;
      this.videoSizeUpNum = this.videoSizeNum;
      if (res.code == 200) {
        if (this.$props.PublishMenuType == 2) {
          this.videoUrl = Api.URL + "/" + res.data.file_path;
          // this.uploadDialogTitle = '视频上传成功'
          this.$message.success("视频上传成功");
        } else {
          // this.uploadDialogTitle1 = '音频上传成功'
          this.audioUrl = Api.URL + "/" + res.data.file_path;
          this.$message.success("音频上传成功");
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    // 视频上传格式   音频上传格式
    beforeAvatarUpload(file) {
      const isLt2000M = file.size / 1024 / 1024 < 2000;
      const isLt200M = file.size / 1024 / 1024 < 200;
      this.videoSizeNum = parseInt(file.size / 1024 / 1024);
      if (this.$props.PublishMenuType == 2) {
        if (["video/mp4", "video/flv", "video/avi"].indexOf(file.type) == -1) {
          this.$message.error("请上传正确的视频格式");
          return false;
        }
        if (!isLt2000M) {
          this.$message.error("上传视频大小不能超过2G!");
          return false;
        }
      } else {
        if (
          ["audio/mp3", "audio/wma", "audio/flac", "audio/mpeg"].indexOf(
            file.type
          ) == -1
        ) {
          this.$message.error("请上传正确的音频格式");
          return false;
        }
        if (!isLt200M) {
          this.$message.error("上传音频大小不能超过200M!");
          return false;
        }
      }
      // const isJPG = file.type === 'image/jpeg';
      // const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      // 	this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      // if (!isLt2M) {
      // 	this.$message.error('上传头像图片大小不能超过 2MB!');
      // return isJPG && isLt2M;
    },
  },
};
</script>

<style>
/* .PublishVideoAudio_menu_bgClose {
		position: absolute;
		background: url(../../../assets/images/deleteImage.png) no-repeat;
		width: 20px;
		height: 20px;
		margin-top: -190px;
		margin-left: 170px
	}

	.PublishVideoAudio_upload {}
 */
.PublishVideoAudio_upload {
  width: 180px;
  height: 180px;
  position: absolute;
  top: 0;
  left: 0;
  background: #000000;
  opacity: 0;
  cursor: pointer;
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}

.PublishVideoAudio_upload:hover {
  opacity: 0.5;
}

.PublishVideoAudio {
  width: 100%;
}

.PublishVideoAudioWrap {
  background-color: #ffffff;
  width: 100%;
}

.PublishVideoAudioWrap_upload {
  padding-top: 214px;
  padding-bottom: 256px;
}

.PublishVideoAudioWrap_upload .el-upload-dragger {
  width: 600px;
  height: 300px;
}

.PublishVideoAudioWrap_upload_text1 {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

.PublishVideoAudioWrap_upload_text2 {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ee4454;
}

.PublishVideoAudioWrap_upload_progressWrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 250px;
  overflow: auto;
  margin: 0;
  z-index: 999999;
  display: flex;
  flex-direction: column;
}

.PublishVideoAudioWrap_upload_progress {
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 28px 33px 10px;
  background-color: #ffffff;
  border-radius: 6px;
}

.PublishVideoAudioWrap_upload_progress_title {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: inherit;
}

.PublishVideoAudioWrap_upload .el-dialog__header {
  padding: 28px 33px 10px;
}

.PublishVideoAudioWrap_upload_progressWrap .el-progress__text {
  display: none;
}

.PublishVideoAudioWrap_upload_progressWrap .el-progress-bar {
  padding-right: 0;
}

.PublishVideoAudioWrap_upload .el-dialog {
  display: none;
}

.leftBox_content_menuBtn {
  padding-top: 6px;
  padding-bottom: 6px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  border-radius: 2px;
  cursor: pointer;
}

.leftBox_content_menuBtn1 {
  border: 1px solid #e6e6e6;
  margin-right: 20px;
  color: #333333;
}

.leftBox_content_menuBtn2 {
  color: #ffffff;
  background-color: #ee4454;
}

/* 添加商品 样式 */
.selectBg {
  width: 300px !important;
  border-radius: 8px !important;
  border: 1px solid #f5f5f5 !important;
  padding: 13px 12px 13px 16px !important;
}

#PublishTwWrap_select {
  padding: 0 14px 10px !important;
  line-height: 20px;
  height: auto !important;
  display: flex !important;
  flex-wrap: wrap !important;
  white-space: inherit !important;
}

.selectBg_title {
  font-size: 14px !important;
  font-family: PingFangSC-Regular, PingFang SC !important;
  font-weight: 400 !important;
  color: #333333 !important;
}

.selectBg_contentLeft_text {
  margin-left: 6px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.selectBg_contentRight {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
</style>
