import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		user:{
			id:0,
		},
		step: 1,
		noMailboxActive: true,
		currentMsg:null,
		orderNum:0,
		chatNum:0,
		AllNum:0,
	},
	mutations: {
		nextStep(state,obj) {
			if(obj.num == state.step) {  // num == state.step  更改当前的状态执行下一步  step+1       不等于回到第一步
				return state.step += 1;
			} else {
				return state.step = 1;
			}
		},
		editMailboxActive(state,obj) {    // 默认打开状态
			return state.noMailboxActive = obj.isActive;
		},
		SetUser: (state, user) => {
			state.user.id = user;
			// uni.setStorageSync('user', user);
		},
		SetOrderNum(state, msg) {
			state.orderNum = msg;
		},
		SetChatNum(state, msg) {
			state.chatNum = msg;
		},
		SetAllNum(state,msg){
			state.AllNum = msg;
		},
		SetMessages(state, msg) {
			state.currentMsg = msg;
		},
	},
	actions: {
	},
	modules: {
	}
})
