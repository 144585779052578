<template>
	<div class='shoppingCartTable'>
		<div class='titleBox'>
			<div style='display: flex;'>
				<div class='title1'>我的购物车</div>
				<div style='display: flex;'>
					<div class='menuText' v-for='(item,index) in menuList' :key='index' @click='ck_menu(index)'>
						<div :style="item.active?'color: #EE4454':''">
							{{item.text}}
							<!-- <span style='color: #EE4454;'>{{item.num}}</span> -->
						</div>
						<div v-show='item.active' class='line'></div>
					</div>
				</div>
			</div>
			<div class='DIS-CENTER'>
				<div class='title2'>
					已选商品（不含运费）
					<span style='color: #EE4454;font-size: 1rem;'>{{goodsPrice}}</span>
				</div>
				<div 
					class='btn DIS-CENTER'
					:style="goodsNum>0?'background: #ee4454':''"
					@click='goPage("/SubmitOrder")'
				>
					结算
				</div>
			</div>
		</div>
		
		<div class='navigation'>
			<div 
				style='display: flex;cursor: pointer;align-items: center;'
				@click='ck_checkAll'
			>
				<img 
					v-if='isAllCheck==1'
					style='width: 0.875rem;height: 0.875rem;margin-left: 1.125rem;' 
					src="../../assets/images/choice-active.png" 
					alt=""
				>
				<img 
					v-else
					style='width: 0.875rem;height: 0.875rem;margin-left: 1.125rem;' 
					src="../../assets/images/choice.png" 
					alt=""
				>
				<div style='margin-left: 0.5625rem;'>全选</div>
			</div>
			<div style='margin-left: 4.5625rem;'>
				商品信息
			</div>
			<div style='margin-left: 29.1875rem;'>
				单价
			</div>
			<div style='margin-left: 6.8125rem;'>
				数量
			</div>
			<div style='margin-left: 7.25rem;'>
				金额
			</div>
			<div style='margin-left: 7.625rem;'>
				操作
			</div>
		</div>

		<!-- 购物车详细列表 -->
		<div v-show='shoppingCartData.length > 0' v-for='(item,index) in shoppingCartData' :key='index'>
			<div 
				style='display: flex;align-items: center;margin-top: 1.0625rem;margin-left: 1.125rem;cursor: pointer;'
				@click='ck_shopChooseAll(index)'
			>
				<img 
					v-if='item.isCheck == 1'
					style='width: 0.875rem;height: 0.875rem;' 
					src="../../assets/images/choice-active.png" alt=""
				>
				<img 
					v-else
					style='width: 0.875rem;height: 0.875rem;'
					src="../../assets/images/choice.png" alt=""
				>
				<div class='title1' style='margin-left: 0.5625rem;'>
					<span style='font-weight: bold;'>
						店铺：
					</span> {{item.shopName}}
				</div>
			</div>
			<div style='margin-top: 1.25rem;'>
				<div v-for='(item1,index1) in item.list' :key='index1'>
					<div class='cartItem' :style='index1 ==0 && item.list.length>1 ?"border-bottom:none":""'>
						<img 
							v-if='item1.isCheck == 1'
							style='width: 0.875rem;height: 0.875rem;margin-left: 1.125rem;cursor: pointer;' 
							src="../../assets/images/choice-active.png" alt=""
							@click='ck_goodChooseAll(-1,item1.cartId)'
						>
						<img 
							v-else
							style='width: 0.875rem;height: 0.875rem;margin-left: 1.125rem;cursor: pointer;' 
							src="../../assets/images/choice.png" alt=""
							@click='ck_goodChooseAll(1,item1.cartId)'
						>
						<img 
							style='width: 5rem;height: 5rem;border: 1px solid #E6E6E6;margin-left: 0.5625rem;' 
							:src="item1.goodsImg"
							alt=""
						>
						<div class='title1' style='width: 19.75rem;margin-left: 1.0625rem;'>
							{{item1.goodsName}}
						</div>
						<div style="margin-left: 12.375rem;margin-top: 0.5rem;width: 6.25rem;">
							<div class='price1'>￥{{item1.defaultShopPrice}}</div>
							<div class='price2'>￥{{item1.shopPrice}}</div>
						</div>
						<div class='counter'>
							<div class='counterText DIS-CENTER' style='flex: 1;cursor: pointer;' @click='eidtNum("del",index,index1,item1.cartId)'>
								-
							</div>
							<div class='counterText DIS-CENTER' style='flex: 2;border-left: 1px solid #E6E6E6;border-right: 1px solid #E6E6E6;'>
								{{item1.cartNum}}
							</div>
							<div class='counterText DIS-CENTER' style='flex: 1;cursor: pointer;' @click='eidtNum("add",index,index1,item1.cartId)'>
								+
							</div>
						</div>
						<div class='price3'>
							￥{{item1.shopPrice*item1.cartNum}}
						</div>
						<div style='margin-left: 6.0625rem;margin-top: 1.125rem;'>
							<div 
								v-if='item1.isFavorite == 0'
								class='title1' style='cursor: pointer;'
								@click='ck_collect(item1.cartId,item1.goodsId)'
							>
								移入收藏夹
							</div>
							<div v-else class='title1'>
								已加入收藏夹
							</div>
							<div 
								class='title1' style='margin-top: 0.375rem;cursor: pointer;'
								@click='delData(item1.cartId)'
							>
								删除
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class='close' v-if='shoppingCartData.length > 1'>
			<div style='cursor: pointer;display: flex;align-items: center;' @click='ck_checkAll'>
				<img 
					v-if='isAllCheck == 1'
					style='width: 0.875rem;height: 0.875rem;margin-left: 1.375rem;' 
					src="../../assets/images/choice-active.png" 
					alt=""
				>
				<img 
					v-else
					style='width: 0.875rem;height: 0.875rem;margin-left: 1.375rem;' 
					src="../../assets/images/choice.png" 
					alt=""
				>
				<div class='title1' style='margin-left: 0.5625rem;'>全选</div>
			</div>
			<div 
				class='title1' 
				style='cursor: pointer;margin-left: 2.25rem;'
				@click='allDelData'
			>
				删除
			</div>
			<div class='title1' style='cursor: pointer;margin-left: 1.9375rem;' @click='ck_allCollect'>
				移入收藏夹
			</div>
			<div class='title1' style='margin-left: 36.35rem;'>
				已选商品 <span style='color: #EE4454;font-weight: bold;font-size: 1rem;'>{{goodsNum}}</span> 件
			</div>
			<div class='title1 DIS-CENTER' style='margin-left: 2.1875rem;'>
				合计（不含运费）： 
				<span 
					style='color: #EE4454;font-size: 24px;font-weight: bold;width: 4.375rem;'
				>
					{{goodsPrice}}
				</span>
			</div>
			<div 
				class='closeBtn DIS-CENTER' 
				:style="goodsNum > 0?'background: #ee4454':''"
				@click='goPage("/SubmitOrder")'
			>
				结算
			</div>
		</div>
		
		<div
			v-if='shoppingCartData.length < 1' 
			style='width: 75rem;margin-top: 20px;'
			class='DIS-CENTER'
		>
			<NoneItem title='购物车还没有商品哦~' text='请先去加入一些商品吧'/>
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	import NoneItem from '../NoneItem.vue';
	
	export default {
		components: { NoneItem },
		data() {
			return {
				chooseAll: false,
				menuList: [{
						text: '全部商品',
						num: 3,
						active: true
					},
					{
						text: '降价商品',
						num: 0,
						active: false
					}, {
						text: '库存紧张',
						num: 1,
						active: false
					}
				],
				shoppingCartData: [], // 列表的数据
				isAllCheck: -1, // 全选按钮的参数
				goodsNum: 0, // 已选商品数量
				goodsPrice: 0 // 合计费用
			}
		},
		created() {
			this.initData();
		},
		mounted() {
			this.initData();
		},
		methods: {
			initData() {
				const data = {
					loginid: localStorage.getItem("shuying-token")
				}
				this.axios.post(Api.myCart,data).then(res=>{
					if(res.data.code == 200) {
						this.shoppingCartData = res.data.data.carts;
						this.goodsNum = res.data.data.goodsTotalNum;
						this.goodsPrice = res.data.data.goodsTotalMoney;
						for(let i in this.shoppingCartData) {
							for(let j in this.shoppingCartData[i].list) {
								this.shoppingCartData[i].list[j].goodsImg = `${Api.URL}/${this.shoppingCartData[i].list[j].goodsImg}`
								if(this.shoppingCartData[i].list[j].isCheck != 1) {
									this.$set(this.shoppingCartData[i],'isCheck',-1)
								}
							}
						}
						for(let i in this.shoppingCartData) {
							this.$set(this.shoppingCartData[i],'isCheck',1)
							for(let j in this.shoppingCartData[i].list) {
								if(this.shoppingCartData[i].list[j].isCheck != 1) {
									this.$set(this.shoppingCartData[i],'isCheck',-1)
								}
							}
						}
						this.isAllCheck = 1;
						for(let i in this.shoppingCartData) {
							for(let j in this.shoppingCartData[i].list) {
								if(this.shoppingCartData[i].list[j].isCheck == 0) {
									this.isAllCheck = -1;
									return
								}
							}
						}
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			goPage(path) {
				if(this.goodsNum > 0) {
					this.$router.push({path})
				}
			},
			eidtNum(type, parentId, id, goodsId) {
				if (type == 'del') {
					if (this.shoppingCartData[parentId].list[id].cartNum > 1) {
						this.shoppingCartData[parentId].list[id].cartNum = this.shoppingCartData[parentId].list[id].cartNum - 1;
					}
				} else {
					this.shoppingCartData[parentId].list[id].cartNum = this.shoppingCartData[parentId].list[id].cartNum + 1;
				}
				this.editGoodsNum(goodsId,this.shoppingCartData[parentId].list[id].cartNum)
			},
			ck_menu(index) {
				for (let i in this.menuList) {
					this.menuList[i].active = false;
				}
				this.menuList[index].active = true;
			},
			// 全选
			ck_checkAll() {
				if(this.isAllCheck == 1) {
					this.isAllCheck = -1
					this.editCheckAll(-1)
				} else if(this.isAllCheck == -1) {
					this.isAllCheck = 1
					this.editCheckAll(1)
				}
			},
			// 全选遍历所有元素，并改变
			editCheckAll(value) {
				let ids = [];
				for(let i in this.shoppingCartData) {
					for(let j in this.shoppingCartData[i].list) {
						ids.push(this.shoppingCartData[i].list[j].cartId)
						this.shoppingCartData[i].list[j].isCheck = value
					}
				}
				this.selectGoods(value,ids.toString())
			},
			// 店铺选择
			ck_shopChooseAll(index) {
				let ids = [];
				if(this.shoppingCartData[index].isCheck == -1) {
					this.shoppingCartData[index].isCheck = 1;
					for(let i in this.shoppingCartData[index].list) {
						this.shoppingCartData[index].list[i].isCheck = 1;
						ids.push(this.shoppingCartData[index].list[i].cartId)
					}
				} else if(this.shoppingCartData[index].isCheck == 1) {
					this.shoppingCartData[index].isCheck = -1;
					for(let i in this.shoppingCartData[index].list) {
						this.shoppingCartData[index].list[i].isCheck = -1;
						ids.push(this.shoppingCartData[index].list[i].cartId)
					}
				}
				this.selectGoods(this.shoppingCartData[index].isCheck,ids.toString())
			},
			// 单间商品选择
			ck_goodChooseAll(isCheck,ids) {
				this.selectGoods(isCheck,ids);
				
			},
			// 计算商品数量及计算金额
			counterNum() {
				this.goodNum = 0;
				this.totalMoney = 0;
				for(let i in this.shoppingCartData) {
					for(let j in this.shoppingCartData[i].child) {
						if(this.shoppingCartData[i].child[j].isChoose) {
							this.goodNum = this.goodNum + 1;
							this.totalMoney = this.totalMoney + this.shoppingCartData[i].child[j].price2 * 1;
						}
					}
				}
			},
			// 删除单个商品(直接调接口就行)
			delData(id) {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					id
				}
				this.axios.post(Api.delCart,data).then(res=>{
					if(res.data.code == 200) {
						this.$message.success(res.data.msg);
						this.initData();
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			// 删除勾选商品(直接调接口就行)
			allDelData() {
				let ids = [];
				for(let i in this.shoppingCartData){
					for(let j in this.shoppingCartData[i].list) {
						if(this.shoppingCartData[i].list[j].isCheck==1){
							ids.push(this.shoppingCartData[i].list[j].cartId)
						}
					}
				}
				this.delData(ids.toString())
			},
			// 修改购物车数量
			editGoodsNum(id,buyNum) {
				const data = {
					loginid: localStorage.getItem("shuying-token"),
					id,
					buyNum
				}
				this.axios.post(Api.changeCartGoods,data).then(res=>{
					if(res.data.code != 200) {
						this.$message.error(res.data.msg)
					}
				})
			},
			// 选中商品
			selectGoods(isCheck,ids) {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					isCheck,
					ids
				}
				this.axios.post(Api.selectGoods,data).then(res=>{
					if(res.data.code == 200) {
						this.initData();
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			// 加入收藏夹
			ck_collect(cartIds,goodsIds) {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					cartIds,
					goodsIds
				}
				this.axios.post(Api.moveToFavorites,data).then(res=>{
					if(res.data.code==200) {
						this.$message.success(res.data.msg)
						this.initData();
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			// 多选加入收藏夹
			ck_allCollect() {
				let goodsIds = [];
				let cartIds = [];
				for(let i in this.shoppingCartData){
					for(let j in this.shoppingCartData[i].list) {
						if(this.shoppingCartData[i].list[j].isCheck==1){
							goodsIds.push(this.shoppingCartData[i].list[j].goodsId);
							cartIds.push(this.shoppingCartData[i].list[j].goodsCatId);
						}
					}
				}
				this.ck_collect(cartIds.toString(),goodsIds.toString());
			}
		}
	}
</script>

<style scoped>
	.shoppingCartTable {
		width: 75rem;
	}

	.navigation {
		width: 75rem;
		height: 44px;
		background: #FAFAFA;
		border: 1px solid #E6E6E6;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		display: flex;
		align-items: center;
		margin-top: 1.0625rem;
	}

	.title1 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.cartItem {
		width: 1200px;
		border: 1px solid #E6E6E6;
		display: flex;
		padding: 1.0625rem 0;
	}

	.price1 {
		font-size: 14px;
		font-family: AlibabaPuHuiTiR;
		color: #999999;
		text-decoration: line-through;
	}

	.price2 {
		font-size: 14px;
		font-family: AlibabaPuHuiTiH;
		color: #333333;
	}

	.counter {
		width: 86px;
		height: 24px;
		border: 1px solid #E6E6E6;
		display: flex;
		margin-top: 1.25rem;
		margin-left: 2rem;
	}

	.counterText {
		font-size: 14px;
		font-family: AlibabaPuHuiTiR;
		color: #333333;
	}

	.price3 {
		font-size: 14px;
		font-family: AlibabaPuHuiTiH;
		color: #EE4454;
		margin-left: 3.125rem;
		margin-top: 1.5625rem;
	}
	
	.close {
		width: 1200px;
		height: 50px;
		background: #FAFAFA;
		display: flex;
		align-items: center;
		margin-top: 1.25rem;
	}
	
	.closeBtn {
		width: 100px;
		height: 50px;
		background: #B0B0B0;
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FFFFFF;
		cursor: pointer;
	}
	
	.titleBox {
		width: 75rem;
		color: red;
		margin-top: 1.25rem;
		display: flex;
		justify-content: space-between;
	}
	
	.titleBox .title1 {
		font-size: 20px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.titleBox .menuText {
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-left: 2.1875rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		margin-top: 0.25rem;
	}
	
	.titleBox .line {
		width: 1.75rem;
		border-top: 4px solid #EE4454;
		margin-top: 0.375rem;
	}
	
	.titleBox .title2 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.titleBox .btn {
		width: 4.375rem;
		height: 1.875rem;
		background: #B0B0B0;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
		margin-left: 0.9375rem;
	}
</style>
