<template>
	<div class='title-box'>
		<div class='title'>{{title}}</div>
		<div 
			class='DIS-CENTER'
			style='cursor: pointer;'
			@click='ck_goPage(path)'
		>
			<div class='text'>{{text}}</div>
			<img
				class='right-img'
				src="../../assets/images/arrow_right.png" 
				alt=""
			>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['title','text','path'],
		methods: {
			ck_goPage(name) {
				this.$router.push({ name })
			}
		}
	}
</script>

<style scoped>
	.title-box {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		width: 100%;
	}
	
	.title-box .title {
		font-size: 1.5rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.title-box .text {
		font-size: 1rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
	
	.title-box .right-img {
		width: 0.875rem;
		height: 0.875rem;
		margin-left: 0.625rem;
	}
</style>
