<template>
	<div class='Advertising'>
		<div class='content'>
			<img 
				class='first-img'
				src="../assets/advertising/advertising-first.png" 
				alt=""
			>
			<div class='content-box'>
				<img
					class='second-img'
					src="../assets/advertising/advertising-second.png" 
					alt=""
				>
				<img 
					class='third-img'
					src="../assets/advertising/advertising-third.png" 
					alt=""
				>
				<div class='four-box'>
					<div 
						style='width: 430px;margin-left: 120px;margin-top: 8.125rem;height: 15.625rem;overflow: hidden;position: relative;'
					>
						<div class='item-box' :style='`top: ${top1}px`'>
							<div class='item' v-for='(item,index) in dataList' :key='index'>
								<div>{{item.name}}</div>
								<div>{{item.phone}}</div>
								<div>{{item.time}}</div>
							</div>
						</div>
						<div class='item-box' :style='`top: ${top2}px`'>
							<div class='item' v-for='(item,index) in dataList' :key='index'>
								<div>{{item.name}}</div>
								<div>{{item.phone}}</div>
								<div>{{item.time}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class='inputBox'>
				<div class='text1'>
					您的姓名*
				</div>
				<el-input v-model="input"></el-input>
				<div class='text1' style='margin-top: 2.75rem;'>
					您的电话*
				</div>
				<el-input v-model="input"></el-input>
				<div class='text1' style='margin-top: 2.75rem;'>
					您的出书预算*<span style='font-size: 1.5rem;'>(单选)</span>
				</div>
				<div class='radioBox'>
					<div 
						class='DIS-CENTER' 
						style='cursor: pointer;'
						@click='ck_radio1(1)'
					>
						<img 
							v-if='radio1 == 1'
							style='width: 2rem;height: 2rem;' 
							src="../assets/advertising/isRadio.png" alt=""
						>
						<img
							v-else
							style='width: 2rem;height: 2rem;' 
							src="../assets/advertising/radio.png" alt=""
						>
						<div class='text1' style='margin-left: 1.5625rem;'>3万~5万</div>
					</div>
					<div 
						class='DIS-CENTER' 
						style='margin-left: 3.75rem;cursor: pointer;'
						@click='ck_radio1(0)'
					>
						<img 
							v-if='radio1 == 0'
							style='width: 2rem;height: 2rem;' 
							src="../assets/advertising/isRadio.png" alt=""
						>
						<img
							v-else
							style='width: 2rem;height: 2rem;' 
							src="../assets/advertising/radio.png" alt=""
						>
						<div class='text1' style='margin-left: 1.5625rem;'>5万以上</div>
					</div>
				</div>
				<div class='text1' style='margin-top: 2.75rem;'>
					书稿字数、页数
				</div>
				<el-input v-model="input"></el-input>
				<div class='text1' style='margin-top: 2.75rem;'>
					书稿有无公式、插图<span style='font-size: 22px;'>(请说清楚数量)</span>
				</div>
				<el-input v-model="input"></el-input>
				<div class='text1' style='margin-top: 2.75rem;'>
					印刷数量
				</div>
				<el-input v-model="input"></el-input>
				<div class='text1' style='margin-top: 2.75rem;'>
					数量
				</div>
				<el-input v-model="input"></el-input>
				<div class='text1' style='margin-top: 2.75rem;'>
					是否是电子稿<span style='font-size: 1.5rem;'>(单选)</span>
				</div>
				<div class='radioBox'>
					<div 
						class='DIS-CENTER' 
						style='cursor: pointer;'
						@click='ck_radio2(1)'
					>
						<img 
							v-if='radio2 == 1'
							style='width: 2rem;height: 2rem;' 
							src="../assets/advertising/isRadio.png" alt=""
						>
						<img
							v-else
							style='width: 2rem;height: 2rem;' 
							src="../assets/advertising/radio.png" alt=""
						>
						<div class='text1' style='margin-left: 1.5625rem;'>是</div>
					</div>
					<div 
						class='DIS-CENTER' 
						style='margin-left: 3.75rem;cursor: pointer;'
						@click='ck_radio2(0)'
					>
						<img 
							v-if='radio2 == 0'
							style='width: 2rem;height: 2rem;' 
							src="../assets/advertising/isRadio.png" alt=""
						>
						<img
							v-else
							style='width: 2rem;height: 2rem;' 
							src="../assets/advertising/radio.png" alt=""
						>
						<div class='text1' style='margin-left: 1.5625rem;'>否</div>
					</div>
				</div>
				<div class='text1' style='margin-top: 2.75rem;'>
					硬刷需求<span style='font-size: 1.5rem;'>(多选)</span>
				</div>
				<div class='choiceBox'>
					<div 
						class='DIS-CENTER' 
						style='justify-content: flex-start;margin-top: 2.375rem;cursor: pointer;width: 300px;'
						v-for='(item,index) in printingList'
						:key='index'
						@click='ck_chose(index)'
					>
						<img
							v-if='item.active'
							style='width: 2rem;height: 2rem;'	
							src="../assets/advertising/isChoice.png" 
							alt=""
						>
						<img
							v-else
							style='width: 2rem;height: 2rem;'	
							src="../assets/advertising/choice.png" 
							alt=""
						>
						<div class='text1' style='margin-left: 1.5625rem;'>
							{{item.text}}
						</div>
					</div>
				</div>
				<div class='text1' style='margin-top: 2.75rem;'>
					您的出书需求<span style='font-size: 1.375rem;'>(增值服务)</span>
				</div>
				<el-input v-model="input"></el-input>
				<div 
					style='display: flex;align-items: center;cursor: pointer;margin-top: 1.6875rem;'
					@click='ck_setAgreement'
				>
					<img
						v-if='isAgreement'
						style='width: 2rem;height: 2rem;' 
						src="../assets/advertising/isChoice.png" alt=""
					>
					<img
						v-else
						style='width: 2rem;height: 2rem;' 
						src="../assets/advertising/choice.png" alt=""
					>
					<div class='text2'>
						自动输入历史手机号<span class='text3'>《个人信息授权与保护声明》</span>
					</div>
				</div>
				<div style='height: 12.5rem;'></div>
				<div class='btnBox' style=''>
					<div class='btn1'>
						<img
							style='width: 2.25rem;height: 2.25rem;'
							src="../assets/advertising/phone.png" alt=""
						>
						<div class='text4' style='margin-top: 0.875rem;'>
							作家咨询
						</div>
					</div>
					<div class='btn2 DIS-CENTER'>
						出版策划/免费报价
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				top1: 0,
				top2: 0,
				dataList: [
					{
						name: '黄**',
						phone: '155****4420',
						time: '1分钟前'
					},
					{
						name: '沈**',
						phone: '155****4420',
						time: '1分钟前'
					},
					{
						name: '吕**',
						phone: '155****4420',
						time: '1分钟前'
					},
					{
						name: '钟**',
						phone: '155****4420',
						time: '1分钟前'
					},
					{
						name: '付**',
						phone: '155****4420',
						time: '1分钟前'
					},
					{
						name: '泽**',
						phone: '155****4420',
						time: '1分钟前'
					},
					{
						name: '塔**',
						phone: '155****4420',
						time: '1分钟前'
					},
				],
				input: '',
				radio1: 1,
				radio2: 1,
				printingList: [
					{
						text: '16开',
						active: false
					},
					{
						text: '32开',
						active: false
					},
					{
						text: '平装',
						active: false
					},
					{
						text: '精装',
						active: false
					},
					{
						text: '黑白印刷',
						active: false
					},
					{
						text: '彩色印刷',
						active: false
					},
					{
						text: '需要勒口',
						active: false
					},
					{
						text: '需要护封',
						active: false
					}
				],
				isAgreement: false
			}
		},
		created() {
			this.top2 = this.dataList.length * 50;
			setInterval(()=>{
				this.top1 = this.top1 - 1;
				this.top2 = this.top2 - 1;
				if(this.top1 == -(this.dataList.length * 50)) {
					this.top1 = this.dataList.length * 50;
				}
				if(this.top2 == -(this.dataList.length * 50)) {
					this.top2 = this.dataList.length * 50;
				}
			},30)
		},
		methods: {
			ck_radio1(value) {
				this.radio1 = value;
			},
			ck_radio2(value) {
				this.radio2 = value;
			},
			ck_chose(index) {
				this.printingList[index].active = !this.printingList[index].active;
			},
			ck_setAgreement() {
				this.isAgreement = !this.isAgreement;
			}
		}
	}
</script>

<style scoped>
	.Advertising {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-image: linear-gradient(to bottom right, #ffe1b4, #ca5d60 );
	}
	
	.content {
		width: 46.875rem;
	}
	
	.first-img {
		width: 46.875rem;
		height: 59.375rem;
		position: absolute;
	}
	
	.content-box {
		background-color: rgb(153,49,50);
		/* height: 5000px; */
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	
	.second-img {
		width: 42.9375rem;
		position: absolute;
		top: 830px;
		z-index: 9;
	}
	
	.third-img {
		width: 41.25rem;
		margin-top: 1950px;
	}
	
	.four-box {
		width: 41.25rem;
		height: 28.5625rem;
		background: url(../assets/advertising/advertising-four.png);
		background-size: 100% 100%;
		margin-top: 3.125rem;
	}
	
	.item {
		height: 3.125rem;
		line-height: 3.125rem;
		display: flex;
		justify-content: space-between;
		font-size: 24px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #595845;
		width: 26.875rem;
	}
	
	.item-box {
		position: absolute;
	}
	
	.inputBox {
		background-color: rgb(153,49,50);
		padding: 4.125rem 2.6875rem 0 2.6875rem;
	}
	
	.text1 {
		font-size: 28px;
		font-family: PingFang SC;
		font-weight: 600;
		color: #FFEACE;
	}
	
	.inputBox>>>.el-input__inner{
		width: 41.25rem;
		height: 80px;
		border-radius: 0;
		font-size: 1.75rem;
		margin-top: 1.25rem;
	}
	
	.radioBox {
		display: flex;
		margin-top: 2.6875rem;
	}
	
	.choiceBox {
		display: flex;
		flex-wrap: wrap;
	}
	
	.text2 {
		font-size: 24px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}
	
	.text3 {
		color: #FFD9A8;
	}
	
	.btn1 {
		width: 210px;
		height: 6.25rem;
		background-color: #FFFFFF;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	
	.text4 {
		font-size: 24px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #B86969;
	}
	
	.btn2 {
		width: 33.75rem;
		height: 6.25rem;
		background-color: #E66868;
		font-size: 36px;
		font-family: PingFang SC;
		font-weight: 600;
		color: #FFFFFF;
	}
	
	.btnBox {
		display: flex;
		position: fixed;
		bottom: 0;
		left: 50%;
		margin-left: -23.4375rem;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		z-index: 11;
	}
</style>
