<template>
	<div class='personalDp'>
		<!-- 头部 -->
		<div style='display: flex;align-items: center;justify-content: space-between;'>
			<div style='display: flex;align-items: center;margin: 0.9375rem;'>
				<div class='headerLeft'></div>
				<div class='title' style='margin-left: 1.125rem;'>全部店铺</div>
			</div>
			<!-- <div style='display: flex;'>
				<div style='width: 12.125rem;height: 1.75rem;'>
					<el-input v-model="input" placeholder="请输入店铺名"></el-input>
				</div>
				<div class='seathBtm DIS-CENTER'>
					搜索
				</div>
			</div> -->
		</div>
	
		<div 
			v-show='total > 0'
			class='contentItem'
			v-for='(item,index) in shopList'
			:key='index'
		>
			<div style='display: flex;align-items: center'>
				<img
					style='width: 3.125rem;height: 3.125rem;'
					:src="item.shop_info.shopImg" alt=""
				>
				<div style='margin-left: 1.0625rem;'>
					<div 
						style='width: 5.625rem;overflow: hidden;white-space:nowrap;text-overflow:ellipsis;'
						class='text1'
					>
						{{item.shop_info.shopName}}
					</div>
					<img 
						style='width: 0.75rem;height: 0.75rem;cursor: pointer;' 
						src="../../assets/PersonalCenter/contact-kf.png" alt=""
					>
				</div>
				<div style='margin-left: 1.625rem;'>
					<img
						v-for='(item1,index1) in item.recom'
						:key='index1'
						style='width: 5rem;height: 5rem;border: 1px solid #E6E6E6;margin-left: 0.625rem;'
						:src="item1.goodsImg" alt=""
					>
				</div>
			</div>
			<div style='display: flex;align-items: center;'>
				<div v-if='item.isfollow==0' class='btn1 DIS-CENTER' @click='ck_collect(item.shop_info.shopId,1)'>收藏店铺</div>
				<div v-else class='btn2 DIS-CENTER' @click='ck_collect(item.shop_info.shopId,2)'>
					取消收藏
				</div>
				<!-- <div class='btn2 DIS-CENTER' style='margin-left: 0.625rem;'>删除</div> -->
			</div>
		</div>
		
		<div class='sorter' v-if='total > 0'>
			<el-pagination
				background
				layout="pager"
				:total="total"
				@current-change='ck_setPage'
			>
			</el-pagination>
		</div>
		
		<div v-if='total < 1'>
			<NoneItem title='您还没有购买过商品哦~' text='请快去购买您心仪的商品吧'/>
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	
	import NoneItem from '../NoneItem.vue';
	
	export default {
		components: { NoneItem },
		data() {
			return {
				input: '',
				shopList: [], // 店铺列表
				total: 0, //分页器的数据
				page: 1,
			}
		},
		created() {
			this.initData();
		},
		methods: {
			initData() {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					page: this.page
				}
				this.axios.post(Api.hasBuyShop,data).then(res=>{
					if(res.data.code == 200) {
						this.shopList = res.data.data.shop;
						this.total = res.data.data.count;
					} else {
						this.$message.warning(res.data.msg);
					}
				})
			},
			ck_setPage(value) {
				this.page = value;
				this.initData();
			},
			ck_collect(id,type) {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					id,
					type,
				}
				this.axios.post(Api.followShop,data).then(res=>{
					if(res.data.code == 200) {
						this.$message.success(res.data.msg);
						this.initData();
					} else {
						this.$message.warning(res.data.msg);
					}
				})
			}
		}
	}
</script>

<style scoped>
	.personalDp {
		width: 58.4375rem;
		min-height: 73.4375rem;
		background-color: #FFFFFF;
		margin-left: 0.5625rem;
		padding-top: 3rem;
	}
	
	.headerLeft {
		width: 6px;
		height: 20px;
		background: #EE4454;
	}
	
	.title {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.personalDp>>>.el-input__inner{
		font-size: 12px!important;
		width: 12.125rem;
		height: 1.75rem;
		border-radius: 0;
		border-right: none;
	} 
	
	.seathBtm {
		width: 60px;
		height: 28px;
		background: #EFEFEF;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
		margin-right: 1.875rem;
	}
	
	.contentItem {
		width: 54.75rem;
		border-bottom: 1px solid #E6E6E6;
		padding: 28px 0;
		margin-left: 1.875rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.btn1 {
		width: 70px;
		height: 28px;
		background: #EE4454;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
	}
	
	.btn2 {
		width: 70px;
		height: 28px;
		border: 1px solid #E6E6E6;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
	}
	
	.sorter {
		width: 57.5rem;
		display: flex;
		justify-content: flex-end;
		margin-top: 1.875rem;
	}
	
	.sorter>>>.el-pager li {
		background-color: transparent !important;
		color: #333333 !important;
		border: 1px solid #E6E6E6;
		width: 36px;
		height: 36px;
		line-height: 2.25rem;
	}
	
	.sorter>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color:  #333333 !important;
		color: white!important;
	}
</style>
