<template>
	<div class='EditPhone'>
		<div>
			<Header bgIndex='4' @dialogBoxIndex="dialogBoxIndex" />
		</div>
		
		<div class='SY-CONTENT' style='background-color: #F5F5F5;'>
			<div class='content'>
				<!-- 面包屑 -->
				<div class='BreadCrumbs'>
					<div 
						class='breadText1' 
						style='cursor: pointer;'
						@click='ck_goPage("/PersonalCenter")'
					>
						主页
					</div>
					<div class='breadText1' style='margin: 0 1.5625rem;'>/</div>
					<div 
						class='breadText1' 
						style='cursor: pointer;'
						@click='ck_goPage("/PersonalCenter",9)'
					>
						账户设置
					</div>
					<div class='breadText1' style='margin: 0 1.5625rem;'>/</div>
					<div class='breadText2'>修改手机号</div>
				</div>
				
				<!-- 内容区 -->
				<div class='title DIS-CENTER'>
					您正在为账户 <span>{{userInfo.newUserPhone}}</span> 修改手机号，请选择修改方式：
				</div>
				<div 
					class='DIS-CENTER' 
					style='justify-content: flex-start;margin-left: 19.4375rem;margin-top: 46px;'
				>
					<div class='text1'>当前绑定手机是否可用</div>
					<img
						v-if='radioValue == 0'
						style='width: 1rem;height: 1rem;margin-left: 1.875rem;cursor: pointer;'
						src="../assets/images/radio_btn_selected@2x.png" alt=""
					>
					<img
						v-else
						style='width: 1rem;height: 1rem;margin-left: 1.875rem;cursor: pointer;'
						src="../assets/images/radio_btn@2x.png" alt=""
						@click='ck_setRadio(0)'
					>
					<div class='text1' style='margin-left: 0.9375rem;'>是</div>
					<img
						v-if='radioValue == 1'
						style='width: 1rem;height: 1rem;margin-left: 3.5rem;cursor: pointer;'
						src="../assets/images/radio_btn_selected@2x.png" alt=""
					>
					<img
						v-else
						style='width: 1rem;height: 1rem;margin-left: 3.5rem;cursor: pointer;'
						src="../assets/images/radio_btn@2x.png" alt=""
						@click='ck_setRadio(1)'
					>
					<div class='text1' style='margin-left: 1.0625rem;'>否</div>
				</div>
				
				<div>
					<!-- 当前绑定手机 可用 -->
					<div v-if='radioValue == 0' style='margin-left: 24.6875rem;'>
						<PhoneAvailable :userInfo='userInfo' />
					</div>
					<!-- 当前绑定手机  不可用 -->
					<div v-else>
						<!-- 个人 状态-->
						<div v-if='true' style='margin-left: 385px;'>  
							<PersonalPhoneAvailable :userInfo='userInfo' />
						</div>
						<!-- 企业 状态-->
						<div v-else style='margin-left: 280px;'>
							<EnterprisePhoneAvaliable  :userInfo='userInfo'/>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class='SY-CONTENT' style='background-color: #333333;'>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Header from '../components/Header.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	import PhoneAvailable from '../components/SetPayPassword/PhoneAvailable.vue';
	import PersonalPhoneAvailable from '../components/SetPayPassword/PersonalPhoneAvailable.vue';
	import EnterprisePhoneAvaliable from '../components/SetPayPassword/EnterprisePhoneAvaliable.vue';
	
	export default {
		components: {
			Header,
			HomeFooter,
			PhoneAvailable,
			PersonalPhoneAvailable,
			EnterprisePhoneAvaliable
		},
		data() {
			return {
				radioValue: 0,
				userInfo:{},
				isShowDialogBox:false,
			}
		},
		mounted() {
			this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
			this.userInfo.newUserPhone=this.userInfo.userPhone.substr(0,3) + '****' + this.userInfo.userPhone.substr(7);
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			ck_goPage(path,type) {
				this.$router.push({path,query:{type}});
			},
			ck_setRadio(value) {
				this.radioValue = value;
			}
		}
	}
</script>


<style scoped>
	.EditPhone {
		
	}
	
	.content {
		background-color: #FFFFFF;
		width: 75rem;
		min-height: 73.4375rem;
		padding-top: 1.875rem;
	}
	
	.BreadCrumbs {
		display: flex;
		align-items: center;
		margin-left: 2.5rem;
	}
	
	.breadText1 {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.breadText2 {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.title {
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		width: 75rem;
		margin-top: 7.8125rem;
	}
	
	.title span {
		color: #EE4454;
	}
	
	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
</style>