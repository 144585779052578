<template>
	<div class="PublishBox">
		<!-- 顶部 -->
		<div class="PublishBox_PublishHeader">
			<PublishContent @dialogBoxIndex="dialogBoxIndex" />
		</div>

		<!-- 中间 -->
		<div class="contentBox">
			<!-- 左侧菜单 -->
			<div class="menuBox">
				<div style="height: 5.3125rem;"></div>
				<!-- 上传推荐卡 -->
				<img src="../assets/Publish/publish.png" class="img2" @click="ck_publish" />
				<!-- 菜单栏 -->
				<div v-for="(item,index) in menuList" :key="index" style="display: flex;flex-direction: column;">
					<div :class="item.active?'active menuItem':'menuItem'" @click="ck_menu(index)"
						style="cursor: pointer;">
						<div style="display: flex;align-items: center;">
							<img v-if='item.active' class='c_img3' :src="item.Aimg">
							<img v-else class='c_img3' :src="item.img">
							<div :class="item.active?'c_text2 c_text':'c_text1 c_text'" style='margin-left: 1.25rem;'>
								{{item.text}}
							</div>
						</div>
						<img v-if="index==1" :src="isShowMenuListSec?topImg:bottomImg" class="img3" />
					</div>

					<!-- 二级菜单 -->
					<div v-if="index==1 && isShowMenuListSec">
						<div v-for="(items,indexs) in menuListSec" :key="indexs" @click="ck_menuSec(indexs)"
							style="cursor: pointer;">
							<div :class="items.active?'active menuItem menuListSecItem':'menuItem menuListSecItem'">
								<div style="display: flex;">
									<img v-if='items.active' class='c_img3' :src="items.Aimg">
									<img v-else class='c_img3' :src="items.img">
									<div :class="items.active?'c_text2 c_text':'c_text1 c_text'"
										style='margin-left: 1.25rem;'>{{items.text}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 左侧菜单页面 -->
			<div v-if="!isShowPublish" class="rightContent">
				<!-- 首页 -->
				<div v-if="menuValue==0">
					<PublishIndex />
				</div>

				<!-- 发布管理 -->
				<div v-if="menuValue==1">
					<!-- 稿件管理 -->
					<div v-if="menuSecValue==0">
						<PublishContentgjgl />
					</div>
					<!-- 申诉管理 -->
					<div v-if="menuSecValue==1">
						<PublishContentssgl />
					</div>
				</div>

				<!-- 粉丝管理 -->
				<div v-if="menuValue==2">
					<PublishContentfsgl />
				</div>

				<!-- 评论管理 -->
				<div v-if="menuValue==3">
					<PublishContentplgl @checkScorll='checkScorll' :newCheckScorll='newCheckScorll' />
				</div>

				<!-- 收益管理 -->
				<div v-if="menuValue==4">
					<PublishContentsygl />
				</div>

				<!-- 设置 -->
				<div v-if="menuValue==5">
					<PublishContentsz />
				</div>
			</div>

			<!-- 右侧 发布  -->
			<div v-if="isShowPublish && publistNum==0" class="rightTw">
				<!-- 头 -->
				<div class="pub_header">
					<div :class="item.active?'pub_headerBox_active pub_headerBox':'pub_headerBox'"
						v-for="(item,index) in PublishMenuList" :key="index" @click="ck_PublishMenu(index)">
						<div :class="item.active?'pub_headerBox_textActive':'pub_headerBox_text'">{{item.text}}</div>
					</div>
				</div>

				<!-- 内容 -->
				<div v-if="PublishMenuValue==0">
					<Publishtw @publist='publist' @publistTitle='publistTitle' />
				</div>
				<div v-else>
					<Publishvideoaudio :PublishMenuType='PublishMenuValue' @publist='publist'
						@publistTitle='publistTitle' />
				</div>
			</div>

			<!-- 上传成功步骤条 -->
			<div v-if="isShowPublish && publistNum!=0" class="rightTw">
				<PublishSteps :publistNum='publistNum' :publistTitleNew='publistTitleNew' @goOnPublist='goOnPublist' />
			</div>
		</div>

		<!-- 页脚 -->
		<div class="footer">
			<div class="footer_text">Copyright© 书营网 2019-2020 , All Rights Reserved 浙ICP证021908号 杭州滨江分局备案编号 1101082227
			</div>
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import PublishContent from '../components/PublishContent/PublishHeader.vue'
	import PublishIndex from '../components/PublishContent/PublishIndex.vue'
	import PublishContentfsgl from '../components/PublishContent/PublishContentfsgl.vue'
	import PublishContentplgl from '../components/PublishContent/PublishContentplgl.vue'
	import PublishContentsygl from '../components/PublishContent/PublishContentsygl.vue'
	import PublishContentsz from '../components/PublishContent/PublishContentsz.vue'
	import PublishContentgjgl from '../components/PublishContent/PublishContentfbgl/PublishContentgjgl.vue'
	import PublishContentssgl from '../components/PublishContent/PublishContentfbgl/PublishContentssgl.vue'
	import Publishtw from '../components/PublishContent/PublishValue/PublishTw.vue'
	import Publishvideoaudio from '../components/PublishContent/PublishValue/PublishVideoAudio.vue'
	import PublishSteps from '../components/PublishContent/PublishValue/PublishSteps.vue'

	import menuSy from '../assets/Publish/menu-sy.png'
	import menuSyA from '../assets/Publish/menu-sy-act.png'
	import menuFbgl from '../assets/Publish/menu-fbgl.png'
	import menuFsgl from '../assets/Publish/menu-fsgl.png'
	import menuFsglA from '../assets/Publish/menu-fsgl-act.png'
	import menuPlgl from '../assets/Publish/menu-plgl.png'
	import menuPlglA from '../assets/Publish/menu-plgl-act.png'
	import menuSygl from '../assets/Publish/menu-sygl.png'
	import menuSyglA from '../assets/Publish/menu-sygl-act.png'
	import menuSz from '../assets/Publish/menu-sz.png'
	import menuSzA from '../assets/Publish/menu-sz-act.png'
	import menuGjgl from '../assets/Publish/menu-gjgl.png'
	import menuGjglA from '../assets/Publish/menu-gjgl-act.png'
	import menuSsgl from '../assets/Publish/menu-ssgl.png'
	import menuSsglA from '../assets/Publish/menu-ssgl-act.png'
	import arrowImg from '../assets/images/arrow-bottom.png'
	import arrowImgA from '../assets/images/arrow_up@2x.png'
	export default {
		components: {
			PublishContent,
			PublishIndex,
			PublishContentfsgl,
			PublishContentplgl,
			PublishContentsygl,
			PublishContentsz,
			PublishContentgjgl,
			PublishContentssgl,
			Publishtw,
			Publishvideoaudio,
			PublishSteps,
		},
		data() {
			return {
				publistTitleNew: '',
				publistNum: 0,
				PublishMenuValue: 0,
				PublishMenuList: [{
						text: '图文推荐',
						active: true,
					},
					{
						text: '音频推荐',
						active: false,
					},
					{
						text: '视频推荐',
						active: false,
					},
				],
				menuValue: 0,
				menuList: [{
						text: '首页',
						img: menuSy,
						Aimg: menuSyA,
						active: true
					},
					{
						text: '发布管理',
						img: menuFbgl,
						Aimg: menuFbgl, // 发布管理 点击
						active: false
					},
					{
						text: '粉丝管理',
						img: menuFsgl,
						Aimg: menuFsglA,
						active: false
					},
					{
						text: '评论管理',
						img: menuPlgl,
						Aimg: menuPlglA,
						active: false
					},
					{
						text: '收益管理',
						img: menuSygl,
						Aimg: menuSyglA,
						active: false
					},
					{
						text: '设置',
						img: menuSz,
						Aimg: menuSzA,
						active: false
					}
				],
				menuListSec: [{
						text: '稿件管理',
						img: menuGjgl,
						Aimg: menuGjglA,
						active: true
					},
					{
						text: '申诉管理',
						img: menuSsgl,
						Aimg: menuSsglA,
						active: false
					}
				],
				menuSecValue: 0,
				isShowMenuListSec: false, // 二级菜单的显示
				topImg: arrowImg,
				bottomImg: arrowImgA,
				isShowPublish: false,
				newCheckScorll: true,
				isShowDialogBox:false,
			}
		},
		mounted() {
			this.getType()
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			checkScorll(data) {
				this.newCheckScorll = data
			},
			goOnPublist(data) {
				this.isShowPublish = data
				this.publistNum = 0
			},
			publist(data) {
				this.publistNum = data
			},
			publistTitle(data) {
				this.publistTitleNew = data
			},
			getType() {
				if (this.$route.query.type) {
					// 推荐管理
					for (let i in this.menuList) {
						this.menuList[i].active = false;
					}
					if (this.$route.query.type == 3) {
						this.menuList[4].active = true
						this.menuValue = 4
					} else {
						// type  0    图文
						// type  1    音频
						// type  2    视频
						// console.log('上传推文 选项卡')
					}
				}
			},
			// 上传
			ck_publish() {
				this.publistNum = 0
				this.isShowMenuListSec = false
				for (let i in this.menuList) {
					this.menuList[i].active = false;
				}
				for (let i in this.menuListSec) {
					this.menuListSec[i].active = false;
				}
				this.isShowPublish = true
			},
			// 一级菜单
			ck_menu(index) {
				this.publistNum = 0
				this.isShowPublish = false
				for (let i in this.menuList) {
					this.menuList[i].active = false;
				}
				this.menuList[index].active = true;
				this.menuValue = index

				if(this.menuValue==3){
					this.newCheckScorll=true
				}else{
					this.newCheckScorll=false
				}

				if (index == 1) {
					this.menuList[1].active = false;

					if (this.isShowMenuListSec) {
						this.isShowMenuListSec = false
					} else {
						this.isShowMenuListSec = true
					}
					// 点击上传推文  再次点击发布管理  active全部==false 的情况 
					if (this.menuListSec[0].active == false && this.menuListSec[1].active == false) {
						this.menuListSec[this.menuSecValue].active = true
					}
				} else {
					this.isShowMenuListSec = false
				}
			},
			// 二级菜单
			ck_menuSec(index) {
				this.publistNum = 0
				this.isShowPublish = false
				for (let i in this.menuListSec) {
					this.menuListSec[i].active = false;
				}
				this.menuListSec[index].active = true;
				this.menuSecValue = index;
			},

			// 上传推文 菜单
			ck_PublishMenu(index) {
				for (let i in this.PublishMenuList) {
					this.PublishMenuList[i].active = false
				}
				this.PublishMenuList[index].active = true
				this.PublishMenuValue = index
			}
		},
	}
</script>

<style>
	.PublishBox {
		background-color: #F2F2F2;
		width: 100%;
		min-width: 90rem;
	}

	.PublishBox_PublishHeader {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 1000;
		height: 3.75rem !important;
		min-width: 90rem;
	}

	.PublishBox .contentBox {
		width: 100%;
		display: flex;
		min-height: 57.9375rem;
	}

	.contentBox .menuBox {
		background: #FFFFFF;
		width: 16.5625rem !important;
		height: 100%;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
	}

	.menuBox .img2 {
		width: 12.5rem;
		height: 3.125rem;
		margin: 0 2.125rem 2.125rem 1.9375rem;
		cursor: pointer;
	}

	.menuBox .img3 {
		width: 0.75rem;
		height: 0.75rem;
	}

	.menuBox .active {
		border-left: 0.3125rem solid #EE4454;
		background: #FBFBFB;
	}

	.menuBox .menuItem {
		padding: 1.1875rem 1.3125rem 1.3125rem 1.9375rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.c_text {
		color: #333333;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-size: 18px;
	}

	.c_text2 {
		font-weight: 600;
	}

	.c_text1 {
		font-weight: 400;
	}

	.c_img3 {
		width: 1.25rem;
		height: 1.25rem;
	}

	.menuBox .menuListSecItem {
		padding: 1.1875rem 1.3125rem 1.3125rem 3rem;
	}

	.contentBox .rightContent {
		margin: 5rem 1.5rem 0 17.875rem;
		background-color: #FFFFFF;
		width: 100%;
	}

	.contentBox .rightTw {
		margin: 5rem 1.5rem 0 17.875rem;
		width: 100%;
	}

	.footer {
		padding: 1.5625rem 1.5rem 1.5rem 17.875rem;
	}

	.footer_text {
		text-align: center;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}

	.rightTw .pub_header {
		width: 100%;
		display: flex;
		background: #FFFFFF;
		border-bottom: 1px solid #E8E8E8;
	}

	.rightTw .pub_headerBox {
		margin-right: 5.5rem;
		margin-top: 2.1875rem;
		padding-bottom: 1.3125rem;
		margin-left: 3.9375rem;
		cursor: pointer;
	}

	.rightTw .pub_headerBox_active {
		border-bottom: 0.25rem solid #EE4454;
	}

	.rightTw .pub_headerBox_text {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.rightTw .pub_headerBox_textActive {
		font-weight: 600;
		color: #EE4454;
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
	}
</style>
