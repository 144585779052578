<template>
	<div class='ApplyRefundFirst'>
		<div class='item'>
			<div class='title' style='margin-top: 0.9375rem;'>
				所有商品
			</div>
			<div style='margin-left: 2.125rem;'>
				<div v-if='false' class='goodsItem1'>
					<img class='img1' src="../../assets/images/homethirddemo.png" alt="">
					<div style='margin-left: 1.0625rem;'>
						<div class='textBox1'>
							价格的发现复杂约束市场中的拍卖设计（全新修订版 精装）上海人民出版社
						</div>
						<div>
							<div class='counterBox'>
								<div 
									class='DIS-CENTER' 
									style='flex: 1;cursor: pointer;'
									@click='ck_counter("del")'
								>
									-
								</div>
								<div class='DIS-CENTER counterBox-num' style='flex: 1.5;'>{{num}}</div>
								<div 
									class='DIS-CENTER' 
									style='flex: 1;cursor: pointer;'
									@click='ck_counter("add")'
								>
									+
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else class='goodsItem2'>
					<div 
						class='goodsItem1' 
						style='border: none;width: 19.0625rem;border-bottom: 1px solid #E6E6E6;margin-left: 0.9375rem;'
						v-for='(item,index) in goodsList'
						:key='index'
					>
						<img class='img1' style='margin-left: 0;' src="../../assets/images/homethirddemo.png" alt="">
						<div style='margin-left: 0.9375rem;'>
							<div class='textBox1'>
								价格的发现复杂约束市场中的拍卖设计（全新修订版 精装）上海人民出版社
							</div>
							<div>
								<div class='counterBox'>
									<div 
										class='DIS-CENTER' 
										style='flex: 1;cursor: pointer;'
										@click='ck_counter("del",index)'
									>
										-
									</div>
									<div class='DIS-CENTER counterBox-num' style='flex: 1.5;'>{{item.num}}</div>
									<div 
										class='DIS-CENTER' 
										style='flex: 1;cursor: pointer;'
										@click='ck_counter("add",index)'
									>
										+
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class='item' style='margin-top: 2.5rem;'>
			<div class="title">
				<span style='color: #EE4454;'>*</span>服务类型
			</div>
			<div class='DIS-CENTER' style='margin-left: 2.1875rem;'>
				<img 
					v-if='radioValue==0'
					class='radio' 
					src="../../assets/images/radio_btn_selected@2x.png" 
					alt=""
				>
				<img 
					v-else
					class='radio' 
					src="../../assets/images/radio_btn@2x.png" 
					alt=""
					@click='ck_radio(0)'
				>
				<div class='text1' style='margin-left: 0.9375rem;'>
					仅退款
				</div>
				
				<img
					v-if='radioValue==1'
					class='radio' 
					style='margin-left: 3.4375rem;'
					src="../../assets/images/radio_btn_selected@2x.png" 
					alt=""
				>
				<img 
					v-else
					class='radio' 
					style='margin-left: 3.4375rem;'
					src="../../assets/images/radio_btn@2x.png" 
					alt=""
					@click='ck_radio(1)'
				>
				<div class='text1' style='margin-left: 0.9375rem;'>
					退货退款
				</div>
			</div>
		</div>
		<div class="item" style='align-items: center;margin-top: 1.4375rem;'>
			<div class="title">
				<span style='color: #EE4454;'>*</span>退款原因
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-select v-model="value" placeholder="请选择">
					<el-option
						v-for="item in options"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
					</el-option>
				</el-select>
			</div>
		</div>
		<div class="item" style='align-items: center;margin-top: 2.0625rem;'>
			<div class="title">
				<span style='color: #EE4454;'>*</span>退款金额
			</div>
			<div style='width: 340px;height: 40px;border: 1px solid #DDDDDD;margin-left: 2.1875rem;'>
				<div style='margin-left: 1rem;color: #EE4454;height: 2.5rem;line-height: 2.5rem;'>
					￥59.00
				</div>
			</div>
		</div>
		<div class="item" style='margin-top: 36px;'>
			<div class="title" style='margin-top: 0.75rem;'>
				<span style='color: #EE4454;'>*</span>退款说明
			</div>
			<div style='width: 21.25rem;margin-left: 2.25rem;'>
				<el-input
					resize="none"
					type="textarea"
					:autosize="{ minRows: 4, maxRows: 4}"
					placeholder="请输入退款说明"
					v-model="textarea1"
				>
				</el-input>
			</div>
		</div>
		<div class="item" style="margin-top: 1.875rem;">
			<div class="title" style='margin-top: 0.75rem;'>
				上传图片
			</div>
			<div style='margin-left: 2.25rem;'>
				<img
					style='width: 6.25rem;height: 6.25rem;cursor: pointer;' 
					src="../../assets/images/sczj.png" alt=""
				>
				<div 
					class='title' 
					style='margin-top: 1.4375rem;font-size: 0.75rem;color: #EE4454;width: 21.25rem;'
				>
					(上传图片支持jpg、png、jpeg格式，大小不超过10M，最多3张)
				</div>
			</div>	
		</div>
		<div class='btn DIS-CENTER'>
			提交
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				radioValue: 0,
				options: [{
					value: '选项1',
					label: '黄金糕'
				}, {
					value: '选项2',
					label: '双皮奶'
				}, {
					value: '选项3',
					label: '蚵仔煎'
				}, {
					value: '选项4',
					label: '龙须面'
				}, {
					value: '选项5',
					label: '北京烤鸭'
				}],
				value: '',
				textarea1: '',
				// 单件退款
				num: 1,
				// 多件退款，
				goodsList: [
					{
						title: '价格的发现复杂约束市场中的拍卖设计（全新修订版 精装）上海人民出版社',
						num: 1
					},
					{
						title: '价格的发现复杂约束市场中的拍卖设计（全新修订版 精装）上海人民出版社',
						num: 1
					},{
						title: '价格的发现复杂约束市场中的拍卖设计（全新修订版 精装）上海人民出版社',
						num: 1
					}
				]
				
			}
		},
		methods: {
			ck_radio(value) {
				this.radioValue = value;
			},
			ck_counter(type,index) {
				if(index ||index == 0) {
					if(type == 'del') {
						if(this.goodsList[index].num > 1) {
							this.goodsList[index].num--;
							return 
						}
					}
					if(type == 'add') {
						this.goodsList[index].num++;
						return 
					}
				} else {
					if(type == 'del') {
						if(this.num > 1) {
							this.num --;
						}
						return 
					}
					if(type == 'add') {
						this.num ++;
					}
				}
				
				
			},
		}
	}
</script>

<style scoped>
	.ApplyRefundFirst {
		
	} 
	
	.item {
		display: flex;
	}
	
	.title {
		width: 4.375rem;
		display: flex;
		justify-content: flex-end;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.text2 {
		font-size: 14px;
		font-family: AlibabaPuHuiTiH;
		color: #333333;
		font-weight: 600;
	}
	
	.radio {
		width: 1rem;
		height: 1rem;
		cursor: pointer;
	}
	
	.ApplyRefundFirst>>>.el-input__inner{
		width: 340px;
		height: 44px;
		border: 1px solid #DDDDDD; 
	} 
	
	.counterBox {
		display: flex;
		align-items: center;
		width: 5.375rem;
		height: 1.5rem;
		border: 1px solid #E6E6E6;
		color: #CCCCCC;
		font-weight: 600;
		font-size: 1.125rem;
		margin-top: 0.5625rem;
	}
	
	.counterBox-num {
		border-left: 1px solid #E6E6E6;
		border-right: 1px solid #E6E6E6;
		font-weight: 400;
		font-size: 14px;
		font-family: AlibabaPuHuiTiR;
		color: #333333;
	}
	
	.btn {
		width: 140px;
		height: 44px;
		background: #EE4454;
		border-radius: 4px;
		cursor: pointer;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		margin-left: 5.75rem;
		margin-top: 2.625rem;
		margin-bottom: 8.625rem;
	}
	
	.goodsItem1 {
		width: 335px;
		height: 111px;
		border: 1px solid #DDDDDD;
		display: flex;
		align-items: center;
	}
	
	.img1 {
		width: 80px;
		height: 80px;
		border: 1px solid #E6E6E6;
		margin-left: 0.875rem;
	}
	
	.textBox1 {
		width: 208px;
		height: 36px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.goodsItem2 {
		width: 335px;
		height: 221px;
		border: 1px solid #DDDDDD;
		overflow: auto;
	}
</style> 
