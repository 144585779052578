<template>
	<!-- 申诉管理 -->
	<div class="PublishContentssgl">
		<div class="PublishContentssgl_header">申诉管理</div>
		<div class="PublishContentssgl_menu">
			<div class="PublishContentssgl_menuBox">
				<div class="PublishContentssgl_menuItem"
					:style="index==0 || index==1 || index==2?'border-right: 0.0625rem solid #FFFFFF;':''"
					v-for="(item,index) in ssglListMenu" :key='index'>
					<div :class="item.active?'ssglListMenu_text1_active':'ssglListMenu_text1'">{{item.text}}</div>
					<div :class="item.active?'ssglListMenu_text1_active':'ssglListMenu_text1'">{{item.num}}</div>
				</div>
			</div>
		</div>
		
		<div class="DIS-CENTER" style="display: flex;flex-direction: column;">
			<img src='../../../assets/none/gjgl_none.png'
				style="width: 26.25rem;height: 16.25rem;margin-top:9.0625rem;" />
			<div class="pub_headerBox_text1">当前一个稿件都没有，请换个筛选条件~</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				ssglListMenu: [{
						text: '全部稿件',
						num: 0,
						active: true,
					},
					{
						text: '进行中',
						num: 0,
						active: false,
					},
					{
						text: '已通过',
						num: 0,
						active: false,
					},
					{
						text: '未通过',
						num: 0,
						active: false,
					},
				]
			}
		}
	}
</script>

<style>
	.PublishContentssgl {
		margin-top: 1.5rem;
	}

	.PublishContentssgl .PublishContentssgl_header {
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-left: 1.9375rem;
	}

	.PublishContentssgl_menu {
		margin-top: 1.4375rem;
		margin-left: 2rem;
	}

	.PublishContentssgl_menuBox {
		display: flex;
		justify-content: flex-start;
	}

	.PublishContentssgl_menuItem {
		border: 0.0625rem solid #E8E8E8;
		padding: 0.75rem 1.4375rem 0.75rem 1.625rem;
		display: flex;
	}

	.ssglListMenu_text1_active {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		margin-left: 0.5625rem;
		color: #EE4454;
	}

	.ssglListMenu_text1 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-left: 0.5625rem;
	}

	.pub_headerBox_text1 {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-top: 2rem;
	}
</style>
