<template>
	<div class='EnterprisePhoneAvaliable'>
		<div class='itemBox' style='margin-top: 3.25rem;'>
			<div class='text1'>
				<span>*</span>营业执照注册号
			</div>
			<div class='inputBox'>
				<el-input placeholder="请输入营业执照注册号"></el-input>
			</div>
		</div>
		<div class='itemBox' style='margin-top: 1.875rem;'>
			<div class='text1'>
				<span>*</span>证件类型
			</div>
			<div class='inputBox'>
				<el-select v-model="value" placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
		</div>
		<div class='text2'>
			(上传图片支持jpg、png、jpeg格式，大小不超过10M)
		</div>
		<div class='itemBox' style='margin-top: 1.375rem;align-items: flex-start;'>
			<div class='text1' style='margin-top: 0.125rem;'>
				<span>*</span>证件类型
			</div>
			<img style='width: 6.25rem;height: 6.25rem;margin-left: 1.6875rem;cursor: pointer;'
				src="../../assets/images/sczj.png" alt="">
		</div>
		<div class='itemBox' style='margin-top: 1.25rem;align-items: flex-start;'>
			<div class='text1' style='margin-top: 0.125rem;'>
				<span>*</span>上传加盖公章的文字说明
			</div>
			<img style='width: 6.25rem;height: 6.25rem;margin-left: 1.6875rem;cursor: pointer;'
				src="../../assets/images/sczj.png" alt="">
		</div>
		<div class='itemBox' style='margin-top: 1.75rem;'>
			<div class='text1'>
				<span>*</span>新手机号
			</div>
			<div class='inputBox'>
				<el-input placeholder="请输入新手机号"></el-input>
			</div>
		</div>
		<div class='itemBox' style='margin-top: 1.75rem;align-items: flex-start;'>
			<div class='text1' style='margin-top: 0.75rem;'>
				备注
			</div>
			<div class='inputBox' style='border: none;height: 6.25rem;'>
				<el-input type="textarea" resize='none' :rows="4" placeholder="请输入内容">
				</el-input>
			</div>
		</div>
		<div class='btn DIS-CENTER'>
			提交
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				options: [{
					value: '1',
					label: '居民身份证'
				}, {
					value: '2',
					label: '临时身份证'
				}, {
					value: '3',
					label: '军官证'
				}, {
					value: '4',
					label: '武警警官证'
				}, {
					value: '5',
					label: '士兵证'
				},{
					value: '6',
					label: '军队学员证'
				}, {
					value: '7',
					label: '军队文职干部证'
				}, {
					value: '8',
					label: '军队离退休干部证和军队职工证'
				},{
					value: '9',
					label: '护照'
				}, {
					value: '10',
					label: '港澳同胞回乡证'
				}, {
					value: '11',
					label: '港澳居民来往内地通行证'
				},{
					value: '12',
					label: '中华人民共和国来往港澳通行证'
				}, {
					value: '13',
					label: '台湾居民来往大陆通行证'
				}, {
					value: '14',
					label: '大陆居民往来台湾通行证'
				},{
					value: '15',
					label: '外国人居留证'
				}, {
					value: '16',
					label: '外国人出入境证'
				}, {
					value: '17',
					label: '外交官证'
				},{
					value: '18',
					label: '领事馆证'
				}, {
					value: '19',
					label: '海员证'
				}],
				value: ''
			}
		}
	}
</script>

<style scoped>
	.EnterprisePhoneAvaliable {}

	.itemBox {
		display: flex;
		align-items: center;
	}

	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		width: 10.625rem;
		display: flex;
		justify-content: flex-end;
	}

	.text1 span {
		color: #EE4454;
	}

	.inputBox {
		width: 340px;
		height: 44px;
		border: 1px solid #DDDDDD;
		margin-left: 1.5rem;
	}

	.inputBox>>>.el-input__inner {
		border: none;
		width: 340px;
		height: 44px;
	}

	.text2 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
		margin-left: 6.875rem;
		margin-top: 1.0625rem;
	}

	.btn {
		width: 340px;
		height: 50px;
		background: #EE4454;
		border-radius: 4px;
		cursor: pointer;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		margin-top: 1.875rem;
		margin-left: 190px;
	}
</style>
