<template>
	<!-- 收益管理 -->
	<div>
		<div class="PublishContentsygl">
			<div class="PublishContentsygl_title">专栏数据</div>

			<div class="PublishContentsygl_listBox">
				<div class="PublishContentsygl_wrapBox" v-for="(item,index) in dataList" :key="index"
					:style="index=3?'margin-top:1.25rem;':''">
					<PublishComponents :item='item' />
				</div>
			</div>


			<!-- margin-top: 43px -->
			<div class="PublishContentsygl_title" style="margin-top: 180px;">收益明细</div>
			<div class="PublishContentsygl_detailed">
				<div v-if='scoreList.length>0'>

					<div v-for="(item,index) in scoreList" :key='index'>
						<div v-if="index<5">
							<div class="PublishContentsygl_contentItem">
								<div class="PublishContentsygl_contentItem_left">
									<div class="leftText1">订单成交：“价值的发现…”</div>
									<div class="leftText2">2021-07-10 00:00:00</div>
								</div>
								<div class="PublishContentsygl_contentItem_right">+50元</div>
							</div>
						</div>

						<div v-if="index>4 && isCheckMore">
							<div class="PublishContentsygl_contentItem">
								<div class="PublishContentsygl_contentItem_left">
									<div class="leftText1">订单成交：“价值的发现…”</div>
									<div class="leftText2">2021-07-10 00:00:00</div>
								</div>
								<div class="PublishContentsygl_contentItem_right">+50元</div>
							</div>
						</div>
					</div>
					<div class="DIS-CENTER checkMore" @click="isCheckMore=true" v-if="!isCheckMore">查看更多</div>
				</div>
				<div v-else class="DIS-CENTER PublishContentsygl_noneText">
					暂无数据
				</div>
			</div>
		</div>
	</div>


</template>

<script>
	import PublishComponents from '../../components/PublishContent/PublishComponents.vue';
	export default {
		data() {
			return {
				// dataList: [{
				// 		title: '成交额',
				// 		num: '20,809',
				// 		dayTitle: '昨日新增:',
				// 		dayNum: 136,
				// 	},
				// 	{
				// 		title: '订单数',
				// 		num: '20,809',
				// 		dayTitle: '昨日新增:',
				// 		dayNum: 136,
				// 	},
				// 	{
				// 		title: '返佣金数',
				// 		num: '20,809',
				// 		dayTitle: '昨日新增:',
				// 		dayNum: 136,
				// 	}
				// ],
				dataList:[],

				scoreList: [{
						text: '订单成交：“价值的发现…”',
						creatTime: '2021-07-10 00:00:00',
						num: '50',
					},
					{
						text: '订单成交：“价值的发现…”',
						creatTime: '2021-07-10 00:00:00',
						num: '50',
					},
					{
						text: '订单成交：“价值的发现…”',
						creatTime: '2021-07-10 00:00:00',
						num: '50',
					},
					{
						text: '订单成交：“价值的发现…”',
						creatTime: '2021-07-10 00:00:00',
						num: '50',
					},
					{
						text: '订单成交：“价值的发现…”',
						creatTime: '2021-07-10 00:00:00',
						num: '50',
					},
					{
						text: '订单成交：“价值的发现…”',
						creatTime: '2021-07-10 00:00:00',
						num: '50',
					}
				],
				isCheckMore: false,
			}
		},
		components: {
			PublishComponents
		}
	}
</script>

<style>
	.PublishContentsygl {
		margin-top: 24px;
	}

	.PublishContentsygl_title {
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-left: 1.875rem;
	}

	.PublishContentsygl_listBox {
		margin: 1.1875rem 2.3125rem 0 1.9375rem;
		display: flex;
		flex-wrap: wrap;
	}

	.PublishContentsygl_wrapBox {
		width: 31.25rem;
	}

	.PublishContentsygl_detailed {
		margin: 33px 42px 0 31px;
	}

	.PublishContentsygl_noneText {
		font-size: 24px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #C4C4C4;
		padding-top: 4.25rem;
	}

	.PublishContentsygl_contentItem {
		border-bottom: 1px solid #E5E5E5;
		display: flex;
		justify-content: space-between;
		padding-top: 13px;
		padding-bottom: 15px;
	}

	.PublishContentsygl_contentItem_left {
		display: flex;
		flex-direction: column;
	}

	.PublishContentsygl_contentItem_left .leftText1 {
		color: #333333;
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		margin-bottom: 6px;
	}

	.PublishContentsygl_contentItem_left .leftText2 {
		color: #999999;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
	}

	.PublishContentsygl_contentItem_right {
		color: #EE4454;
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
	}

	.checkMore {
		margin-top: 31px;
		margin-bottom: 20px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
	}
</style>
