/**
 *  tomcat 部署 图片名称不能使用中文
 */
// 模拟数据
// 用户列表
const user_list = [{
		id: 1,
		name: "七月",
		online: true,
		type: "",
		avatar: "",
		role: 'master'
	},
	{
		id: 1,
		name: "初音",
		online: true,
		type: "",
		// avatar: require("../public/avatar/chuyin.png"),
		role: 'manager'
	},
	{
		id: 2,
		name: "雪",
		online: false,
		type: "",
		// avatar: require("../public/avatar/xue.png"),
		role: 'manager'
	},
	{
		id: 3,
		name: "可可罗",
		online: false,
		type: "",
		// avatar: require("../public/avatar/kekeluo.png"),
	},
	{
		id: 4,
		name: "栞",
		online: false,
		type: "",
		// avatar: require("../public/avatar/kan.png"),
	},
];

// 公告列表
const notice_list = [{
		id: 1,
		type: "文件",
		title: "RocketMQ原理.dock",
	},
	{
		id: 2,
		type: "公告",
		title: "公主连接Rank推荐",
	},
]

// 历史记录
// const history = [{
// 	username: "历史记录",
// 	// avatar: require("../public/avatar/temp2.jpg"),
// 	id: 1,
// 	type: "group",
// 	content: "audio[https://www.w3school.com.cn/i/horse.mp3]",
// 	cid: 0,
// 	mine: false,
// 	fromid: 3,
// 	timestamp: new Date(),
// }, ];

//  测试发送数据
const test_send_msg = [{
		username: "小白",
		// avatar: require("../public/avatar/temp1.jpg"),
		id: 1,
		type: "group",
		content: "img[/emoticon/emoticon_1.jpg]",
		cid: 0,
		mine: false,
		fromid: 2,
		timestamp: new Date(),
	},
	{
		username: "小奶",
		// avatar: require("../public/avatar/temp2.jpg"),
		id: 1,
		type: "group",
		content: "video[https://www.w3school.com.cn/i/movie.mp4]",
		cid: 0,
		mine: false,
		fromid: 3,
		timestamp: new Date(),
	},
	{
		username: "老八",
		// avatar: require("../public/avatar/temp2.jpg"),
		id: 1,
		type: "group",
		content: "audio[https://www.w3school.com.cn/i/horse.mp3]",
		cid: 0,
		mine: false,
		fromid: 3,
		timestamp: new Date(),
	},
]

// 发送者信息
const mine = {
	// 昵称
	username: "七月",
	// 用户id
	id: "10001",
	//状态
	status: "online",
	//签名
	sign: "与其感慨路难行,不如马上出发！",
	// avatar: require("../public/avatar/avatar_meteor.png"),
}

// 默认:系统消息 id:-1    判断类型 type: group群组    sys_msg 系统消息    friend 好友
// 更改默认  最近消息  
// 系统消息 id:-1   type:sys_msg
// 验证消息 id:-2   type:vft_msg
// 好友消息 id:1++  type:friend


const allChats = [{
		text: '最近消息',
		active: true,
	},
	// {
	// 	text: '我的好友',
	// 	active: false,
	// }
]

// 最近消息
const recentNews = [{
		id: 1,
		name: "海喵突基队",
		type: "group",
		userList: user_list,
		notices: [],
		master: 1,
		avatar: '', // 头像
		msg:'你好，这本书有新版吗~',
		createTime:'19:58',
		// avatar: require("../public/avatar/chuyin.png"),
	},
	{
		id: -1,
		name: "系统消息",
		type: "sys_msg",
		online: false,
		avatar: '',
		msg:'书营官方活动将于1月20日在…',
		createTime:'19:58',
	},
	{
		id: 2,
		name: "张三",
		type: "friend",
		online: true,
		avatar: '',
		msg:'是的呢！',
		createTime:'19:58',
	},
	{
		id: 3,
		name: "李四",
		type: "friend",
		online: false,
		avatar: '',
		msg:'好的，谢谢！',
		createTime:'19:58',
	}
]

// 好友列表
const friendChats = []


export default {
	notice_list,
	user_list,
	history,
	test_send_msg,
	mine,
	allChats,
	recentNews,
	friendChats
}
