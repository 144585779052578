<template>
	<div>
		<div v-if='goodsItem' class='GoodsListFrist' @click='ck_goPage("/GoodsDetails",goodsItem.goodsId)'>
			<img class='img' :src="goodsItem.goodsImg" alt="">
			<div class='title'>
				<!-- <img src="../../assets/images/ershou.png" alt="">
				<img src="../../assets/images/ziying.png" alt=""> -->
				<span class='ziying' v-if='goodsItem.isSelfSale == 1'>自营</span>
				<span class='ershou' v-if='goodsItem.isSecondhandBook == 1'>二手</span>
				{{goodsItem.goodsName}}
			</div>
			<div class='user'>
				{{goodsItem.goodsTips}}
			</div>
			<div class='money'>
				<div>￥{{goodsItem.shopPrice}}</div>
				<div style='color: #999999;margin-left: 0.25rem;'>起</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['goodsItem'],
		methods: {
			ck_goPage(path, id) {
				this.$router.push({
					path,
					query: {
						id
					}
				});
			}
		}
	}
</script>

<style scoped>
	.GoodsListFrist {
		width: 8.75rem;
		cursor: pointer;
	}
	
	.GoodsListFrist:hover .title {
		color: #EE4454;
	}

	.GoodsListFrist .img {
		width: 8.75rem;
		height: 8.75rem;
	}

	.GoodsListFrist .title {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-top: 0.5rem;
		width: 8.75rem;
		line-height: 1rem;
		height: 32px;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.GoodsListFrist .user {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-top: 0.375rem;
		width: 8.75rem;
		height: 1rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space:nowrap;
	}

	.GoodsListFrist .money {
		display: flex;
		margin-top: 0.375rem;
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
	}
	
	.title img {
		width: 1.625rem;
		height: 0.875rem;
	}
</style>
