import { render, staticRenderFns } from "./phoneFindPassword.vue?vue&type=template&id=080c4297&scoped=true&"
import script from "./phoneFindPassword.vue?vue&type=script&lang=js&"
export * from "./phoneFindPassword.vue?vue&type=script&lang=js&"
import style0 from "./phoneFindPassword.vue?vue&type=style&index=0&id=080c4297&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "080c4297",
  null
  
)

export default component.exports