<template>
	<div class='PersonalTkgl'>
		<div style='display: flex;align-items: center;justify-content: space-between;'>
			<div style='display: flex;align-items: center;'>
				<div class='line1'></div>
				<div class='title1' style='margin-left: 1.125rem;'>退款管理</div>
			</div>
			<div class='DIS-CENTER'>
				<div class='btn1 DIS-CENTER'>
					我要维权
				</div>
				<div class='btn2 DIS-CENTER' style='margin-left: 0.6875rem;margin-right: 1.1875rem;'>
					我要投诉
				</div>
			</div>
		</div>

		<div style='display: flex;align-items: center;margin-top: 2.1875rem;'>
			<div class='text1'>退款类型</div>
			<div style='width: 10.3125rem;margin-left: 1rem;'>
				<el-select v-model="refundTypeValue" placeholder="全部">
					<el-option v-for="item in refundType" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<div class='text1' style='margin-left: 2.1875rem;'>退款状态</div>
			<div style='width: 10.3125rem;margin-left: 1rem;'>
				<el-select v-model="refundStatusValue" placeholder="全部">
					<el-option v-for="item in refundStatus" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
		</div>
		<div style='display: flex;align-items: center;margin-top: 1.5625rem;'>
			<div class='text1'>退款时间</div>
			<div style='margin-left: 1rem;cursor: pointer;'>
				<el-date-picker 
					v-model="time" 
					type="datetimerange" 
					range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
					value-format='yyyy-MM-dd HH:mm'
				>
				</el-date-picker>
			</div>
		</div>
		<div 
			class='btn1 DIS-CENTER'
			style='width: 8.75rem;height: 2.75rem;margin-top: 1.875rem;font-size: 1.125rem;'
			@click='initData(1)'
		>
			提交
		</div>
		
		<!-- table头部 -->
		<div class='tableHeader'>
			<div style='margin-left: 6.875rem;'>商品信息</div>
			<div style='margin-left: 11.1875rem;'>退款金额</div>
			<div style='margin-left: 6.3125rem;'>申请退款时间</div>
			<div style='margin-left: 8.625rem;'>服务类型</div>
			<div style='margin-left: 4.8125rem;'>退款状态</div>
		</div>
		
		<div 
			class='tableItem'
			v-show='total > 0'
			v-for='(item,index) in tableData'
			:key='index'
		>
			<img 
				style='width: 5rem;height: 5rem;border: 1px solid #E6E6E6;margin-left: 1.3125rem;'
				:src="item.glists[0].goodsImg" alt=""
			>
			<div style='margin-left: 0.9375rem;'>
				<div class='text2' style='width: 10.9375rem;'>
					{{item.glists[0].goodsName}}
				</div>
				<div class='text3' style='margin-top: 0.5625rem;'>
					退款编号：{{item.orderNo}}
				</div>
			</div>
			<div class='text4' style='margin-left: 2.625rem;width: 3.125rem;'>
				￥{{item.refundableMoney  }}
			</div>
			<div class='text2' style='margin-left: 6.375rem;width: 13rem;'>
				{{item.createTime}}
			</div>
			<div class='text2' style="margin-left: 1rem;width: 3.5rem;">
				{{item.goodsServiceTypeText}}
			</div>
			<div class='text2' v-if='item.serviceStatus ==0' style='cursor: pointer;color: #56C8B0;margin-left: 3.5rem;width: 4rem;'>
				待商家审核
			</div>
			<div class='text2' v-if='item.serviceStatus ==1' style='cursor: pointer;color: #56C8B0;margin-left: 3.5rem;width: 4rem;'>
				等待用户发货
			</div>
			<div class='text2' v-if='item.serviceStatus ==2' style='cursor: pointer;color: #56C8B0;margin-left: 3.5rem;width: 4rem;'>
				等待商家收货
			</div>
			<div class='text2' v-if='item.serviceStatus ==3' style='cursor: pointer;color: #56C8B0;margin-left: 3.5rem;width: 4rem;'>
				等待商家发货
			</div>
			<div class='text2' v-if='item.serviceStatus ==4' style='cursor: pointer;color: #56C8B0;margin-left: 3.5rem;width: 4rem;'>
				等待用户收货
			</div>
			<div class='text2' v-if='item.serviceStatus ==5' style='cursor: pointer;color: #56C8B0;margin-left: 3.5rem;width: 4rem;'>
				完成退货退款
			</div>
			<div class='text2' v-if='item.serviceStatus ==6' style='cursor: pointer;color: #56C8B0;margin-left: 3.5rem;width: 4rem;'>
				商家已确认收货
			</div>
			<div class='text2' v-if='item.serviceStatus ==7' style='cursor: pointer;color: #56C8B0;margin-left: 3.5rem;width: 4rem;'>
				商家受理，等待退款
			</div>
		</div>
		
		<div class='sorter' v-if='total > 0'>
			<el-pagination
				background
				layout="pager"
				:total="total"
				@current-change='ck_setPage'
			>
			</el-pagination>
		</div>
		
		<div v-if='total < 1'>
			<NoneItem title='您还没有退款记录哦~' text='愿您购物愉快' />
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	
	import NoneItem from '../NoneItem.vue';

	export default {
		components: { NoneItem },
		data() {
			return {
				refundType: [  //退款类型的参数
					{
						value: 0,
						label: '退款退货'
					},
					{
						value: 1,
						label: '退款'
					}
				],
				refundTypeValue: '', // 退款类型的值
				refundStatus: [ // 退款状态的参数
					{
						value: 0,
						label: '待商家审核'
					},
					{
						value: 1,
						label: '等待用户发货'
					},
					{
						value: 2,
						label: '等待商家收货'
					},
					{
						value: 3,
						label: '等待商家发货'
					},
					{
						value: 4,
						label: '等待用户收货'
					},
					{
						value: 5,
						label: '完成退货退款'
					},
					{
						value: 6,
						label: '商家确认收货'
					},
					{
						value: 7,
						label: '商家受理，等待退款'
					}
				],
				refundStatusValue: '', // 退款类型的值
				time: [], // 退款时间的值
				tableData: [], // 列表的数据
				total: 0, // 分页器的总条数
			}
		},
		created() {
			this.initData(1);
		},
		methods: {
			initData(num) {
				let startTime = '';
				let endTime = '';
				if(this.time.length > 0) {
					startTime = this.time[0];
					endTime = this.time[1];
				}
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					page: num,
					startTime,
					endTime,
					serviceStatus: this.refundStatusValue,
					goodsServiceType: this.refundTypeValue,
				}
				this.axios.post(Api.oslist,data).then(res=>{
					if(res.data.code == 200) {
						this.tableData = res.data.data.data;
						this.total = res.data.data.count;
						// 给图片前面加上域名
						for(let i in this.tableData) {
							for(let j in this.tableData[i].glists) {
								this.tableData[i].glists[j].goodsImg = Api.URL + '/' + this.tableData[i].glists[j].goodsImg
							}
						}
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			ck_setPage(value) {
				this.initData(value);
			}
		}
	}
</script>

<style scoped>
	.PersonalTkgl {
		padding-top: 2.5rem;
		padding-left: 1.4375rem;
		width: 57rem;
		background-color: #FFFFFF;
		min-height: 73.4375rem;
	}

	.line1 {
		width: 6px;
		height: 20px;
		background: #EE4454;
	}

	.title1 {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.btn1 {
		width: 70px;
		height: 28px;
		background: #EE4454;
		border-radius: 2px;
		cursor: pointer;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}

	.btn2 {
		width: 70px;
		height: 28px;
		border-radius: 2px;
		border: 1px solid #E6E6E6;
		cursor: pointer;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.tableHeader {
		width: 891px;
		height: 44px;
		background: #FAFAFA;
		border: 1px solid #E6E6E6;
		font-size: 12px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		display: flex;
		align-items: center;
		margin-top: 3.4375rem;
	}
	
	.tableItem {
		padding: 1.25rem 0;
		border-bottom: 1px solid #E6E6E6;
		width: 55.6875rem;
		display: flex;
		align-items: center;
	}
	
	.text2 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.text3 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
	
	.text4 {
		font-size: 12px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #EE4454;
	}
	
	.sorter {
		display: flex;
		justify-content: flex-end;
		margin-top: 1.875rem;
	}
	
	.sorter>>>.el-pager li {
		background-color: transparent !important;
		color: #333333 !important;
		border: 1px solid #E6E6E6;
		width: 36px;
		height: 36px;
		line-height: 2.25rem;
	}
	
	.sorter>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color:  #333333 !important;
		color: white!important;
	}
</style>
