<template>
	<div class='OrderInfo'>
		<div class='title'>
			确认订单信息
		</div>
		
		<div class='navigation'>
			<div class='text1' style='margin-left: 9.875rem;'>商品名称</div>
			<div class='text1' style='margin-left: 14.1875rem;'>商品属性</div>
			<div class='text1' style='margin-left: 10.75rem;'>单价</div>
			<div class='text1' style='margin-left: 6.8125rem;'>数量</div>
			<div class='text1' style='margin-left: 7.25rem;'>优惠</div>
			<div class='text1' style='margin-left: 10.75rem;'>小计</div>
		</div>
		
		<div 
			class='goodslist' style='margin-top: 0.75rem;'
			v-for='(item,index) in tableList'
			:key='index'
		>
			<div class='text1'>
				<span style='font-weight: bold;'>店铺：</span> {{item.shopName}}
			</div>
			<div 
				style='display: flex;margin: 0.75rem;'
				v-for='(item1,index1) in item.list'
				:key='index1'
			>
				<img
					style='width: 5rem;height: 5rem;border: 1px solid #E6E6E6;'
					:src="item1.goodsImg" 
					alt=""
				>
				<div class='text1' style='width: 15.25rem;margin-left: 1.0625rem;'>
					{{item1.goodsName}}
				</div>
				<div class='text1' style='margin-left: 4.75rem;width: 120px;'>
					{{item1.strNameTxt}}
				</div>
				<div class='text1' style='margin-left: 6.2rem;width: 100px;'>
					￥{{item1.shopPrice}}
				</div>
				<div class='text1' style='margin-left: 2.5rem;width: 6.25rem;'>
					{{item1.cartNum}}
				</div>
				<div class='text1' style='margin-left: 2.125rem;'>
					暂无优惠
				</div>
				<div 
					class='text2' 
					style='margin-left: 5rem;width: 100px;display: flex;justify-content: flex-end;margin-right: 1.25rem;'
				>
					￥{{item1.cartNum * item1.shopPrice}}
				</div>
			</div>
			<div class='info'>
				<div>
					<div style='display: flex;align-items: center;margin-top: 0.8125rem;cursor: pointer;'>
						<img
							v-if='item.isInvoice == 1'
							style='width: 0.875rem;height: 0.875rem;'
							src="../../assets/images/choice-active.png" alt=""
							@click='ck_isInvoice(0,index)'
						>
						<img 
							v-else
							style='width: 0.875rem;height: 0.875rem;'
							src="../../assets/images/choice.png" alt=""
							@click='ck_isInvoice(1,index)'
						>
						<div class='text1' style='margin-left: 0.5625rem;'>
							开具发票
						</div>
					</div>
					<div style='display: flex;margin-top: 0.5rem;'>
						<div 
							class='text1' 
							style='width: 84px;margin-left: 1.4375rem;margin-top: 0.4375rem;'
						>
							给店家留言
						</div>
						<el-input
							style='width: 15.625rem;outline:none'
							type="textarea"
							placeholder="请输入内容"
							v-model="remark[index]"
							maxlength="100"
							resize="none"
							rows="3"
							show-word-limit
						>
						</el-input>
					</div>
				</div>
				<div>
					<div style='display: flex;align-items: center;margin-top: 0.875rem;'>
						<div class='text1' style='margin-left: 1.5625rem;'>店铺优惠:</div>
						<div class='text1' style='margin-left: 1rem;'>暂无优惠</div>
						<div class='text2' style='margin-left: 11.8125rem;'>-￥0.00</div>
					</div>
					<div style='display: flex;align-items: center;margin-top: 0.8125rem;'>
						<div class='text1' style='margin-left: 1.5625rem;'>运送方式:</div>
						<div class='text1' style='margin-left: 1rem;'>普通配送 快递 免邮</div>
						<div class='text2' style='margin-left: 144px;'>￥{{item.shopFreight}}</div>
					</div>
					<div style='display: flex;align-items: center;margin-top: 1.0625rem;'>
						<div style='display: flex;align-items: center;cursor: pointer;'>
							<img
								v-if='freightChose'
								style='width: 0.875rem;height: 0.875rem;' 
								src="../../assets/images/choice-active.png" alt=""
								@click='freightChose = false'
							>
							<img
								v-else
								style='width: 0.875rem;height: 0.875rem;' 
								src="../../assets/images/choice.png" alt=""
								@click='freightChose = true'
							>
							<div class='text1' style="margin-left: 0.6875rem;">运费险：</div>
						</div>
						<div class='text1' style='margin-left: 1rem;'>若确认收货前退货，可赔运费</div>
						<div class='text2' style='margin-left: 96px;'>￥0.00</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class='btnBox'>
			<div style="display:flex;">
				<div class='text1' style='margin-left: 1.0625rem;width: 280px;'>
				<span style='font-weight: bold;'>寄送至：</span> 
				{{addressValue.areaName}}{{addressValue.userAddress}}
			</div>
			<div class='text1' style='margin-left: 1.0625rem;width: 180px;'>
				<span style='font-weight: bold;'>收货人：</span> 
				{{addressValue.userName}} {{addressValue.userPhone}}
			</div>
			</div>
			
			<div style="display:flex;align-items: center;">
				<div style='display: flex;align-items: baseline;'>
				<div class='text1'>
					实付款：
				</div>
				<div class='text2'>
					￥<span style='font-size: 1.2rem;'>{{goodsTotalMoney}}</span>
				</div>
			</div>
			
			<div 
				class='text1' 
				style='margin-left: 3.125rem;cursor: pointer;'
				@click='ck_goPage("/ShoppingCart")'
			>
				返回购物车
			</div>
			<div 
				class='btn DIS-CENTER' 
				style='margin-left: 1.875rem;'
				@click='ck_subOrder'
			>
				提交订单
			</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	
	export default {
		props: ['tableList','addressValue','goodsTotalMoney'],
		data() {
			return {
				remark:[], // 每一个店铺的留言
				shopId:[], // 每家店铺的ID
				freightChose: false
			}
		},
		methods: {
			ck_goPage(path,pkey) {
				this.$router.push({path,query:{pkey}})
			},
			ck_isInvoice(value,index) {
				this.$props.tableList[index].isInvoice = value;
			},
			ck_subOrder() {
				for(let i in this.$props.tableList) {
					this.shopId.push(this.$props.tableList[i].shopId)
				}
				let data = {}
				for(let i in this.shopId) {
					data[`deliverType_${this.shopId[i]}`] = 0;
					data[`storeId_${this.shopId[i]}`] = this.shopId[i];
					data[`remark_${this.shopId[i]}`] = this.remark[i]?this.remark[i]:'';
					data[`couponId_${this.shopId[i]}`] = 0;
					data[`isInvoice_${this.shopId[i]}`] = this.$props.tableList[i].isInvoice;
					data[`invoiceClient_${this.shopId[i]}`] = this.shopId[i];
				}
				data.loginid = localStorage.getItem('shuying-token');
				data.s_addressId = this.$props.addressValue.addressId;
				data.s_areaId = this.$props.addressValue.areaId;
				data.payType = 1;
				data.isUseScore = 0;
				data.auhtor_id = 0;
				this.axios.post(Api.submit,data).then(res=>{
					if(res.data.code == 200) {
						this.ck_goPage("/Payment",res.data.data.pkey)
					} else {
						this.$message.error(res.data.msg)
					}
				})
			}
		}
	}
</script>

<style scoped>
	.OrderInfo {
		width: 75rem;
	}
	
	.title {
		font-size: 20px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.navigation {
		width: 1200px;
		height: 44px;
		background: #FAFAFA;
		border: 1px solid #E6E6E6;
		margin-top: 1.4375rem;
		display: flex;
		align-items: center;
	}
	
	.text1 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.text2 {
		font-size: 14px;
		font-family: AlibabaPuHuiTiH;
		color: #EE4454;
		font-weight: bold;
	}
	
	.info {
		width: 1159px;
		background: #FAFAFA;
		padding: 0 1.4375rem;
		margin-top: 1rem;
		display: flex;
		justify-content: space-between;
		padding-bottom: 1.0625rem;
	}
	
	.OrderInfo>>>.el-input__inner{	
		width: 15.625rem;
		height: 3.75rem;
		border: none;
	}
	
	.btnBox {
		width: 1200px;
		height: 50px;
		background: #FAFAFA;
		display: flex;
		align-items: center;
		margin-top: 2.5rem;
		justify-content: space-between;
	}
	
	.btn {
		width: 120px;
		height: 50px;
		background: #EE4454;
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FFFFFF;
		cursor: pointer;
	}
</style>
