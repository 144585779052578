export function dateTrans(date) {
	// let _date = new Date(parseInt(date)); //13位
	let _date = new Date(parseInt(date) * 1000); //10位
	let y = _date.getFullYear();
	let m = _date.getMonth() + 1;
	m = m < 10 ? ('0' + m) : m;
	let d = _date.getDate();
	d = d < 10 ? ('0' + d) : d;
	let h = _date.getHours();
	h = h < 10 ? ('0' + h) : h;
	let minute = _date.getMinutes();
	minute = minute < 10 ? ('0' + minute) : minute;
	let dates = y + '-' + m + '-' + d + ' ' + '' + h + ':' + minute; //年月日时分秒
	return dates;
}

// 处理图片尺寸，如果不处理宽高，新进入页面加载图片时候会闪
export function setPicSize(content) {
	// 让图片最长边等于设置的最大长度，短边等比例缩小，图片控件真实改变，区别于aspectFit方式。
	let maxW = 175; //350是定义消息图片最大宽度
	let maxH = 175; //350是定义消息图片最大高度
	if (content.w > maxW || content.h > maxH) {
		let scale = content.w / content.h;
		content.w = scale > 1 ? maxW : maxH * scale;
		content.h = scale > 1 ? maxW / scale : maxH;
	}
	return content;
}
