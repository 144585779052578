<template>
	<div class='personlWdyh'>
		<!-- 头部菜单 -->
		<div style='display: flex;'>
			<div 
				class='menuBox'
				v-for='(item,index) in menuList'
				:key='index'
				@click='ck_menu(index)'
			>
				<div 
					class='text1'
					:style="item.active?'color: #EE4454':''"
				>
					{{item.text}}
				</div>
				<div class='line' :style="item.active?'':'background:#ffffff'"></div>
			</div>
		</div>
		
		<!-- 内容 -->
		<div style='margin-top: 2.125rem;'>
			<div class='contentHeader'>
				<div class='text2' style='margin-left: 3.9375rem;'>
					{{menuValue==0?'红包名称/来源':'卡券名称/来源'}}
				</div>
				<div class='text2' style='margin-left: 8.5625rem;'>
					金额
				</div>
				<div class='text2' style='margin-left: 5.9375rem;'>
					有效期
				</div>
				<div class='text2' style='margin-left: 10rem;'>
					使用规则
				</div>
				<div class='text2'>
					状态
				</div>
			</div>
		
			<div 
				v-show='tableList.length > 0'
				class='contentItem'
				v-for='(item,index) in tableList'
				:key='index'
			>
				<div v-if='menuValue == 0' style='display: flex;align-items: center;'>
					<img
						v-if='item.status==0'
						style='width: 1.25rem;height: 1.5rem;margin-left: 1.4375rem;' 
						src="../../assets/PersonalCenter/hongbao-a.png" alt=""
					>
					<img
						v-else
						style='width: 1.25rem;height: 1.5rem;margin-left: 1.4375rem;' 
						src="../../assets/PersonalCenter/hongbao.png" alt=""
					>
				</div>
				<div v-else style='display: flex;align-items: center;'>
					<img
						v-if='item.status==0'
						style='width: 1.875rem;height: 1.0625rem;margin-left: 1.4375rem;' 
						src="../../assets/PersonalCenter/coupon-a.png" alt=""
					>
					<img
						v-else
						style='width: 1.875rem;height: 1.0625rem;margin-left: 1.4375rem;' 
						src="../../assets/PersonalCenter/coupon.png" alt=""
					>
				</div>
				<div class='text3' style='margin-left: 1.0625rem;width: 8.25rem;'>
					{{item.source}}
				</div>
				<div class='text4' style='margin-left: 4.625rem;'>
					￥{{item.price}}
				</div>
				<div class='text3' style='width: 8rem;margin-left: 5.125rem;'>
					{{item.time}}
				</div>
				<div class='text3' style='margin-left: 3.4375rem;'>
					{{item.rule}}
				</div>
				<div class='text3' style="margin-left: 3.1875rem;">
					{{item.status==0?'可用':'已失效'}}
				</div>
			</div>
			
			
			<div v-if='tableList.length < 1'>
				<div v-if="menuValue==0">
					<NoneItem title='您还没有可用优惠哦~' text='请多关注平台活动吧' />
				</div>
				
				<div v-else>
					<NoneItem title='您还没有卡券~' text='记得多多关注节假日和活动哦' />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	
	import NoneItem from '../NoneItem.vue';
	
	export default {
		components: { NoneItem },
		data() {
			return {
				menuList: [
					{
						text: '红包专享',
						active: true
					},
					{
						text: '优惠卡券',
						active: false
					},
				],
				menuValue: 0, //0 专享红包， 1 优惠卡券
				dataList1: [], // 红包数据
				dataList2: [], // 优惠券数据
				tableList: [],
			}
		},
		created() {
			this.initData();
		},
		methods: {
			initData() {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					type: this.menuValue+1,
				}
				this.axios.post(Api.couponList,data).then(res=>{
					if(res.data.code == 200) {
						if(this.menuValue == 0) {
							this.dataList1 = res.data.data
							return 
						}
						if(this.menuValue == 1) {
							this.dataList2 = res.data.data
							return 
						}
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			ck_menu(index) {
				for(let i in this.menuList) {
					this.menuList[i].active = false;
				}
				this.menuList[index].active = true;
				if(index == 0) {
					this.tableList = this.dataList1
				} else if(index == 1) {
					this.tableList = this.dataList2
				}
				this.menuValue = index;
				this.initData();
			}
		}
	}
</script>

<style scoped> 
	.personlWdyh {
		padding-top: 1.3125rem;
		width: 56.75rem;
		padding-left: 1.6875rem;
		background-color: #FFFFFF;
		min-height: 73.4375rem;
	}
	
	.menuBox {
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		margin-right: 3.125rem;
	}
	
	.text1 {
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.line {
		width: 28px;
		height: 4px;
		background: #EE4454;
		margin-top: 0.375rem;
	}
	
	.contentHeader {
		width: 55.6875rem;
		height: 2.75rem;
		background: #FAFAFA;
		border: 1px solid #E6E6E6;
		display: flex;
		align-items: center;
		
	}
	
	.text2 {
		font-size: 12px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.contentItem {
		width: 55.6875rem;
		border-bottom: 1px solid #E6E6E6;
		padding: 17px 0 1.3125rem;
		display: flex;
		align-items: center;
	}
	
	.text3 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.text4 {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #EE4454;
	}
</style>
