<template>
	<div>
		<div class='CloseAnAccount'>
			<div>
				<Header bgIndex='4' @dialogBoxIndex="dialogBoxIndex" />
			</div>

			<!-- 内容 -->
			<div class='SY-CONTENT'>
				<div class='content'>
					<!-- 面包屑 -->
					<div class='BreadCrumbsBox'>
						<div class='BreadCrumbs-text1' @click='ck_goPage("/PersonalCenter")'>
							主页
						</div>
						<div class='BreadCrumbs-text1' style='cursor: none;margin: 0 1.5625rem;'>/</div>
						<div class='BreadCrumbs-text1' @click='ck_goPage("/PersonalCenter",{type: 9})'>
							账户设置
						</div>
						<div class='BreadCrumbs-text1' style='cursor: none;margin: 0 1.5625rem;'>/</div>
						<div class='BreadCrumbs-text2'>管理银行卡</div>
					</div>

					<div class="listBox">
						<div class="listBoxTitle">
							<div class="listBoxTitle_text1">全部绑定银行卡</div>
							<img src="../assets/images/addBankCard.png" class="listBoxTitle_img" @click='ck_goPage("/AddBankCard")' />
						</div>
						<div class="listBoxaTable">
							<!-- <el-table :data="tableData" border style="width: 100%">
								<el-table-column fixed prop="date" label="银行名称" width="320">
								</el-table-column>
								<el-table-column prop="name" label="卡号" width="280">
								</el-table-column>
								<el-table-column prop="zip" label="状态" width="230">
								</el-table-column>
								<el-table-column fixed="right" label="操作" width="200">
									<template slot-scope="scope">
										<el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
										<el-button type="text" size="small">编辑</el-button>
									</template>
								</el-table-column>
							</el-table> -->

							<el-table :data="tableData" style="width: 100%" :row-class-name="tableRowClassName">
								<el-table-column prop="bankName" label="银行名称" width="320">
								</el-table-column>
								<el-table-column prop="accNo" label="卡号" width="280">
								</el-table-column>
								<el-table-column prop="zip" label="状态" width="230">
								</el-table-column>
								<el-table-column fixed="right" label="操作" width="200">
									<template slot-scope="scope">
										<el-button @click="handleClick(scope.row)" type="text" size="small">解绑
										</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</div>
			</div>

			<!-- 页脚 -->
			<div class='SY-CONTENT' style='background-color: #333333;'>
				<HomeFooter />
			</div>
		</div>


		<!-- BankManagement -->
		<div class="bindBox" v-if="isUnbundle">
			<div class="bindingVerify">
				<div class="bindingVerify_text1">解绑验证</div>
				<div style="display: flex;">
					<div class="bindingVerify_text2 bindingVerify_text">验证码已发送至</div>
					<div class="bindingVerify_text3 bindingVerify_text">188****88</div>
					<div class="bindingVerify_text2 bindingVerify_text">58s</div>
				</div>
				<el-input v-model="phoneCode" style="width: 290px;"/>
				<div style="display: flex;justify-content: flex-end;">
					<div class="bindingVerify_btn bindingVerify_btn1" @click="isUnbundle=false">取消</div>
					<div class="bindingVerify_btn bindingVerify_btn2" @click="ck_unbundle">确定</div>
				</div>
			</div>
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Header from '../components/Header.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	import Api from '../request/api.js';

	export default {
		components: {
			Header,
			HomeFooter,
		},
		data() {
			return {
				phone: '',
				code: '', // 验证码
				codeText: '获取验证码',
				timer: null,
				userInfo: {},
				setEmail: '',
				newUserPhone: '',
				bank: '',
				cardNumber: '',
				phoneNumber: '',
				phoneCode:'',
				// tableData: [{
				// 	date: '中国工商银行',
				// 	name: '6224**********0809',
				// 	zip: '已激活'
				// }, {
				// 	date: '招商银行',
				// 	name: '6228**********4321',
				// 	zip: '已冻结'
				// }],
				tableData:[],
				isShowDialogBox:false,
				isUnbundle:true,//解绑弹框
			}
		},
		mounted() {
			this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
			this.newUserPhone = this.userInfo.userPhone.substr(0, 3) + '****' + this.userInfo.userPhone.substr(7);
		},
		created() {
			this.initData()
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			initData(){
				const data = {
					loginid: localStorage.getItem('shuying-token'),
				}
				this.axios.post(Api.bankList, data).then(res => {
					if (res.data.code == 200) {
						// 默认已激活
						for(var i in res.data.data){
							res.data.data[i].zip='已激活'
						}
						this.tableData=res.data.data
					} else {
						this.$message.error(res.data.msg)
					}
				})
				
			},
			ck_unbundle(){
				// 提交验证码
				// this.isUnbundle=false
			},
			ck_goPage(path, query) {
				this.$router.push({
					path,
					query
				});
			},
			tableRowClassName({
				row,
				rowIndex
			}) {
				console.log(row)
				if (rowIndex === 1) {
					return 'warning-row';
				} else if (rowIndex === 3) {
					return 'success-row';
				}
				return '';
			},
			handleClick(row) {
				console.log(row);
			}
		}
	}
</script>

<style scoped>
	.CloseAnAccount {
		background: #F5F5F5;
	}

	.content {
		width: 75rem;
		padding-top: 1.875rem;
		min-height: 71.5625rem;
		background-color: #FFFFFF;
	}

	.BreadCrumbsBox {
		display: flex;
		align-items: center;
		margin-left: 2.4375rem;
	}

	.BreadCrumbs-text1 {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
	}

	.BreadCrumbs-text2 {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		cursor: pointer;
	}

	.listBox {
		margin: 38px 60px 0 49px;
		display: flex;
		flex-direction: column;
	}

	.listBoxTitle {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.listBoxTitle_text1 {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.listBoxTitle_img {
		width: 109px;
		height: 36px;
		cursor: pointer;
	}

	.listBoxaTable {
		margin-top: 20px;
	}

	.has-gutter .cell {
		background-color: #E6E6E6 !important;
		font-size: 12px !important;
		font-family: PingFangSC-Semibold, PingFang SC !important;
		font-weight: 600 !important;
		color: #333333 !important;
		z-index: 99999999999;
	}

	.el-button--text {
		color: #56C8B0;
	}

	.bindBox {
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.2);
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 0;
		z-index: 9;
	}

	.bindingVerify {
		background-color: #FFFFFF;
		border-radius: 6px;
		padding: 33px 37px 35px 40px;
		display: flex;
		flex-direction: column;
	}
	
	.bindingVerify .el-input .el-input__inner{
		background-color: #E6E6E6 !important;
	}

	.bindingVerify_text1 {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.bindingVerify_text {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		margin-top: 4px;
		margin-bottom: 10px;
	}

	.bindingVerify_text2 {
		color: #333333;
	}

	.bindingVerify_text3 {
		color: #EE4454;
		margin-left: 5px;
		margin-right: 5px;
	}

	.bindingVerify_btn {
		padding: 6px 27px;
		font-size: 13px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-top: 26px;
		border-radius: 6px;
		cursor: pointer;
	}

	.bindingVerify_btn1 {
		background-color: #FFFFFF;
		color: #333333;
		border: 1px solid #E6E6E6;
	}

	.bindingVerify_btn2 {
		background-color: #EE4454;
		color: #FFFFFF;
		margin-left: 20px;
	}
</style>
