<template>
	<div class='FindPasswordTitle'>
		<div class='title'>重设密码</div>
		<div 
			class='text'
			style='margin-left: 1rem;'
		>
			{{text}}
		</div>
	</div>
</template>

<script>
	export default {
		props: ['text']
	}
</script>

<style scoped>
	.FindPasswordTitle { 
		display: flex;
		align-items: baseline;
	}
	
	.title {
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.text {
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
</style>
