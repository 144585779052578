<template>
	<div class='Restaurent'>
		<div class='SY-CONTENT' style='background-color: #FAFAFA;'>
			<div class='RestaurentHeader'>
				<img style='width: 6.25rem;height: 2.625rem;cursor: pointer;' src="../assets/logo/logo3.png" alt="" @click='ck_goPage("/")'>
			</div>
		</div>
		<!-- 协议 -->
		<div class='AgreementBox' v-if='showAgreement'>
			<Agreement @agreement='agreement'/>
		</div>
		<div class='SY-CONTENT'>
			<div>
				<div class='DIS-CENTER' style='margin-top: 2.5rem;'>
					<div class='title1'>
						个人用户摆摊认证
					</div>
				</div>
				<div class='DIS-CENTER' style='margin-top: 1.875rem;'>
					<div class='text1' style='width: 90px;'>
						<span style='color:#EE4454;'>*</span>
						真实姓名
					</div>
					<div style='margin-left: 2.125rem;'>
						<el-input placeholder="姓名和证件必须一致" v-model="legalPersonName"></el-input>
					</div>

				</div>
				<div class='DIS-CENTER' style='margin-top: 1.875rem;'>
					<div class='text1' style='width: 90px;'>
						<span style='color:#EE4454;'>*</span>
						证件类型
					</div>
					<el-select v-model="legalCertificateType" style='margin-left: 2.125rem;' placeholder="请选择">
						<el-option
							v-for="item in certificateList"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</div>
				<div class='DIS-CENTER' style='margin-top: 1.875rem;'>
					<div class='text1' style='width: 90px;'>
						<span style='color:#EE4454;'>*</span>
						证件号码
					</div>
					<div style='margin-left: 2.125rem;'>
						<el-input placeholder="必须是18~70周岁的二代身份证" v-model="legalCertificate"></el-input>
					</div>
				</div>
				<div class='DIS-CENTER' style='margin-top: 1.875rem;'>
					<div class='text1' style='width: 90px;'>
						<span style='color:#EE4454;'>*</span>
						证件到期
					</div>
					<div style='margin-left: 2.125rem;width: 21.25rem;'>
						<el-date-picker v-model="legalCertificateEndDate" type="date" placeholder="证件有效期至少30天">
						</el-date-picker>
					</div>
				</div>
				<div class='text1' style="margin-top: 1.875rem;">
					<span style='color:#EE4454;'>*</span>
					上传身份证
					<span class='text2'>
						(支持jpg、png、jpeg格式，大小不超过10M)
					</span>
				</div>
				<div style='margin-top: 1.875rem;display: flex;'>
					<div class='text1' style='width: 90px;'>
						<span style='color:#EE4454;'>*</span>
						正面人像
					</div>
					<label for="card-Z">
						<img 
							v-if='legalCertificateImgShow' 
							style='width: 10.3125rem;height: 6.25rem;margin-left: 2.125rem;cursor: pointer;' 
							:src="legalCertificateImgShow" alt=""
						>
						<img
							v-else
							style='width: 10.3125rem;height: 6.25rem;margin-left: 2.125rem;cursor: pointer;' 
							src="../assets/images/sfz-zheng.png"
							alt=""
						>
					</label>
					<input
						class='input-img' 
						type="file" 
						id='card-Z'
						@change="ce_upLoad_Z"
						accept="image/png,image/jpeg"
					>
				</div>
				<div style='margin-top: 1.875rem;display: flex;'>
					<div class='text1' style='width: 90px;'>
						<span style='color:#EE4454;'>*</span>
						反面国徽
					</div>
					<label for="card-F">
						<img
							v-if='legalCertificateImg_bShow'
							style='width: 10.3125rem;height: 6.25rem;margin-left: 2.125rem;cursor: pointer;' 
							:src="legalCertificateImg_bShow"
							alt=""
						>
						<img 
							v-else
							style='width: 10.3125rem;height: 6.25rem;margin-left: 2.125rem;cursor: pointer;' 
							src="../assets/images/sfz-fan.png"
							alt=""
						>
					</label>
					<input 
						class='input-img' 
						type="file" 
						id='card-F'
						@change="ce_upLoad_F"
						accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
					>
				</div>
				<div style='margin-top: 1.875rem;display: flex;'>
					<div class='text1' style='width: 90px;'>
						<span style='color:#EE4454;'>*</span>
						手持证件
					</div>
					<label for="card-R">
						<img
							v-if='handCardImgShow'
							style='width: 6.25rem;height: 6.25rem;margin-left: 2.125rem;cursor: pointer;' 
							:src="handCardImgShow"
							alt=""
						>
						<img 
							v-else
							style='width: 6.25rem;height: 6.25rem;margin-left: 2.125rem;cursor: pointer;' 
							src="../assets/images/sczj.png"
							alt=""
						>
					</label>
					<input
						class='input-img' 
						type="file" 
						id='card-R'
						@change="ce_upLoad_S"
						accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
					>
				</div>
				<div class='btn DIS-CENTER' @click='ck_btn'>
					提交
				</div>
			</div>
		</div>

		<div class='SY-CONTENT' style='background-color: #333333;margin-top: 7.625rem;'>
			<HomeFooter />
		</div>
	</div>
</template>

<script>
	import Api from '../request/api.js';
	
	import HomeFooter from '../components/Home/HomeFooter.vue';
	import Agreement from '../components/Enterprises/Agreement.vue';

	export default {
		components: {
			HomeFooter, Agreement
		},
		data() {
			return {
				showAgreement: true,
				legalPersonName: '',
				legalCertificateType: 0,
				legalCertificate: '',
				legalCertificateEndDate: '',
				legalCertificateImg: '',
				legalCertificateImgShow: '',
				legalCertificateImg_b: '',
				legalCertificateImg_bShow: '',
				handCardImg: '',
				handCardImgShow: '',
				certificateList: [
					{
						value: 0,
						label: '大陆身份证'
					},
					{
						value: 1,
						label: '护照'
					},
					{
						value: 2,
						label: '港澳居民通行证'
					},
					{
						value: 3,
						label: '台湾居民通行证'
					}
				],
			}
		},
		methods: {
			ck_goPage(path) {
				this.$router.push({
					path
				});
			},
			ce_upLoad_Z(value) {
				const file = value.srcElement.files[0];
				if(!file) {return}
				let data = new FormData();
				data.append('file',file);
				this.axios.post(
					Api.uploadFile,
					data,
				).then(res=>{
					if(res.data.code ==200) {
						this.legalCertificateImg = res.data.data.file_path;
						this.legalCertificateImgShow = Api.URL + '/' + res.data.data.file_path;
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			ce_upLoad_F(value) {
				const file = value.srcElement.files[0];
				if(!file) {return}
				let data = new FormData();
				data.append('file',file);
				this.axios.post(
					Api.uploadFile,
					data,
				).then(res=>{
					if(res.data.code ==200) {
						this.legalCertificateImg_b = res.data.data.file_path;
						this.legalCertificateImg_bShow = Api.URL + '/' + res.data.data.file_path;
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			ce_upLoad_S(value) {
				const file = value.srcElement.files[0];
				if(!file) {return}
				let data = new FormData();
				data.append('file',file);
				this.axios.post(
					Api.uploadFile,
					data,
				).then(res=>{
					if(res.data.code ==200) {
						this.handCardImg = res.data.data.file_path;
						this.handCardImgShow = Api.URL + '/' + res.data.data.file_path;
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			ck_btn() {
				if(this.legalPersonName == '' || this.legalCertificate == '' || this.legalCertificate == '') {
					this.$message.warning('请填写完整资料');
					return 
				} 
				if(this.legalCertificateImg == '' || this.legalCertificateImg_b == '' || this.handCardImg == '') {
					this.$message.warning('请上传相应图片')
					return 
				}
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					type: 1,
					legalPersonName: this.legalPersonName,
					legalCertificateType: this.legalCertificateType,
					legalCertificate: this.legalCertificate,
					legalCertificateEndDate: this.legalCertificateEndDate,
					legalCertificateImg: this.legalCertificateImg,
					legalCertificateImg_b: this.legalCertificateImg_b,
					handCardImg: this.handCardImg,
				}
				this.axios.post(Api.setSettlement,data).then(res=>{
					if(res.data.code == 200) {
						this.$router.push({path:"/"});
						this.$message.success('资料已提交，审核中...');
					} else {
						this.$message.error(res.data.msg)
					}
				})
				
			},
			agreement(data) {
				this.showAgreement = data;
			}
		}
	}
</script>

<style scoped>
	.RestaurentHeader {
		width: 75rem;
		height: 60px;
		display: flex;
		align-items: center;
	}

	.logo {
		width: 133px;
		height: 40px;
		background: #E9E9E9;
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.title1 {
		font-size: 30px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.Restaurent>>>.el-input__inner {
		width: 340px;
		height: 44px;
		border: 1px solid #DDDDDD;
		border-radius: 0;
	}

	.sfzBox {
		width: 317px;
		height: 44px;
		border: 1px solid #DDDDDD;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		display: flex;
		align-items: center;
		padding-left: 1.4375rem;
	}

	.text2 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}

	.btn {
		width: 340px;
		height: 50px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		margin-top: 3.125rem;
		margin-left: 6rem;
		cursor: pointer;
	}
	
	.input-img {
		display: none;
	}
	
	.AgreementBox {
		width: 100vw;
		height: 100vh;
		background-color: ;
		background: rgba(0,0,0,0.2);
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 0;
		z-index: 9;
	}
</style>
