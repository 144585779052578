<template>
	<div class='RegistereFour'>
		<div class='DIS-CENTER'>
			<img style='width: 2.25rem;height: 2.25rem;' src="../../assets/images/succeed.png" alt="">
			<div class='text'>恭喜您！注册成功！</div>
		</div>
		<div class='DIS-CENTER' style='margin-top: 2.1875rem;'>
			<div class='btn1 DIS-CENTER' @click='goPage("/Login")'>立即登录</div>
			<div class='btn2 DIS-CENTER' @click='goPage("/")'>返回首页</div>
		</div>
	</div>
</template>

<script>
	export default {
		methods: {
			goPage(path) {
				this.$router.push(path)
			}
		}
	}
</script>

<style scoped>
	.RegistereFour .text {
		font-size: 28px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-left: 0.9375rem;
	}
	
	.btn1 {
		width: 174px;
		height: 50px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
	}
	
	.btn2 {
		width: 174px;
		height: 50px;
		border-radius: 4px;
		border: 1px solid #E6E6E6;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
		margin-left: 1.875rem;
	}
</style>
