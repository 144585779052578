<template>
	<div class='GoodData'>
		<div class='titleBox'>
			<div 
				:class='item.active? "item1 DIS-CENTER":"item DIS-CENTER"' :style="index==0? 'border-right: none':''" v-for='(item,index) in menuList'
				:key='index' @click='ck_menu(index)'
			>
				{{item.text}}
			</div>
		</div>

		<div v-if='menuValue == 0'>
			<!-- 详细数据 -->
			<div class='titleContent'>
				<div class='title'>
					产品参数：
				</div>
				<div style='display: flex;margin-top: 0.8125rem;margin-left: 1.625rem;margin-bottom: 1.4375rem;flex-wrap: wrap;'>
					<div 
						v-for='(item,index) in goodsInfo.attributes' 
						:key='index'
						class='item1'
						:style="index==0 || index%3==0?'':'margin-left: 3.4375rem;'"
					>
						{{item.attrName}}：{{item.attrVal}}
					</div>
				</div>
			</div>

			<!-- 详情图 -->
			<div class='imgStyle' v-html='goodsInfo.goodsDesc'>
			</div>
		</div>

		<div v-if='menuValue == 1'>
			<!-- 详细数据 -->
			<div class='titleContent'>
				<div style='display: flex;align-items: center;'>
					<div class='good'>
						好评率：
						<span style='color: #EE4454;font-size: 1.75rem;'>99.80</span>
						<span style='color: #EE4454;margin-left: 0.25rem;'>%</span>
					</div>
					<div style='margin-left: 1.5625rem;width: 30rem;display: flex;flex-wrap: wrap;'>
						<div class='evaluate DIS-CENTER' v-for='(item,index) in 6' :key='index'>
							非常满意(662)
						</div>
					</div>
				</div>
			</div>

			<div style='margin-top: 1.375rem;display: flex;'>
				<div 
					class='DIS-CENTER' v-for='(item,index) in radioList' :key='index' style='margin-right: 2rem;cursor: pointer;'
					@click='ck_radio(index)'
				>
					<img 
						v-show='!item.value' 
						style='width: 1rem;height: 1rem;cursor: pointer;' src="../../assets/images/radio_btn@2x.png"
						alt=""
					>
					<img 
						v-show='item.value' 
						style='width: 1rem;height: 1rem;cursor: pointer;' src="../../assets/images/radio_btn_selected@2x.png"
						alt=""
					>
					<div class='radioText' style='margin-left: 0.5rem;'>
						{{item.text}}
					</div>
				</div>
			</div>

			<div class='evaluateTitle'>
				全部评论
			</div>
			<div style='display: flex;margin-top: 1.5625rem;' v-for='(item,index) in commentData' :key='index'>
				<img style='width: 1.875rem;height: 1.875rem;' :src="item.userPhoto" alt="">
				<div style='margin-left: 0.75rem;'>
					<div style='display: flex;width: 41.625rem;display: flex;justify-content: space-between;'>
						<div class='evaluateText1'>{{item.loginName}}</div>
						<div class='evaluateText2'>{{item.createTime}}</div>
					</div>
					<div class='evaluateText3' style='margin-top: 0.75rem;'>{{item.content}}</div>
					<div 
						v-if='item.shopReply'
						class='evaluateText3'
						style='background-color: #F5F5F5;margin-top: 0.75rem;padding: 0.9375rem;'
					>
						{{item.shopName}}: {{item.shopReply}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	
	export default {
		props: ['goodsInfo'],
		data() {
			return {
				menuList: [{
						text: '商品详情',
						active: true,
					},
					{
						text: '累计评价',
						active: false,
					},
				],
				menuValue: 0,
				radioList: [
					{
						text: '全部',
						value: true,
					},
					{
						text: '有图',
						value: false,
					}, {
						text: '好评',
						value: false,
					}, {
						text: '中评',
						value: false,
					}, {
						text: '差评',
						value: false,
					}
				],
				commentData: [], //评论数据
			}
		},
		created() {
			this.initData();
		},
		methods: {
			initData() {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					goodsId: 1,
					pagesize: 10,
					anonymous: 0,
				}
				this.axios.post(Api.getGoodsComment,data).then(res=>{
					if(res.data.code == 200) {
						this.commentData = res.data.data.data;
						this.radioList[0].text = `${this.radioList[0].text}(${res.data.data.sum})`;
						this.radioList[1].text = `${this.radioList[1].text}(${res.data.data.picNum})`;
						this.radioList[2].text = `${this.radioList[2].text}(${res.data.data.bestNum})`;
						this.radioList[3].text = `${this.radioList[3].text}(${res.data.data.goodNum})`;
						this.radioList[4].text = `${this.radioList[4].text}(${res.data.data.badNum})`;
					} else {
						this.$message.warning(res.data.msg)
					}
				})
			},
			ck_menu(index) {
				this.menuValue = index;
				for (let i in this.menuList) {
					this.menuList[i].active = false;
				}
				this.menuList[index].active = true;
			},
			ck_radio(index) {
				for (let i in this.radioList) {
					this.radioList[i].value = false;
				}
				this.radioList[index].value = true;
			},
		}
	}
</script>

<style scoped>
	.GoodData {
		width: 44.25rem;
		border-left: none;
	}

	.titleBox {
		width: 44.1875rem;
		background: #FBFBFB;
		height: 3.125rem;
		display: flex;
		border: 1px solid #E6E6E6;
		border-left: none;
	}

	.titleBox .item {
		height: 3.125rem;
		width: 11.5625rem;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
		border-left: 1px solid #E6E6E6;
	}

	.titleBox .item1 {
		height: 2.9375rem;
		width: 11.5625rem;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
		background-color: #FFFFFF;
		border-top: 3px solid #EE4454;
		border-right: 1px solid #E6E6E6;
		border-left: 1px solid #E6E6E6;
		border-bottom: 1px solid #FFFFFF;
	}

	.titleContent {
		border: 1px solid #E6E6E6;
		border-top: none;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		padding-top: 0.8125rem;
	}

	.titleContent .title {
		font-size: 12px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-left: 1.625rem;
	}

	.particulars {
		width: 710px;
		height: 1058px;
		background: #F5F9FC;
		margin-top: 1.25rem;
	}

	.titleContent .good {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		display: flex;
		align-items: center;
		margin-left: 1.25rem;
	}

	.evaluate {
		width: 104px;
		height: 30px;
		border: 1px solid #E6E6E6;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-right: 0.625rem;
		margin-bottom: 0.625rem;
		cursor: pointer;
	}

	.radioText {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.evaluateTitle {
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-top: 1.875rem;
	}

	.evaluateText1 {
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.evaluateText2 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}

	.evaluateText3 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.imgStyle>>>img{
		width: 100%;
		display: block;
	}
	
	.item1 {
		/* border: 1px solid red; */
		width: 180px;
		margin-bottom: 4px;
	}
</style>
