<template>
	<div class='AccurateSearchBottom'>
		<!-- left -->
		<div>
			<div class='navigation'>
				<!-- <div class='text2' style='margin-left: 1.375rem;'>
					综合排序
				</div> -->
				<div class='text2' style='margin-left: 1.875rem;display: flex;align-items: center;' @click='ck_setOrder(1)'>
					<div>销量</div>
					<img 
						v-if='order=="saleNum desc"'
						style='width: 0.375rem;height: 0.375rem;margin-left: 0.1875rem;' 
						src="../../assets/images/top-bottom.png" alt=""
					>
					<img 
						v-else
						style='width: 0.375rem;height: 0.375rem;margin-left: 0.1875rem;' 
						src="../../assets/images/bottom-top.png" alt=""
					>
				</div>
				<!-- <div class='text2' style='margin-left: 1.8125rem;display: flex;align-items: center;'>
					<div>评论数</div>
					<img style='width: 0.375rem;height: 0.375rem;margin-left: 0.8125rem;' src="../../assets/images/bottom-top.png" alt="">
				</div> -->
				<div class='text2' style='margin-left: 1.25rem;display: flex;align-items: center;' @click='ck_setOrder(2)'>
					<div>人气</div>
					<img
						v-if='order=="collectNum desc"'
						style='width: 0.375rem;height: 0.375rem;margin-left: 0.1875rem;' 
						src="../../assets/images/top-bottom.png" alt=""
					>
					<img 
						v-else
						style='width: 0.375rem;height: 0.375rem;margin-left: 0.1875rem;' 
						src="../../assets/images/bottom-top.png" alt=""
					>
				</div>
				<div class='text2' style='margin-left: 1.25rem;display: flex;align-items: center;' @click='ck_setOrder(3)'>
					<div>上架时间</div>
					<img
						v-if='order=="createTime desc"'
						style='width: 0.375rem;height: 0.375rem;margin-left: 0.1875rem;' 
						src="../../assets/images/top-bottom.png" alt=""
					>
					<img 
						v-else
						style='width: 0.375rem;height: 0.375rem;margin-left: 0.1875rem;' 
						src="../../assets/images/bottom-top.png" alt=""
					>
				</div>
				<!-- <div class='text2' style='margin-left: 1.25rem;display: flex;align-items: center;'>
					<div>价格</div>
					<img style='width: 0.375rem;height: 0.375rem;margin-left: 0.1875rem;' src="../../assets/images/top-bottom.png" alt="">
				</div> -->
				<div class='priceBox' style='margin-left: 1.3125rem;'>
					<el-input v-model="price.startPrice"></el-input>
				</div>
				<div class='line'></div>
				<div class='priceBox'>
					<el-input v-model="price.endPrice"></el-input>
				</div>
				<div class='btn DIS-CENTER' @click='ck_setPrice'>
					确 定
				</div>
				<div class='text2' style='margin-left: 1.8125rem;display: flex;align-items: center;'>
					<div>发货地</div>
					<img style='width: 0.5rem;height: 0.5rem;margin-left: 0.4375rem;' src="../../assets/images/arrow-bottom.png" alt="">
				</div>
				<div class='text2' style='margin-left: 1.125rem;display: flex;align-items: center;' @click='choice1 = !choice1'>
					<img
						v-if='isStock == 1' 
						style='width: 0.75rem;height: 0.75rem;' 
						src="../../assets/images/choice-active.png" 
						alt=""
						@click='ck_setStock(0)'
					>
					<img 
						v-else 
						style='width: 0.75rem;height: 0.75rem;' 
						src="../../assets/images/choice.png" 
						alt=""
						@click='ck_setStock(1)'
					>
					<div style='margin-left: 0.375rem;'>仅显示有货</div>
				</div>
				<div class='text2' style='margin-left: 1.125rem;display: flex;align-items: center;' @click='choice2 = !choice2'>
					<img
						v-if='isNew == 1' 
						style='width: 0.75rem;height: 0.75rem;' 
						src="../../assets/images/choice-active.png" 
						alt=""
						@click='ck_setNew(0)'
					>
					<img 
						v-else 
						style='width: 0.75rem;height: 0.75rem;' 
						src="../../assets/images/choice.png" 
						alt=""
						@click='ck_setNew(1)'
					>
					<div style='margin-left: 0.375rem;'>新品</div>
				</div>
				<div class='text2' style='margin-left: 1.125rem;display: flex;align-items: center;'>
					<img 
						v-if='isFee == 1' 
						style='width: 0.75rem;height: 0.75rem;' 
						src="../../assets/images/choice-active.png" 
						alt=""
						@click='ck_setFee(0)'
					>
					<img 
						v-else 
						style='width: 0.75rem;height: 0.75rem;' 
						src="../../assets/images/choice.png" 
						alt=""
						@click='ck_setFee(1)'
					>
					<div style='margin-left: 0.375rem;'>包邮</div>
				</div>
			</div>

			<div style='display: flex;flex-wrap: wrap;width: 957px;'>
				<div 
					v-show='dataList.length > 0'
					:class="index == 0|| index%4==0 ? 'goodsList-second1 goodsList-second' : 'goodsList-second2 goodsList-second'" 
					v-for='(item,index) in dataList'
					:key='index'
				>
					<img style='width: 14rem;height: 14rem;' :src="item.goodsImg" alt="">
					<div class='title'>
						{{item.goodsName}}
					</div>
					<div class='introduce'>
						<div class='money-box'>
							<div>￥</div>
							<div style='font-size: 1.125rem;'>{{item.shopPrice}}</div>
							<div class='text'>起</div>
						</div>
						<div class='btn1 DIS-CENTER' @click='ck_goPage("/GoodsDetails",item.goodsId)'>立即购买</div>
					</div>
				</div>
				<div class='noneBox' v-if='dataList.length < 1'>
					<TableNone />
				</div>
			</div>
			
			<!-- 分页器 -->
			<div class='SY-CONTENT'>
				<div class='sorter'>
					<el-pagination
						background
						layout="pager"
						:total="total"
						@current-change='editPage'
						:page-size='20'
					>
					</el-pagination>
				</div>
			</div>
		</div>
		<!-- right -->
		<div style='margin-top: 0.625rem;'>
			<div class='titleBox'>
				热销商品
			</div>
			<div
				class="goodsList-second3" 
				v-for='(item,index) in dataList2'
				:key='index'
				@click='ck_goPage("/GoodsDetails",item.goodsId)'
			>
				<img style='width: 14rem;height: 14rem;' :src="item.goodsImg" alt="">
				<div class='title' style='margin-top: 0.625rem;'>
					{{item.goodsName}}
				</div>
				<div class='introduce' style='align-items: flex-end;'>
					<div class='money-box'>
						<div>￥</div>
						<div style='font-size: 1.125rem;'>{{item.shopPrice}}</div>
						<div class='text'>起</div>
					</div>
					<div class='payNum'>
						已卖 <span style='color: #EE4454;'>{{item.saleNum}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import TableNone from '../TableNone.vue';
	
	export default {
		props: ['dataList','total','dataList2'],
		components: { TableNone },
		data() {
			return {
				input: '',
				choice1: false,
				choice2: false,
				
				price: {
					startPrice: '',
					endPrice: '',
				},
				isFee: 0,
				isNew: 0,
				isStock: 0,
				order: 'saleNum desc'
			}
		},
		methods: {
			ck_goPage(path,id) {
				this.$router.push({path,query:{id}});
			},
			editPage(value) {
				this.$emit('ckPage',value)
			},
			ck_setPrice() {
				if(this.price.startPrice == '') {
					this.$message.warning('请输入价格区间');
					return 
				}
				if(this.price.startPrice == '') {
					this.$message.warning('请输入价格区间');
					return 
				}
				this.$emit('setPrice',this.price)
			},
			// 是否包邮
			ck_setFee(value) {
				this.isFee = value;
				this.$emit('setFee',this.isFee);
			},
			ck_setNew(value) {
				this.isNew = value;
				this.$emit('setNew',this.isNew);
			},
			ck_setStock(value) {
				this.isStock = value;
				this.$emit('setStock',this.isStock);
			},
			ck_setOrder(type) {
				if(type == 1) {
					if(this.order == 'saleNum desc') {
						this.order = 'saleNum asc'
					} else {
						this.order = 'saleNum desc'
					}
				} else if(type == 2) {
					if(this.order == 'collectNum desc') {
						this.order = 'collectNum asc'
					} else {
						this.order = 'collectNum desc'
					}
				} else if(type == 3) {
					if(this.order == 'createTime desc') {
						this.order = 'createTime asc'
					} else {
						this.order = 'createTime desc'
					}
				}
				this.$emit('setOrder',this.order)
			}
		}
	}
</script>

<style scoped>
	.AccurateSearchBottom {
		display: flex;
		justify-content: space-between;
		width: 75rem;
	}

	.navigation {
		width: 955px;
		height: 44px;
		background: #FAFAFA;
		border: 1px solid #E6E6E6;
		display: flex;
		align-items: center;
	}

	.text1 {
		font-size: 12px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #EE4454;
	}

	.text2 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.navigation div {
		cursor: pointer;
	}

	.priceBox {
		width: 62px;
		height: 24px;
		background: #FFFFFF;
		border: 1px solid #E6E6E6;
		display: flex;
		align-items: center;
	}

	.priceBox>>>.el-input__inner {
		height: 24px;
		width: 62px;
		border: none;
		font-size: 0.75rem;
	}

	.line {
		width: 6px;
		height: 2px;
		background: #333333;
		margin: 0 0.375rem;
	}

	.btn {
		width: 54px;
		height: 24px;
		background: #FFFFFF;
		border-radius: 2px;
		border: 1px solid #E6E6E6;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
		margin-left: 0.3125rem;
	}
	
	.goodsList-second {
		cursor: pointer;
	}
	
	.goodsList-second:hover .btn1{
		animation: pulse;
		animation-duration: 1s; 
	}

	.goodsList-second1 {
		margin-top: 1.25rem;
		width: 14rem;
		height: 20rem;
		border: 1px solid #E6E6E6;
		background: #FFFFFF;
	}

	.goodsList-second2 {
		margin-top: 1.25rem;
		width: 14rem;
		height: 20rem;
		border: 1px solid #E6E6E6;
		background: #FFFFFF;
		margin-left: 1.1rem;
	}

	.title {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		padding: 0 0.5625rem;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		height: 2rem;
	}

	.money-box {
		display: flex;
		font-size: 0.8125rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		align-items: center;
	}

	.money-box .text {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-left: 0.1875rem;
	}

	.btn1 {
		width: 4.375rem;
		height: 1.875rem;
		background: #EE4454;
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
		margin-top: 0;
	}
	
	.introduce {
		display: flex;
		justify-content: space-between;
		padding: 0 0.8125rem;
		margin-top: 0.75rem;
		align-items: center;
	}
	
	.sorter {
		width: 59.6875rem;
		display: flex;
		justify-content: flex-end;
		margin-top: 1.875rem;
	}
	
	.sorter>>>.el-pager li {
		background-color: transparent !important;
		color: #333333 !important;
		border: 1px solid #E6E6E6;
		width: 36px;
		height: 36px;
		line-height: 2.25rem;
	}
	
	.sorter>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color:  #333333 !important;
		color: white!important;
	}
	
	.titleBox {
		width: 14rem;
		border-bottom: 1px solid #EE4454;
		padding-bottom: 0.75rem;
	}
	
	.goodsList-second3 {
		margin-top: 1.25rem;
		width: 14rem;
		height: 20rem;
		border: 1px solid #E6E6E6;
		background: #FFFFFF;
		margin-top: 1.25rem;
		cursor: pointer;
	}
	
	.payNum {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.noneBox {
		width: 955px;
		padding-top: 3.125rem;
	}
</style>
