<template>
  <div class="wrap">
    <div class="PublishHeader">
      <div class="header_left">
        <img src="../../assets/logo/logo3.png" class="img1" />
        <div
          style="
            display: flex;
            margin-left: 1.5rem;
            align-items: center;
            cursor: pointer;
          "
          @click="ck_goBack('/')"
        >
          <img src="../../assets/Publish/back.png" class="img2" />
          <div class="text">返回</div>
        </div>
      </div>

      <div class="header_right">
        <div class="leftBox" @click="ck_goBack('/PersonalCenter')">
          <img src="../../assets/Publish/mineCenter.png" class="img3" />
          <div class="text1">个人中心</div>
        </div>
        <div class="line"></div>
        <div class="leftBox" style="position: relative" @click="ck_dialogBox">
          <img src="../../assets/Publish/message.png" class="img3" />
          <div class="spotNum DIS-CENTER" v-if="unreadMessage">
            {{ unreadMessageNum }}
          </div>
          <div class="text1">消息通知</div>
          <img src="../../assets/images/top-bottom.png" class="img4" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["bgIndex"],
  computed: mapState(["chatNum", "AllNum"]),
  data() {
    return {
      isLogin: false, // 判断是否登录
      userHeader: "", // 用户头像
      unreadMessage: false,
      unreadMessageNum: "",
    };
  },
  watch: {
    AllNum: {
      deep: true,
      handler(newMsg) {
        if (newMsg == 0) {
          this.unreadMessage = false;
        } else {
          this.unreadMessage = true;
        }
        if (newMsg > 99) {
          this.unreadMessageNum = "...";
        } else {
          this.unreadMessageNum = newMsg;
        }
      },
    },
  },
  created() {
    if (localStorage.getItem("shuying-token")) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
  },
  mounted() {
    // 获取 用户头像
    setTimeout(() => {
      if (localStorage.getItem("userInfo")) {
        this.userHeader = JSON.parse(
          localStorage.getItem("userInfo")
        ).userPhoto;
      }
    }, 300);
  },
  methods: {
    ck_dialogBox() {
      this.$emit("dialogBoxIndex", true);
    },
    ck_goBack(path) {
      this.$router.push({
        path,
      });
    },
    goPage(path, value) {
      this.$router.push({
        path,
        query: {
          value,
        },
      });
    },
    ck_out() {
      localStorage.removeItem("shuying-token");
      localStorage.removeItem("userInfo");
      this.$router.push({
        path: "/Login",
      });
    },
  },
};
</script>

<style scoped>
.wrap {
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0px 2px 14px 0px rgba(216, 216, 216, 0.5);
}

.wrap .PublishHeader {
  margin-left: 4.75rem;
  display: flex;
  justify-content: space-between;
}

.header_left {
  padding-top: 0.3125rem;
  padding-bottom: 0.8125rem;
  display: flex;
  align-items: center;
}

.header_left .img1 {
  width: 6.25rem;
  height: 2.625rem;
}

.header_left .img2 {
  width: 0.875rem;
  height: 0.875rem;
}

.text {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-left: 0.25rem;
}

.header_right {
  display: flex;
  align-items: center;
  margin-right: 21.375rem;
}

.leftBox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.img3 {
  width: 1.25rem;
  height: 1.25rem;
}

.img4 {
  width: 0.5rem;
  height: 0.5rem;
  margin-left: 0.375rem;
}

.text1 {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-left: 0.8125rem;
}

.line {
  width: 0.0625rem;
  height: 1.125rem;
  background: #e3e3e3;
  margin-left: 1.625rem;
  margin-right: 1.375rem;
}

.leftBox .spotNum {
  width: 14px;
  height: 14px;
  background: #ee4454;
  border-radius: 50%;
  font-size: 12px;
  position: absolute;
  top: -6px;
  right: 72px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #ffffff;
}
</style>
