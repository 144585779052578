<template>
	<div class='EditMessage'>
		<div>
			<Header bgIndex='4' @dialogBoxIndex="dialogBoxIndex" />
		</div>

		<!-- 内容 -->
		<div class='SY-CONTENT'>
			<div class='content'>
				<!-- 面包屑 -->
				<div class='BreadCrumbsBox'>
					<div class='BreadCrumbs-text1' @click='ck_goPage("/PersonalCenter")'>
						主页
					</div>
					<div class='BreadCrumbs-text1' style='cursor: none;margin: 0 1.5625rem;'>/</div>
					<div class='BreadCrumbs-text1' @click='ck_goPage("/PersonalCenter",{type: 9})'>
						账户设置
					</div>
					<div class='BreadCrumbs-text1' style='cursor: none;margin: 0 1.5625rem;'>/</div>
					<div class='BreadCrumbs-text2'>修改邮箱</div>
				</div>
				
				<div class='DIS-CENTER'>
					<!-- 修改邮箱01 -->
					<div v-if='step == 0' style='margin-top: 8rem;'>
						<EditMessageFirst @editStep='editStep' :userInfo='userInfo' @getCode='getCode'/>
					</div>
					<div v-if='step == 1' style='margin-top: 8rem;'>
						<EditMessageSecond @getEmail='getEmail' :userInfo='userInfo'/>
					</div>
					<div v-if='step == 2' style='margin-top: 8rem;'>
						<EditMessageThird :setEmail='setEmail' />
					</div>
				</div>
			</div>
		</div>

		<!-- 页脚 -->
		<div class='SY-CONTENT' style='background-color: #333333;'>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Header from '../components/Header.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	import EditMessageFirst from '../components/editMessage/EditMessageFirst.vue';
	import EditMessageSecond from '../components/editMessage/EditMessageSecond.vue';
	import EditMessageThird from '../components/editMessage/EditMessageThird.vue';
	import Api from '../request/api.js';

	export default {
		components: {
			Header,
			HomeFooter,
			EditMessageFirst,
			EditMessageSecond,
			EditMessageThird
		},
		data() {
			return {
				step: 0,
				userInfo:{},
				code:0,
				setEmail:'',
				isShowDialogBox:false,
			}
		},
		mounted() {
			this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
			this.userInfo.newUserPhone=this.userInfo.userPhone.substr(0,3) + '****' + this.userInfo.userPhone.substr(7);
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			ck_goPage(path,query) {
				this.$router.push({
					path,
					query
				});
			},
			editStep(data) {
				this.step = data;
			},
			getCode(data){
				this.code=data
			},
			getEmail(email){
				this.setEmail=email
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					phone: this.userInfo.userPhone,
					code:this.code,
					newEmail:email,
				}
				this.axios.post(Api.editEmail,data).then(res=>{
					if(res.data.code == 200) {
						this.$message.success(res.data.msg)
						this.step=2
					} else {
						this.$message.error(res.data.msg)
					}
				})
			}
		}
	}
</script>

<style scoped>
	.EditMessage {
		background: #F5F5F5;
	}

	.content {
		width: 75rem;
		padding-top: 1.875rem;
		min-height: 71.5625rem;
		background-color: #FFFFFF;
	}

	.BreadCrumbsBox {
		display: flex;
		align-items: center;
		margin-left: 2.4375rem;
	}

	.BreadCrumbs-text1 {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
	}

	.BreadCrumbs-text2 {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		cursor: pointer;
	}
</style>
