<template>
	<div class='HomeCooperation'>
		<div class='title'>
			友情链接
		</div>
		
		<div class='CooperationContent'>
			<div
				:class="index==0 || index%5==0 ? 'item1' : 'item2'"
				v-for='(item,index) in homeFriendlinks'
				:key='index'
				@click='ck_goLink(item.friendlinkUrl)'
			>
				<img style='width: 100%;height: 100%;' :src="item.friendlinkIco" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["homeFriendlinks"],
		methods: {
			ck_goLink(link) {
				window.open(link, "_blank");
			}
		}
	}
</script>

<style scoped>
	.HomeCooperation {
		width: 75rem;
	}
	
	.HomeCooperation .title {
		font-size: 1.5rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.HomeCooperation .CooperationContent {
		display: flex;
		flex-wrap: wrap;
		width: auto;
		height: auto;
		cursor: pointer;
	}
	
	.CooperationContent .item1 {
		width: 12.1875rem;
		height: 3.75rem;
		background-color: #E7E7E7;
		margin-top: 2.6875rem;
	}
	
	.CooperationContent .item2 {
		width: 12.1875rem;
		height: 3.75rem;
		background-color: #E7E7E7;
		margin-top: 2.6875rem;
		margin-left: 3.5rem;
	}
	
	.item1:hover {
		animation: rubberBand; /* referring directly to the animation's @keyframe declaration */
		animation-duration: 1s; /* don't forget to set a duration! */
	}
	
	.item2:hover {
		animation: rubberBand; /* referring directly to the animation's @keyframe declaration */
		animation-duration: 1s; /* don't forget to set a duration! */
	}
</style>
