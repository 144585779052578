export const IDTYPE = [{
		value: '1',
		label: '居民身份证'
	},
	{
		value: '2',
		label: '临时身份证'
	}, {
		value: '3',
		label: '军官证'
	}, {
		value: '4',
		label: '武警警官证'
	}, {
		value: '5',
		label: '士兵证'
	}, {
		value: '6',
		label: '军队学员证'
	}, {
		value: '7',
		label: '军队文职干部证'
	}, {
		value: '8',
		label: '军队离退休干部证和军队职工证'
	}, {
		value: '9',
		label: '护照'
	}, {
		value: '10',
		label: '港澳同胞回乡证'
	}, {
		value: '11',
		label: '港澳居民来往内地通行证'
	}, {
		value: '12',
		label: '中华人民共和国来往港澳通行证'
	}, {
		value: '13',
		label: '台湾居民来往大陆通行证'
	}, {
		value: '14',
		label: '大陆居民往来台湾通行证'
	}, {
		value: '15',
		label: '外国人居留证'
	}, {
		value: '16',
		label: '外国人出入境证'
	}, {
		value: '17',
		label: '外交官证'
	}, {
		value: '18',
		label: '领事馆证'
	}, {
		value: '19',
		label: '海员证'
	}
]


export default {
	IDTYPE
}
