<template>
	<div>
		<div class='progressFirst' v-if='way == 1'>
			<div class='item'>
				<div class='active'>1</div>
				<div class='activeText'>
					设置用户名
				</div>
			</div>
			<div :class="this.$store.state.step>1?'progressFirstLine':'progressFirstLine-none'"></div>
			<div class='item'>
				<div :class="this.$store.state.step>1?'active':'active-none'">2</div>
				<div :class="this.$store.state.step>1?'activeText':'activeText-none'">
					填写账号信息
				</div>
			</div>
			<div :class="this.$store.state.step>2?'progressFirstLine':'progressFirstLine-none'"></div>
			<div class='item'>
				<div :class="this.$store.state.step>2?'active':'active-none'">3</div>
				<div :class="this.$store.state.step>2?'activeText':'activeText-none'">
					设置支付方式
				</div>
			</div>
			<div :class="this.$store.state.step>3?'progressFirstLine':'progressFirstLine-none'"></div>
			<div class='item'>
				<div :class="this.$store.state.step>3?'active':'active-none'">4</div>
				<div :class="this.$store.state.step>3?'activeText':'activeText-none'">
					注册成功
				</div>
			</div>
		</div>
		
		<div class='progressFirst' v-if='way == 2'>
			<div class='item'>
				<div class='active'>1</div>
				<div class='activeText'>
					设置用户名
				</div>
			</div>
			<div :class="this.$store.state.step>1?'progressFirstLine':'progressFirstLine-none'"></div>
			<div class='item'>
				<div :class="this.$store.state.step>1?'active':'active-none'">2</div>
				<div :class="this.$store.state.step>1?'activeText':'activeText-none'">
					填写企业信息
				</div>
			</div>
			<div :class="this.$store.state.step>3?'progressFirstLine':'progressFirstLine-none'"></div>
			<div class='item'>
				<div :class="this.$store.state.step>3?'active':'active-none'">3</div>
				<div :class="this.$store.state.step>3?'activeText':'activeText-none'">
					注册成功
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['way'],
		data() {
			return {
				
			}
		}
	}
</script>

<style scoped>
	.progressFirst {
		display: flex;
		
	}
	
	.progressFirst .item {
		display: flex;
		align-items: center;
		flex-direction: column;
	}
	
	.progressFirst .active {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: #56C8B0;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 20px;
		font-family: TsangerYuYangT-W05, TsangerYuYangT;
		font-weight: normal;
		color: #FFFFFF;
	}
	
	.progressFirst .active-none {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: #E0E0E0;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 20px;
		font-family: TsangerYuYangT-W05, TsangerYuYangT;
		font-weight: normal;
		color: #FFFFFF;
	}
	
	.progressFirst .activeText {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #56C8B0;
		margin-top: 0.6875rem;
	}
	
	.progressFirst .activeText-none {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-top: 0.6875rem;
	}
	
	.progressFirstLine {
		width: 12.625rem;
		height: 0;
		border: 1px solid  #56C8B0;
		margin-top: 1.3125rem;
	}
	
	.progressFirstLine-none {
		width: 12.625rem;
		height: 0;
		border: 1px solid #EDEDED;
		margin-top: 1.3125rem;
	}
</style>
