<template>
	<div class='LoginSecond'>
		<!-- 头部导航 -->
		<div class='SY-CONTENT headerBox'>
			<div class='Header'>
				<div style='display: flex;align-items: center;'>
					<div class='logo DIS-CENTER'>书营logo</div>
					<div class='text1' style='margin-left: 1rem;cursor: pointer;'>
						商家中心
					</div>
				</div>
				<div class='text1 DIS-CENTER' style='height: 3.75rem;'>
					客服热线：400-888-9999
				</div>
			</div>
		</div>
		
		<!-- 顶部 -->
		<div class='top SY-CONTENT' style='flex-direction: column;'>
			<div class='SY-CONTENT'>
				<div style='width: 75rem;display: flex;justify-content: flex-end;'>
					<div class='loginBox'>
						<div style='width: 26.25rem;display: flex;justify-content: flex-end;'>
							<img 
								style='width: 3.125rem;height: 3.125rem;cursor: pointer;'
								src="../../assets/images/loginSecond-ewm.png" alt=""
							>
						</div>
						<div class='text2'>
							商户登录
						</div>
						<div style='width: 21.25rem;margin-left: 2.5rem;margin-top: 1.375rem;'>
							<el-input
								placeholder="用户名"
								v-model="input3"
							>
								<i slot="prefix" class="el-input__icon el-icon-user"></i>
							</el-input>
						</div>	
						<div style='width: 21.25rem;margin-left: 2.5rem;margin-top: 1.375rem;'>
							<el-input
								placeholder="密码"
								v-model="input3"
							>
								<i slot="prefix" class="el-input__icon el-icon-lock"></i>
							</el-input>
						</div>
						<div class='text3Box'>
							<div class='text3'>首次登录</div>
							<div class='text3'>忘记密码？</div>
						</div>
						<div class='btn DIS-CENTER' style='margin-left: 2.5rem;margin-top: 3.125rem;'>
							登录
						</div>
					</div>
				</div>
			</div>
			
			<div class='SY-CONTENT message'>
				<div style='width: 75rem;height: 3.75rem;display: flex;align-items: center;'>
					<img style='width: 1.25rem;height: 1.25rem;' src="../../assets/images/laba.png" alt="">
					<div class='text1' style='margin-left: 1.875rem;'>
						[11-30]书营致所有商家的一封信！
					</div>
					<div class='text1' style='margin-left: 2.5rem;'>
						[11-30]书营致所有商家的一封信！
					</div>
				</div>
			</div>
		</div>
	
		<!-- 服务能力 -->
		<div class='SY-CONTENT' style='margin-top: 4.125rem;'>
			<div style='width: 75rem;display: flex;align-items: center;flex-direction: column;'>
				<div class='text4'>
					服务能力
				</div>
				<div style='display: flex;justify-content: space-between;margin-top: 4rem;width: 75rem;'>
					<img 
						class='img1'
						src="../../assets/images/loginSecond-fw5.png" alt=""
					>
					<img
						class='img1'
						src="../../assets/images/loginSecond-fw4.png" alt=""
					>
					<img
						class='img1'
						src="../../assets/images/loginSecond-fw3.png" alt=""
					>
					<img
						class='img1'
						src="../../assets/images/loginSecond-fw2.png" alt=""
					>
					<img
						class='img1' 
						src="../../assets/images/loginSecond-fw1.png" alt=""
					>
				</div>
			</div>
		</div>
	
		<!-- 解决方案 -->
		<div class='SY-CONTENT' style='margin-top: 4.6875rem;'>
			<div style='width: 75rem;display: flex;align-items: center;flex-direction: column;'>
				<div class='text4'>
					解决方案
				</div>
				<div style='display: flex;justify-content: space-between;margin-top: 4rem;width: 75rem;'>
					<img 
						class='img2'
						src="../../assets/images/LoginSecond-fn3.png" alt=""
					>
					<img
						class='img2'
						src="../../assets/images/LoginSecond-fn2.png" alt=""
					>
					<img
						class='img2'
						src="../../assets/images/LoginSecond-fn1.png" alt=""
					>
				</div>
			</div>
		</div>
		
		<!-- 页脚 -->
		<div 
			class='SY-CONTENT'
			style='background-color: #333333;margin-top: 16.0625rem;'
		>
			<HomeFooter />
		</div>
	</div>
</template>

<script>
	import HomeFooter from '../../components/Home/HomeFooter.vue';
	
	export default {
		components: { HomeFooter },
		data() {
			return {
				input3: ''
			}
		}
	}
</script>

<style scoped>
	.LoginSecond {
		
	}
	
	.Header {
		width: 75rem;
		height: 3.75rem;
		display: flex;
		justify-content: space-between;
	}
	
	.headerBox {
		width: 100%;
		height: 60px;
		background: rgba(0, 0, 0, 0.2);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}
	
	.logo {
		width: 133px;
		height: 40px;
		background: #FFFFFF;
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		cursor: pointer;
	}
	
	.text1 {
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FFFFFF;
	}
	
	.top {
		width: 100%;
		height: 42.5rem;
		background-image: url(../../assets/images/loginSecond-banner.png);
		background-size: 100% 100%;
		position: relative;
	}
	
	.loginBox {
		width: 420px;
		height: 420px;
		background: rgba(0,0,0,0.5);
		margin-top: 11.25rem;
	}
	
	.text2 {
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FFFFFF;
		margin-left: 2.5rem;
	}
	
	.LoginSecond>>>.el-input__inner{
		width: 340px;
		height: 44px;
		background: #F5F5F5;
		border: 1px solid #DDDDDD;     
	} 
	
	.text3 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #7CA3FF;
		cursor: pointer;
	}
	
	.text3Box {
		width: 21.25rem;
		display: flex;
		justify-content: space-between;
		margin-left: 2.5rem;
		margin-top: 0.625rem;
	}
	
	.btn {
		width: 340px;
		height: 50px;
		background: #4D82FF;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FFFFFF;
		border: none;
	}
	
	.message {
		height: 3.75rem;
		background: rgba(0,0,0,0.5);
		position: absolute;
		bottom: 0;
		left: 0;
	}
	
	.text4 {
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.img1 {
		width: 14.25rem;
		height: 15rem;
		cursor: pointer;
	}
	
	.img2 {
		width: 24.375rem;
		height: 13.75rem;
		cursor: pointer;
	}
</style>
