<template>
  <!-- <div style="padding: 1.5625rem 1.875rem;background-color: #FFFFFF;;"></div> -->
  <div>
    <!-- <div class="demo-box">
            <div class="demo-header demo-title">
                操作示例
            </div>
            <div class="demo-body">
                <button class="m-button m-button-primary" @click="handleAddChat">增加会话</button>
                <button class="m-button m-button-primary" @click="handleDelChat">删除会话</button>
                <button class="m-button m-button-primary" @click="config.brief= !config.brief">简约模式</button> 
            </div>
        </div> -->

    <mchat
      ref="mchat"
      :config="config"
      :chats="chats"
      :mine="mine"
      :systemList="systemList"
      :allChats="allChats"
      :friendChats="friendChats"
      :msgList="msgList"
      @ckPaneChat="handleCkPaneChat"
      @checkChat="handleCheckChat"
      @removeChat="handleChatRemove"
      @talkUserClick="handleTalkUserClick"
      @talkClick="handleTalkClick"
      @chatInfo="handleChatInfo"
      @sendMessage="sendMessage"
      @uploadEvent="handleUpload"
      @chatClose="handleDialogClose"
      @changeChatMaxOperation="changeChatMaxOperation"
    >
      <!-- 右侧隐藏的插槽 -->
      <mchat-right-box>
        <template slot-scope="props">
          <mchat-group-list
            v-if="props.chat.type == 'group'"
            :notices="props.chat.notices"
            :userList="props.chat.userList"
            :filter-user-method="filterUser"
            @click="handleRightEvent"
          ></mchat-group-list>
          <div v-else>发挥你的想象</div>
        </template>
      </mchat-right-box>
    </mchat>
  </div>
</template>
<script>
import CONST from "./constant";
import { mapState } from "vuex";
import Api from "../../request/api.js";
import { ws } from "../../websocket.js"; // 发送消息
// let alwaysSend = {
//   op_type: 101,
//   uid: JSON.parse(localStorage.getItem("userInfo")).userId,
// };
// ws.send(JSON.stringify(alwaysSend));

import { setPicSize } from "../../utils/util.js";

var replay = [
  "我认识的人很多，而且我的同志遍布五湖四海。emoji[嘻嘻]",
  "我才五年级，老马讲什么都听不懂了，因为他坚持奋斗福报走资理论。emoji[威武]",
  "我喜欢你，emoji[爱你]从初级阶段直到共产主义。",
  "奋斗了三十年他终究还是离开了我们，但他还在我们身边。 ",
  "我通过了你的入党申请，现在我们可以开始为全人类解放的伟大事业共同奋斗了。emoji[心]emoji[心]",
  "资产阶级的白鸽不会亲吻乌鸦，世间没有所谓跨阶级的爱。",
  "中国真好，下次还来。",
  "生而为人，为人民服务。",
  "<（@￣︶￣@）>",
  "(*^__^*) emojio[嘻嘻] ，请我喝咖啡吗？",
];
export default {
  props: ["getShopId"],
  name: "i-chat-test",
  computed: mapState(["orderNum", "currentMsg", "AllNum"]),
  data() {
    return {
      //获取主面板列表信息，下文会做进一步介绍
      config: {
        // 是否有下拉按钮
        downBtn: true,
        // rightBox: true,  //控制右侧侧边栏显示  更改默认
        rightBox: false,
        // 简约模式
        brief: false,
        // 是否开启桌面消息提醒，即在浏览器之外的提醒
        notice: false,
        // 设定
        voice: true,
        //上传文件的扩展名
        fileExt: "",
        tabRemove: false,
        fixed: true,
        isFullscreen: false, // 当前是否是全屏    isFullscreen
        sender_uid: "",
        receive_uid: "",
        userInfo: "",
        targetInfo: "",
      },
      //我的信息
      mine: {},
      //列表分类
      allChats: CONST.allChats,

      //最近消息
      // chats: CONST.recentNews,  //更改默认
      chats: [],
      friendChats: CONST.friendChats, // 好友列表
      orderNummessage: "", //系统消息 未读条数
      systemList: [], //系统消息
      talkList: [], //列表消息
      msgList: [], //历史消息
      // 历史数据操作
      page: {
        pageNo: 0,
        pageSize: 10,
        end: false,
        isHistoryLoading: false,
      },
      msgImgList: [],
      setPicSize: setPicSize,
    };
  },
  watch: {
    // 监听 系统消息未读条数
    orderNum: {
      deep: true,
      handler(newMsg) {
        this.orderNummessage = newMsg;
      },
    },
    //监听 收到消息  currentMsg
    currentMsg: {
      deep: true,
      handler(newMsg) {
        if (
          (newMsg.sender_user.user_id == this.config.sender_uid &&
            newMsg.receive_user.user_id == this.config.receive_uid) ||
          (newMsg.sender_user.user_id == this.config.receive_uid &&
            newMsg.receive_user.user_id == this.config.sender_uid)
        ) {
          newMsg.senduser_id = newMsg.sender_user.user_id;
          this.msgList.push(newMsg);
          this.initData();
          this.messageInitData(false);
        }
      },
    },

    AllNum: {
      deep: true,
      handler(newMsg) {
        if (newMsg != 0) {
          this.messageInitData(false);
        }
      },
    },
  },
  mounted() {
    // this.initData(); // 系统消息
    // 对话列表 数据
    if (this.$props.getShopId) {
      this.messageInitData(true, this.$props.getShopId);
    } else {
      this.messageInitData(false);
    }
    window.onresize = () => {
      if (this.checkFull()) {
        this.config.isFullscreen = true;
      } else {
        this.config.isFullscreen = false;
      }
    };
  },

  methods: {
    //最近消息 系统消息
    initData() {
      const data = {
        loginid: localStorage.getItem("shuying-token"),
      };
      this.axios.post(Api.messageList, data).then((res) => {
        if (res.data.code == 200) {
          this.systemList = res.data.data;
          // console.log(this.systemList, "系统消息");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 历史消息
    initHistoryData(target_uid) {
      const data = {
        target_uid: target_uid,
        loginid: localStorage.getItem("shuying-token"),
      };
      this.axios.post(Api.getChatMsg, data).then((res) => {
        if (res.data.code == 200) {
          if (target_uid != res.data.data.targetUserInfo.userId) {
            this.config.receive_uid = res.data.data.targetUserInfo.userId;
            this.config.targetInfo = res.data.data.targetUserInfo;
          }
          for (let i = 0; i < res.data.data.chat_msg_list.length; i++) {
            if (res.data.data.chat_msg_list[i].type == "1") {
              res.data.data.chat_msg_list[i].content = this.setPicSize(
                res.data.data.chat_msg_list[i].content
              );
              this.msgImgList.push(
                this.analysisImg(res.data.data.chat_msg_list[i].content)
              );
            }
          }

          this.msgList = res.data.data.chat_msg_list;
          // if (this.page.pageNo === 0) {
          //   this.msgList = res.data.data.chat_msg_list;
          //   // 滚动到底部
          //   this.$nextTick(() => {
          //     //进入页面滚动到底部
          //     this.scrollTop = 9999;
          //     this.$nextTick(() => {
          //       this.scrollAnimation = true;
          //     });
          //   });
          // } else {
          //   // 方法将新项添加到数组的开头，并返回新的长度。
          //   this.msgList.unshift(...res.data.data.chat_msg_list);
          //   this.$nextTick(function () {
          //     this.scrollToView = "msg" + viewId; //跳转上次的第一行信息位置
          //     this.$nextTick(function () {
          //       this.scrollAnimation = true; //恢复滚动动画
          //     });
          //   });
          // }
          // if (res.data.data.chat_msg_list.length === this.page.pageSize) {
          //   this.page.pageNo++;
          //   this.page.isHistoryLoading = false;
          // } else {
          //   this.page.end = true;
          // }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    messageInitData(isShopId, shopId) {
      var data;
      if (isShopId) {
        data = {
          shopid: shopId,
          loginid: localStorage.getItem("shuying-token"),
        };
      } else {
        data = {
          loginid: localStorage.getItem("shuying-token"),
        };
      }
      this.axios.post(Api.chatPageData, data).then((res) => {
        if (res.data.code == 200) {
          if (isShopId) {
            if (shopId != res.data.data.userinfo.userId) {
              this.config.sender_uid = res.data.data.userinfo.userId;
              this.config.userInfo = res.data.data.userinfo;
            }
            this.initHistoryData(shopId);
          }

          this.chats = res.data.data.lately_contact;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    analysisImg(msg) {
      let imgReg = /<img.*?(?:>|\/>)/gi;
      let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
      let arr = msg.match(imgReg);
      let srcArr = [];
      for (let i = 0; i < arr.length; i++) {
        let src = arr[i].match(srcReg);
        srcArr.push(src[1]);
      }
      return srcArr[0];
    },

    analysisImgWidth(msg) {
      var matchReg = /(?<=width:).*?(?=;height)/;
      let arr = msg.match(matchReg);
      return parseInt(arr[0]);
    },

    analysisImgHeigth(msg) {
      var matchReg = /(?<=height:).*?(?=;)/;
      let arr = msg.match(matchReg);
      return parseInt(arr[0]);
    },

    // 判断当前浏览器是否是全屏
    checkFull() {
      if (document.mozFullScreen) {
        return true;
      } else if (document.webkitIsFullScreen) {
        return true;
      } else if (document.msFullscreenElement) {
        return true;
      } else if (window.fullScreen) {
        return true;
      }
      return false;
    },

    handleDialogClose(data) {
      this.$emit("closeDialogBox", false);
    },

    changeChatMaxOperation(event) {
      if (event) {
        //全屏 最大化
        this.config.isFullscreen = true;
      }
      if (!event) {
        // 正常 最小化
        this.config.isFullscreen = false;
      }
    },

    filterUser(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    fetchNotices() {
      return CONST.notice_list;
    },

    // 切换列表
    handleCheckChat(chat) {
      if (chat) {
        this.allChats[0].active = false;
        // this.allChats[1].active = true;
      } else {
        // this.allChats[1].active = false;
        this.allChats[0].active = true;
      }
    },

    // 点击 删除对话框操作
    handleChatRemove({ id, name, type }) {
      let channels = this.chats;
      let len = channels.length;
      if (len < 1) return;
      let ary = [];
      for (let i = 0; i < len; i++) {
        let model = channels[i];
        if (model.id !== id) {
          ary.push(model);
        }
      }
      this.chats = ary;
    },

    handleRightEvent(event) {
      console.log("右边框事件", event);
    },

    // 点击切换对话用户
    handleCkPaneChat(item) {
      this.config.userInfo = item.user_info;
      this.mine = item.user_info;
      this.config.targetInfo = item.target_info;
      this.config.receive_uid = item.target_info.userId;
      this.config.sender_uid = item.user_id;
      this.initHistoryData(this.config.receive_uid);
    },

    // 发送文本信息
    sendMessage(data) {
      ws.send(JSON.stringify(data));
      // this.$im.emit("getMessage", data); 监听发送消息事件
    },

    // 点击删除会话  隐藏
    handleDelChat() {
      this.chats.pop();
    },

    // 发送图片
    handleUpload(msg) {
      ws.send(JSON.stringify(msg));
    },

    handleTalkUserClick(item) {
      console.log("事件名：talkUserClick。对话用户头像点击事件", item);
    },

    handleTalkClick(item) {
      console.log("事件名：talkClick。对话内容点击事件", item);
    },

    handleChatInfo(item) {
      console.log("事件名：chatInfo。点击窗口对话头像触发", item);
    },
  },
};
</script>

<style lang="scss">
.demo-box {
  border: 1px solid #ebeef5;
  background-color: #fff;
  color: #303133;
  transition: 0.3s;
  border-radius: 4px;
  overflow: hidden;
  .demo-header {
    padding: 18px 20px;
    border-bottom: 1px solid #ebeef5;
    box-sizing: border-box;
  }
  .demo-body {
    padding: 20px;
  }
}
</style>
