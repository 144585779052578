<template>
	<div class='CloseAnAccount'>
		<div>
			<Header bgIndex='4' @dialogBoxIndex="dialogBoxIndex" />
		</div>

		<!-- 内容 -->
		<div class='SY-CONTENT'>
			<div class='content'>
				<!-- 面包屑 -->
				<div class='BreadCrumbsBox'>
					<div class='BreadCrumbs-text1' @click='ck_goPage("/PersonalCenter")'>
						主页
					</div>
					<div class='BreadCrumbs-text1' style='cursor: none;margin: 0 1.5625rem;'>/</div>
					<div class='BreadCrumbs-text1' @click='ck_goPage("/PersonalCenter",{type: 9})'>
						账户设置
					</div>
					<div class='BreadCrumbs-text1' style='cursor: none;margin: 0 1.5625rem;'>/</div>
					<div class='BreadCrumbs-text2'>注销账户</div>
				</div>

				<div class='DIS-CENTER'>
					<!-- 注销账户01 -->
					<div style='margin-top: 8rem;'>
						<div class='EditMessageFirst'>
							<div class='text1'>
								您正在为账户 <span style='color: #EE4454;'>{{userInfo.userEmail}}</span> 注销账户：
							</div>
							<div class='itemBox' style="margin-top: 2.9375rem;">
								<div class='text2'>手机号</div>
								<div class='phoneBox'>
									<el-input :disabled='true' :placeholder="newUserPhone"></el-input>
								</div>
							</div>
							<div class='itemBox' style="margin-top: 1.875rem;">
								<div class='text2'>验证码</div>
								<div class='codeBox'>
									<el-input v-model="code" placeholder="请输入验证码"></el-input>
									<div class='text2 DIS-CENTER'
										style='width: 6rem;margin-right: 0.8125rem;cursor: pointer;' @click='ck_code'>
										{{codeText}}
									</div>
								</div>
							</div>
							<div class='btn DIS-CENTER' @click='ck_step'>
								注销
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<!-- 页脚 -->
		<div class='SY-CONTENT' style='background-color: #333333;'>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Header from '../components/Header.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	import Api from '../request/api.js';

	export default {
		components: {
			Header,
			HomeFooter,
		},
		data() {
			return {
				phone: '',
				code: '', // 验证码
				codeText: '获取验证码',
				timer: null,
				userInfo: {},
				setEmail: '',
				newUserPhone: '',
				isShowDialogBox:false,
			}
		},
		mounted() {
			this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
			this.newUserPhone = this.userInfo.userPhone.substr(0, 3) + '****' + this.userInfo.userPhone.substr(7);
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			ck_goPage(path, query) {
				this.$router.push({
					path,
					query
				});
			},
			ck_code() {
				if (this.codeText == '获取验证码') {
					const data = {
						phone: this.userInfo.userPhone
					}
					this.axios.post(Api.sendPhoneCode, data).then(res => {
						if (res.data.code == 200) {
							this.$message.success('验证码已发送')
						} else {
							this.$message.error('验证码发送失败')
						}
					})
					this.codeText = 60;
					this.timer = setInterval(() => {
						this.codeText = this.codeText - 1;
						if (this.codeText < 1) {
							window.clearInterval(this.timer);
							this.codeText = '获取验证码'
						}
					}, 1000)
				}
			},
			ck_step() {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					phone: this.userInfo.userPhone,
					code: this.code,
				}
				this.axios.post(Api.cancellation, data).then(res => {
					if (res.data.code == 200) {
						this.$message.success('注销成功')
						localStorage.removeItem('shuying-token');
						localStorage.removeItem('userInfo');
						this.$router.push({
							path: '/Login'
						})
					} else {
						this.$message.error(res.data.msg)
					}
				})
			}
		}
	}
</script>

<style scoped>
	.CloseAnAccount {
		background: #F5F5F5;
	}

	.content {
		width: 75rem;
		padding-top: 1.875rem;
		min-height: 71.5625rem;
		background-color: #FFFFFF;
	}

	.BreadCrumbsBox {
		display: flex;
		align-items: center;
		margin-left: 2.4375rem;
	}

	.BreadCrumbs-text1 {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
	}

	.BreadCrumbs-text2 {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		cursor: pointer;
	}


	.EditMessageFirst {
		width: 26rem;
	}

	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.itemBox {
		display: flex;
		align-items: center;
	}

	.text2 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.phoneBox {
		width: 340px;
		height: 44px;
		/* border: 1px solid #DDDDDD; */
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		line-height: 2.75rem;
		margin-left: 1.875rem;
	}

	.codeBox {
		width: 21.25rem;
		height: 2.75rem;
		border: 1px solid #DDDDDD;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: 1.875rem;
	}

	.codeBox>>>.el-input__inner {
		width: 200px;
		border: none;
	}

	.btn {
		width: 120px;
		height: 44px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
		margin-top: 2.0625rem;
		margin-left: 4.5rem;
	}
</style>
