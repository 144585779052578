<template>
  <div class="MineMaterial">
    <div>
      <Header bgIndex="4" @dialogBoxIndex="dialogBoxIndex" />
    </div>

    <!-- 内容区 -->
    <div class="SY-CONTENT">
      <div
        style="width: 75rem; background-color: #ffffff; padding-top: 1.875rem"
      >
        <div class="Bread">
          <span style="cursor: pointer" @click="ck_goPage('/PersonalCenter')"
            >主页</span
          >
          <span style="margin: 0 1.5625rem">/</span>
          <span class="title">我的资料</span>
        </div>
        <div
          class="DIS-CENTER"
          style="width: 100%; flex-direction: column; margin-top: 3.875rem"
        >
          <div>
            <div style="display: flex">
              <div
                class="text1"
                style="
                  width: 80px;
                  display: flex;
                  justify-content: flex-end;
                  margin-top: 0.6875rem;
                "
              >
                <span style="color: #ee4656">*</span>
                当前头像
              </div>
              <div style="margin-left: 2.1875rem; position: relative">
                <img
                  style="
                    width: 5rem;
                    height: 5rem;
                    cursor: pointer;
                    border-radius: 50%;
                  "
                  :src="userHeader"
                  alt=""
                />
                <label
                  class="upload DIS-CENTER"
                  for="upload"
                  accept="image/png,image/jpeg"
                >
                  上传头像
                </label>
              </div>
              <input
                id="upload"
                type="file"
                style="display: none"
                @change="ce_upload"
              />
            </div>

            <div
              style="display: flex; margin-top: 3.3125rem; align-items: center"
            >
              <div
                class="text1"
                style="width: 80px; display: flex; justify-content: flex-end"
              >
                <span style="color: #ee4656">*</span>
                昵称
              </div>
              <div style="margin-left: 2.1875rem">
                <el-input
                  v-model="userInfo.loginName"
                  placeholder="请输入昵称"
                ></el-input>
              </div>
            </div>

            <div
              style="display: flex; margin-top: 1.875rem; align-items: center"
            >
              <div
                class="text1"
                style="width: 80px; display: flex; justify-content: flex-end"
              >
                真实姓名
              </div>
              <div style="margin-left: 2.1875rem">
                <el-input
                  v-model="userInfo.trueName"
                  placeholder="请输入真实姓名"
                ></el-input>
              </div>
            </div>

            <div
              style="display: flex; margin-top: 1.875rem; align-items: center"
            >
              <div
                class="text1"
                style="width: 80px; display: flex; justify-content: flex-end"
              >
                性别
              </div>
              <div style="margin-left: 2.1875rem" class="DIS-CENTER">
                <div
                  class="DIS-CENTER"
                  style="cursor: pointer"
                  @click="ck_editRadio(0)"
                >
                  <img
                    v-if="userInfo.userSex == 0"
                    style="width: 1rem; height: 1rem"
                    src="../assets/images/radio_btn_selected@2x.png"
                    alt=""
                  />
                  <img
                    v-else
                    style="width: 1rem; height: 1rem"
                    src="../assets/images/radio_btn@2x.png"
                    alt=""
                  />
                  <span class="text1" style="margin-left: 0.9375rem">
                    保密
                  </span>
                </div>
                <div
                  class="DIS-CENTER"
                  style="cursor: pointer; margin-left: 3.3125rem"
                  @click="ck_editRadio(1)"
                >
                  <img
                    v-if="userInfo.userSex == 1"
                    style="width: 1rem; height: 1rem"
                    src="../assets/images/radio_btn_selected@2x.png"
                    alt=""
                  />
                  <img
                    v-else
                    style="width: 1rem; height: 1rem"
                    src="../assets/images/radio_btn@2x.png"
                    alt=""
                  />
                  <span class="text1" style="margin-left: 0.9375rem"> 男 </span>
                </div>
                <div
                  class="DIS-CENTER"
                  style="margin-left: 3.3125rem; cursor: pointer"
                  @click="ck_editRadio(2)"
                >
                  <img
                    v-if="userInfo.userSex == 2"
                    style="width: 1rem; height: 1rem"
                    src="../assets/images/radio_btn_selected@2x.png"
                    alt=""
                  />
                  <img
                    v-else
                    style="width: 1rem; height: 1rem"
                    src="../assets/images/radio_btn@2x.png"
                    alt=""
                  />
                  <span class="text1" style="margin-left: 0.9375rem"> 女 </span>
                </div>
              </div>
            </div>

            <div style="display: flex; margin-top: 1.875rem">
              <div
                class="text1"
                style="width: 80px; display: flex; justify-content: flex-end"
              >
                <span style="color: #ee4656">*</span>
                所在地区
              </div>
              <div
                v-if="userInfo.areaId"
                style="margin-left: 2.1875rem"
                class="text2"
              >
                {{ userInfo.province }}-{{ userInfo.city }}-{{ userInfo.area }}
              </div>
              <div
                v-else
                style="
                  margin-left: 2.1875rem;
                  display: flex;
                  flex-direction: column;
                "
              >
                <!-- ------------------ 获取地址 ---------------- -->
                <el-select
                  v-model="provinceValue"
                  placeholder="请选择省份"
                  @change="getCity"
                >
                  <el-option
                    v-for="item in provinceOptions"
                    :key="item.areaId"
                    :label="item.areaName"
                    :value="item.areaId"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-model="cityValue"
                  placeholder="请选择市"
                  style="margin: 10px 0"
                  @change="getArea"
                >
                  <el-option
                    v-for="item in cityOptions"
                    :key="item.areaId"
                    :label="item.areaName"
                    :value="item.areaId"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-model="areaValue"
                  placeholder="请选择区"
                  @change="getAddress"
                >
                  <el-option
                    v-for="item in areaOptions"
                    :key="item.areaId"
                    :label="item.areaName"
                    :value="item.areaId"
                  >
                  </el-option>
                </el-select>
                <!-- ------------------------------------------------- -->
              </div>
            </div>

            <div
              style="display: flex; margin-top: 1.875rem; align-items: center"
            >
              <div
                class="text1"
                style="width: 80px; display: flex; justify-content: flex-end"
              >
                <span style="color: #ee4656">*</span>
                手机号
              </div>
              <div style="margin-left: 2.1875rem" class="text2">
                {{ userPhone }}
              </div>
            </div>

            <div
              style="display: flex; margin-top: 1.875rem; align-items: center"
            >
              <div
                class="text1"
                style="width: 80px; display: flex; justify-content: flex-end"
              >
                <span style="color: #ee4656">*</span>
                邮箱
              </div>
              <div
                v-if="userInfo.userEmail"
                style="margin-left: 2.1875rem"
                class="text2"
              >
                {{ userInfo.userEmail }}
              </div>
              <div v-else style="margin-left: 2.1875rem">
                <el-input
                  v-model="userEmail"
                  placeholder="请输入邮箱"
                ></el-input>
              </div>
            </div>
          </div>
        </div>

        <div
          class="btn DIS-CENTER"
          style="margin: 3.5rem 0 27.875rem 30.0625rem"
          @click="ck_editUserData"
        >
          保存
        </div>
      </div>
    </div>

    <!-- 页脚 -->
    <div class="SY-CONTENT" style="background-color: #333333">
      <HomeFooter />
    </div>

    <div
      v-if="isShowDialogBox"
      class="isShowDialogShade"
      @click="isShowDialogBox = false"
    ></div>
    <div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
      <DialogBoxIndex
        @closeDialogBox="closeDialogBox"
        :getShopId="false"
      ></DialogBoxIndex>
    </div>
  </div>
</template>

<script>
import Api from "../request/api.js";

import Header from "../components/Header.vue";
import HomeFooter from "../components/Home/HomeFooter.vue";

export default {
  components: {
    Header,
    HomeFooter,
  },
  data() {
    return {
      input: "",
      radioValue: 1,
      userInfo: {}, // 用户的基本信息,
      /*----------- 获取地址 ----------*/
      provinceOptions: [], // 省的数组
      provinceValue: "", // 省的数据
      provinceText: "",
      cityOptions: [], // 市的数组
      cityValue: "", // 市的数据
      cityText: "",
      areaOptions: [], // 区的数组
      areaValue: "", // 区的数据
      areaText: "",
      /*----------------------------*/
      value: [],
      options: [],
      userEmail: "", // 邮箱信息
      userPhone: "", // 用户显示的手机号
      userHeader: "", // 用户显示头像，带域名的
      userHeader1: "", // 上传给服务器的头像，不带域名的
      isShowDialogBox: false,
    };
  },
  mounted() {
    this.initLocalData();
    this.initData();
  },
  methods: {
    dialogBoxIndex(data) {
      this.isShowDialogBox = data;
    },
    closeDialogBox(data) {
      this.isShowDialogBox = data;
    },
    initLocalData() {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.userHeader = this.userInfo.userPhoto;
      this.userPhone =
        this.userInfo.userPhone.substr(0, 3) +
        "****" +
        this.userInfo.userPhone.substr(7);
    },
    /*--------------- 获取地址 ----------------*/
    initData(parentId, type) {
      const data = {
        parentId,
      };
      this.axios.post(Api.getArea, data).then((res) => {
        if (res.data.code == 200) {
          // this.provinceOptions = res.data.data
          if (type == "city") {
            this.cityOptions = res.data.data;
          } else if (type == "area") {
            this.areaOptions = res.data.data;
          } else {
            this.provinceOptions = res.data.data;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    getCity(value) {
      this.initData(value, "city");
      this.provinceText = value
        ? this.provinceOptions.find((ele) => ele.areaId === value).areaName
        : "";
    },
    getArea(value) {
      this.initData(value, "area");
      this.cityText = value
        ? this.cityOptions.find((ele) => ele.areaId === value).areaName
        : "";
    },
    getAddress(value) {
      this.initData(value, "area");
      this.areaText = value
        ? this.areaOptions.find((ele) => ele.areaId === value).areaName
        : "";
    },
    /*------------------------------------------------*/
    ck_editRadio(value) {
      this.userInfo.userSex = value;
    },
    ck_goPage(path) {
      this.$router.push({
        path,
      });
    },

    ck_editUserData() {
      if (this.userHeader1 == "") {
        if (this.userInfo.userPhoto.indexOf("http") >= 0) {
          this.userHeader1 = this.userInfo.userPhoto.split(
            "https://admin.showwins.com/"
          )[1];
        } else {
          this.userHeader1 = this.userInfo.userPhoto;
        }
      }

      if (this.userInfo.userEmail) {
        this.userEmail = this.userInfo.userEmail;
      }
      if (this.userInfo.areaId) {
        this.areaValue = this.userInfo.areaId;
        this.provinceText = this.userInfo.province;
        this.cityText = this.userInfo.city;
        this.areaText = this.userInfo.area;
      }
      const data = {
        loginid: localStorage.getItem("shuying-token"),
        userName: this.userInfo.loginName,
        trueName: this.userInfo.trueName,
        userEmail: this.userEmail,
        userPhone: this.userInfo.userPhone,
        areaId: this.areaValue,
        province: this.provinceText,
        city: this.cityText,
        area: this.areaText,
        userSex: this.userInfo.userSex,
        userPhoto: this.userHeader1,
      };
      this.axios.post(Api.editUserData, data).then((res) => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
          this.getUserInfo();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    getUserInfo() {
      const data = {
        loginid: localStorage.getItem("shuying-token"),
      };
      this.axios.post(Api.getUserInfo, data).then((res) => {
        if (res.data.code == 200) {
          localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          this.initLocalData();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    ce_upload(value) {
      const file = value.srcElement.files[0];
      if (!file) {
        return;
      }
      let data = new FormData();
      data.append("file", file);
      this.axios.post(Api.uploadFile, data).then((res) => {
        if (res.data.code == 200) {
          this.userHeader1 = res.data.data.file_path;
          this.userHeader = Api.URL + "/" + res.data.data.file_path;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped>
.MineMaterial {
  background-color: #f5f5f5;
}

.Bread {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-left: 2.5rem;
}

.title {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}

.text1 {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.upload {
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: 0;
  left: 0;
  background: #000000;
  opacity: 0;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}

.upload:hover {
  opacity: 0.5;
}

.MineMaterial >>> .el-input__inner {
  width: 340px;
  height: 44px;
  border: 1px solid #dddddd;
  border-radius: 0;
}

/* .addressBox>>>.el-input__inner {
		width: 120px;
		height: 44px;
		border: 1px solid #DDDDDD;
		border-radius: 0;
	} */

.text2 {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

.btn {
  width: 120px;
  height: 44px;
  background: #ee4454;
  border-radius: 4px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}
</style>
