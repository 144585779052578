<template>
	<div class='JfThird'>
		<div style='display: flex;align-items: center;'>
			<div class='title'>充值金额</div>
			<div class='inputBox'>
				<el-input v-model="input" placeholder="请输入充值金额"></el-input>
			</div>
		</div>
		
		<div style='display: flex;margin-top: 2.3125rem;'>
			<div class='title'>支付方式</div>
			<div style='margin-left: 1.6875rem;'>
				<div class='item' @click='radioValue = 0'>
					<img 
						v-if='radioValue == 0'
						style='width: 1rem;height: 1rem;margin-left: 2.5rem;' 
						src="../../../assets/images/radio_btn_selected@2x.png" 
						alt=""
					>
					<img
						v-else
						style='width: 1rem;height: 1rem;margin-left: 2.5rem;' 
						src="../../../assets/images/radio_btn@2x.png" 
						alt=""
					>
					<img 
						style='width: 1.5625rem;height: 1.5625rem;margin-left: 1.6875rem;'
						src="../../../assets/images/pay-zfb.png" alt=""
					>
					<div class='text1' style='margin-left: 1rem;'>
						支付宝
					</div>
				</div>
				
				<div class='item' style='margin-top: 1.1875rem;' @click='radioValue = 1'>
					<img 
						v-if='radioValue == 1'
						style='width: 1rem;height: 1rem;margin-left: 2.5rem;' 
						src="../../../assets/images/radio_btn_selected@2x.png" 
						alt=""
					>
					<img
						v-else
						style='width: 1rem;height: 1rem;margin-left: 2.5rem;' 
						src="../../../assets/images/radio_btn@2x.png" 
						alt=""
					>
					<img 
						style='width: 1.5625rem;height: 1.5625rem;margin-left: 1.6875rem;'
						src="../../../assets/images/pay-wx.png" alt=""
					>
					<div class='text1' style='margin-left: 1rem;'>
						微信支付
					</div>
				</div>
			</div>
		</div>
	
		<div class='text2' style='margin-top: 1.5rem;margin-left: 100px'>
			提示：充值金额只能用于购买商品，不能提现。
		</div>
		
		<div class='btn DIS-CENTER' style='margin-left: 6.25rem;margin-top: 2.6875rem;'>
			充值
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				input: '',
				radioValue: 0,
			}
		}
	}
</script>

<style scoped> 
	.JfThird {
		
	}
	
	.title {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.inputBox {
		width: 130px;
		height: 44px;
		border: 1px solid #DDDDDD;
		margin-left: 1.6875rem;
	}
	
	.JfThird>>>.el-input__inner{
		border: none;
	} 
	
	.item {
		width: 750px;
		height: 80px;
		border: 1px solid #E6E6E6;
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	
	.text1 {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.text2 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
	}
	
	.btn {
		width: 140px;
		height: 44px;
		background: #EE4454;
		border-radius: 4px;
		cursor: pointer;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}
</style>
