<template>
	<div>
		<div id='DB'>
			<Header bgIndex='1' @dialogBoxIndex='dialogBoxIndex' />
		</div>

		<div class='SY-CONTENT'>
			<HomeMenu :menuList='menuList' />
		</div>

		<!-- 好书精选 -->
		<div class='SY-CONTENT' id='HSJX'>
			<HomeClassify :homeClassify='classfiy' />
			<div style='width: 62.5rem;'>
				<HomeBanner :homeBanner='banner' />
				<HomeFirstContent :homeChoicenessGoods='choicenessGoods' />
			</div>
		</div>

		<!-- 广告位 -->
		<div class='SY-CONTENT'>
			<div class='HomeFirst-advertising DIS-CENTER'>
				<img style='width: 100%;' :src="advertisingBanner.adFile" alt="">
			</div>
		</div>

		<!-- 新书上市 -->
		<div class='SY-CONTENT' id='XSSS'>
			<div style='margin-top: 2.75rem;'>
				<HomeSecondContent :homeNewBooks='newBooks' />
			</div>
		</div>

		<!-- 达人推荐 -->
		<div class='SY-CONTENT' id='DRTJ' v-if="true">
			<div style='margin-top: 2.125rem;'>
				<HomeThirdContent />
			</div>
		</div>

		<!-- 资讯 -->
		<div class='SY-CONTENT' id='ZX'>
			<div style='margin-top: 3.1875rem;'>
				<HomeFourContent :homeFooterAritcles='footerAritcles' />
			</div>
		</div>

		<!-- 旧书摊 -->
		<div class='SY-CONTENT' id='JST'>
			<div style='margin-top: 2.75rem;display: flex;'>
				<HomeFiveContent title='左图右史' text='更多好书' path='History' :goodsList='oldBooks' />
				<div style='margin-left: 3.25rem;'>
					<HomeRecommend :shops='bookstall' />
				</div>
			</div>
		</div>

		<!-- 文创集市 -->
		<div class='SY-CONTENT' id='WCJS'>
			<div style='margin-top: 3.125rem;display: flex;'>
				<HomeFiveContent title='文创集市' text='更多好物' path='Market' :goodsList='culturalFair' />
				<div style='margin-left: 3.25rem;'>
					<HomeRecommend :shops='shops' />
				</div>
			</div>
		</div>

		<!-- 合作伙伴 -->
		<div class='SY-CONTENT'>
			<div style='margin-top: 2.9375rem;'>
				<HomeCooperation :homeFriendlinks='friendlinks' />
			</div>
		</div>

		<!-- 页尾文字 -->
		<div class='SY-CONTENT' style='margin-top: 8.5rem;background-color: #FAFAFA;'>
			<HomeText />
		</div>

		<!-- 页脚 -->
		<div class='SY-CONTENT' style='background-color: #333333;'>
			<HomeFooter />
		</div>

		<!-- 右侧导航条 -->
		<!-- <div class='navigation'>
			<div :class="navigationCSS[0] ? 'item1': 'item2'" @click="ck_navigation(0,'ck')">
				<div :class="navigationCSS[0] ? '': 'line'">
					<div>好书</div>
					<div>精选</div>
				</div>
			</div>
			<div :class="navigationCSS[1] ? 'item1': 'item2'" @click="ck_navigation(1,'ck')">
				<div :class="navigationCSS[1] ? 'line1': 'line2'">
					<div>新书</div>
					<div>上市</div>
				</div>
			</div>
			<div v-if='false' :class="navigationCSS[2] ? 'item1': 'item2'" @click="ck_navigation(2,'ck')">
				<div :class="navigationCSS[2] ? 'line1': 'line2'">
					<div>达人</div>
					<div>推荐</div>
				</div>
			</div>
			<div :class="navigationCSS[3] ? 'item1': 'item2'" @click="ck_navigation(3,'ck')">
				<div :class="navigationCSS[3] ? 'line1': 'line2'">
					资讯
				</div>
			</div>
			<div :class="navigationCSS[4] ? 'item1': 'item2'" @click="ck_navigation(4,'ck')">
				<div :class="navigationCSS[4] ? 'line1': 'line2'">
					旧书摊
				</div>
			</div>
			<div :class="navigationCSS[5] ? 'item1': 'item2'" @click="ck_navigation(5,'ck')">
				<div :class="navigationCSS[5] ? 'line1': 'line2'">
					<div>文创</div>
					<div>集市</div>
				</div>
			</div>
			<div :class="navigationCSS[6] ? 'item1': 'item2'" @click="ck_navigation(6,'ck')">
				<div :class="navigationCSS[6] ? 'line1': 'line2'">
					<img style='width: 0.875rem;height: 0.875rem;margin-bottom: 0.125rem;'
						src="../assets/images/arrow_up@2x.png" alt="">
					顶部
				</div>
			</div>
			<div :class="navigationCSS[7] ? 'item1': 'item2'" @click="ck_navigation(7,'ck')">
				反馈
			</div> -->

			<!-- <div class="item2" @click="kfTest">客服test</div> -->
		<!-- </div> -->
		
		<!-- 对话框组件 -->
		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Header from '../components/Header.vue';
	import HomeMenu from '../components/Home/HomeMenu.vue';
	import HomeClassify from '../components/Home/HomeClassify.vue';
	import HomeBanner from '../components/Home/HomeBanner.vue';
	import HomeFirstContent from '../components/Home/HomeFirstContent.vue';
	import HomeSecondContent from '../components/Home/HomeSecondContent.vue';
	import HomeThirdContent from '../components/Home/HomeThirdContent.vue';
	import HomeFourContent from '../components/Home/HomeFourContent.vue';
	import HomeFiveContent from '../components/Home/HomeFiveContent.vue';
	import HomeRecommend from '../components/Home/HomeRecommend.vue';
	import HomeCooperation from '../components/Home/HomeCooperation.vue';
	import HomeText from '../components/Home/HomeText.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	import Api from '../request/api.js';

	export default {
		components: {
			Header,
			HomeMenu,
			HomeClassify,
			HomeBanner,
			HomeFirstContent,
			HomeSecondContent,
			HomeThirdContent,
			HomeFourContent,
			HomeFiveContent,
			HomeRecommend,
			HomeCooperation,
			HomeText,
			HomeFooter,
		},
		data() {
			return {
				menuList: [{
						text: '首页',
						active: true,
						path: '/'
					},
					{
						text: '左图右史',
						active: false,
						path: '/History'
					},
					{
						text: '新书',
						active: false,
						path: '/NewBook'
					},
					{
						text: '文创集市',
						active: false,
						path: '/Market'
					},
					{
						text: '资讯',
						active: false,
						path: '/Information'
					},
					// {
					// 	text: '排行榜',
					// 	active: false,
					// 	path: '/'
					// },
					// {
					// 	text: '推荐中心',
					// 	active: false,
					// 	path: '/Intelligent'
					// }
				],
				navigationCSS: [true, false, false, false, false, false, false, false],
				scrollTop: 0,
				// 接口数据
				classfiy: [ //分类
					{
						text: [],
						title: '全部图书分类'
					}
				],
				banner: [], //轮播图
				choicenessGoods: [], //精选商品,
				advertisingBanner: [], //中间广告banner
				newBooks: [], //新书上架
				recomgoods: [], //达人推荐
				footerAritcles: [], //资讯
				oldBooks: [], //旧书摊
				culturalFair: [], //文创集市
				bookstall: [], //左图右史的排行店
				shops: [], // 文创集市的排行店
				friendlinks: [], // 友情链接
				isShowDialogBox: false,
			}
		},
		created() {
			this.initData();
		},
		methods: {
			// kfTest() {
			// 	// 弹出全局组件
			// },
			// 点击消息打开对话框
			dialogBoxIndex(data) {
				// console.log(data,'打开对话框')
				this.isShowDialogBox = data
			},
			// 关闭对话框
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			// 初始化数据
			initData() {
				this.axios.post(Api.index).then(res => {
					if (res.data.code == 200) {
						this.classfiy[0].text = res.data.data.categorys;
						this.banner = res.data.data.banners;
						this.choicenessGoods = res.data.data.bestgoods;
						this.newBooks = res.data.data.newgoods;
						// 达人推荐暂时屏蔽
						// this.recomgoods = res.data.data.recomgoods;
						this.footerAritcles = res.data.data.footer_aritcles;
						this.oldBooks = res.data.data.oldBooks;
						this.culturalFair = res.data.data.culturalFair;
						this.bookstall = res.data.data.bookstall;
						this.shops = res.data.data.shops;
						this.friendlinks = res.data.data.friendlinks;

					} else {
						this.$message.error(res.data.msg)
					}
				});
				this.axios.post(Api.getAdImg, {
					adPositionId: 293
				}).then(res => {
					if (res.data.code == 200) {
						this.advertisingBanner = res.data.data[0];
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			ck_navigation(index, type) {
				for (let i in this.navigationCSS) {
					this.navigationCSS[i] = false;
				}
				this.navigationCSS[index] = true;
				if (type == 'ck') {
					if (index == 0) {
						document.getElementById("HSJX").scrollIntoView();
					} else if (index == 1) {
						document.getElementById("XSSS").scrollIntoView();
					} else if (index == 2) {
						document.getElementById("DRTJ").scrollIntoView();
					} else if (index == 3) {
						document.getElementById("ZX").scrollIntoView();
					} else if (index == 4) {
						document.getElementById("JST").scrollIntoView();
					} else if (index == 5) {
						document.getElementById("WCJS").scrollIntoView();
					} else if (index == 6) {
						document.getElementById("DB").scrollIntoView();
					}
				}
				this.$forceUpdate();
			},
			windowScroll() {
				// 滚动条距离页面顶部的距离
				// 以下写法原生兼容
				this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (this.scrollTop >= 0 && this.scrollTop < 1249) {
					this.ck_navigation(0, 'gl');
				} else if (this.scrollTop >= 1249 && this.scrollTop < 1668) {
					this.ck_navigation(1, 'gl');
				} else if (this.scrollTop >= 1668 && this.scrollTop < 2583) {
					this.ck_navigation(2, 'gl');
				} else if (this.scrollTop >= 2583 && this.scrollTop < 3129) {
					this.ck_navigation(3, 'gl');
				} else if (this.scrollTop >= 3129 && this.scrollTop < 3736) {
					this.ck_navigation(4, 'gl');
				} else if (this.scrollTop >= 3736) {
					this.ck_navigation(5, 'gl');
				}
			}
		}
	}
</script>

<style scoped>
	.HomeFirst-advertising {
		width: 75rem;
		height: 7.5rem;
		background: #F5F5F5;
		font-size: 1.5rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-top: 1.9375rem;
	}

	.navigation {
		align-items: center;
		position: fixed;
		z-index: 99;
		top: 50%;
		margin-top: -12.5rem;
		right: 0;
		background-color: #FFFFFF;
		border: 1px solid #EDEDED;
	}

	.navigation .item1 {
		width: 3.125rem;
		height: 3.125rem;
		background: #EE4454;
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		cursor: pointer;
	}

	.navigation .item2 {
		width: 3.125rem;
		height: 3.125rem;
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		cursor: pointer;
	}

	.line1 {
		width: 2.4rem;
		height: 3.125rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.line2 {
		width: 2.4rem;
		height: 3.125rem;
		border-bottom: 1px solid #f2f2f2;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
</style>
