<template>
	<div class='paymentHeader'>
		<div style='display: flex;align-items: center;'>
			<img 
				class='logo' 
				src="../assets/logo/logo3.png" 
				@click='ck_goPage("/")'
				alt=""
			>
			<div class='checkstand'>
				收营台
			</div>
		</div>
		<div style='display: flex;align-items: center;'>
			<!-- <div class='text1'>
				支付宝账号：18888888888
			</div>
			<div class='line'></div> -->
			<div 
				class='text1' style='color: #666666;cursor: pointer;'
				@click="ck_goPage('/HelpCenter',1)"
			>
				使用帮助
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		methods: {
			ck_goPage(path,id) {
				if(id) {
					this.$router.push({path,query:{id}});
					return 
				}
				this.$router.push({path});
			}
		}
	}
</script>

<style scoped>
	.paymentHeader {
		width: 75rem;
		height: 3.625rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.logo {
		width: 133px;
		height: 50px;
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		cursor: pointer;
	}
	
	.checkstand {
		font-size: 20px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-left: 1.6875rem;
		cursor: pointer;
	}
	
	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.line {
		border: 1px solid #cfcfcf;
		margin: 0 30px 0 2.1875rem;
		height: 1rem;
	}
</style>
