<template>
	<div class='PhoneAvailable'>
		<div class='itemBox'>
			<div class='text1 text1Box'>
				手机号
			</div>
			<div class='inputBox'>
				<el-input :disabled="true" :placeholder="userInfo.userPhone"></el-input>
			</div>
		</div>
		<div class='itemBox'>
			<div class='text1 text1Box'>
				验证码
			</div>
			<div class='inputBox code'>
				<el-input v-model="code1" placeholder="请输入验证码"></el-input>
				<div 
					class='text1 DIS-CENTER' 
					style='width: 100px;cursor: pointer;margin-right: 0.8125rem;'
					@click='getCode1()'
				>
					{{codeText1}}
				</div>
			</div>
		</div>
		<div class='itemBox'>
			<div class='text1 text1Box'>
				新手机号
			</div>
			<div class='inputBox'>
				<el-input v-model="newPhone" placeholder="请输入手机号"></el-input>
			</div>
		</div>
		<div class='itemBox'>
			<div class='text1 text1Box'>
				验证码
			</div>
			<div class='inputBox code'>
				<el-input v-model="code2" placeholder="请输入验证码"></el-input>
				<div 
					class='text1 DIS-CENTER' 
					style='width: 100px;cursor: pointer;margin-right: 0.8125rem;'
					@click='getCode2()'
				>
					{{codeText2}}
				</div>
			</div>
		</div>
		<div class="btn DIS-CENTER" @click="submit">
			保存
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	export default {
		props:['userInfo'],
		data() {
			return {
				codeText1: '获取验证码',
				timer1: null,
				codeText2: '获取验证码',
				timer2: null,
				newPhone:'',
				code1:'',
				code2:'',
			}
		},
		methods: {
			getCode1() {
				if (this.codeText1 == '获取验证码') {
					const data = {
						phone: this.userInfo.userPhone
					}
					this.axios.post(Api.sendPhoneCode, data).then(res => {
						if (res.data.code == 200) {
							this.$message.success('验证码已发送')
						} else {
							this.$message.error('验证码发送失败')
						}
					})
					this.codeText1 = 60;
					this.timer1 = setInterval(() => {
						this.codeText1 = this.codeText1 - 1;
						if (this.codeText1 < 1) {
							window.clearInterval(this.timer1);
							this.codeText1 = '获取验证码'
						}
					}, 1000)
				}
			},
			getCode2() {
				if(this.newPhone==''){
					this.$message.warning('新手机号不能为空')
					return;
				}
				if (this.codeText2 == '获取验证码') {
					const data = {
						phone: this.newPhone
					}
					this.axios.post(Api.sendPhoneCode, data).then(res => {
						if (res.data.code == 200) {
							this.$message.success('验证码已发送')
						} else {
							this.$message.error('验证码发送失败')
						}
					})
					this.codeText2 = 60;
					this.timer2 = setInterval(() => {
						this.codeText2 = this.codeText2 - 1;
						if (this.codeText2 < 1) {
							window.clearInterval(this.timer2);
							this.codeText2 = '获取验证码'
						}
					}, 1000)
				}
			},
			
			submit(){
				if(this.newPhone=='' || this.code1=='' || this.code2==''){
					this.$message.warning('内容不能为空')
					return;
				}
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					type:0,
					oldPhone: this.userInfo.userPhone,
					oldCode:this.code1,
					newPhone:this.newPhone,
					newCode:this.code2,
				}
				
				// 错误返回 code 200
				this.axios.post(Api.editPhone, data).then(res => {
					if (res.data.code == 200) {
						if(res.data.data==''){
							this.$message.error(res.data.msg)
						}else{
							this.$message.success('手机号修改成功，请重新登录');
							this.$router.push({path:"/Login"})
						}
					} else {
						this.$message.error(res.data.msg)
					}
				})
			}
		}
	}
</script>

<style scoped>
	.PhoneAvailable {
		
	}
	
	.itemBox {
		display: flex;
		align-items: center;
		margin-top: 1.875rem;
	}
	
	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.inputBox {
		width: 340px;
		height: 44px;
		border: 1px solid #DDDDDD;
		margin-left: 1.8125rem;
	}
	
	.inputBox>>>.el-input__inner{
		width: 340px;
		height: 44px;
		border: none;
	}
	
	.code {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	
	.code>>>.el-input__inner{
		width: 12.5rem;
		height: 44px;
		border: none;
	}
	
	.text1Box {
		width: 3.75rem;
		display: flex;
		justify-content: flex-end;
	}
	
	.btn {
		width: 120px;
		height: 44px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		margin-left: 5.625rem;
		cursor: pointer;
		margin-top: 1.875rem;
	}
</style>
