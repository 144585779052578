<template>
	<div style='position: relative;'>
		<el-carousel height="280px">
			<el-carousel-item 
				v-for="(item,index) in homeBanner" 
				:key="index"
			>
				<img 
					class='banner-img'
					:src="item.adFile" 
					alt=""
				>
			</el-carousel-item>
		</el-carousel>
		
		<div class='aa'>
			<div
				class='banner-text DIS-CENTER'
				v-for='(item,index) in bannerText'
				:key='index'
			>
				{{item}}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['homeBanner'],
		data() {
			return {
				bannerText: ['新书广场：台湾时报出','新书广场：现在时间的','回流精品，墨笺楼海淘','店铺：三希堂石头记',
					'书单：银铃的生活','精选：那时候的我们和','独家：陈丹青签名《木','后浪：钟明柳的意想和'
				]
			}
		}
	}
</script>

<style scoped>
	.banner-img {
		width: 62.5rem;
		height: 17.5rem;
	}
	
	.aa {
		position: absolute;
		top: 0.875rem;
		right: 0.875rem;
		z-index: 2;
	}
	
	.banner-text {
		width: 160px;
		height: 30px;
		background: #FFFFFF;
		opacity: 0.49;
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		margin-bottom: 0.125rem;
		cursor: pointer;
	}
</style>
