<template>
	<div class='EditMessageFirst'>
		<div class='text1'>
			<!-- zhang***@163.com -->
			您正在为账户 <span style='color: #EE4454;'>{{userInfo.userEmail}}</span> 修改邮箱：
		</div>
		<div class='itemBox' style="margin-top: 2.9375rem;">
			<div class='text2'>手机号</div>
			<div class='phoneBox'>
				<!-- 188******88 -->
				<el-input :disabled='true' :placeholder="userInfo.newUserPhone"></el-input>
			</div>
		</div>
		<div class='itemBox' style="margin-top: 1.875rem;">
			<div class='text2'>验证码</div>
			<div class='codeBox'>
				<el-input v-model="code" placeholder="请输入验证码"></el-input>
				<div class='text2 DIS-CENTER' style='width: 6rem;margin-right: 0.8125rem;cursor: pointer;'
					@click='ck_code'>
					{{codeText}}
				</div>
			</div>
		</div>
		<div class='btn DIS-CENTER' @click='ck_step'>
			下一步
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	export default {
		props: ['userInfo'],
		data() {
			return {
				phone: '',
				code: '',
				codeText: '获取验证码',
				timer: null,
			}
		},
		methods: {
			ck_code() {
				if (this.codeText == '获取验证码') {
					const data = {
						phone: this.userInfo.userPhone
					}
					this.axios.post(Api.sendPhoneCode, data).then(res => {
						if (res.data.code == 200) {
							this.$message.success('验证码已发送')
						} else {
							this.$message.error('验证码发送失败')
						}
					})
					this.codeText = 60;
					this.timer = setInterval(() => {
						this.codeText = this.codeText - 1;
						if (this.codeText < 1) {
							window.clearInterval(this.timer);
							this.codeText = '获取验证码'
						}
					}, 1000)
				}
			},
			ck_step() {
				if (this.userInfo.userPhone == '' || this.code == '') {
					this.$message.warning('内容不能为空')
				} else {
					this.$emit('getCode', this.code);
					this.$emit('editStep', 1);
				}
			}
		}
	}
</script>

<style scoped>
	.EditMessageFirst {
		width: 26rem;
	}

	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.itemBox {
		display: flex;
		align-items: center;
	}

	.text2 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.phoneBox {
		width: 340px;
		height: 44px;
		/* border: 1px solid #DDDDDD; */
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		line-height: 2.75rem;
		margin-left: 1.875rem;
	}

	.codeBox {
		width: 21.25rem;
		height: 2.75rem;
		border: 1px solid #DDDDDD;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: 1.875rem;
	}

	.codeBox>>>.el-input__inner {
		width: 200px;
		border: none;
	}

	.btn {
		width: 120px;
		height: 44px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
		margin-top: 2.0625rem;
		margin-left: 4.5rem;
	}
</style>
