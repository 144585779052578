<template>
	<div class='GoodsDetails'>
		<div class='SY-CONTENT' style='background-color: #f2f2f2;'>
			<GoodsDetailsHeader @dialogBoxIndex="dialogBoxIndex"/>
		</div>

		<div class='SY-CONTENT' style='margin-top: 1.25rem;'>
			<GoodsSearch />
		</div>

		<div class='SY-CONTENT'>
			<div class='zhaopai DIS-CENTER'>
				店铺招牌
			</div>
		</div>

		<div class='SY-CONTENT'>
			<div class='Details-menu'>
				<div class='text' v-for='(item,index) in menuList' :key='index'>
					{{item.catName}}
				</div>
			</div>
		</div>

		<div class='SY-CONTENT'>
			<div style='width: 75rem;margin-top: 0.875rem;display: flex;justify-content: space-between;'>
				<ContentLeftImg :goodsImg='goodsImg'/>
				<ContentMidBox :goodsInfo='goodsInfo' :specs='specs' :isFavorites='isFavorites'/>
				<ContentRightBox :shopInfo='shopInfo' @ckCollect='ckCollect' @getTalkeShopId="getTalkeShopId"/>
			</div>
		</div>

		<div class='SY-CONTENT' style='margin-top: 2.6875rem;'>
			<div style='width: 75rem;margin-top: 0.875rem;display: flex;justify-content: space-between;'>
				<div>
					<BabyClassify :menuList='menuList'/>
					<div style='margin-top: 1.375rem;'>
						<Recommend title='本店推荐' :dataList='recommendGoods' @setId='setId'/>
					</div>
				</div>
				<div>
					<GoodData :goodsInfo='goodsInfo'/>
				</div>
				<div>
					<Recommend title='相似推荐' :dataList='similarRecommendGoods' @setId='setId'/>
				</div>
			</div>
		</div>
		
		<!-- 页尾文字 -->
		<div 
			class='SY-CONTENT'
			style='margin-top: 1.625rem;background-color: #FAFAFA;'
		>
			<HomeText />
		</div>
		
		<!-- 页脚 -->
		<div 
			class='SY-CONTENT'
			style='background-color: #333333;'
		>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import GoodsDetailsHeader from '../components/GoodsDetailsHeader.vue';
	import GoodsSearch from '../components/GoodsSearch.vue';
	import ContentLeftImg from '../components/goodDetails/ContentLeftImg.vue';
	import ContentMidBox from '../components/goodDetails/ContentMidBox.vue';
	import ContentRightBox from '../components/goodDetails/ContentRightBox.vue';
	import BabyClassify from '../components/goodDetails/BabyClassify.vue';
	import Recommend from '../components/goodDetails/Recommend.vue';
	import GoodData from '../components/goodDetails/GoodData.vue';
	import HomeText from '../components/Home/HomeText.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	
	import Api from '../request/api.js';

	export default {
		components: {
			GoodsDetailsHeader,
			GoodsSearch,
			ContentLeftImg,
			ContentMidBox,
			ContentRightBox,
			BabyClassify,
			Recommend,
			GoodData,
			HomeText,
			HomeFooter
		},
		data() {
			return {
				menuList: [],
				goodsImg: [], //商品详情产品图
				goodsInfo: {}, //商品所有的信息
				similarRecommendGoods: [], // 相似商品
				shopInfo: {}, //店铺信息
				specs: [], // 规格
				recommendGoods: [], // 推荐商品,
				isFavorites: 0, // 是否关注
				id: '',
				isShowDialogBox:false,
				getShopId:"",
			}
		},
		mounted() {
			this.id = this.$route.query.id;
			this.initData();
		},
		methods: {
			initData() {
				this.goodsImg=[];
				const data = {
					loginid: localStorage.getItem("shuying-token"),
					goodsId: this.id,
				}
				this.axios.post(Api.goodsDetail,data).then(res=>{
					if(res.data.code == 200) {
						this.goodsImg.push(res.data.data.goods_info.goodsImg);
						this.goodsInfo = res.data.data.goods_info;
						this.similarRecommendGoods = res.data.data.similar_recommend_goods;
						this.shopInfo = res.data.data.shop_info;
						this.specs = res.data.data.goods_info.specs;
						this.recommendGoods = res.data.data.shop_info.recommend_goods;
						this.isFavorites = res.data.data.isFavorites;
						this.menuList = res.data.data.shop_info.category;
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			ckCollect(data) {
				const data1 = {
					loginid: localStorage.getItem('shuying-token'),
					id: this.shopInfo.shopId,
					type: data
				}
				this.axios.post(Api.followShop,data1).then(res=>{
					if(res.data.code == 200) {
						this.initData();
						this.$message.success(res.data.msg)
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			setId(data) {
				this.id = data;
				this.initData();
			},
			dialogBoxIndex(data){
				this.getShopId=false
				this.isShowDialogBox = data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			getTalkeShopId(data){
				if(data){
					this.getShopId=data
					this.isShowDialogBox=true
				}
			},
		}
	}
</script>

<style scoped>
	.GoodsDetails .zhaopai {
		width: 75rem;
		height: 5rem;
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		background: #E5F4F9;
		margin-top: 1.25rem;
	}

	.GoodsDetails .Details-menu {
		width: 73rem;
		padding-left: 1.875rem;
		height: 2.75rem;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		display: flex;
		align-items: center;
		background: #FAFAFA;
		border: 1px solid #E6E6E6;
	}

	.Details-menu .text {
		margin-right: 2.5rem;
		cursor: pointer;
	}
</style>
