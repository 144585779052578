<template>
	<div>
		<LoginHeader passWordType='2'/>
		
		<!-- 注册协议 -->
		<div class='SY-CONTENT' v-if='showAgreement' style='position: relative;'>
			<div class='Agreement-box'>
				<Agreement @ckShowAgreement='ckShowAgreement'/>
			</div>
		</div>
		<!-- 遮罩层 -->
		<div class='shade' v-if='showAgreement' @click='showAgreement = false'></div>
		
		<div class='SY-CONTENT' style='margin-top: 4.0625rem;'>
			<ProgressFirst :way='this.way'/>
		</div>
		
		<div class='SY-CONTENT' style='margin-top: 5.0625rem;' v-if='this.$store.state.step < 4 && this.$store.state.noMailboxActive'>
			<div class='logon'>
				<div class='logonLeft'>{{loginLeftText}}</div>
				<div class='logonRight' @click='ck_way'>{{loginRightText}}</div>
			</div>
		</div>
		
		<div class='SY-CONTENT' style='margin-top: 2rem;'>
			<div v-if='way == 1'>
				<!-- 个人注册内容区 -->
				<div v-if='this.$store.state.step == 1'>
					<RegisteredFirst @getPhone='getPhone'/>
				</div>
				
				<div v-if='this.$store.state.step == 2'>
					<RegisteredSecond @getLoginData='getLoginData'/>
				</div>
				
				<div v-if='this.$store.state.step == 3'>
					<RegisteredThird :interactionData='interactionData' />
				</div>
				
				<div v-if='this.$store.state.step == 4' style='margin-top: 7rem;margin-bottom: 8.4375rem;'>
					<RegisteredFour />
				</div>
			</div>
			
			<div v-else>
				<div v-if='this.$store.state.step == 1' style='margin-bottom: 7.5rem;'>
					<BusinessFirst />
				</div>
				
				<div v-if='this.$store.state.step == 2'>
					<BusinessSecond />
				</div>
				
				<div v-if='this.$store.state.step == 3' style='margin-top: 7rem;margin-bottom: 8.4375rem;'>
					<RegisteredFour />
				</div>
			</div>
		</div>
		
		<!-- 页尾文字 -->
		<div 
			class='SY-CONTENT'
			style='margin-top: 11.43rem;background-color: #FAFAFA'
		>
			<HomeText />
		</div>
		
		<!-- 页脚 -->
		<div 
			class='SY-CONTENT'
			style='background-color: #333333;'
		>
			<HomeFooter />
		</div>
	</div>
</template>

<script>
	import LoginHeader from '../../components/LoginHeader.vue';
	import ProgressFirst from '../../components/login/ProgressFirst.vue';
	import RegisteredFirst from '../../components/login/RegisteredFirst.vue';
	import RegisteredSecond from '../../components/login/RegisteredSecond.vue';
	import RegisteredThird from '../../components/login/RegisteredThird.vue';
	import RegisteredFour from '../../components/login/RegisteredFour.vue';
	import BusinessFirst from '../../components/login/BusinessFirst.vue';
	import BusinessSecond from '../../components/login/BusinessSecond.vue';
	import Agreement from '../../components/login/Agreement.vue';
	import HomeText from '../../components/Home/HomeText.vue';
	import HomeFooter from '../../components/Home/HomeFooter.vue';
	
	
	
	export default {
		components: { 
			LoginHeader, RegisteredFirst, ProgressFirst, HomeText, 
			HomeFooter,RegisteredSecond, Agreement, RegisteredThird,
			RegisteredFour, BusinessFirst, BusinessSecond},
		data()  {
			return {
				loginLeftText: '个人用户注册',
				loginRightText: '企业用户注册',
				way: 1, //1是个人注册 2是企业注册
				interactionData: {
					phone: '',
					username: '',
					password:'',
				},
				showAgreement: true,
			}
		},
		methods: {
			ck_way() {
				if(this.loginRightText == '企业用户注册') {
					this.loginLeftText = '企业用户注册';
					this.loginRightText = '个人用户注册';
					this.way = 2;
				} else {
					this.loginLeftText = '个人用户注册';
					this.loginRightText = '企业用户注册';
					this.way = 1;
				}
				this.$store.commit('nextStep',{num:  0})
			},
			
			
			// 子组件获取参数
			getPhone(data) {
				this.interactionData.phone = data;
			},
			getLoginData(data) {
				this.interactionData.username = data.username;
				this.interactionData.password = data.password;
			},
			ckShowAgreement(data) {
				this.showAgreement = data;
			}
		}
	}
</script>

<style scoped>
	.logon {
		display: flex;
		align-items: baseline;
		justify-content: space-between;
		width: 23.9375rem;
	}
	
	.logonLeft {
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.logonRight {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		cursor: pointer;
		margin-right: 2.6875rem;
	}
	
	.Agreement-box {
		position: absolute;
		margin: 0 auto;
		top: 5rem;
		z-index: 99;
	}
	
	.shade {
		position: absolute;
		width: 100%;
		height: 60rem;
		background: rgba(0, 0, 0, 0.2);
		top: 0;
		z-index: 9;
	}
</style>
