<template>
	<div class='EncryptedFindPassword'>
		<FindPasswordTitle text='密保验证' />

		<div v-if='step == 0'>
			<div class='itemBox' style='margin-top: 2rem;'>
				<div class='text1' style='margin-left: 16px;width: 3.5rem;'>账户名</div>
				<div style='margin-left: 20px;'>
					<el-input v-model="input" placeholder="请输入您的账户名"></el-input>
				</div>
			</div>
			<div class='btn DIS-CENTER' @click='step = 1'>
				下一步
			</div>
			<div class='btnBox'>
				<div @click='ck_Switch(0)'>手机验证</div>
				<div @click='ck_Switch(1)'>邮箱验证</div>
			</div>
		</div>

		<div v-if='step == 1'>
			<div class='itemBox' style='margin-top: 2rem;'>
				<div class='text1' style='margin-left: 16px;width: 3.5rem;'>问题一</div>
				<div style='margin-left: 20px;'>
					<el-select v-model="value" placeholder="请选择">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class='itemBox' style='margin-top: 2rem;'>
				<div class='text1' style='margin-left: 16px;width: 3.5rem;'>您的答案</div>
				<div style='margin-left: 20px;'>
					<el-input v-model="input" placeholder="请输入您的邮箱"></el-input>
				</div>
			</div>
			<div class='itemBox' style='margin-top: 2rem;'>
				<div class='text1' style='margin-left: 16px;width: 3.5rem;'>问题二</div>
				<div style='margin-left: 20px;'>
					<el-select v-model="value" placeholder="请选择">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class='itemBox' style='margin-top: 2rem;'>
				<div class='text1' style='margin-left: 16px;width: 3.5rem;'>您的答案</div>
				<div style='margin-left: 20px;'>
					<el-input v-model="input" placeholder="请输入您的邮箱"></el-input>
				</div>
			</div>
			<div class='btn DIS-CENTER' @click='ck_step'>
				验证
			</div>
		</div>

		<div v-if='step == 2'>
			<div class='itemBox' style='margin-top: 2.6875rem;'>
				<div class='text1' style='margin-left: 16px;width: 3.5rem;'>设置密码</div>
				<div style='margin-left: 20px;'>
					<el-input v-model="input" placeholder="请输入您的邮箱"></el-input>
				</div>
			</div>
			<div class='itemBox' style='margin-top: 2rem;'>
				<div class='text1' style='margin-left: 16px;width: 3.5rem;'>重复密码</div>
				<div style='margin-left: 20px;'>
					<el-input v-model="input" placeholder="请输入您的邮箱"></el-input>
				</div>
			</div>
			<div class='btn DIS-CENTER'>
				提交
			</div>
		</div>

		<transition name="el-zoom-in-top">
			<div class='messageBox DIS-CENTER' v-if='showMessage'>
				<img style='width: 1rem;height: 1rem;' src="../../assets/images/succeed.png" alt="">
				<div class='text1' style='margin-left: 0.625rem;'>
					密保验证成功！
				</div>
			</div>
		</transition>

	</div>
</template>

<script>
	import FindPasswordTitle from './FindPasswordTitle.vue';

	export default {
		components: {
			FindPasswordTitle
		},
		data() {
			return {
				step: 0,
				input: '',
				options: [{
					value: '选项1',
					label: '黄金糕'
				}, {
					value: '选项2',
					label: '双皮奶'
				}, {
					value: '选项3',
					label: '蚵仔煎'
				}, {
					value: '选项4',
					label: '龙须面'
				}, {
					value: '选项5',
					label: '北京烤鸭'
				}],
				value: '',
				showMessage: false,
			}
		},
		methods: {
			ck_Switch(value) {
				this.$emit('ckSwitch', value)
			},
			ck_step() {
				this.step = 2;
				this.showMessage = true;
				setTimeout(() => {
					this.showMessage = false;
				}, 3000)
			}
		}
	}
</script>

<style scoped>
	.itemBox {
		width: 21.25rem;
		height: 2.75rem;
		border: 1px solid #DDDDDD;
		display: flex;
		align-items: center;
	}

	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.itemBox>>>.el-input__inner {
		border: none;
		height: 2.75rem;
		width: 15.5rem;
	}

	.btn {
		width: 340px;
		height: 50px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
		margin-top: 1.9375rem;
	}

	.btnBox {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
		margin-top: 1.25rem;
	}

	.btnBox div {
		cursor: pointer;
	}

	.messageBox {
		width: 168px;
		height: 44px;
		background: #FFFFFF;
		border-radius: 4px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		position: fixed;
		top: 4.3125rem;
		left: 50%;
		margin-left: -5.25rem;
	}
</style>
