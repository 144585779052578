<template>
	<div class='SetPayPAssword'>
		<div>
			<Header bgIndex='4' @dialogBoxIndex="dialogBoxIndex" />
		</div>
		
		<div class='SY-CONTENT' style='background-color: #F5F5F5;'>
			<div class='content'>
				<!-- 面包屑 -->
				<div class='BreadCrumbs'>
					<div 
						class='breadText1' 
						style='cursor: pointer;'
						@click='ck_goPage("/PersonalCenter")'
					>
						主页
					</div>
					<div class='breadText1' style='margin: 0 1.5625rem;'>/</div>
					<div 
						class='breadText1' 
						style='cursor: pointer;'
						@click='ck_goPage("/PersonalCenter",9)'
					>
						账户设置
					</div>
					<div class='breadText1' style='margin: 0 1.5625rem;'>/</div>
					<div class='breadText2'>支付密码</div>
				</div>
			
				<div v-if='isPaw'>
					<div class='title1'>
						您正在为账户 <span>{{userInfo.newUserPhone}}</span> 修改支付密码：
					</div>
					<div style='margin-left: 50px;'>
						<div class='input-box DIS-CENTER' style='margin-top: 1.875rem;'>
							<div class='inputText'>手机号</div>
							<div class='inputItem'>
								<el-input :placeholder="userInfo.userPhone" :disabled="true"></el-input>
							</div>
						</div>
						
						<div class='input-box DIS-CENTER' style='margin-top: 1.875rem;'>
							<div class='inputText'>验证码</div>
							<div class='inputItem getCode'>
								<el-input placeholder="请输入验证码" v-model="code"></el-input>
								<div 
									class='inputText DIS-CENTER' 
									style='width: 120px;cursor: pointer;margin-right: 0.8125rem;'
									@click='ck_getCode'
								>
									{{codeText}}
								</div>
							</div>
							
						</div>
						
						<div class='input-box DIS-CENTER' style='margin-top: 1.875rem;'>
							<div class='inputText'>新支付密码</div>
							<div class='inputItem'>
								<el-input placeholder="请输入新支付密码" v-model="newPass" show-password></el-input>
							</div>
						</div>
						<div class='input-box DIS-CENTER' style='margin-top: 1.875rem;'>
							<div class='inputText'>确认密码</div>
							<div class='inputItem'>
								<el-input placeholder="请确认新支付密码" v-model="newPass2" show-password></el-input>
							</div>
						</div>
					</div>
					<div class='btn DIS-CENTER' style='margin-left: 510px' @click='ck_save(1)'>
						保存
					</div>
				</div>
				
				<div v-else>
					<!-- 内容区 -->
					<div class='title1'>
						您还没有设置密码!
					</div>
					<div class='title2'>
						请先设置一个6位数字的支付密码~
					</div>
					<div class='inputBox DIS-CENTER' style='margin-top: 3.125rem;'>
						<div>支付密码</div>
						<div class='inputItem'>
							<el-input placeholder="请输入支付密码" v-model="newPass" show-password></el-input>
						</div>
					</div>
					<div class='inputBox DIS-CENTER' style='margin-top: 1.875rem;'>
						<div>确认密码</div>
						<div class='inputItem'>
							<el-input placeholder="请确认支付密码"  v-model="newPass2" show-password></el-input>
						</div>
					</div>
					<div class='btn DIS-CENTER' @click='ck_save(0)'>
						保存
					</div>
				</div>
			</div>
		</div>
		
		<!-- 页脚 -->
		<div class='SY-CONTENT' style='background-color: #333333;'>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Api from '../request/api.js';
	
	import Header from '../components/Header.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	
	export default {
		components: {
			Header,
			HomeFooter
		},
		data() {
			return {
				codeText: '获取验证码',
				timer: null,
				isPaw: false, // 判断是否设置了支付密码
				code: '',
				newPass: '',
				newPass2: '',
				userInfo:{},
				isShowDialogBox:false,
			}
		},
		created() {
			this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
			this.userInfo.newUserPhone=this.userInfo.userPhone.substr(0,3) + '****' + this.userInfo.userPhone.substr(7);
			this.ckIsPaw();   // 判断是否设置了支付密码
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			ck_goPage(path,type) {
				this.$router.push({path,query:{type}});
			},
			ck_getCode() {
				if(this.codeText == '获取验证码') {
					const data = {
						loginid: localStorage.getItem('shuying-token'),
						phone: this.userInfo.userPhone
					}
					this.axios.post(Api.sendPhoneCode,data).then(res=>{
						if(res.data.code == 200) {
							this.$message.success('验证码已发送，请注意查收')
						} else {
							this.$message.error(res.data.msg)
						}
					})
					this.codeText = 60;
					this.timer = setInterval(()=>{
						if(this.codeText > 1) {
							this.codeText = this.codeText-1;
						} else {
							this.codeText = '获取验证码';
							clearInterval(this.timer);
						}
					},1000)
				}
			},
			ckIsPaw() {
				const userInfo = JSON.parse(localStorage.getItem('userInfo'));
				if(userInfo.payPwd) {
					this.isPaw = true;
					return 
				}
				this.isPaw = false;
			},
			ck_save(type) {
				let data = {};
				if(type == 1) { 
					if(!(/[0-9]{4}$/.test(this.code))){
						this.$message.warning('验证码有误')
						return 
					} 
					if(this.newPass.length < 6){
						this.$message.warning('密码不能少于6位')
						return 
					} 
					if(this.newPass != this.newPass2) {
						this.$message.warning('两次设置的密码不一致')
						return 
					}
					data = {
						loginid: localStorage.getItem('shuying-token'),
						phone: this.userInfo.userPhone,
						code: this.code,
						newPass: this.newPass,
						type
					}
				} else if(type == 0) {
					if(this.newPass.length < 6) {
						this.$message.warning('密码不能少于6位')
						return 
					}
					if(this.newPass != this.newPass2) {
						this.$message.warning('两次设置的密码不一致')
						return 
					}
					data = {
						loginid: localStorage.getItem('shuying-token'),
						newPass: this.newPass,
					}
				}
				this.axios.post(Api.setPayPwd,data).then(res=>{
					if(res.data.code == 200) {
						this.code = '',
						this.newPass = '',
						this.newPass2 = '',
						this.$message.success(res.data.msg);
					} else {
						this.$message.error(res.data.msg);
					}
				})
			}
		}
	}
</script>

<style scoped>
	.content {
		background-color: #FFFFFF;
		width: 75rem;
		min-height: 48.125rem;
		padding-top: 1.875rem;
	}
	
	.BreadCrumbs {
		display: flex;
		align-items: center;
		margin-left: 2.5rem;
	}
	
	.breadText1 {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.breadText2 {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.title1 {
		width: 75rem;
		display: flex;
		justify-content: center;
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-top: 4.5rem;
	}
	
	.title1 span {
		color: #EE4454;
	}
	
	.title2 {
		width: 75rem;
		display: flex;
		justify-content: center;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-top: 0.25rem;
	}
	
	.inputBox {
		width: 75rem;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.inputItem {
		margin-left: 2rem;
		width: 21.25rem;
		border: 1px solid #DDDDDD;      
	}
	
	.inputItem>>>.el-input__inner{
		width: 340px;
		height: 44px;
		border: none;
		border-radius: none;
	} 
	
	.btn {
		width: 120px;
		height: 44px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		margin-top: 2.25rem;
		margin-left: 29.8125rem;
		cursor: pointer;
	}
	
	.inputText {
		width: 5rem;
		display: flex;
	}
	
	.getCode {
		border: 1px solid #DDDDDD;   
		display: flex;
		align-items: center;
	}
	
	.getCode>>>.el-input__inner{
		width: 200px;
		height: 44px;
		border-radius: none;
		border: none;
	} 
</style>
