<template>
	<div class='BookRank'>
		<div 
			class='item'
			v-for='(item,index) in hotBookList'
			:key='index'
		>
			<div style='display: flex;align-items: center;'>
				<div>
					<img v-if='index == 0' class='img1' src="../../assets/images/Rank-01.png" alt="">
					<img v-if='index == 1' class='img1' src="../../assets/images/Rank-02.png" alt="">
					<img v-if='index == 2' class='img1' src="../../assets/images/Rank-03.png" alt="">
					<div v-if='index > 2' class='indexNum DIS-CENTER'>
						{{index+1}}
					</div>
				</div>
				<img class='img2' :src="item.cover_image" alt="">
				<div style='margin-left: 2.875rem;'>
					<!-- <div class='text1'>儿童教育心理学</div> -->
					<div class='text2' style='margin-top: 0.5rem;'>{{item.title}}</div>
				<!-- 	<div class='text1' style='margin-top: 0.5rem;'>
						<span style='font-size: 0.75rem;'>￥</span>39 
						<span class='text2'>起</span>
					</div> -->
				</div>
			</div>
			<div style='margin-right: 2.0625rem;display: flex;align-items: center;'>
				<img class='img3' src="../../assets/images/like.png" alt="">
				<div class='text2' style='color: #333333;margin-left: 1rem;'>
					{{item.zan_num}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	export default{
		data(){
			return{
				pageSize:1,
				checkScorll:true,
				hotBookList:[],
				checkList:[],
			}
		},
		mounted() {
			this.hotBookListInit()
			if (this.checkScorll) {
				this.$nextTick(function() {
					window.addEventListener('scroll', this.onScroll);
				})
			} else {
				// console.log('checkScorll 为false的情况')
			}
		},
		beforeDestroy() {
			this.checkScorll = false
		},
		methods:{
			hotBookListInit(){
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					page: this.pageSize,
				}
				this.axios.post(Api.hotGoodsRank, data).then(res => {
					if (res.data.code == 200) {
						for(let i in res.data.data){
							this.hotBookList.push(res.data.data[i])
						}
						this.checkList=res.data.data
					} else {
						this.$message.error(res.data.msg)
					}
				});
			},
			onScroll() {
				if (this.getScrollHeight() - this.getClientHeight() - this.getScrollTop() <= 0) {
					if (this.checkScorll) {
						if (this.checkList.length == 10) {
							this.pageSize++
							this.hotBookListInit()
						}
					}
				}
			},
			
			// 获取滚动条当前的位置
			getScrollTop() {
				let scrollTop = 0
				if (document.documentElement && document.documentElement.scrollTop) {
					scrollTop = document.documentElement.scrollTop
				} else if (document.body) {
					scrollTop = document.body.scrollTop
				}
				return scrollTop
			},
			
			// 获取当前可视范围的高度
			getClientHeight() {
				let clientHeight = 0
				if (document.body.clientHeight && document.documentElement.clientHeight) {
					clientHeight = Math.min(document.body.clientHeight, document.documentElement.clientHeight)
				} else {
					clientHeight = Math.max(document.body.clientHeight, document.documentElement.clientHeight)
				}
				return clientHeight
			},
			
			// 获取文档完整的高度
			getScrollHeight() {
				return Math.max(document.body.scrollHeight, document.documentElement.scrollHeight)
			}
		}
	}
</script>

<style scoped>
	.BookRank {
		width: 75rem;
	}
	
	.item {
		display: flex;
		align-items: center;
		width: 75rem;
		height: 8.4375rem;
		border-bottom: 1px solid #E6E6E6;
		justify-content: space-between;
	}
	
	.img1 {
		width: 2.75rem;
		height: 2.75rem;
		margin-left: 1.0625rem;
	}
	
	.img2 {
		width: 4.25rem;
		height: 5.9375rem;
		margin-left: 3.125rem;
	}
	
	.text1 {
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.text2 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
	
	.img3 {
		width: 1.5rem;
		height: 1.5rem;
		cursor: pointer;
	}
	
	.indexNum {
		width: 2.75rem;
		height: 2.75rem;
		background: #E6E6E6;
		border-radius: 50%;
		margin-left: 1.0625rem;
	}
</style>
