<template>
	<div class='payment'>
		<div class='SY-CONTENT' style='background-color: #FFFFFF;'>
			<PaymentHeader />
		</div>

		<div class='SY-CONTENT' style='margin-top: 3rem;' v-if='showMain < 3'>
			<div class='orderNum'>
				<div>
					<div style='display: flex;align-items: center;'>
						<img style='width: 1.5rem;height: 1.5rem;' src="../assets/images/succeed.png" alt="">
						<div class='text1' style='margin-left: 0.75rem;'>
							订单提交成功！
						</div>
						<div class='text2'>
							请尽快付款！订单号：105702506382
						</div>
					</div>
					<div style='display: flex;align-items: center;margin-top: 0.875rem;'>
						<div class='text2'>
							推荐使用支付宝、微信扫码支付
						</div>
						<div class='text2'>
							请您在 <span style='color: #EE4454;font-weight: 600;'>24小时</span> 内完成支付，否则系统将自动取消订单
						</div>
					</div>
				</div>
				<div style='display: flex;flex-direction: column;align-items: flex-end;'>
					<div class='text1'>
						应付款： <span style='color: #EE4454;font-weight: 600;'>{{totalMoney}}</span> 元
					</div>
					<div class='orderNumBtn DIS-CENTER' style='margin-top: 0.75rem;'>
						订单详情
					</div>
				</div>
			</div>
		</div>

		<div class='SY-CONTENT' style='margin-top: 3.375rem;'>
			<div class='paymentBox' v-if='showMain == 1'>
				<div class='payContent' @click='ck_payRadio(1)'>
					<img v-if='payRadio == 1' style='width: 1rem;height: 1rem;margin-left: 2.5rem;'
						src="../assets/images/radio_btn_selected@2x.png" alt="">
					<img v-else style='width: 1rem;height: 1rem;margin-left: 2.5rem;'
						src="../assets/images/radio_btn@2x.png" alt="">
					<img style='width: 1.5625rem;height: 1.5625rem;margin-left: 1.6875rem;'
						src="../assets/images/pay-zfb.png" alt="">
					<div class='text3' style='margin-left: 1rem;'>支付宝支付</div>
					<img style='width: 2.125rem;height: 1.125rem;margin-left: 1.25rem;'
						src="../assets/images/pay-tj.png" alt="">
				</div>
				<div class='payContent' @click='ck_payRadio(2)'>
					<img v-if='payRadio == 2' style='width: 1rem;height: 1rem;margin-left: 2.5rem;'
						src="../assets/images/radio_btn_selected@2x.png" alt="">
					<img v-else style='width: 1rem;height: 1rem;margin-left: 2.5rem;'
						src="../assets/images/radio_btn@2x.png" alt="">
					<img style='width: 1.5625rem;height: 1.5625rem;margin-left: 1.6875rem;'
						src="../assets/images/pay-wx.png" alt="">
					<div class='text3' style='margin-left: 1rem;'>微信支付</div>
				</div>
				<div class='payContent' @click='ck_payRadio(3)'>
					<img v-if='payRadio == 3' style='width: 1rem;height: 1rem;margin-left: 2.5rem;'
						src="../assets/images/radio_btn_selected@2x.png" alt="">
					<img v-else style='width: 1rem;height: 1rem;margin-left: 2.5rem;'
						src="../assets/images/radio_btn@2x.png" alt="">
					<img style='width: 1.5625rem;height: 1.5625rem;margin-left: 1.6875rem;'
						src="../assets/images/Payment-yue.png" alt="">
					<div class='text3' style='margin-left: 1rem;'>余额支付</div>
				</div>
				<div style='width: 69.375rem;'>
					<img style='width: 20.9375rem;height: 5rem;cursor: pointer;' src="../assets/images/pay-add.png"
						alt="">
				</div>
				<div style='width: 69.375rem;'>
					<div class='btn DIS-CENTER' @click='ck_showMain(2)'>
						立即支付
					</div>
				</div>
			</div>

			<div class='paymentBox' v-if='showMain == 2'>
				<div style='display: flex;align-items: flex-end;width: 69.375rem;'>
					<div class='text1'>
						微信支付
					</div>
					<div class='text2' style='margin-left: 1.875rem;'>
						距离二维码过期还剩 <span style='color: #EE4454;'>58</span> 秒，过期后请刷新页面重新获取二维码
					</div>
				</div>
				<div class='CodeContent'>
					<div class="qrcode" ref="qrCodeUrl"></div>
					<div>
						<div class='text2' style='font-weight: 600;'>
							1.打开 <span style='color: #EE4454'>微信APP</span>
						</div>
						<div class='text2' style='font-weight: 600;'>
							2.点击<span style='color: #EE4454'>“扫一扫”</span>扫码支付
						</div>
					</div>
					<img src="../assets/images/zhifubao-img.jpeg" alt="">
				</div>
				<div style='display: flex;align-items: center;width: 69.375rem;margin-top: 3.625rem;cursor: pointer;'>
					<img style='width: 0.875rem;height: 0.875rem;' src="../assets/images/arrow-left.png" alt="">
					<div class='text2' style='margin-left: 0.625rem;' @click='ck_showMain(1)'>
						选择其他支付方式
					</div>
				</div>
			</div>
		</div>

		<div class='SY-CONTENT'>
			<div v-if='showMain == 3' style='margin-top: 7.5rem;width: 31.25rem;'>
				<div style='display: flex;align-items: center'>
					<img style='width: 2.25rem;height: 2.25rem;' src="../assets/images/succeed.png" alt="">
					<div class='text1' style='margin-left: 0.9375rem;font-size: 1.75rem;'>
						恭喜您！支付成功！
					</div>
				</div>
				<div style='display: flex;align-items: center;margin-top: 1.3125rem;'>
					<div class='text2'>您已成功支付，订单号：105702506382</div>
					<div class='text2' style="margin-left: 1.875rem;">下单时间：2020-12-01 21:30:58</div>
				</div>
				<div style='display: flex;align-items: center;margin-top: 3.125rem;'>
					<div class='btn1 DIS-CENTER'>
						继续购物
					</div>
					<div class='btn2 DIS-CENTER'>
						查看订单
					</div>
				</div>
			</div>
		</div>

		<div v-if='showPassword' class='SY-CONTENT'
			style='position: fixed;top: 0;left: 0;background-color: rgba(0,0,0,0.2);width: 100%;height: 100%;'>
			<div style='margin-top: 12.5rem;'>
				<div v-if='PayPaw'>
					<PayPassword @ckpayyue='ckPayyue' @ckShowPassword='ckShowPassword' :pkey='this.$route.query.pkey' />
				</div>
				<div v-else>
					<NewPayPassword @ckShowPassword='ckShowPassword' />
				</div>
			</div>
		</div>

		<!-- 页脚 -->
		<div class='SY-CONTENT' style='background-color: #333333;position: absolute;bottom: 0;'>
			<HomeFooter />
		</div>
	</div>
</template>

<script>
	import Api from '../request/api.js';
	import QRCode from 'qrcodejs2'

	import PaymentHeader from '../components/PaymentHeader.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	import PayPassword from '../components/Payment/PayPassword.vue';
	import NewPayPassword from '../components/Payment/NewPayPassword.vue';

	export default {
		components: {
			PaymentHeader,
			HomeFooter,
			PayPassword,
			NewPayPassword
		},
		data() {
			return {
				payRadio: 1,
				showMain: 1,
				showPassword: false,
				timer: null, // 时间代码
				totalMoney: '', // 应付款
				PayPaw: false, // 判断用户是否有设置支付密码
			}
		},
		created() {
			this.initData();
			this.isPayPaw();
		},
		methods: {
			// // 余额支付成功后  
			ckPayyue(data) {
				console.log(data,'获取到子组件的结果')
				this.showMain = data;
			},
			initData() {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					pkey: this.$route.query.pkey
				}
				this.axios.post(Api.succeed, data).then(res => {
					if (res.data.code == 200) {
						this.totalMoney = res.data.data.object.totalMoney;
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			ck_payRadio(value) {
				this.payRadio = value;
			},
			ck_showMain(index) {
				if (index == 2) {
					if (this.payRadio == 3) { // 余额支付
						this.showPassword = true;
					} else if (this.payRadio == 1) { // 支付宝支付
						this.zhifubaoPay();
					} else if (this.payRadio == 2) { // 微信支付
						this.weixinPay();
						this.showMain = index;
					}
				} else if (index == 1) {
					this.showMain = index;
				}
			},
			// 设置支付密码
			ckShowPassword(data) {
				this.showPassword = data;
			},
			zhifubaoPay() {
				const newTab = window.open();
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					pkey: this.$route.query.pkey,
					payObj: '',
					// needPay: this.totalMoney
				}
				this.axios.post(Api.getAlipaysUrl, data).then(res => {
					if (res.data.code == 200) {
						const div = document.createElement('div');
						div.innerHTML = res.data.data.result; // html code
						newTab.document.body.appendChild(div);
						newTab.document.forms.alipaysubmit.submit();
						this.$message.success(res.data.msg)
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			weixinPay() {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					pkey: this.$route.query.pkey,
				}
				this.axios.post(Api.getWeixinPaysURL, data).then(res => {
					if (res.data.code == 200) {
						const data1 = {
							loginid: localStorage.getItem('shuying-token'),
							pkey: res.data.data.pkey,
						}
						this.axios.post(Api.createQrcode, data1).then(res => {
							if (res.data.code == 200) {
								// this.weixinCode = res.data.data.code_url
								this.creatQrCode(res.data.data.code_url)
							} else {
								this.$message.error(res.data.msg)
							}
							// this.creatQrCode(res.data.data.code_url)
						})
					}
				})
			},
			ck_goPage(path) {
				this.$router.push({
					path
				})
			},
			// 判断用户是否有支付密码
			isPayPaw() {
				let payPaw = JSON.parse(localStorage.getItem('userInfo')).payPwd;
				if (payPaw) {
					this.PayPaw = true;
				}
			},
			creatQrCode(text) {
				new QRCode(this.$refs.qrCodeUrl, {
					text,
					width: 150,
					height: 150,
					colorDark: '#000000',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.H
				})
			},
		}
	}
</script>

<style scoped>
	.payment {
		background-color: #FAFAFA;
		height: 98vh;
	}

	.orderNum {
		width: 75rem;
		display: flex;
		justify-content: space-between;
	}

	.text1 {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.text2 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
	}

	.orderNumBtn {
		width: 90px;
		height: 30px;
		background: #FFFFFF;
		border: 1px solid #E6E6E6;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		cursor: pointer;
	}

	.paymentBox {
		background-color: #FFFFFF;
		width: 75rem;
		padding: 2.8125rem 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.text3 {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.payContent {
		display: flex;
		align-items: center;
		width: 69.375rem;
		height: 5rem;
		border: 1px solid #e6e6e6;
		cursor: pointer;
		margin-bottom: 1.25rem;
	}

	.btn {
		width: 170px;
		height: 50px;
		background: #EE4454;
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FFFFFF;
		margin-top: 2.8125rem;
		cursor: pointer;
	}

	.CodeContent {
		display: flex;
		align-items: center;
		margin-top: 3.625rem;
		width: 69.375rem;
		justify-content: space-around;
	}

	.btn1 {
		width: 174px;
		height: 50px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
	}

	.btn2 {
		width: 174px;
		height: 50px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #E6E6E6;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-left: 1.875rem;
		cursor: pointer;
	}

	.qrcode {
		border: 1px solid #CCCCCC;
		padding: 20px;
	}
</style>
