<template>
	<div class='GoodsSearch'>
		<!-- <div class='left DIS-CENTER'>书营logo</div> -->
		<img class='left DIS-CENTER' src="../assets/logo/logo2.png" alt="">
		<div class='right DIS-CENTER'>
			<div class='DIS-CENTER'>
				<div class='input-box'>
					<el-input v-model="searchValue" placeholder="商品名称、作者、出版社"></el-input>
				</div>
				<div 
					class='searchStore DIS-CENTER'
					style='color: #FFFFFF;background: #EE4454;margin-right: 0.625rem;'
				>
					搜书店
				</div>
			</div>
			<div class='searchStore DIS-CENTER'>搜本店</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				searchValue: ''
			}
		}
	}
</script>

<style scoped>
	.GoodsSearch {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 75rem;
	}
	
	.GoodsSearch .left {
		width: 8.125rem;
		height: 3.625rem;
		font-size: 1rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.searchStore {
		width: 4.6875rem;
		height: 2.25rem;
		background: #333333;
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FFFFFF;
		cursor: pointer;
	}
	
	.input-box {
		width: 19.625rem;
		/* height: 2rem; */
		border: 2px solid #EE4454;
	}
	
	.GoodsSearch>>>.el-input__inner{
		color: #333333;
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;   
		border: none;
		height: 2rem;
	}
</style>
