<template>
	<div class='Message'>
		<!-- 我的消息 -->
		<div>
			<Header bgIndex='4' @dialogBoxIndex="dialogBoxIndex" />
		</div>
		
		<div class='SY-CONTENT'>
			<div class='content'>
				<div class='Bread'>
					<span style='cursor: pointer;' @click='ck_goPage("/PersonalCenter")'>首页</span>
					<span style='margin: 0 1.5625rem;'>/</span>
					<span class='title'>我的消息</span>
				</div>
				
				<div style='display: flex;align-items: center;justify-content: space-between;margin-top: 1.6875rem;'>
					<div class='menuBox'>
						<div 
							:class="item.active?'menuBoxItem1 DIS-CENTER':'menuBoxItem DIS-CENTER'"
							v-for='(item,index) in menuList'
							:style='index==0?"border:none":""'
							:key='index'
							@click='ck_menu(index)'
						>
							{{item.text}}
						</div>
					</div>
					<div class='messageBtn DIS-CENTER'>
						<img 
							style='width: 0.9375rem;height: 0.9375rem;margin-right: 0.5625rem;' 
							src="../assets/PersonalCenter/message-img.png" 
							alt=""
						>
						发消息
					</div>
				</div>
				
				<div style='display: flex;align-items: center;justify-content: space-between;margin-top: 2.25rem;'>
					<div class='text1' style='margin-left: 3.125rem;'>
						<span style='color: #EE4454;'>提示</span>
						：网站只保留最近三个月内的消息，请及时查阅！
					</div>
					<div style='display: flex;align-items: center;margin-right: 3.75rem;'>
						<el-input v-model="input" placeholder="请输入内容"></el-input>
						<div class='seatchBtn DIS-CENTER'>
							搜索
						</div>
					</div>
				</div>
				
				<!-- 表头 -->
				<div class='tableHeader'>
					<div class='tableTitle' style='margin-left: 4.3125rem;'>发送者</div>
					<div class='tableTitle' style='margin-left: 6.1875rem;'>接收者</div>
					<div class='tableTitle' style='margin-left: 9.5rem;'>消息内容</div>
					<div class='tableTitle' style='margin-left: 17.4375rem;'>发送时间</div>
					<div class='tableTitle' style='margin-left: 9rem;'>操作</div>
				</div>
				
				<!-- 表格内容 -->
				<div 
					class='tableItem'
					v-for='(item,index) in tableData'
					:key='index'
				>
					<div class='text1' style='width: 3.75rem;margin-left: 4.375rem;'>{{item.sendName}}</div>
					<div class='text1' style='width: 9.375rem;margin-left: 4.5625rem;'>{{item.receiveName}}</div>
					<div class='text1' style='width: 17.25rem;margin-left: 2.25rem;'>
						{{item.messageContent}}
					</div>
					<div class='text1' style='margin-left: 3.375rem;'>{{item.time}}</div>
					<div class='text1' style='margin-left: 4.8125rem;display: flex;align-items: center'>
						<div style='cursor: pointer;'>回复</div>
						<div style='margin-left: 1rem;cursor: pointer;'>删除</div>
					</div>
				</div>
				
				<div class='sorter'>
					<el-pagination
						background
						layout="pager"
						:total="1000">
					</el-pagination>
				</div>
			</div>
		</div>
		
		<!-- 页脚 -->
		<div class='SY-CONTENT' style='background-color: #333333;'>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Header from '../components/Header.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	
	export default {
		components: {
			Header,
			HomeFooter
		},
		data() {
			return {
				menuList: [
					{
						text: '收件箱',
						active: true,
					},
					{
						text: '发件箱',
						active: false,
					},{
						text: '所有消息',
						active: false,
					}
				],
				input: '',
				tableData: [
					{
						sendName: '阿村书画阁',
						receiveName: '笑歪了嘴',
						messageContent: 'a地方那你说DNF发送的就能发是点击防溺水',
						time: '2020-11-11 20:10:59'
					},
					{
						sendName: '彩虹书屋',
						receiveName: '笑歪了嘴',
						messageContent: 'a地方那你说DNF发送的就能发是点击防溺水',
						time: '2020-11-11 20:10:59'
					},
					{
						sendName: '系统消息',
						receiveName: '笑歪了嘴',
						messageContent: 'a地方那你说DNF发送的就能发是点击防溺水',
						time: '2020-11-11 20:10:59'
					},
				],
				isShowDialogBox:false,
			}
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			ck_menu(index) {
				for(let i in this.menuList) {
					this.menuList[i].active = false;
				}
				this.menuList[index].active =true;
			}
		}
	}
</script>

<style scoped>
	.content {
		width: 75rem;
		height: 73.4375rem;
		background-color: #FFFFFF;
		padding-top: 2.9375rem;
	}
	
	.Bread {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-left: 3.125rem;
	}
	
	.title {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.menuBox {
		width: 343px;
		height: 40px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #E8E8E8;
		display: flex;
		align-items: center;
		margin-left: 3.125rem;
	}
	
	.menuBoxItem {
		flex: 1;
		border-left: 1px solid #E8E8E8;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		height: 2.5rem;
		cursor: pointer;
	}
	
	.menuBoxItem1 {
		flex: 1;
		border-left: 1px solid #E8E8E8;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
		height: 2.5rem;
		cursor: pointer;
	}
	
	.messageBtn {
		width: 90px;
		height: 28px;
		border-radius: 2px;
		border: 1px solid #E6E6E6;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
		margin-right: 3.75rem;
	}
	
	.text1 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.seatchBtn {
		width: 60px;
		height: 28px;
		background: #EFEFEF;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
	}
	
	.Message>>>.el-input__inner{
		width: 194px;
		height: 28px;
		border: 1px solid #EEEEEE;
		border-right: none;
		border-radius: 0;
		margin-right: -0.6875rem;
	} 
	
	.tableHeader {
		width: 1089px;
		height: 44px;
		background: #FAFAFA;
		border: 1px solid #E6E6E6;
		margin-left: 3.125rem;
		margin-top: 1.125rem;
		display: flex;
		align-items: center;
	}
	
	.tableTitle {
		font-size: 12px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.tableItem {
		height: 5.3125rem;
		width: 67.75rem;
		margin-left: 3.125rem;
		border-bottom: 1px solid #E6E6E6;
		display: flex;
		align-items: center;
	}
	
	.sorter {
		display: flex;
		justify-content: flex-end;
		margin-top: 1.875rem;
		margin-right: 3.75rem;
	}
	
	.sorter>>>.el-pager li {
		background-color: transparent !important;
		color: #333333 !important;
		border: 1px solid #E6E6E6;
		width: 36px;
		height: 36px;
		line-height: 2.25rem;
	}
	
	.sorter>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color:  #333333 !important;
		color: white!important;
	}
</style>
