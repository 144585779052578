<template>
	<div class='ShoppingCart'>
		<div class='SY-CONTENT' style='background-color: #F2F2F2;'>
			<GoodsDetailsHeader @dialogBoxIndex="dialogBoxIndex" />
		</div>

		<div class='SY-CONTENT' style='margin-top: 1.375rem;'>
			<GoodsSearch />
		</div>
		
		<div class='SY-CONTENT' style='margin-top: 1.1875rem;'>
			<ShoppingCartTable />
		</div>
		
		<!-- 页尾文字 -->
		<div 
			class='SY-CONTENT'
			style='margin-top: 18.5rem;background-color: #FAFAFA;'
		>
			<HomeText />
		</div>
		
		<!-- 页脚 -->
		<div 
			class='SY-CONTENT'
			style='background-color: #333333;'
		>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import GoodsDetailsHeader from '../components/GoodsDetailsHeader.vue';
	import GoodsSearch from '../components/GoodsSearch.vue';
	import ShoppingCartTable from '../components/shoppingCart/shoppingCartTable.vue';
	import HomeText from '../components/Home/HomeText.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';

	export default {
		components: {
			GoodsDetailsHeader,
			GoodsSearch,
			ShoppingCartTable,
			HomeText,
			HomeFooter,
		},
		data() {
			return {
				isShowDialogBox:false,
			}
		},
		methods: {
			ck_menu(index) {
				for (let i in this.menuList) {
					this.menuList[i].active = false;
				}
				this.menuList[index].active = true;
			},
			dialogBoxIndex(data){
				this.isShowDialogBox = data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
		}
	}
</script>

<style scoped>

</style>
