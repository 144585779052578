<template>
	<div class='StepFirst'>
		<div class='item'>
			<div class='text1'>
				<span style='color: #EE4454;'>*</span>
				公司名称
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-input v-model="enterpriseData.shopCompany" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				<span style='color: #EE4454;'>*</span>
				公司所在地
			</div>
			<div style='margin-left: 2.1875rem;display: flex;flex-direction: column;'>
				<!-- ------------------ 获取地址 ---------------- -->
				<el-select v-model="provinceValue1" placeholder="请选择省份" @change='getCity1'>
					<el-option 
						v-for="item in provinceOptions1" 
						:key="item.areaId" 
						:label="item.areaName" 
						:value="item.areaId"
					>
					</el-option>
				</el-select>
				<el-select v-model="cityValue1" placeholder="请选择市" style='margin: 10px 0;' @change='getArea1'>
					<el-option 
						v-for="item in cityOptions1" 
						:key="item.areaId" 
						:label="item.areaName" 
						:value="item.areaId"
					>
					</el-option>
				</el-select>
				<el-select v-model="areaValue1" placeholder="请选择区" @change='getAddress1'>
					<el-option 
						v-for="item in areaOptions1" 
						:key="item.areaId" 
						:label="item.areaName" 
						:value="item.areaId"
					>
					</el-option>
				</el-select>
				<!-- ------------------------------------------------- -->
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				公司详细地址
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-input v-model="enterpriseData.shopAddress" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				<span style='color: #EE4454;'>*</span>
				公司电话
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-input v-model="enterpriseData.shopTel" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				<span style='color: #EE4454;'>*</span>
				公司紧急联系人
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-input v-model="enterpriseData.shopkeeper" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				<span style='color: #EE4454;'>*</span>
				公司紧急联系人手机号
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-input v-model="enterpriseData.telephone" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				执照类型
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-select v-model="enterpriseData.businessLicenceType" placeholder="请选择">
					<el-option
						v-for="item in licenseOptions"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
					</el-option>
				</el-select>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				营业执照注册号
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-input v-model="enterpriseData.businessLicence" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				法人代表姓名
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-input v-model="enterpriseData.legalPersonName" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				营业执照所在地
			</div>
			<div style='margin-left: 2.1875rem;display: flex;flex-direction: column;'>
				<!-- ------------------ 获取地址 ---------------- -->
				<el-select v-model="provinceValue2" placeholder="请选择省份" @change='getCity2'>
					<el-option 
						v-for="item in provinceOptions2" 
						:key="item.areaId" 
						:label="item.areaName" 
						:value="item.areaId"
					>
					</el-option>
				</el-select>
				<el-select v-model="cityValue2" placeholder="请选择市" style='margin: 10px 0;' @change='getArea2'>
					<el-option 
						v-for="item in cityOptions2" 
						:key="item.areaId" 
						:label="item.areaName" 
						:value="item.areaId"
					>
					</el-option>
				</el-select>
				<el-select v-model="areaValue2" placeholder="请选择区" @change='getAddress2'>
					<el-option 
						v-for="item in areaOptions2" 
						:key="item.areaId" 
						:label="item.areaName" 
						:value="item.areaId"
					>
					</el-option>
				</el-select>
				<!-- ------------------------------------------------- -->
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				营业执照详细地址
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-input v-model="enterpriseData.licenseAddress" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				<span style='color: #EE4454;'>*</span>
				成立日期
			</div>
			<div style='margin-left: 2.1875rem;cursor: pointer;'>
				<el-date-picker
					v-model="enterpriseData.establishmentDate"
					type="date"
					placeholder="选择日期">
				</el-date-picker>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				<span style='color: #EE4454;'>*</span>
				营业期限
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-date-picker
					v-model="timeValue"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					@input='setLicenseTime'
					value-format='yyyy-MM-dd'
				>
				</el-date-picker>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				注册资本（万元）
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-input v-model="enterpriseData.registeredCapital" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div class='item' style='align-items: flex-start;'>
			<div class='text1' style='margin-top: 0.75rem;'>
				<span style='color: #EE4454;'>*</span>
				经营范围
			</div>
			<div style='margin-left: 2.1875rem;width: 43.4375rem;'>
				<el-input
					type="textarea"
					rows='6'
					resize='none'
					placeholder="请输入内容"
					v-model="enterpriseData.empiricalRange"
				>
				</el-input>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				法人代表证件类型
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-select v-model="enterpriseData.legalCertificateType" placeholder="请选择">
					<el-option
						v-for="item in certificateOptions"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
					</el-option>
				</el-select>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				法人代表证件号
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-input v-model="enterpriseData.legalCertificate" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				法人代表证件有效期
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-date-picker
					v-model="ZjTime"
					type="daterange"
					@change="ce_ZjTime"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					value-format='yyyy-MM-dd'
				>
				</el-date-picker>
			</div>
		</div>
		<div class='item' style='align-items: flex-start;'>
			<div class='text1'>
				上传法人证件
			</div>
			<div style='margin-left: 2.1875rem;'>
				<label for="ZJ">
					<img 
						v-if='legalCertificateImgShow' 
						style='width: 6.25rem;height: 6.25rem;cursor: pointer;' 
						:src="legalCertificateImgShow" alt=""
					>
					<img
						v-else
						style='width: 6.25rem;height: 6.25rem;cursor: pointer;' 
						src="../../assets/images/sczj.png" alt=""
					>
				</label>
				<input
					class='input-img' 
					type="file" 
					id='ZJ'
					@change="ce_upLoad_ZJ"
					accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
				>
			</div>
		</div>
		<div class='item' style='align-items: flex-start;'>
			<div class='text1'>
				上传营业执照
			</div>
			<div style='margin-left: 2.1875rem;'>
				<label for="ZZ">
					<img 
						v-if='businessLicenceImgShow' 
						style='width: 6.25rem;height: 6.25rem;cursor: pointer;' 
						:src="businessLicenceImgShow" alt=""
					>
					<img
						v-else
						style='width: 6.25rem;height: 6.25rem;cursor: pointer;' 
						src="../../assets/images/sczj.png" alt=""
					>
				</label>
				<input
					class='input-img' 
					type="file" 
					id='ZZ'
					@change="ce_upLoad_ZZ"
					accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
				>
			</div>
		</div>
		<div class='item' style='align-items: flex-start;'>
			<div class='text1'>
				上传银行开户许可证
			</div>
			<div style='margin-left: 2.1875rem;'>
				<label for="XKZ">
					<img 
						v-if='bankAccountPermitImgShow' 
						style='width: 6.25rem;height: 6.25rem;cursor: pointer;' 
						:src="bankAccountPermitImgShow" alt=""
					>
					<img
						v-else
						style='width: 6.25rem;height: 6.25rem;cursor: pointer;' 
						src="../../assets/images/sczj.png" alt=""
					>
				</label>
				<input
					class='input-img' 
					type="file" 
					id='XKZ'
					@change="ce_upLoad_XKZ"
					accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
				>
			</div>
		</div>
		<!-- <div class='item'>
			<div class='text1'>
				联系人姓名
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-input v-model="input" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				联系人手机
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-input v-model="input" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				联系人电子邮箱
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-input v-model="input" placeholder="请输入内容"></el-input>
			</div>
		</div> -->
		<div class='item' style='align-items: flex-start;'>
			<div class='text1'>
				对接商城招商人员
			</div>
			<div style='margin-left: 2.1875rem;'>
				<div>
					<div style='display: flex;align-items: center;'>
						<div class='text1' style='width: 1rem;'>有</div>
						<img
							v-if='enterpriseData.isInvestment == 1'
							style='width: 1rem;height: 1rem;margin-left: 0.9375rem;cursor: pointer;'
							src="../../assets/images/radio_btn_selected@2x.png" 
							alt=""
							@click='getRadio(1)'
						>
						<img
							v-else
							style='width: 1rem;height: 1rem;margin-left: 0.9375rem;cursor: pointer;'
							src="../../assets/images/radio_btn@2x.png" 
							@click='getRadio(1)'
							alt=""
						>
						<div class='text1' style='width: 1rem;margin-left: 3.4375rem;'>无</div>
						<img
							v-if='enterpriseData.isInvestment == 0'
							style='width: 1rem;height: 1rem;margin-left: 0.9375rem;cursor: pointer;'
							src="../../assets/images/radio_btn_selected@2x.png" 
							alt=""
							@click='getRadio(0)'
						>
						<img
							v-else
							style='width: 1rem;height: 1rem;margin-left: 0.9375rem;cursor: pointer;'
							src="../../assets/images/radio_btn@2x.png" 
							alt=""
							@click='getRadio(0)'
						>
					</div>
				</div>
				<div class='text2' style='margin-top: 0.5rem;'>如果没有联系过商城招商人员请选择无</div>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				开户银行名称
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-select v-model="enterpriseData.bankId" placeholder="请选择">
					<el-option
						v-for="item in bankOptions"
						:key="item.bankId"
						:label="item.bankName"
						:value="item.bankId"
					>
					</el-option>
				</el-select>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				对公结算银行账号
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-input v-model="enterpriseData.bankNo" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				银行开户名
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-input v-model="enterpriseData.bankUserName" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				开户银行支行所在地
			</div>
			<div style='margin-left: 2.1875rem;display: flex;flex-direction: column;'>
				<!-- ------------------ 获取地址 ---------------- -->
				<el-select v-model="provinceValue3" placeholder="请选择省份" @change='getCity3'>
					<el-option 
						v-for="item in provinceOptions3" 
						:key="item.areaId" 
						:label="item.areaName" 
						:value="item.areaId"
					>
					</el-option>
				</el-select>
				<el-select v-model="cityValue3" placeholder="请选择市" style='margin: 10px 0;' @change='getArea3'>
					<el-option 
						v-for="item in cityOptions3" 
						:key="item.areaId" 
						:label="item.areaName" 
						:value="item.areaId"
					>
					</el-option>
				</el-select>
				<el-select v-model="areaValue3" placeholder="请选择区" @change='getAddress3'>
					<el-option 
						v-for="item in areaOptions3" 
						:key="item.areaId" 
						:label="item.areaName" 
						:value="item.areaId"
					>
					</el-option>
				</el-select>
				<!-- ------------------------------------------------- -->
			</div>
		</div>
		<div class='btn DIS-CENTER' @click='ck_btn'>
			下一步
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js'
	
	export default {
		props: ['companyInfo'],
		data() {
			return {
				enterpriseData: {
					shopCompany: '',
					areaIdPath: '',
					shopAddress: '',
					shopTel: '',
					shopkeeper: '',
					telephone: '',
					businessLicenceType: 0,
					businessLicence: '',
					legalPersonName: '',
					businessAreaPath: '',
					licenseAddress: '',
					businessStartDate: '',
					businessEndDate: '',
					establishmentDate: '',
					registeredCapital: '',
					empiricalRange: '',
					legalCertificateType: 0,
					legalCertificate: '',
					legalCertificateEndDate:'',
					isInvestment: 1,
					bankId: '',
					bankNo: '',
					bankUserName: '',
					legalCertificateImg: '',
					businessLicenceImg: '',
					bankAccountPermitImg: '',
					legalCertificateStartDate: '',
				},
				input: '',
				textarea1: '',
				/*----------- 获取地址 ----------*/
				provinceOptions1: [], // 省的数组
				provinceValue1: '', // 省的数据
				provinceText1: '',
				cityOptions1: [], // 市的数组
				cityValue1: '', // 市的数据
				cityText1: '',
				areaOptions1: [], // 区的数组
				areaValue1: '', // 区的数据
				areaText1: '',
				/*----------------------------*/
				/*----------- 获取地址 ----------*/
				provinceOptions2: [], // 省的数组
				provinceValue2: '', // 省的数据
				provinceText2: '',
				cityOptions2: [], // 市的数组
				cityValue2: '', // 市的数据
				cityText2: '',
				areaOptions2: [], // 区的数组
				areaValue2: '', // 区的数据
				areaText2: '',
				/*----------------------------*/
				/*----------- 获取地址 ----------*/
				provinceOptions3: [], // 省的数组
				provinceValue3: '', // 省的数据
				provinceText3: '',
				cityOptions3: [], // 市的数组
				cityValue3: '', // 市的数据
				cityText3: '',
				areaOptions3: [], // 区的数组
				areaValue3: '', // 区的数据
				areaText3: '',
				/*----------------------------*/
				licenseOptions: [
					{
						value: 0,
						label: '普通执照'
					},
					{
						value: 1,
						label: '多证合一营业执照(统一社会信用代码)'
					},
					{
						value: 2,
						label: '多证合一营业执照(非统一社会信用代码)'
					},
				],
				certificateOptions: [
					{
						value: 0,
						label: '大陆身份证'
					},
					{
						value: 1,
						label: '护照'
					},
					{
						value: 2,
						label: '港澳居民通行证'
					},
					{
						value: 3,
						label: '台湾居民通行证'
					},
				],
				bankOptions: [],
				timeValue: '',
				legalCertificateImgShow: '',
				businessLicenceImgShow: '',
				bankAccountPermitImgShow: '',
				ZjTime: '',
			}
		},
		created() {
			this.initData();
			this.initPageData();
			this.enterpriseData = this.$props.companyInfo;
		},
		methods: {
			initPageData() {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					type: 1
				}
				this.axios.post(Api.joinStepNext,data).then(res=>{
					if(res.data.code == 200) {
						this.bankOptions = res.data.data.bankId;
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			ck_btn() {
				if(this.enterpriseData.shopCompany == '' || this.enterpriseData.shopTel == '') {
					this.$message.warning('请填写必填信息')
					return 
				}
				if(this.enterpriseData.shopkeeper == '' || this.enterpriseData.telephone == '') {
					this.$message.warning('请填写必填信息')
					return 
				}
				if(this.enterpriseData.areaIdPath == '') {
					this.$message.warning('请选择公司所在地')
					return
				}
				if(this.enterpriseData.empiricalRange == '') {
					this.$message.warning('请填写经营范围')
					return
				}
				if(this.enterpriseData.establishmentDate == '') {
					this.$message.warning('请选择成立日期')
					return
				}
				if(this.enterpriseData.establishmentDate == '') {
					this.$message.warning('请选择成立日期')
					return
				}
				if(this.enterpriseData.establishmentDate == '') {
					this.$message.warning('请选择成立日期')
					return
				}
				if(this.enterpriseData.businessStartDate == '' || this.enterpriseData.businessEndDate == '') {
					this.$message.warning('请选择营业执照期限')
					return
				}
				this.$emit('step',2)
				this.$emit('ckCompanyInfo',this.enterpriseData);
			},
			/*--------------- 获取地址 ----------------*/
			initData(parentId,type) {
				const data = {
					parentId
				}
				this.axios.post(Api.getArea, data).then(res => {
					if (res.data.code == 200) {
						// this.provinceOptions = res.data.data
						if(type=='city') {
							this.cityOptions1 = res.data.data;
							this.cityOptions2 = res.data.data;
							this.cityOptions3 = res.data.data;
						} else if(type=='area') {
							this.areaOptions1 = res.data.data;
							this.areaOptions2 = res.data.data;
							this.areaOptions3 = res.data.data;
						} else {
							this.provinceOptions1 = res.data.data;
							this.provinceOptions2 = res.data.data;
							this.provinceOptions3 = res.data.data;
						}
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			getCity1(value) {
				this.initData(value,'city');
				this.provinceText1 = value ? this.provinceOptions1.find(ele => ele.areaId === value).areaName : ''
			},
			getArea1(value) {
				this.initData(value,'area');
				this.cityText1 = value ? this.cityOptions1.find(ele => ele.areaId === value).areaName : ''
			},
			getAddress1(value) {
				this.initData(value,'area');
				this.areaText1 = value ? this.areaOptions1.find(ele => ele.areaId === value).areaName : ''
				this.enterpriseData.areaIdPath = `${this.provinceValue1}_${this.cityValue1}_${this.areaValue1}`
			},
			getCity2(value) {
				this.initData(value,'city');
				this.provinceText2 = value ? this.provinceOptions1.find(ele => ele.areaId === value).areaName : ''
			},
			getArea2(value) {
				this.initData(value,'area');
				this.cityText2 = value ? this.cityOptions1.find(ele => ele.areaId === value).areaName : ''
			},
			getAddress2(value) {
				this.initData(value,'area');
				this.areaText2 = value ? this.areaOptions1.find(ele => ele.areaId === value).areaName : ''
				this.enterpriseData.businessAreaPath = `${this.provinceValue1}_${this.cityValue1}_${this.areaValue1}`
			},
			getCity3(value) {
				this.initData(value,'city');
				this.provinceText3 = value ? this.provinceOptions1.find(ele => ele.areaId === value).areaName : ''
			},
			getArea3(value) {
				this.initData(value,'area');
				this.cityText3 = value ? this.cityOptions1.find(ele => ele.areaId === value).areaName : ''
			},
			getAddress3(value) {
				this.initData(value,'area');
				this.areaText3 = value ? this.areaOptions1.find(ele => ele.areaId === value).areaName : ''
				this.enterpriseData.businessAreaPath = `${this.provinceValue1}_${this.cityValue1}_${this.areaValue1}`
			},
			/*------------------------------------------------*/
			setLicenseTime(value) {
				this.enterpriseData.businessStartDate = value[0];
				this.enterpriseData.businessEndDate = value[1];
			},
			getRadio(value) {
				this.enterpriseData.isInvestment = value;
			},
			// 上传
			ce_upLoad_ZJ(value) {
				const file = value.srcElement.files[0];
				if(!file) {return}
				let data = new FormData();
				data.append('file',file);
				this.axios.post(
					Api.uploadFile,
					data,
				).then(res=>{
					if(res.data.code ==200) {
						this.enterpriseData.legalCertificateImg = res.data.data.file_path;
						this.legalCertificateImgShow = Api.URL + '/' + res.data.data.file_path;
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			ce_upLoad_ZZ(value) {
				const file = value.srcElement.files[0];
				if(!file) {return}
				let data = new FormData();
				data.append('file',file);
				this.axios.post(
					Api.uploadFile,
					data,
				).then(res=>{
					if(res.data.code ==200) {
						this.enterpriseData.businessLicenceImg = res.data.data.file_path;
						this.businessLicenceImgShow = Api.URL + '/' + res.data.data.file_path;
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			ce_upLoad_XKZ(value) {
				const file = value.srcElement.files[0];
				if(!file) {return}
				let data = new FormData();
				data.append('file',file);
				this.axios.post(
					Api.uploadFile,
					data,
				).then(res=>{
					if(res.data.code ==200) {
						this.enterpriseData.bankAccountPermitImg = res.data.data.file_path;
						this.bankAccountPermitImgShow = Api.URL + '/' + res.data.data.file_path;
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			
			ce_ZjTime(value) {
				this.enterpriseData.legalCertificateStartDate = value[0];
				this.enterpriseData.legalCertificateEndDate = value[1];
			}
		}
	}
</script>

<style scoped>
	.StepFirst {
		width: 62.5rem;
	}
	
	.item {
		display: flex;
		align-items: center;
		margin-left: 4.6875rem;
		margin-bottom: 1.875rem;
	}
	
	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		width: 9.375rem;
		display: flex;
		justify-content: flex-end;
	}
	
	.StepFirst>>>.el-input__inner{
		width: 340px;
		height: 44px;
		border: 1px solid #DDDDDD;    
		border-radius: 0;
	} 
	
	.btn {
		width: 223px;
		height: 50px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		margin-left: 15.9375rem;
		cursor: pointer;
	}
	
	.text2 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
	
	.input-img {
		display: none;
	}
</style>
