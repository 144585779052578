<template>
	<div class='LoginHeader DIS-CENTER'>
		<div style='width: 75rem;display: flex;justify-content: space-between;align-items: center;'>
			<img class='logo' src="../assets/logo/logo3.png" alt="" @click='ck_goPage("/")'>
			<div class='LoginHeader-text' v-if='passWordType > 0'>
				{{passWordType == 1?"想起来了？":"已有账号？"}}
				<span 
					style='color: #EE4454;cursor: pointer;'
					@click='ck_goPage("/Login")'
				>
					登录
				</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:['passWordType'],
		methods:{
			ck_goPage(path) {
				this.$router.push({path})
			}
		}
	}
</script>

<style scoped>
	.LoginHeader {
		width: 100%;
		background-color: #FAFAFA;
		height: 3.75rem;
	}
	
	.logo {
		width: 5.9375rem;
		height: 42px;
		cursor: pointer;
	}
	
	.LoginHeader-text {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
</style>
