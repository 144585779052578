<template>
	<div class='HR-right'>
		<div class='top'>
			<div class='title'>
				推荐人信息
			</div>
			<div class='line'></div>
			<div class='user'>
				<div class='DIS-CENTER' style='margin-left: 1.5625rem;'>
					<img style='width: 3.125rem;height: 3.125rem;border-radius: 32px;'
						:src="imgUrl+author_info.userPhoto" alt="">
					<div style='margin-left: 0.875rem;'>{{author_info.userName}}</div>
				</div>

				<!-- 作者自己不能关注自己 -->
				<div v-if="userInfo.userId!=author_id">
					<div class='btn DIS-CENTER' v-if="author_info.is_follow==0" @click="ck_checkFollow">关注</div>
					<div class='btn DIS-CENTER' v-if="author_info.is_follow==1" @click="ck_checkAllFollow">已关注</div>
				</div>
			</div>
			<div class='num-box'>
				<div class='DIS-CENTER' style='flex-direction: column;' v-for="(item,index) in userInfoList"
					:key='index'>
					<div class='num'>{{item.num}}</div>
					<div class='text'>{{item.text}}</div>
				</div>
			</div>
		</div>

		<div class='bottom'>
			<div class='title'>
				相似推荐
			</div>
			<div class='line'></div>
			<div class='DIS-CENTER' style='margin-top: 1.4375rem;cursor: pointer;' v-for='(item,index) in goodList.slice(0,10)' :key='index' @click="ck_goPage(item.id)">
				<img style='width: 5rem;height: 5rem;border: 1px solid #E6E6E6;' :src="item.cover_image" alt="">
				<div style='margin-left: 1.0625rem;'>
					<div class='text'>
						{{item.title}}
					</div>
					<div style='display: flex;align-items: center;margin-top: 1.1875rem;'>
						<img style='width: 1.06257</div>rem;height: 1.0625rem;' src="../../assets/images/like.png" alt="">
						<div class='num'>{{item.zan_num}}</div>
					</div>
				</div>
			</div>
			<div class='line' style='margin-top: 1.3125rem;'></div>
			<div class='DIS-CENTER title' style='cursor: pointer;'>
				更多推荐
			</div>
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	export default {
		props: ['author_id', 'author_info'],
		data() {
			return {
				imgUrl: Api.URL,
				userInfo: '',
				userInfoList: [{
						num: '0',
						text: '发布',
					},
					{
						num: '0',
						text: '粉丝',
					},
					{
						num: '0',
						text: '点赞',
					},
				],
				goodList: [],
			}
		},
		created() {
			this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
			this.userInfoData()
			this.authorRecommendData()
		},
		methods: {
			authorRecommendData() {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					page: 1,
				}
				this.axios.post(Api.authorRecommend, data).then(res => {
					if (res.data.code == 200) {
						this.goodList = res.data.data
					} else {
						this.$message.error(res.data.msg)
					}
				});
			},
			initData(typeNum) {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					author_id: this.$props.author_id,
					type: typeNum,
				}
				this.axios.post(Api.checkFollow, data).then(res => {
					if (res.data.code == 200) {
						this.$emit('refreshAuthorArticleDetail', true)
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			userInfoData() {
				const data = {
					loginid: localStorage.getItem("shuying-token"),
				};
				this.axios.post(Api.authorIndex, data).then((res) => {
					if (res.data.code == 200) {
						console.log(res.data.data,'res.data.data')
						// this.userId=res.data.data.userInfo.userId
						// this.user_name = res.data.data.userInfo.userName
						// this.userImg = Api.URL + res.data.data.userInfo.userPhoto
						this.userInfoList[0].num = res.data.data.articleNum
						this.userInfoList[1].num = res.data.data.myFansNum
						this.userInfoList[2].num = res.data.data.zanNum

						for (let i in this.userInfoList) {
							if (this.userInfoList[i].num > 1000 && this.userInfoList[i].num < 10000) {
								this.userInfoList[i].num = this.userInfoList[i].num / 1000 + 'k';
							} else if (this.userInfoList[i].num > 10000) {
								this.userInfoList[i].num = this.userInfoList[i].num / 10000 + 'w';
							}
						}
					} else {
						this.$message.error(res.data.msg);
					}
				});
			},
			ck_checkFollow() {
				this.initData(0)
			},
			ck_checkAllFollow() {
				this.initData(1)
			},
			ck_goPage(id){
				this.$router.push({
					path: '/IntelligentRecommend?id='+id
				})
				location.reload()
			}
		}
	}
</script>

<style>
	.HR-right {
		width: 24.1875rem;
	}

	.HR-right .top {
		width: 24.1875rem;
		height: 14.0625rem;
		border: 1px solid #E6E6E6;
	}

	.HR-right .title {
		font-size: 1rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin: 0.75rem 0 0.625rem 1.6875rem;
	}

	.HR-right .line {
		width: 24.1875rem;
		border-top: 0.0625rem solid #E6E6E6;
	}

	.top .user {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 1.25rem;
	}

	.top .btn {
		width: 4.5rem;
		height: 1.75rem;
		border-radius: 0.0625rem;
		border: 0.0625rem solid #E6E6E6;
		cursor: pointer;
		margin-right: 1.125rem;
	}

	.HR-right .num-box {
		display: flex;
		justify-content: space-around;
		margin-top: 2.0625rem;
	}

	.num-box .num {
		font-size: 1.125rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.num-box .text {
		font-size: 0.875rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}

	.HR-right .bottom {
		width: 24.1875rem;
		border: 1px solid #E6E6E6;
		margin-top: 2.5rem;
	}

	.bottom .text {
		font-size: 0.875rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		width: 14.875rem;
	}

	.bottom .num {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-left: 0.5rem;
	}
</style>
