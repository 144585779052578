<script>
import MChatTabs from "./chatTabs"; //左侧列表
import MChatIndex from "../chat"; // 右侧对话框

import { playTipSound } from "../util/play";
import { layerPosition, layerDrag } from "../util/layer";
import closeImg from "../static/images/close.png";
import minimizeImg from "../static/images/minimize.png";
import fullScreenImg from "../static/images/fullScreen.png";
import screenfull from "screenfull";
import Api from "../../src/request/api.js";
import { mapState } from "vuex";
import { setPicSize } from "../../src/utils/util.js";
export default {
  name: "mchat",
  components: {
    MChatTabs,
    MChatIndex,
  },
  provide() {
    return {
      rootChat: this,
    };
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        rightBox: false,
        brief: false,
        voice: false,
        notice: false,
        fixed: true,
      }),
    },
    mine: {
      type: Object,
      default: () => ({
        id: "10001",
        username: "jule-meteor",
        status: "online",
        sign: "",
        avatar: "/avatar/avatar_meteor.png",
      }),
    },
    chats: {
      type: Array,
      default: () => [],
    },
    msgList: {
      type: Array,
      default: () => [],
    },
    systemList: {
      type: Array,
      default: () => [],
    },
    allChats: {
      type: Array,
      default: () => [],
    },

    friendChats: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      panes: [],
      selected: "0",
      display: true, // 主体是否隐藏
      chatDisplay: true, //右侧chats是否隐藏
      width: "800",
      //左上角操作按钮
      closeImg: closeImg,
      minimizeImg: minimizeImg,
      fullScreenImg: fullScreenImg,
      msgImgList: [],
      renderComponent: true,
    };
  },
  computed: {
    // alone() {
    //   let flag = true;
    //   if (this.chats.length > 1) {
    //     flag = false;
    //   }
    //   return flag;
    // },
    ...mapState(["currentMsg"]),
  },
  watch: {
    // alone(nv, ov) {
    //   if (nv) {
    //     this.chatDisplay = true;
    //   }
    // },
    "config.brief"(nv, ov) {
      if (!nv) {
        this.chatDisplay = true;
      }
    },
    // 观察窗口变化
    panes(nv, ov) {
      // 如果 窗口是从0变多
      let o_len = ov.length;
      if (0 === o_len) {
        this.$nextTick(() => {
          this.initChatPosition();
        });
      }
    },
  },

  methods: {
    // 初始化窗口的位置
    initChatPosition() {
      if (this.config.fixed) return;
      let el = this.$refs.chat;
      if (el) {
        this.$nextTick(() => {
          layerPosition(el, "center");
        });
      }
    },

    handleTabClick({ pane }) {
      this.$emit("ckPaneChat", pane.chat);
      this.selected = pane.index;
      this.handleEnterFocus(pane);
    },

    handleTabRemove({ pane, ev }) {
      const { name, type, id } = pane.chat;
      this.$emit("removeChat", { id, name, type });
    },

    // 切换列表
    handleCheckAllChat(chat) {
      this.$emit("checkChat", chat);
    },

    // enterBox 关闭事件
    handleChatRemove() {
      const { name, type, id } = this.getCurrent().chat;
      this.$emit("removeChat", { id, name, type });
    },

    // 对话内容点击
    handleTalkClick(item) {
      this.$emit("talkClick", item);
    },

    // 讲话用户头像点击
    handleTalkUserClick(item) {
      this.$emit("talkUserClick", item);
    },

    handleChatHeaderClick(pane) {
      this.$emit("chatInfo", pane);
    },

    // handleLoadHistory(data) {
    //   this.$emit("loadHistory", data);
    // },

    //  获得当前对话框
    getCurrent() {
      let { chat, taleList } = this.getCurrentPane();
      return { chat, taleList };
    },

    //  获得当前对话框内部使用
    getCurrentPane() {
      for (let pane of this.panes) {
        // 激活的就是当前的
        if (pane.active) {
          return pane;
        }
      }
    },

    // 收到消息
    // getMessage(message) {
    //   this.$nextTick(() => {
    //     // let voice = this.config.voice;
    //     // // 提示音
    //     // if (voice) {
    //     //   if (!message.mine) {
    //     //     playTipSound();
    //     //   }
    //     // }

    //     console.log(this.panes, "panes");
    //     this.panes.forEach((item) => {
    //       let { chat } = item;

    //       console.log(chat, "chat");
    //       if (chat.id !== message.id || chat.type !== message.type) return;

    //       console.log("执行到.........");
    //       item.getMessage(message);
    //     });
    //   });
    // },

    handleEvent(event, data) {
      switch (event) {
        default:
          this.bindChatEvent(event, data);
          break;
      }
    },
    calculateImageSize(url) {
      return new Promise(function (resolve, reject) {
        const image = document.createElement("img");
        image.addEventListener("load", function (e) {
          resolve({
            width: e.target.width,
            height: e.target.height,
          });
        });

        image.addEventListener("error", function () {
          reject();
        });

        // 将图片的url地址添加到图片地址中
        image.src = url;
      });
    },

    // 定义发送图片内容
    handleUploadEvent(file, fn) {
      if (!file.file) {
        return;
      }
      const imageReader = new FileReader();
      imageReader.readAsDataURL(file.file);

      let _this = this;
      imageReader.addEventListener("loadend", function (e) {
        const imageSrc = e.target.result;
        _this.calculateImageSize(imageSrc).then(function ({ width, height }) {
          return new Promise(function (resolve, reject) {
            let data = new FormData();
            data.append("file", file.file);
            _this.axios.post(Api.uploadFile, data).then((res) => {
              if (res.data.code == 200) {
                const msg = {
                  url: Api.URL + "/" + res.data.data.file_path,
                  w: width,
                  h: height,
                };
                resolve(msg);
              } else {
                reject(_this.$message.error(res.data.msg));
              }
            });
          }).then((options) => {
            _this.sendImg(options);
          });
        });
      });
    },

    sendImg(options) {
      options = setPicSize(options);
      this.msgImgList.push(options);
      let message = {
        content: `<a><img src='${options.url}' style='width:${options.w};height:${options.h};'></a>`,
        op_type: 102,
        sender_uid: this.config.sender_uid,
        receive_uid: this.config.receive_uid,
        type: 1,
      };
      this.$emit("uploadEvent", message);
    },

    // 左上角操作事件
    handleChatSet(event) {
      if (event == "chatMax") {
        if (!screenfull.isEnabled) {
          this.$message.error("不支持全屏");
          return false;
        }
        this.$emit("changeChatMaxOperation", true);
        const element = document.getElementById("DialogBoxIndex");
        screenfull.request(element);
      }

      if (event == "chatMin") {
        if (screenfull.isFullscreen) {
          screenfull.toggle();
        }
        this.$emit("changeChatMaxOperation", false);
      }
      const { name, type, id } = this.getCurrent().chat;
      this.$emit(event, { id, name, type });
    },

    handleEnter(content) {
      let message = {
        content: content,
        op_type: 102,
        sender_uid: this.config.sender_uid,
        receive_uid: this.config.receive_uid,
        type: 0,
      };

      //是否写回去
      this.$emit("sendMessage", message);
    },

    handPanesDrag(e) {
      if (this.config.fixed) return;
      let el = this.$refs.chat;
      layerDrag(e, el);
    },

    // 输入框对焦
    handleEnterFocus(pane) {
      if (!pane) {
        pane = this.getCurrentPane();
      }
      setTimeout(() => {
        if (!pane || pane.$children.length < 1) {
          return;
        }
        const childrenEl = pane.$children.filter(
          (item) =>
            item.$vnode.tag &&
            item.$vnode.componentOptions &&
            item.$vnode.componentOptions.Ctor.options.name === "enter-box"
        );

        // 输入框聚焦
        if (childrenEl.length > 0) {
          childrenEl[0].handleInputFocus();
        }
      }, 200);
    },

    //生成panes的数据
    calcPaneInstances(isForceUpdate = false) {
      //绕了一圈最终决定由chat-box 来决定 chat-tabs的属性  // 拿到子组件中 最近消息列表数据 显示
      if (this.$children) {
        const childPanes = this.$children.filter(
          (item) =>
            item.$vnode.tag &&
            item.$vnode.componentOptions &&
            item.$vnode.componentOptions.Ctor.options.name === "mchat-index"
        );
        const panes = childPanes.map((item) => item.$vnode.componentInstance);
        const panesChanged = !(
          panes.length === this.panes.length &&
          panes.every((pane, index) => pane === this.panes[index])
        );
        if (isForceUpdate || panesChanged) {
          this.selected = "0";
          this.panes = panes;
          this.handleEnterFocus(panes[0]);
        }
      } else if (this.panes.length !== 0) {
        this.panes = [];
      }
    },
  },
  render() {
    let {
      handPanesDrag,
      config,
      chats,
      msgList,
      systemList,
      allChats,
      friendChats,
      panes,
      handleTabClick,
      handleTabRemove,
      handleChatRemove,
      handleTalkClick,
      handleTalkUserClick,
      handleChatHeaderClick,
      // handleLoadHistory,
      handleUploadEvent,
      handleEnter,
      handleChatSet,
      handleCheckAllChat,
      chatDisplay,
      closeImg,
      minimizeImg,
      fullScreenImg,
      alone,
    } = this;

    if (chats.length < 1) return;
    // if (chats.length < 1 || systemList.length<1) return;  //暂无消息
    // 窗口页面

    const el_chat_panes = this._l(chats, (chat) => {
      let data_chat = {
        props: {
          chat,
          config,
          panes,
          msgList,
          // callLoadHistory: handleLoadHistory,
          emitMessage: handleEnter,
          callChatClose: handleChatRemove,
          callTalkClick: handleTalkClick,
          callTalkUserClick: handleTalkUserClick,
          callHeaderClick: handleChatHeaderClick,
        },
        ref: "MChatIndex",
        on: {
          uploadEvent: function (data, fn) {
            handleUploadEvent(data, fn);
          },
        },
      };
      return <m-chat-index {...data_chat}></m-chat-index>;
    });

    // 标签页面 左侧列表页
    // allChats
    const el_chat_tabs = {
      props: {
        panes,
        chats,
        allChats,
        friendChats,
        systemList,
        callTabAllChat: handleCheckAllChat, //切换列表
        callTabRemove: handleTabRemove, //删除会话
        callTabClick: handleTabClick, //增加会话
      },
      ref: "MChatTabs",
    };

    return (
      <div>
        <div
          class={{
            fixed: config.fixed,
            "im-layer layer-anim im-box im-chat": true,
            "chat-show": chatDisplay,
          }}
          id="DialogBoxIndex"
          ref="chat"
          style={{ "z-index": 1002, display: "inline" }}
        >
          <div
            class="im-layer-title"
            style="background-color: #EE4454;"
            on-mousedown={(ev) => {
              handPanesDrag(ev);
            }}
          ></div>
          <div class="im-layer-tabs im-layer-content">
            <m-chat-tabs {...el_chat_tabs}></m-chat-tabs>
            <div>{el_chat_panes}</div>
          </div>

          <span class="im-box-setwin">
            <img
              src={closeImg}
              title="关闭"
              on-click={(ev) => handleChatSet("chatClose", ev)}
            />
            <img
              src={minimizeImg}
              title="最小化"
              on-click={(ev) => handleChatSet("chatMin", ev)}
            />
            <img
              src={fullScreenImg}
              title="最大化"
              on-click={(ev) => handleChatSet("chatMax", ev)}
            />
          </span>
          <span class="im-icon-resize"></span>
        </div>
      </div>
    );
  },
  // created() {
  //   // 设定一些监听的事件  监听回复的消息
  //   this.$im.on("getMessage", (msg) => {
  //     console.log(msg, "最新的chats");
  //     // this.getMessage(msg);
  //   });
  // },

  mounted() {
    this.calcPaneInstances();
  },

  updated() {
    this.calcPaneInstances();
  },
};
</script>

<style scoped>
.chat-show {
  -webkit-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
  min-width: 500px;
  width: 800px;
}

.chat-show.alone {
  width: 620px;
}
</style>
