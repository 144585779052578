<template>
	<div class='PayPassword'>
		<div class='title'>
			请输入支付密码
		</div>
		<div style="position: relative;">
			<div class='itemBox'>
				<div 
					class='item'
					v-for='(item,index) in 6'
					:key='index'
				>
				</div>
			</div>
			<div class='inputBox'>
				<input 
					class='Paddword-input'
					type="password"
					maxlength="6"
					v-model="payPwd"
				>
			</div>
		</div>
		<div class='btnBox'>
			<div class='btn1 DIS-CENTER' @click='ck_btn'>取消</div>
			<div class='btn2 DIS-CENTER' @click='ck_btn("pay")'>支付</div>
		</div>
		
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	
	export default {
		props: ["pkey"],
		data() {
			return {
				payPwd: '',
			}
		},
		methods: {
			ck_btn(type) {
				if(type=='pay') {
					const data = {
						loginid: localStorage.getItem('shuying-token'),
						payPwd: this.payPwd,
						pkey: this.$props.pkey
					}
					this.axios.post(Api.payByWallet,data).then(res=>{
						console.log(res.data,'支付结果')
						if(res.data.code == 200) {
							// 子传父组件，告诉父组件跳转下一个页面
							console.log('执行')
							this.$emit('ckpayyue',3)
						} else {
							this.$message.error(res.data.msg)
						}
					})
				}
				this.$emit('ckShowPassword', false);
			}
		},
	}
</script>

<style scoped>
	.PayPassword {
		width: 367px;
		height: 240px;
		background: #FFFFFF;
		border-radius: 6px;
		border: 1px solid #FFFFFF;
	}
	
	.title {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin: 2.0625rem 0 0 2.375rem;
	}
	
	.itemBox {
		display: flex;
		align-items: center;
		margin: 31px 0 0 2.375rem;
	}
	
	.item {
		width: 40px;
		height: 40px;
		background: #F4F4F4;
		border: 1px solid #E6E6E6;
		margin-right:0.625rem;
	}
	
	.inputBox {
		width: 18.75rem;
		position: absolute;
		z-index: 9;
		top: 0;left: 2.5rem;
		background-color: rgba(0,0,0,0);
		
	}
	
	.Paddword-input {
		letter-spacing:45px;
		width: 20rem;
		height: 2.5rem;
		border: none;
		background-color: rgba(0,0,0,0);
		outline:none;
		padding-left:17px;
		color: transparent;
		text-shadow: 0 0 0 #000;  
		font-size: 1.25rem;
	}
	
	.btnBox {
		margin-top: 2.875rem;
		display: flex;
		align-items: center;
	}
	
	.btn1 {
		width: 80px;
		height: 30px;
		border-radius: 2px;
		border: 1px solid #E6E6E6;
		cursor: pointer;
		font-size: 13px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-left: 9.375rem;
	}
	
	.btn2 {
		width: 80px;
		height: 30px;
		background: #EE4454;
		border-radius: 2px;
		cursor: pointer;
		font-size: 13px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		margin-left: 1.25rem;
	}
</style>
