<template>
	<div class='EnterprisesTitle'>
		<div class='title'>
			{{title}}
		</div>
	</div>
</template>

<script>
	export default {
		props: ['title']
	}
</script>

<style scoped>
	.EnterprisesTitle {
		width: 1000px;
		padding-bottom: 1.375rem;
		border-bottom: 2px solid #EE4454;
	}
	
	.title {
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
</style>
