<template>
	<div class='Recommend'>
		<div class='titleBox'>
			{{title}}
		</div>
		
		<div class='content'>
			<div 
				class='item'
				v-for='(item,index) in dataList'
				:key='index'
				@click='ck_setId(item.goodsId)'
			>
				<img style='width: 14rem;height: 14rem;' :src="item.goodsImg" alt="">
				<div class='title'>
					{{item.goodsName}}
				</div>
				<div class='priceBox'>
					<div>
						<span class='price'>￥</span>{{item.shopPrice}} <span class='qi'>起</span>
					</div>
					<div class='sell'>
						已卖 <span style='color: #EE4454'>{{item.saleNum}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['title','dataList'],
		data() {
			return {
				
			}
		},
		methods: {
			// ck_goPage(path,id) {
			// 	this.$router.push({path,query:{id}});
			// }
			ck_setId(id) {
				this.$emit('setId',id);
			}
		}
	}
</script>

<style scoped>
	.Recommend {
		width: 14rem;
	}
	
	.Recommend .titleBox {
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		border-bottom: 1px solid #EE4454;
		padding-bottom: 0.75rem;
	}
	
	.content .item {
		width: 12.5rem;
		padding: 0 0.6875rem;
		border: 1px solid #E6E6E6;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 1.25rem;
		cursor: pointer;
	}
	
	.item .title {
		margin-top: 0.875rem;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	
	.item .priceBox {
		width: 100%;
		margin-top: 0.75rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0.625rem;
	}
	
	.priceBox .price {
		font-size: 13px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.priceBox .qi {
		font-size: 11px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
	
	.priceBox .sell {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
</style>
