<template>
	<div class='intelligentRank'>
		<div>
			<Header bgIndex='1' />
		</div>
		
		<!-- 内容区 -->
		<div class='SY-CONTENT' style='margin-top: 1.9375rem;flex-direction: column;align-items: center;'>
			<!-- 头部 -->
			<div class='contentTitleBox'>
				<div :class="rankTitle == 0? 'title1':'title2'" @click='rankTitle = 0'>
					达人榜
				</div>
				<div :class="rankTitle == 1? 'title1':'title2'" style='margin-left: 3rem;' @click='rankTitle = 1'>
					热书榜
				</div>
			</div>
			
			<!-- 详情模块 -->
			<div v-if='rankTitle == 0'>
				<PersonalRank />
			</div>
			<div v-if='rankTitle == 1'>
				<BookRank />
			</div>
		</div>
		
		<!-- 页尾文字 -->
		<div 
			class='SY-CONTENT'
			style='margin-top: 8.5rem;background-color: #FAFAFA;'
		>
			<HomeText />
		</div>
		
		<!-- 页脚 -->
		<div 
			class='SY-CONTENT'
			style='background-color: #333333;'
		>
			<HomeFooter />
		</div>
	</div>
</template>

<script>
	import Header from '../components/Header.vue';
	import HomeText from '../components/Home/HomeText.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	import PersonalRank from '../components/IntelligentRank/PersonalRank.vue'; 
	import BookRank from '../components/IntelligentRank/BookRank.vue'; 
	
	export default {
		components: {
			Header,
			HomeText,
			HomeFooter,
			PersonalRank,
			BookRank
		},
		data() {
			return {
				rankTitle: 0, //0达人榜 1热书榜          
			}
		}
	}
</script>

<style scoped>
	.contentTitleBox {
		width: 75rem;
		border-bottom:2px solid #EE4454;
		padding-bottom: 0.625rem;
		display: flex;
		align-items: baseline;
	}
	
	.title1 {
		width: 4.5rem;
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		cursor: pointer;
	}
	
	.title2 {
		width: 4.5rem;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		cursor: pointer;
	}
</style>
