<template>
	<!-- 稿件管理 -->
	<div>
		<div class="PublishContentgjglBox">
			<div class="PublishContentgjgl_header">
				<div :class="item.active?'PublishContentgjgl_active pub_headerBox':'pub_headerBox'"
					v-for="(item,index) in PublishMenuList" :key="index" @click="ck_PublishMenu(index)">
					<div :class="item.active?'pub_headerBox_textActive':'pub_headerBox_text'">{{item.text}}</div>
				</div>
			</div>
		</div>

		<div class="gjListMenuBox">
			<div style="display: flex;">
				<div class="gjListMenuItem"
					:style="index==0 || index==1 || index==2?'border-right: 0.0625rem solid #FFFFFF;':''"
					v-for="(item,index) in gjListMenu" :key='index' @click="ck_gjListMenu(index)">
					<div :class="item.active?'gjListMenuItem_text1_active':'gjListMenuItem_text1'">{{item.text}}</div>
					<div :class="item.active?'gjListMenuItem_text1_active':'gjListMenuItem_text1'">{{item.num}}</div>
				</div>
			</div>
			<!-- <div class="gjListMenuBox_right">
				<el-select v-model="optionsVal1" placeholder="全部区分">
					<el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>

				<el-select v-model="optionsVal2" placeholder="投稿时间排序">
					<el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div> -->
		</div>

			<div class="DIS-CENTER" style="display: flex;flex-direction: column;">
			<img src='../../../assets/none/gjgl_none.png' style="width: 26.25rem;height: 16.25rem;margin-top:9.0625rem;" />
			<div class="pub_headerBox_text1">当前一个稿件都没有，请换个筛选条件~</div>
		</div>
	</div>

</template>

<script>
	import Api from '../../../request/api.js'
	export default {
		data() {
			return {
				PublishMenuList: [{
						text: '图文推荐',
						active: true,
					},
					{
						text: '音频推荐',
						active: false,
					},
					{
						text: '视频推荐',
						active: false,
					},
				],
				PublishMenuValue: 0,

				gjListMenu: [{
						text: '全部稿件',
						num: 0,
						active: true,
					},
					{
						text: '进行中',
						num: 0,
						active: false,
					},
					{
						text: '已通过',
						num: 0,
						active: false,
					},
					{
						text: '未通过',
						num: 0,
						active: false,
					},
				],
				gjListMenuValue: 0,

				options1: [{
						value: '选项1',
						label: '黄金糕'
					},
					{
						value: '选项2',
						label: '双皮奶'
					},
					{
						value: '选项3',
						label: '蚵仔煎'
					},
				],
				optionsVal1: '',

				options2: [{
						value: '选项1',
						label: '黄金糕'
					},
					{
						value: '选项2',
						label: '双皮奶'
					},
					{
						value: '选项3',
						label: '蚵仔煎'
					},
				],
				optionsVal2:'',
				allArticleList:[],
			}
		},
		mounted() {
			this.initData(0,0)
			this.initData(0,1)
			this.initData(0,2)
			// 稿件管理  请求图文 数量
		},
		methods: {
			ck_PublishMenu(index) {
				for (let i in this.PublishMenuList) {
					this.PublishMenuList[i].active = false
				}
				this.PublishMenuList[index].active = true
				this.PublishMenuValue = index
				this.initData(index,0)
				this.initData(index,1)
				this.initData(index,2)
			},
			ck_gjListMenu(index) {
				for (let i in this.gjListMenu) {
					this.gjListMenu[i].active = false
				}
				this.gjListMenu[index].active = true
				this.gjListMenuValue = index
				if(index==0){
					this.initData(this.PublishMenuValue,0)
					this.initData(this.PublishMenuValue,1)
					this.initData(this.PublishMenuValue,2)
				}else{
					this.initData(this.PublishMenuValue,index-1)
					this.initData(this.PublishMenuValue,index-1)
					this.initData(this.PublishMenuValue,index-1)
				}
			},
			
			initData(type,status){
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					type: type,
					status: status,
				}
				this.axios.post(Api.articleManage,data).then(res=>{
					if(res.data.code == 200) {
						this.allArticleList=[]
						if(res.data.data.length!=0){
							for(var i in res.data.data){
								this.allArticleList.push(res.data.data[i])
							}
						}
					} else {
						this.$message.warning(res.data.msg);
					}
				})
			}
		}
	}
</script>

<style>
	.PublishContentgjglBox {
		border-bottom: 0.0625rem solid #E8E8E8;
		width: 100%;
	}

	.PublishContentgjglBox .PublishContentgjgl_header {
		width: 100%;
		display: flex;
		background: #FFFFFF;
	}

	.PublishContentgjglBox .pub_headerBox {
		margin-right: 5.5rem;
		margin-top: 2.1875rem;
		padding-bottom: 1.3125rem;
		margin-left: 3.9375rem;
		cursor: pointer;
	}

	.PublishContentgjglBox .PublishContentgjgl_active {
		border-bottom: 0.25rem solid #EE4454;
	}

	.PublishContentgjglBox .pub_headerBox_text {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.PublishContentgjglBox .pub_headerBox_textActive {
		font-weight: 600;
		color: #EE4454;
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
	}

	.gjListMenuBox {
		margin-left: 3.875rem;
		margin-top: 1.1875rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.gjListMenuItem {
		border: 0.0625rem solid #E8E8E8;
		padding: 0.75rem 1.625rem;
		display: flex;
		cursor: pointer;
	}

	.gjListMenuItem_text1 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-left: 0.5625rem;
	}

	.gjListMenuItem_text1_active {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		margin-left: 0.5625rem;
		color: #EE4454;
	}

	.pub_headerBox_text1 {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-top: 2rem;
	}

	.gjListMenuBox .gjListMenuBox_right {
		margin-right: 3.25rem;
		width: 15.9375rem;
		display: flex;
	}
</style>
