<template>
	<!-- 首页 -->
	<div class="PublishIndexWrap">
		<div>
			<div class="PublishIndexWrap_title">专栏数据</div>

			<div class="PublishIndexWrap_listBox">
				<div class="PublishIndexWrap_wrapBox" v-for="(item,index) in dataList" :key="index"
					:style="index=3?'margin-top:1.25rem;':''">
					<PublishComponents :item='item' />
				</div>
			</div>
		</div>

		<div style="margin-top: 3.25rem;">
			<div class="PublishIndexWrap_course_header">
				<div class="PublishIndexWrap_title">教程</div>
				<div style="display: flex;align-items: center;">
					<div class="PublishIndexWrap_course_header_text">更多教程</div>
					<img src='../../assets/images/arrow_right.png' class="PublishIndexWrap_img1" />
				</div>
			</div>
			<!-- 教程 -->
			<div class="PublishIndexWrap_course">

			</div>
		</div>


	</div>
</template>

<script>
	import PublishComponents from './PublishComponents.vue';
	import Api from "../../request/api.js"
	export default {
		data() {
			return {
				dataList: [{
						title: '粉丝数',
						num: '',
						dayTitle: '昨日新增:',
						// dayNum: 136,
					},
					// {
					// 	title: '收藏数',
					// 	num: '20,809',
					// 	dayTitle: '昨日新增:',
					// 	dayNum: 136,
					// },
					// {
					// 	title: '阅读量',
					// 	num: '20,809',
					// 	dayTitle: '昨日新增:',
					// 	dayNum: 136,
					// },
					// {
					// 	title: '评论数',
					// 	num: '20,809',
					// 	dayTitle: '昨日新增:',
					// 	dayNum: 136,
					// },

					// {
					// 	title: '点赞数',
					// 	num: '',
					// 	// dayTitle: '昨日新增:',
					// 	// dayNum: 136,
					// },
					// {
					// 	title: '分享数',
					// 	num: '20,809',
					// 	dayTitle: '昨日新增:',
					// 	dayNum: 136,
					// },
				],
				courseList: [{
						img: '',
						text: '',
						headPhoto: '',
						forTitle: '',
					},
					{
						img: '',
						text: '',
						headPhoto: '',
						forTitle: '',
					},
					{
						img: '',
						text: '',
						headPhoto: '',
						forTitle: '',
					},
					{
						img: '',
						text: '',
						headPhoto: '',
						forTitle: '',
					}, {
						img: '',
						text: '',
						headPhoto: '',
						forTitle: '',
					},
				]
			}
		},
		components: {
			PublishComponents,
		},
		created(){
			this.userInfoData()
		},
		methods:{
			userInfoData() {
				const data = {
					loginid: localStorage.getItem("shuying-token"),
				};
				this.axios.post(Api.authorIndex, data).then((res) => {
					if (res.data.code == 200) {
						this.dataList[0].num=res.data.data.zanNum
					} else {
						this.$message.error(res.data.msg);
					}
				});
			}
		}
	}
</script>

<style>
	.PublishIndexWrap {
		margin-top: 1.5rem;
	}

	.PublishIndexWrap_title {
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-left: 1.875rem;
	}

	.PublishIndexWrap_listBox {
		margin: 1.1875rem 2.3125rem 0 1.9375rem;
		display: flex;
		flex-wrap: wrap;
	}

	.PublishIndexWrap_wrapBox {
		width: 31.25rem;
	}

	.PublishIndexWrap_course_header {
		display: flex;
		justify-content: space-between;
		margin-right: 2.1875rem;
		align-items: center;
	}

	.PublishIndexWrap_course_header_text {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}

	.PublishIndexWrap_img1 {
		width: 0.6875rem;
		height: 0.6875rem;
		margin-left: 0.6875rem;
	}

	.PublishIndexWrap_course {
		margin-top: 1.25rem;
	}
</style>
