<template>
	<div class='HomeFour'>
		<HomeTitle 
			title='资讯'
			text='更多资讯'
			path='Information'
		/>
		
		<div class='HomeFour-item'>
			<div
				:class="index==0 || index%5==0 ? 'item-box1' : 'item-box2'"
				style=''
				v-for='(item,index) in homeFooterAritcles'
				:key='index'
				@click='ck_goPage("/IntelligentItem",item.articleId)'
			>
				<img 
					style='width: 14rem;height: 7.75rem;'
					:src="item.coverImg" 
					alt=""
				>
				<div class='title'>{{item.articleTitle}}</div>
				<!-- <div style='display: flex;align-items: center;'>
					<div class='zx-img'></div>
					<div class='text'>{{item.from_txt}}</div>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	import HomeTitle from '../components/Home-title.vue';
	
	export default {
		components: { HomeTitle },
		props: ['homeFooterAritcles'],
		methods: {
			ck_goPage(path,id) {
				this.$router.push({path,query:{id}})
			}
		}
	}
</script>

<style scoped>
	.HomeFour {
		width: 75rem;
	}
	
	.HomeFour-item {
		display: flex;
		flex-wrap: wrap;
	}
	
	.HomeFour-item .item-box1 {
		width: 14rem;
		cursor: pointer;
		margin-top: 1.25rem;
	}
	
	.item-box1:hover .title{
		color: #EE4454;
	}
	
	.item-box2:hover .title{
		color: #EE4454;
	}
	
	.HomeFour-item .item-box2 {
		width: 14rem;
		cursor: pointer;
		margin-top: 1.25rem;
		margin-left: 1.25rem;
	}
	
	.HomeFour .title {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin: 0.9375rem 0 0.8125rem 0;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	
	.HomeFour .zx-img {
		width: 1.25rem;
		height: 1.25rem;
		background: #DCDCDC;
		border-radius: 50%;
	}
	
	.HomeFour .text {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-left: 1.25rem;
	}
	
	
</style>
