export const HELPCENTERMENU = [
	{
		text: '买家帮助',
		active: true,
		child: [
			{
				text: '注册账户',
				active: true,
			},
			{
				text: '购买流程',
				active: false,
			}
		]
	},
	{
		text: '卖家帮助',
		active: false,
		child: [
			{
				text: '入驻流程',
				active: false,
			},
			{
				text: '商品上架',
				active: false,
			},
		]
	},
	{
		text: '资金账户',
		active: false,
		child: [
			{
				text: '支付流程',
				active: false,
			},
			{
				text: '资金管理',
				active: false,
			}
		]
	},
	{
		text: '投诉维权',
		active: false,
	},
	{
		text: '信用评价',
		active: false,
	},
	{
		text: '常见问题',
		active: false,
	},
]
