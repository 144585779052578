<template>
	<div>
		<div>
			<Header bgIndex='2' @dialogBoxIndex="dialogBoxIndex" />
		</div>
		
		<!-- 标题 -->
		<div class='SY-CONTENT'>
			<div class='NewBook-title DIS-CENTER'>
				<img 
					style='width: 1.875rem;height: 1.875rem;'
					src="../assets/images/JX-title-img.png" 
					alt=""
				>
				<div class='title'>精选书籍</div>
			</div>
		</div>
		
		<!-- 商品列表 -->
		<div class='SY-CONTENT'>
			<GoodList :goodList='goodBookList'/>
		</div>
		
		<!-- 分页器 -->
		<div class='SY-CONTENT'>
			<div class='sorter'>
				<el-pagination
					background
					layout="pager"
					@current-change='editPage'
					:page-size='20'
					:total="total">
				</el-pagination>
			</div>
		</div>
		
		<!-- 页尾文字 -->
		<div 
			class='SY-CONTENT'
			style='margin-top: 8.5rem;background-color: #FAFAFA;'
		>
			<HomeText />
		</div>
		
		<!-- 页脚 -->
		<div 
			class='SY-CONTENT'
			style='background-color: #333333;'
		>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Header from '../components/Header.vue';
	import HomeText from '../components/Home/HomeText.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	import GoodList from '../components/childPage/GoodLIst.vue';
	
	import Api from '../request/api.js';
	
	export default {
		components: { Header, HomeText, HomeFooter, GoodList },
		data() {
			return {
				goodBookList: [],
				total: 0,
				isShowDialogBox:false,
			}
		},
		created() {
			this.initData(1);
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			initData(page) {
				const data = {
					loginid: localStorage.getItem("shuying-token"),
					page: page
				}
				this.axios.post(Api.selectionOfGoodBooks,data).then(res=>{
					if(res.data.code == 200) {
						this.goodBookList = res.data.data.bestgoods;
						this.total = res.data.data.count;
					} else {
						this.$message.warning(res.data.msg);
					}
				})
			},
			editPage(value) {
				this.initData(value);
			}
		}
	}
</script>

<style scoped>
	.NewBook-title {
		width: 75rem;
		margin-top: 2.1875rem;
	}
	
	.NewBook-title .title {
		font-size: 1.75rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-left: 1.25rem;
	}
	
	.sorter {
		width: 75rem;
		display: flex;
		justify-content: flex-end;
		margin-top: 1.875rem;
	}
	
	.sorter>>>.el-pager li {
		background-color: transparent !important;
		color: #333333 !important;
		border: 1px solid #E6E6E6;
		width: 36px;
		height: 36px;
		line-height: 2.25rem;
	}
	
	.sorter>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color:  #333333 !important;
		color: white!important;
	}
</style>
