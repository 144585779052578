<template>
	<div class='SelectAddress'>
		<div class='title'>
			选择收货地址
		</div>
		<div style='margin-top: 1.375rem;display: flex;flex-wrap: wrap;'>
			<div 
				:class="index==0||index%4==0?'itemBox1':'itemBox'"
				v-for='(item,index) in addressList'
				:key='index'
			>
				<div class='itemTitle'>
					{{item.areaName1}}({{item.userName}})
				</div>
				<div class='address'>
					{{item.userAddress}}
				</div>
				<!-- <div class='editText'>
					<div v-if='item.isDefault == 1'>
						修改
					</div>
				</div> -->
				<div v-if='item.isDefault == 1' class='itemDefault DIS-CENTER'>
					默认
				</div>
				<img
					v-if='item.isDefault == 1'
					style='width: 1.5rem;height: 1.5rem;position: absolute;bottom: 0;right: 0;' 
					src="../../assets/images/selectAddress-defalut.png" alt=""
				>
			</div>
		</div>
		
		<div class='btnBox'>
			<!-- <div class='unfold DIS-CENTER'>展开全部</div> -->
			<div class='management DIS-CENTER' @click='ck_goPage("/MineAddress")'>管理地址</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:['addressList'],
		data() {
			return {
				
			}
		},
		methods: {
			ck_goPage(path) {
				this.$router.push({path,query:{type: 'SubmitOrder'}});
			}
		}
	}
	
</script>

<style scoped>
	.SelectAddress {
		width: 75rem;
	}
	
	.title {
		font-size: 20px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.itemBox {
		width: 15.375rem;
		padding: 1rem 1.125rem;
		background: url(../../assets/images/address-xf.png);
		background-size: 100% 100%;
		margin-bottom: 1.875rem;
		cursor: pointer;
		margin-left: 1.1875rem;
		position: relative;
	}
	
	.itemBox1 {
		width: 15.375rem;
		padding: 1rem 1.125rem;
		background: url(../../assets/images/address-xf-active.png);
		background-size: 100% 100%;
		margin-bottom: 1.875rem;
		cursor: pointer;
		position: relative;
	}
	
	.itemTitle {
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		padding-bottom: 0.375rem;
		border-bottom: 1px solid #EEEEEE;
	}
	
	.address {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-top: 0.375rem;
		/* border: 1px solid red; */
	}
	
	.editText {
		margin-top: 0.3125rem;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
		cursor: pointer;
		width: 1.75rem;
		height: 1.25rem;
	}
	
	.itemDefault {
		width: 37px;
		height: 19px;
		background: #EE4454;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		position: absolute;
		top: 0;
		right: 0;
	}
	
	.btnBox {
		display: flex;
	}
	
	.unfold {
		width: 120px;
		height: 36px;
		border: 1px solid #E7E7E7;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
	}
	
	.management {
		width: 120px;
		height: 36px;
		background: #FFF5F5;
		border: 1px solid #EE4454;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
		/* margin-left: 1.25rem; */
		cursor: pointer;
	}
</style>
