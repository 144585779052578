<template>
	<div>
		<div class='sort'>
			<!-- <div 
				class='text'
				style='margin-left: 1.375rem;'
			>
				综合排序
			</div> -->
			<div
				class='text'
				style='margin-left: 2.5rem;display: flex;align-items: center;'
				@click='ck_setOrder(1)'
			>
				<div>销量</div>
				<img
					v-if='order == "saleNum desc"'
					style='width: 0.375rem;height: 0.375rem;margin-left: 0.25rem;'
					src="../../assets/images/top-bottom.png" 
					alt=""
				>
				<img
					v-else
					style='width: 0.375rem;height: 0.375rem;margin-left: 0.25rem;'
					src="../../assets/images/bottom-top.png" 
					alt=""
				>
			</div>
			<!-- <div
				class='text'
				style='margin-left: 1.8125rem;display: flex;align-items: center;'
			>
				<div>评论数</div>
				<img
					style='width: 0.375rem;height: 0.375rem;margin-left: 0.25rem;'
					src="../../assets/images/bottom-top.png" 
					alt=""
				>
			</div> -->
			<div
				class='text'
				style='margin-left: 1.8125rem;display: flex;align-items: center;'
				@click='ck_setOrder(2)'
			>
				<div>人气</div>
				<img
					v-if='order == "collectNum desc"'
					style='width: 0.375rem;height: 0.375rem;margin-left: 0.25rem;'
					src="../../assets/images/top-bottom.png" 
					alt=""
				>
				<img
					v-else
					style='width: 0.375rem;height: 0.375rem;margin-left: 0.25rem;'
					src="../../assets/images/bottom-top.png" 
					alt=""
				>
			</div>
			<div
				class='text'
				style='margin-left: 1.8125rem;display: flex;align-items: center;'
				@click='ck_setOrder(3)'
			>
				<div>上架时间</div>
				<img
					v-if='order == "createTime desc"'
					style='width: 0.375rem;height: 0.375rem;margin-left: 0.25rem;'
					src="../../assets/images/top-bottom.png" 
					alt=""
				>
				<img
					v-else
					style='width: 0.375rem;height: 0.375rem;margin-left: 0.25rem;'
					src="../../assets/images/bottom-top.png" 
					alt=""
				>
			</div>
			<!-- <div
				class='text'
				style='margin-left: 1.8125rem;display: flex;align-items: center;'
			>
				<div>价格</div>
				<img
					style='width: 0.375rem;height: 0.375rem;margin-left: 0.25rem;'
					src="../../assets/images/top-bottom.png" 
					alt=""
				>
			</div> -->
			<div 
				class='input-box'
				style='margin-left: 1.3125rem;'
			>
				<div style='margin-left: 0.3125rem;'>￥</div>
				<input 
					type="text"
					class='input-item'
					v-model="price.startPrice"
				>
			</div>
			<div class='line'></div>
			<div
				class='input-box'
				style='margin-left: 0.4375rem;'
			>
				<div style='margin-left: 0.3125rem;'>￥</div>
				<input 
					type="text"
					class='input-item'
					v-model="price.endPrice"
				>
			</div>
			<div 
				class='btn text DIS-CENTER'
				style='margin-left: 0.3125rem;'
				@click='ck_getPrice'
			>
				确定
			</div>
			<!-- <div
				class='text'
				style='margin-left: 2.5625rem;display: flex;align-items: center;'
			>
				<div>发货地</div>
				<img
					style='width: 0.375rem;height: 0.375rem;margin-left: 0.25rem;'
					src="../../assets/images/top-bottom.png" 
					alt=""
				>
			</div> -->
			<div 
				class='DIS-CENTER sort-choose'
				style='margin-left: 1.125rem;'
			>
				<img
					v-if='isStock == 1' style='width: 0.75rem;height: 0.75rem;cursor: pointer;' 
					src="../../assets/images/choice-active.png" alt=""
					@click='ck_setStock(0)'
				>
				<img 
					v-else style='width: 0.75rem;height: 0.75rem;cursor: pointer;' 
					src="../../assets/images/choice.png" alt=""
					@click='ck_setStock(1)'
				>
				<div style='margin-left: 0.375rem;'>仅显示有货</div>
			</div>
			<!-- <div
				class='DIS-CENTER sort-choose'
				style='margin-left: 1rem;'
			>
				<div style='width: 12px;height: 0.75rem;border: 1px solid #E6E6E6;'></div>
				<div style='margin-left: 0.375rem;'>新品</div>
			</div> -->
			<div
				class='DIS-CENTER sort-choose'
				style='margin-left: 0.9375rem;'
			>
				<img 
					v-if='isFee == 1' style='width: 0.75rem;height: 0.75rem;cursor: pointer;' 
					src="../../assets/images/choice-active.png" alt=""
					@click='ck_setChoice(0)'
				>
				<img 
					v-else style='width: 0.75rem;height: 0.75rem;cursor: pointer;' 
					src="../../assets/images/choice.png" alt=""
					@click='ck_setChoice(1)'
				>
				<div style='margin-left: 0.375rem;'>包邮</div>
			</div>
			<!-- <div 
				class='btn DIS-CENTER text'
				style='margin-left: 3.5rem;'
			>
				上一页
			</div>
			<div
				class='btn DIS-CENTER text'
				style='margin-left: 0.625rem;'
			>
				下一页
			</div> -->
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				price: {
					startPrice: '',
					endPrice: '',
				},
				isFee: 0,
				isStock: 0,
				order: 'saleNum desc'
			}
		},
		methods: {
			ck_getPrice() {
				this.$emit('getPrice',this.price)
			},
			ck_setChoice(value) {
				this.isFee = value;
				this.$emit('setChoice',this.isFee);
			},
			ck_setStock(value) {
				this.isStock = value;
				this.$emit('setStock',this.isStock);
			},
			ck_setOrder(type) {
				if(type==1) {
					if(this.order == 'saleNum desc') {
						this.order = 'saleNum asc'
					} else {
						this.order = 'saleNum desc'
					}
				} else if(type==2) {
					if(this.order == 'collectNum desc') {
						this.order = 'collectNum asc'
					} else {
						this.order = 'collectNum desc'
					}
				} else if(type==3) {
					if(this.order == 'createTime desc') {
						this.order = 'createTime asc'
					} else {
						this.order = 'createTime desc'
					}
				}
				this.$emit('setOrder',this.order);
			}
		}
	}
</script>

<style scoped>
	.sort {
		width: 75rem;
		margin-top: 1.1875rem;
		height: 2.75rem;
		display: flex;
		align-items: center;
		background: #FAFAFA;
		border: 1px solid #E6E6E6;
	}
	
	.sort .text {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
	}
	
	.input-box {
		width: 4.5rem;
		height: 1.5rem;
		background: #FFFFFF;
		border: 1px solid #E6E6E6;
		display: flex;
		align-items: center;
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.input-box .input-item {
		border: none;
		width: 3.125rem;
		height: 1.375rem;
		outline: none;
	}
	
	.sort .line {
		width: 0.375rem;
		border: 0.125rem solid #333333;
		margin-left: 0.4375rem;
	}
	
	.sort .btn {
		width: 3.375rem;
		height: 1.5rem;
		background: #FFFFFF;
		border-radius: 2px;
		border: 1px solid #E6E6E6;
	}
	
	.sort .sort-choose {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
</style>
