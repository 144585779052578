<template>
	<div>
		<div>
			<Header bgIndex='1' @dialogBoxIndex="dialogBoxIndex" />
		</div>

		<!-- menu区 -->
		<div class='SY-CONTENT' v-if='false'>
			<HomeMenu :menuList='menuList' />
		</div>
		
		<div class='SY-CONTENT'>
			<div style='width: 75rem;'>
				<h1>资讯</h1>
			</div>
		</div>

		<!-- 内容区 -->
		<div class='SY-CONTENT'>
			<div class='contentBox'>
				<el-carousel height="215px" style='width: 44.5rem;'>
					<el-carousel-item v-for="(item,index) in bannerData" :key="index">
						<img style='width: 100%;height: 100%;' :src="item.adFile" alt="">
					</el-carousel-item>
				</el-carousel>
				
				<!-- 循环数据 --> 
				<div 
					class='item' 
					v-for='(item,index) in videoList' :key='index'
					:style='(index-2)%5==0?"margin-left:0":""'
					@click='ck_goPage("/IntelligentItem",item.articleId)'
				>
					<img class='item-img' :src="item.coverImg" alt="">
					<div class='item-title'>
						{{item.articleTitle}}
					</div>
					<!-- <div class='item-source'>
						<div class='source-img'></div>
						<div class='source-text'>{{item.from_txt}}</div>
					</div> -->
				</div>
			</div>
		</div>
		
		<div class='SY-CONTENT' style='margin-top: 0.625rem;'>
			<!-- 分页器 -->
			<div class='sorter'>
				<el-pagination
					background
					layout="pager"
					:total="total"
					@current-change='editPage'
					:page-size='17'
				>
				</el-pagination>
			</div>
		</div>

		<!-- 页尾文字 -->
		<div class='SY-CONTENT' style='margin-top: 8.5rem;background-color: #FAFAFA;'>
			<HomeText />
		</div>

		<!-- 页脚 -->
		<div class='SY-CONTENT' style='background-color: #333333;'>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Api from '../request/api.js';
	
	import Header from '../components/Header.vue';
	import HomeText from '../components/Home/HomeText.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	import HomeMenu from '../components/Home/HomeMenu.vue';

	export default {
		components: {
			Header,
			HomeText,
			HomeFooter,
			HomeMenu
		},
		data() {
			return {
				menuList: [{
						text: '资讯',
						active: true,
						// path: '/'
					},
					// {
					// 	text: '国学古籍',
					// 	active: false,
					// 	path: '/'
					// },
					// {
					// 	text: '文学小说',
					// 	active: false,
					// 	path: '/'
					// },
					// {
					// 	text: '历史地理',
					// 	active: false,
					// 	path: '/'
					// },
					// {
					// 	text: '政治军事',
					// 	active: false,
					// 	path: '/'
					// },
					// {
					// 	text: '哲学心理',
					// 	active: false,
					// 	path: '/'
					// },
					// {
					// 	text: '经济社科',
					// 	active: false,
					// 	path: '/'
					// },
					// {
					// 	text: '生活体育',
					// 	active: false,
					// 	path: '/'
					// },
					// {
					// 	text: 'IT技术',
					// 	active: false,
					// 	path: '/'
					// },
					// {
					// 	text: '自然科学',
					// 	active: false,
					// 	path: '/'
					// },
					// {
					// 	text: '教材辅导',
					// 	active: false,
					// 	path: '/'
					// },
					// {
					// 	text: '其他',
					// 	active: false,
					// 	path: '/'
					// }
				],
				total: 0,
				videoList: [],
				bannerData: [],
				isShowDialogBox:false,
			}
		},
		created() {
			this.initData(1);
			this.initBanner();
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			initData(page) {
				const data = {
					page
				}
				this.axios.post(Api.moreArticle,data).then(res=>{
					if(res.data.code == 200) {
						this.videoList = res.data.data.articles;
						this.total = res.data.data.count;
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			initBanner() {
				const data = {
					adPositionId: 501
				}
				this.axios.post(Api.getAdImg,data).then(res=>{
					if(res.data.code == 200) {
						this.bannerData = res.data.data;
					} else {
						this.$message.errno(res.data.msg);
					}
				})
			},
			editPage(value) {
				this.initData(value);
			},
			ck_goPage(path,id) {
				this.$router.push({path,query:{id}})
			}
		}
	}
</script>

<style scoped>
	.contentBox {
		width: 75rem;
		display: flex;
		flex-wrap: wrap;
	}

	.el-carousel__item h3 {
		color: #475669;
		font-size: 14px;
		opacity: 0.75;
		line-height: 150px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n+1) {
		background-color: #d3dce6;
	}
	
	.item {
		width: 222px;
		height: 13.4375rem;
		margin-left: 1.1875rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 1.25rem;
		/* border: 1px solid #E6E6E6; */
	}
	
	.item-img {
		width: 14rem;
		height: 7.75rem;
		background-color: pink;
		cursor: pointer;
	}
	
	.item-title {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		height: 2.25rem;
		width: 12.5rem;
		margin-top: 0.75rem;
		cursor: pointer;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	
	.item-source {
		width: 12.5rem;
		display: flex;
		align-items: center;
		margin-top: 0.5625rem;
	}
	
	.source-img {
		width: 1.25rem;
		height: 1.25rem;
		border-radius: 50%;
		background-color: #dcdcdc;
	}
	
	.source-text {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-left: 0.625rem;
		cursor: pointer;
	}
	
	.sorter {
		width: 75rem;
		display: flex;
		justify-content: flex-end;
		margin-top: 1.875rem;
	}
	
	.sorter>>>.el-pager li {
		background-color: transparent !important;
		color: #333333 !important;
		border: 1px solid #E6E6E6;
		width: 36px;
		height: 36px;
		line-height: 2.25rem;
	}
	
	.sorter>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color:  #333333 !important;
		color: white!important;
	}
	
	/*-----------------*/
	.zixunText {
		
	}
</style>
