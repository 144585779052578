<template>
	<div>
		<div>
			<Header bgIndex='1' @dialogBoxIndex="dialogBoxIndex" />
		</div>
		
		<div class='SY-CONTENT'>
			<div class='herderBox'>
				好店推荐
			</div>
		</div>
		
		<div class='SY-CONTENT'>
			<div class='contentBox'>
				<div class='item' v-for='(item,index) in tableData' :key='index'>
					<div style='display: flex;'>
						<img
							class='header-img'
							:src="item.shopImg" alt=""
						>
						<div style='margin-left: 1.125rem;'>
							<div 
								class='title'
								@click="ck_goPage('/ShopHome',item.shopId)"
							>
								{{item.shopName}}
							</div>
							<div class='text' style='margin-top: 0.4375rem;'>
								近30天 
								<span style='color: #333333;'>成功交易</span> 
								<span style='color: #EE4454;margin: 0 0.25rem;'>{{item.orderNum}}</span> 
								<span style='color: #333333;'>笔</span>
								<span style='color: #333333;margin-left: 2.0625rem;'>好评:</span>
								<span style='color: #EE4454;margin-left: 0.5rem'>{{item.scores}}</span>
							</div>
							<img 
								class='kf-img'
								style='margin-top: 0.5625rem;'
								src="../assets/images/contentRIghtBox-kf.png" alt=""
							>
						</div>
					</div>
					
					<div  class='goodsList'>
						<div v-for='(item1,index1) in item.recommend_goods' :key='index1'>
							<img 
								class='goodsItme' 
								:src="item1.goodsImg" 
								alt=""
								@click="ck_goPage('/GoodsDetails',item1.goodsId)"
							>
						</div>
					</div>
					
					<div 
						class='btn1 DIS-CENTER' 
						v-if='item.isShopMember == 0'
						@click='ck_setCollect(item.shopId,1)'
					>
						收藏店铺
					</div>
					
					<div 
						class='btn2 DIS-CENTER' 
						v-else
						@click='ck_setCollect(item.shopId,2)'
					>
						已收藏
					</div>
				</div>
			</div>
		</div>
		
		<!-- 分页器 -->
		<div class='SY-CONTENT'>
			<div class='sorter'>
				<el-pagination
					background
					layout="pager"
					:total="total"
					@current-change='editPage'
					:page-size='20'
				>
				</el-pagination>
			</div>
		</div>
		
		<div
			class='SY-CONTENT'
			style='background-color: #333333;margin-top: 13.4375rem;'
		>
			<HomeFooter />
		</div>
		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Api from '../request/api.js';
	
	import Header from '../components/Header.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	
	export default {
		components: {
			Header,
			HomeFooter
		},
		data() {
			return {
				tableData: [],
				total: 0,
				isShowDialogBox:false,
			}
		},
		created() {
			this.initData(1);
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			initData(page) {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					page
				}
				this.axios.post(Api.goodShops,data).then(res=>{
					if(res.data.code == 200) {
						this.tableData = res.data.data.shops;
						this.total = res.data.data.count;
						for(let i in this.tableData) {
							for(let j in this.tableData[i].recommend_goods) {
								this.tableData[i].recommend_goods[j].goodsImg = `${Api.URL}/${this.tableData[i].recommend_goods[j].goodsImg}`
							}
						}
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			editPage(value) {
				this.initData(value)
			},
			ck_setCollect(id,type) {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					id,
					type
				}
				this.axios.post(Api.followShop,data).then(res=>{
					if(res.data.code == 200) {
						this.initData();
						this.$message.success(res.data.msg)
					} else {
						this.$message.error(res.data.msg)
					}
				}) 
			},
			ck_goPage(path,id) {
				this.$router.push({path,query:{id}})
			}
		}
	}
</script>

<style scoped>
	.herderBox {
		width: 75rem;
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-top: 30px;
		padding-bottom: 0.625rem;
		border-bottom: 2px solid #EE4454;
	}
	
	.contentBox {
		width: 1200px;
	}
	
	.item {
		display: flex;
		height: 8.5rem;
		align-items: center;
		border-bottom: 1px solid #E6E6E6;
	}
	
	.item .header-img {
		width: 3.125rem;
		height: 3.125rem;
	}
	
	.item .kf-img {
		width: 0.75rem;
		height: 0.75rem;
		cursor: pointer;
	}
	
	.goodsList {
		display: flex;
		margin-left: 15.625rem;
		width: 28.125rem;
	}
	
	.goodsItme {
		width: 5rem;
		height: 5rem;
		border: 1px solid #E6E6E6;
		margin-right: 0.625rem;
		cursor: pointer;
	}
	
	.btn1 {
		width: 70px;
		height: 28px;
		background: #EE4454;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
		margin-left: 6.25rem;
	}
	
	.btn2 {
		width: 70px;
		height: 28px;
		background: #F5F5F5;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
		margin-left: 6.25rem;
	}
	
	.title {
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		cursor: pointer;
	}
	
	.text {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
	
	.sorter {
		width: 75rem;
		display: flex;
		justify-content: flex-end;
		margin-top: 1.875rem;
	}
	
	.sorter>>>.el-pager li {
		background-color: transparent !important;
		color: #333333 !important;
		border: 1px solid #E6E6E6;
		width: 36px;
		height: 36px;
		line-height: 2.25rem;
	}
	
	.sorter>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color:  #333333 !important;
		color: white!important;
	}
</style>
