<template>
	<div class='JfFirst'>
		<!-- 顶部 -->
		<div class='header'>
			<div class='title'>
				我的可用资金 ：<span>￥{{userMoney}}</span>
			</div>
			<div class='menuBox'>
				<div 
					:class='index==0?"item1 DIS-CENTER":"item DIS-CENTER"' 
					v-for='(item,index) in headerMenuList' :key='index'
					:style="item.active?'color: #EE4454':''" 
					@click='ck_headerMenu(index)'
				>
					{{item.text}}
				</div>
			</div>
		</div>

		<!-- 列表头部 -->
		<div class='tableHeader'>
			<div class='title1' style='margin-left: 7.0625rem;'>来源/用途</div>
			<div class='title1' style='margin-left: 6.3125rem;'>金额</div>
			<div class='title1' style='margin-left: 5.9375rem;'>流水号</div>
			<div class='title1' style='margin-left: 9.625rem;'>日期</div>
			<div class='title1' style='margin-left: 11.5rem;'>备注</div>
		</div>

		<!-- 列表内容 -->
		<div 
			class='tableContent'
			v-show='total > 0'
			v-for='(item,index) in tableData' 
			:key='index'
		>
			<div class='text1' style='margin-left: 7.0625rem;width: 6.25rem;'>
				{{item.dataSrc}}
			</div>
			<div class='text2' style='margin-left: 3.4375rem;width: 4.375rem;'>
				{{item.moneyType==1?'+':'-'}}{{item.money}}
			</div>
			<div class='text1' style='margin-left: 50px;width: 95px;'>
				{{item.tradeNo?item.tradeNo:'余额支付'}}
			</div>
			<div class='text1' style='margin-left: 96px;width:130px'>
				{{item.createTime}}
			</div>
			<div class='text1' style='margin-left: 83px;width: 50px;'>
				{{item.remark}}
			</div>
		</div>
		<!-- 分页器 -->
		<div class='SY-CONTENT' v-if='total > 0'>
			<div class='sorter'>
				<el-pagination background layout="pager" :total="total" @current-change='ck_setPage'>
				</el-pagination>
			</div>
		</div>
		
		<div v-if='total < 1' style='margin-top: 1.25rem;'>
			<NodeItem title='您还没有资金流水哦~' text='可以尝试在平台购物或充值哦' />
		</div>
	</div>
</template>

<script>
	import Api from '../../../request/api.js';
	
	import NodeItem from '../../NoneItem.vue';

	export default {
		components: { NodeItem },
		data() {
			return {
				headerMenuList: [{
						text: '资金流水',
						active: true
					},
					{
						text: '资金收入',
						active: false
					}, {
						text: '资金支出',
						active: false
					}, {
						text: '冻结资金',
						active: false
					}
				],
				tableData: [], // 列表数据
				userMoney: 0, // 我的可用资金
				moneyType: -1 ,// 显示列表数据的字段
				total: 0, // 总条数
			}
		},
		created() {
			this.initData(1);
		},
		methods: {
			initData(num) {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					page: num,
					type: this.moneyType,
				}
				this.axios.post(Api.userMoney, data).then(res => {
					if (res.data.code == 200) {
						this.tableData = res.data.data.list;
						this.userMoney = res.data.data.userMoney.userMoney;
						this.total = res.data.data.count;
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			ck_headerMenu(index) {
				for (let i in this.headerMenuList) {
					this.headerMenuList[i].active = false;
				}
				this.headerMenuList[index].active = true;
				switch (index) {
					case 0:
						this.moneyType = -1;
						break;
					case 1:
						this.moneyType = 1;
						break;
					case 2:
						this.moneyType = 0;
						break;
				}
				this.initData(1);
			},
			ck_setPage(value) {
				this.initData(value);
			}
		}
	}
</script>

<style scoped>
	.JfFirst {}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}

	.title {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.title span {
		color: #EE4454;
		font-size: 1.5rem;
	}

	.menuBox {
		width: 444px;
		height: 40px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #E8E8E8;
		margin-right: 1.25rem;
		display: flex;
	}

	.menuBox .item {
		flex: 1;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		border-left: 1px solid #E8E8E8;
		cursor: pointer;
	}

	.menuBox .item1 {
		flex: 1;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
	}

	.tableHeader {
		width: 891px;
		height: 44px;
		background: #FAFAFA;
		border: 1px solid #E6E6E6;
		margin-top: 2.0625rem;
		display: flex;
		align-items: center;
	}

	.title1 {
		font-size: 12px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.tableContent {
		width: 55.4375rem;
		height: 7.5625rem;
		border-bottom: 1px solid #E6E6E6;
		display: flex;
		align-items: center;
	}

	.text1 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.text2 {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #EE4454;
	}

	.sorter {
		width: 58.4375rem;
		display: flex;
		justify-content: flex-end;
		margin-top: 1.875rem;
		padding-right: 1.25rem;
	}

	.sorter>>>.el-pager li {
		background-color: transparent !important;
		color: #333333 !important;
		border: 1px solid #E6E6E6;
		width: 36px;
		height: 36px;
		line-height: 2.25rem;
	}

	.sorter>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #333333 !important;
		color: white !important;
	}
</style>
