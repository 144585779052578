<template>
  <div class="ContentRightBox">
    <!-- 标题 -->
    <div class="DIS-CENTER" style="width: 14rem; margin-top: 1rem">
      <div class="title DIS-CENTER">
        <div style="margin-bottom: 0.375rem">
          {{ shopInfo.shopName }}
        </div>
      </div>
    </div>

    <!-- 标题2 -->
    <div class="title2" style="margin-left: 1rem; margin-top: 0.9375rem">
      {{ shopInfo.shopName }}
    </div>

    <!-- 星级评价 -->
    <div style="margin-left: 0.9375rem; margin-top: 0.5625rem">
      <div class="box1">
        <div class="title3">店铺等级</div>
        <img
          v-for="(item, index) in 3"
          :key="index"
          style="width: 0.875rem; height: 0.875rem; margin-right: 0.125rem"
          src="../../assets/images/contentRightBox-huangzhuan.png"
          alt=""
        />
      </div>
      <div class="box1" style="margin-top: 0.5625rem">
        <div class="title3">描述</div>
        <img
          v-for="(item, index) in 5"
          :key="index"
          style="
            width: 0.75rem;
            height: 0.75rem;
            margin-left: -0.25rem;
            margin-right: 0.4rem;
          "
          src="../../assets/images/contnetRightBox-xx.png"
          alt=""
        />
      </div>
      <div class="box1" style="margin-top: 0.5625rem">
        <div class="title3">服务</div>
        <img
          v-for="(item, index) in 5"
          :key="index"
          style="
            width: 0.75rem;
            height: 0.75rem;
            margin-left: -0.25rem;
            margin-right: 0.4rem;
          "
          src="../../assets/images/contnetRightBox-xx.png"
          alt=""
        />
      </div>
      <div class="box1" style="margin-top: 0.5625rem">
        <div class="title3">物流</div>
        <img
          v-for="(item, index) in 5"
          :key="index"
          style="
            width: 0.75rem;
            height: 0.75rem;
            margin-left: -0.25rem;
            margin-right: 0.4rem;
          "
          src="../../assets/images/contnetRightBox-xx.png"
          alt=""
        />
      </div>
    </div>

    <!-- 按钮 -->
    <div style="margin-top: 1.25rem; display: flex; align-items: center">
      <div
        class="btn DIS-CENTER"
        style="margin-left: 1rem; cursor: pointer"
        @click="ck_goPage('/ShopHome', shopInfo.shopId)"
      >
        进店逛逛
      </div>
      <div
        v-if="shopInfo.isShopMember == 0"
        class="btn DIS-CENTER"
        style="margin-left: 1rem; cursor: pointer"
        @click="ck_Collect(1)"
      >
        收藏店铺
      </div>
      <div
        v-else
        class="btn DIS-CENTER"
        style="margin-left: 1rem; cursor: pointer"
        @click="ck_Collect(2)"
      >
        取消收藏
      </div>
    </div>

    <!-- 联系客服 -->
    <div class="customer DIS-CENTER">
      <div class="text1">联系客服</div>
      <div class="text2">
        早{{ shopInfo.serviceStartTime }}~晚{{ shopInfo.serviceEndTime }}
      </div>
    </div>

    <!-- 客服名单 -->
    <div style="width: 13.875rem">
      <div
        v-if="true"
        style="display: flex; align-items: center; flex-wrap: wrap"
      >
        <div
          :class="
            index == 0 || index % 3 == 0
              ? 'customer-kf1 DIS-CENTER'
              : 'customer-kf DIS-CENTER'
          "
          v-for="(item, index) in customerList"
          :key="index"
          @click="ck_openQQ(index)"
        >
          <img
            style="width: 0.75rem; height: 0.75rem"
            src="../../assets/images/contentRIghtBox-kf.png"
            alt=""
          />
          <div class="text2" style="margin-left: 0.4375rem">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div
        v-else
        class="text2 DIS-CENTER"
        style="width: 100%; height: 5rem"
        @click="ck_openQQ"
      >
        QQ: {{ this.shopInfo.shopQQ }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["shopInfo"],
  data() {
    return {
      customerList: [
        {
          name: "客服",
        },
      ],
    };
  },
  methods: {
    ck_openQQ(index) {
      if (index == 0) {
        this.$emit("getTalkeShopId", this.$props.shopInfo.shopId);
      } else {
        // window.open(`http://wpa.qq.com/msgrd?v=3&uin=${this.shopInfo.shopQQ}&site=qq&menu=yes`);
      }
    },
    ck_goPage(path, id) {
      this.$router.push({ path, query: { id } });
    },
    ck_Collect(type) {
      this.$emit("ckCollect", type);
    },
  },
};
</script>

<style scoped>
.ContentRightBox {
  width: 224px;
  height: 438px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
}

.ContentRightBox .title {
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  background-image: url("../../assets/images/contentRightTitle.png");
  width: 10.9375rem;
  height: 2.125rem;
  background-size: 100% 100%;
}

.ContentRightBox .title2 {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}

.ContentRightBox .box1 {
  display: flex;
  align-items: center;
}

.box1 .title3 {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin-right: 0.8125rem;
  margin-left: 0.5625rem;
}

.ContentRightBox .btn {
  width: 84px;
  height: 30px;
  background: #f6f6f6;
  border: 1px solid #e6e6e6;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.ContentRightBox .customer {
  width: 222px;
  height: 60px;
  background: #f6f6f6;
  flex-direction: column;
  margin-top: 1.25rem;
}

.customer .text1 {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}

.text2 {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-top: 0.125rem;
}

.customer-kf {
  margin-top: 0.875rem;
  margin-left: 1.875rem;
  cursor: pointer;
}

.customer-kf1 {
  margin-top: 0.875rem;
  margin-left: 0.9375rem;
  cursor: pointer;
}
</style>
