<template>
	<div>
		<div class="PublishIndexWrap_itemBox">
			<div class="PublishIndexWrap_itemBox_title">{{item.title}}</div>
			<div class="PublishIndexWrap_itemBox_num">{{item.num}}</div>
			<div class="PublishIndexWrap_itemBox_dayContent">
				<div class="PublishIndexWrap_itemBox_dayContent_text">{{item.dayTitle}}</div>
				<div class="PublishIndexWrap_itemBox_dayContent_num">{{item.dayNum}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		props:['item'],
	}
</script>

<style>
	
	/* 组件 */
	
	.PublishIndexWrap_itemBox {
		padding: 1.875rem 0 1.9375rem 2.125rem;
		background: #FBFBFB;
		display: flex;
		flex-direction: column;
		margin-right: 1.3125rem;
	}
	
	.PublishIndexWrap_itemBox_title {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-bottom: 0.5rem;
	}
	
	.PublishIndexWrap_itemBox_num {
		font-size: 35px;
		font-family: AlibabaPuHuiTiB;
		color: #333333;
		font-weight: 600;
		margin-bottom: 0.8125rem;
	}
	
	.PublishIndexWrap_itemBox_dayContent {
		display: flex;
	}
	
	.PublishIndexWrap_itemBox_dayContent_text {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.PublishIndexWrap_itemBox_dayContent_num {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
		margin-left: 0.9375rem;
	}
	
	/* 组件 */
</style>
