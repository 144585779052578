<template>
	<div class='Substep DIS-CENTER'>
		<div class='DIS-CENTER' style='flex-direction: column;'>
			<div 
				:class="step>0?'item1 DIS-CENTER':'item2 DIS-CENTER'"
			>
				1
			</div>
			<div 
				:class="step>0?'text1':'text2'"
				style='margin-top: 0.6875rem;width: 80px;'
			>
				填写公司信息
			</div>
		</div>
		<div :class="step>1?'line1':'line2'"></div>
		<div class='DIS-CENTER' style='flex-direction: column;'>
			<div 
				:class="step>1?'item1 DIS-CENTER':'item2 DIS-CENTER'"
			>
				2
			</div>
			<div 
				:class="step>1?'text1':'text2'"
				style='margin-top: 0.6875rem;width: 80px;'
			>
				填写店铺信息
			</div>
		</div>
		<div :class="step>2 ? 'line1':'line2'"></div>
		<div class='DIS-CENTER' style='flex-direction: column;'>
			<div 
				:class="step>2?'item1 DIS-CENTER':'item2 DIS-CENTER'"
			>
				3
			</div>
			<div 
				:class="step>2?'text1':'text2'"
				style='margin-top: 0.6875rem;width: 80px;'
			>
				入驻审核
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:['step'],
		data() {
			return {
				
			}
		}
	}
</script>

<style scoped>
	.Substep {
		
	}
	
	.item1 {
		width: 40px;
		height: 40px;
		background: #56C8B0;
		border-radius: 50%;
		font-size: 20px;
		font-family: TsangerYuYangT-W05, TsangerYuYangT;
		font-weight: normal;
		color: #FFFFFF;
	}
	
	.item2 {
		width: 40px;
		height: 40px;
		background: #E0E0E0;
		border-radius: 50%;
		font-size: 20px;
		font-family: TsangerYuYangT-W05, TsangerYuYangT;
		font-weight: normal;
		color: #FFFFFF;
	}
	
	.text1 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #56C8B0;
		display: flex;
		justify-content: center;
	}
	
	.text2 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		display: flex;
		justify-content: center;
	}
	
	.line1 {
		width: 202px;
		height: 1px;
		background: #56C8B0;
		margin-bottom: 1.6rem;
	}
	
	.line2 {
		width: 202px;
		height: 1px;
		background: #EDEDED;
		margin-bottom: 1.6rem;
	}
</style>
