export const INTEMENU = [
	{
		text: '全部',
		active: true,
	},
	{
		text: '国学古籍',
		active: false,
	},
	{
		text: '文学小说',
		active: false,
	},
	{
		text: '历史地理',
		active: false,
	},
	{
		text: '政治心理',
		active: false,
	},
	{
		text: '哲学心理',
		active: false,
	},
	{
		text: '经济社科',
		active: false,
	},
	{
		text: '生活体育',
		active: false,
	},
	{
		text: 'IT技术',
		active: false,
	},
	{
		text: '自然科学',
		active: false,
	},
	{
		text: '教材辅导',
		active: false,
	},
	{
		text: '其他',
		active: false,
	},
]
