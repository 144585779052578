<script>
import { default_avatar } from "../util/constant";

import { newDate } from "../util/create-time";
import dialogueImg from "../static/images/dialogue.png";
import Api from "../../src/request/api.js";

function noop() {}

export default {
  name: "MChatTabs",
  inject: ["rootChat"], //注入父级属性

  props: {
    panes: {
      type: Array,
      default: () => [],
    },
    chats: {
      type: Array,
      default: () => [],
    },
    systemList: {
      type: Array,
      default: () => [],
    },
    allChats: {
      type: Array,
      default: () => [],
    },
    friendChats: {
      type: Array,
      default: () => [],
    },
    callTabAllChat: {
      type: Function,
      default: noop,
    },
    callTabRemove: {
      type: Function,
      default: noop,
    },
    callTabClick: {
      type: Function,
      default: noop,
    },
    callRightBoxShow: {
      type: Function,
      default: noop,
    },
  },

  data() {
    return {
      stickyTop: 0,
      zIndex: 1,
      miniMode: false, // 缩小模式默认fasle
      tempRecordDisplay: undefined, // 用于记录窗口是否被打开
      chatsIndex: 0, // 0默认显示最近消息    1我的好友
      dialogueImg: dialogueImg,
      imgUrl: Api.URL,
      newDate: newDate,
    };
  },

  computed: {
    stickyActive() {
      return this.stickyTop > 0;
    },
  },
  methods: {
    handleScroll(event) {
      this.stickyTop = event.target.scrollTop;
    },

    // 处理缩小模式
    handleMiniMode() {
      this.miniMode = !this.miniMode;
      if (this.miniMode && this.rootChat.chatDisplay) {
        this.tempRecordDisplay = this.rootChat.chatDisplay;
        this.callRightBoxShow();
      } else {
        if (
          !this.miniMode &&
          this.tempRecordDisplay !== this.rootChat.chatDisplay
        ) {
          this.callRightBoxShow();
        }
      }
    },

    // 处理 chat box是否要打开
    handleBoxSwitch() {
      this.callRightBoxShow();
      this.tempRecordDisplay = this.rootChat.chatDisplay;
      this.miniMode = false;
    },

    // 处理未读信息
    handleUnread() {},
  },

  render() {
    let {
      rootChat,
      panes,
      chats,
      systemList, //系统消息
      allChats,
      friendChats,
      stickyTop, //列表距离顶部的距离 默认0
      miniMode,
      handleScroll,
      handleMiniMode,
      handleBoxSwitch,
      callTabAllChat,
      callTabRemove,
      callTabClick,
      dialogueImg,
    } = this;

    if (rootChat.alone) return; // 如果只有一个chat的情况 默认不显示
    const { config, chatDisplay } = rootChat;
    let el_chat_tabs;
    // 最近消息  列表数组
    if (allChats[0].active) {
      el_chat_tabs = this._l(panes, (pane, index) => {
        const { active, chat, unread } = pane;
        // 判断下是否是缩小模式如果是只放过激活的
        // if (miniMode && !active) {       更改默认
        //   return;
        // }

        // <img class="allList-dialogueImg" src={dialogueImg} />
        //           <div
        //             class="allList-foot"
        //             on-click={(ev) => {
        //               ev.stopPropagation();
        //               callTabAllChat(true);
        //             }}
        //           >
        //             <div class={{ "all-list-text": allChats[1].active }}>我的好友</div>
        //             <div class={{ "all-list-foot-border": allChats[1].active }}></div>
        //           </div>

        if (miniMode) {
          return;
        }

        let { target_info, id, online, create_time, last_msg, msg_num } = chat;
        let tabName = target_info.loginName + id + index;

        pane.index = `${index}`;
        // 是否有头像
        let userPhoto = target_info.userPhoto || default_avatar; //默认头像
        const el_tab_lable = (
          <div style="display: flex;justify-content: space-between;width:100%;align-items: center;">
            <div style="display: flex;flex-direction: column;">
              <div class="im-label">{target_info.loginName}</div>
              <div class="im-msg">{last_msg ? last_msg : ""}</div>
            </div>

            <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
              <div style="font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #999999;">
                {this.newDate(create_time)}
              </div>

              <div class={{ "im-spot-num": msg_num }}>
                {msg_num ? (msg_num > 99 ? "..." : msg_num) : ""}
              </div>
            </div>
          </div>
        );
        // <div class={{"im-spot-num": msg_num}}>{msg_num?msg_num>99?'...':msg_num:""}</div>
        // 默认选中第一个  显示第一个对话信息
        // const el_unread_badge = unread > 0 ? <span class="badge">{unread}</span> : "";

        let el_chat_tab_remove = "";
        if (config.tabRemove) {
          el_chat_tab_remove = (
            <i
              class="im-icon m-icon-error"
              on-click={(ev) => {
                ev.stopPropagation();
                callTabRemove({ pane, ev });
              }}
            ></i>
          );
        }

        // 单个信息
        const el_chat_tab = (
          <li
            class={{
              "im-chat-tab": true,
              offline: false,
              "im-this": active,
            }}
            id={`tab-${tabName}`}
            key={`tab-${tabName}`}
            on-click={(ev) => {
              ev.stopPropagation();
              callTabClick({ pane, ev });
            }}
          >
            <img
              src={this.imgUrl + "/" + userPhoto}
              on-click={(ev) => {
                ev.stopPropagation();
                callTabClick({ pane, ev });
              }}
            />
            {el_tab_lable}
            {el_chat_tab_remove}
          </li>
        );
        return el_chat_tab;
      });

      // 系统消息  渲染
    } else {
      // 我的好友  列表数组
      el_chat_tabs = "";
    }

    if (config.brief) {
      const el_icon = (
        <i
          class={{
            "im-icon": true,
            "btn-pane-show": true,
            "m-icon-arrow-right": !chatDisplay,
            "m-icon-arrow-left": chatDisplay,
          }}
          on-click={() => {
            handleBoxSwitch();
          }}
        ></i>
      );
    }

    return (
      <ul
        class={{
          "im-chat-tabs": true,
          normal: !miniMode,
          "tabs-shadow": !chatDisplay,
          chatMax: config.isFullscreen,
        }}
        on-mousedown={(ev) => {
          rootChat.handPanesDrag(ev);
        }}
        on-scroll={(ev) => {
          handleScroll(ev);
        }}
      >
        <div class="im-chat-allList">
          <div
            class="allList-foot"
            on-click={(ev) => {
              ev.stopPropagation();
              callTabAllChat(false);
            }}
          >
            <div class={{ "all-list-text": allChats[0].active }}>最近消息</div>
            <div class={{ "all-list-foot-border": allChats[0].active }}></div>
          </div>
        </div>
        {el_chat_tabs}
      </ul>
    );
  },
};
</script>
<style scoped>
.badge {
  position: absolute;
  top: 12px;
  left: 10px;
  transform: translateY(-50%) translateX(100%);
  background-color: #f56c6c;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  height: 18px;
  line-height: 18px;
  padding: 0 6px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #fff;
}
</style>