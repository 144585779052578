<template>
	<div class="orderDetails">
		<div>
			<Header bgIndex="4" @dialogBoxIndex="dialogBoxIndex" />
		</div>
		<!-- 五种状态  没有物流信息：待付款 待发货  有物流信息：交易成功 待收货 待评价-->
		<!-- 已买到的宝贝 订单详情 -->
		<div class="SY-CONTENT">
			<div style="width: 75rem; background-color: #ffffff; padding-top: 1.875rem">
				<div class="Bread">
					<span style="cursor: pointer" @click="ck_goBack">主页</span>
					<span style="margin: 0 1.5625rem">/</span>
					<span>已买到的宝贝</span>
					<span style="margin: 0 1.5625rem">/</span>
					<span class="title">订单详情</span>
				</div>

				<div class="orderState">
					<img src="../assets/images/orderDetail.png" style="width: 40px; height: 40px" />
					<div style="margin-left: 30px">
						<div class="orderState_text">
							订单状态：{{orderStatus==-2?'待付款':orderStatus==0?'待发货':orderStatus==1?'待收货':'待评价'}}</div>

						<!-- orderStatus:  -2:待付款   0:待发货  1:待收货  2:待评价  5:==2 -->
						<!-- 待付款状态 -->
						<div v-if="orderStatus==-2" class="orderState_text1">
							请尽快付款，订单将在
							<span style="color: #ee4454">14:51</span>
							<span> 后自动关闭，</span>
							<span style="color: #56c8b0">去付款</span>
						</div>

						<!-- 待发货状态 -->
						<div v-if="orderStatus==0" class="orderState_text1">
							买家已付款，请耐心等待商家发货
						</div>

						<!-- 待收货状态 -->
						<div v-if="orderStatus==1" class="orderState_text1">
							卖家已发货，还有 <span style="color:#EE4454;">14</span> 天 <span style="color:#EE4454;">23</span> 时
							<span style="color:#EE4454;">59</span> 分 <span style="color:#EE4454;">21</span> 秒 系统将自动确认收货
						</div>

						<!-- 待评价状态 -->
						<!-- <div class="orderState_text1">
							您还没有评价，赶快去评一下吧~
							<span style="color: #56C8B0;">去评价</span>
						</div> -->

						<!-- 交易成功 -->
						<div v-if="orderStatus==2" class="orderState_text1">
							如果没收到货，或收到货有问题可以<span style="color:#56C8B0;">联系卖家</span>协商解决
						</div>
					</div>
				</div>
				
				<!-- orderStatus:  -2:待付款   0:待发货  1:待收货  2:待评价  5:==2 -->
				<div v-if="orderStatus!=-2 && orderStatus!=0">
					<div class="orderDetailInfo">
						<div class="title">物流信息</div>
					</div>
					
					<div class="logisticsInfo">
						<div class="logisticsInfoItem">
							<div class="logisticsInfoItem_text">发货方式：</div>
							<div class="logisticsInfoItem_text1">快递</div>
						</div>
						<div class="logisticsInfoItem">
							<div class="logisticsInfoItem_text">物流公司：</div>
							<div class="logisticsInfoItem_text1">顺丰快递</div>
						</div>
						<div class="logisticsInfoItem">
							<div class="logisticsInfoItem_text">运单号码：</div>
							<div class="logisticsInfoItem_text1">SF38107028723419</div>
						</div>
					
						<div class="logisticsInfoItem">
							<div class="logisticsInfoItem_text">运单号码：</div>
							<div class="logisticsInfoItem_listText">
								<div class="logisticsInfoItem_listText1">
									【杭州市】快件已到达 杭州市余杭区良渚公司营业部
								</div>
								<div class="logisticsInfoItem_listText2">2020-11-17 10:28:01</div>
								<div class="logisticsInfoItem_listText1">
									【杭州市】快件已到达 上海市徐汇区顺丰中转部
								</div>
								<div class="logisticsInfoItem_listText2">2020-11-17 10:28:01</div>
								<div class="logisticsInfoItem_listText1">
									【杭州市】快递员已上门揽件
								</div>
								<div class="logisticsInfoItem_listText2">2020-11-17 10:28:01</div>
							</div>
						</div>
					</div>
				</div>

				<div class="orderDetailInfo">
					<div class="title">订单信息</div>
				</div>

				<div class="userBox">
					<div style="display: flex">
						<div class="text1 userItem">
							收货人： <span class="text2">{{ orderData.userName }}</span>
						</div>
						<div class="text1 userItem">
							电话：<span class="text2">{{ orderData.userPhone }}</span>
						</div>
						<div class="text1 userItem">
							邮编：<span class="text2">{{ orderData.postcode }}</span>
						</div>
						<div class="text1">运送方式：<span class="text2">快递</span></div>
					</div>
					<div style="margin-top: 1rem">
						<div class="text1">
							收货地址：<span class="text2">{{ orderData.userAddress }}</span>
						</div>
					</div>
				</div>

				<div class="orderBox">
					<div style="display: flex">
						<div class="text1 userItem">
							店铺名称： <span class="text2">{{ orderData.shopName }}</span>
							<img style="width: 0.75rem; height: 0.75rem; margin-left: 0.75rem"
								src="../assets/PersonalCenter/contact-kf.png" alt="" />
						</div>
						<div class="text1 userItem">
							联系卖家：<span class="text2">{{
                orderData.shopTel ? orderData.shopTel : "暂无联系方式"
              }}</span>
						</div>
					</div>
					<div style="display: flex; margin-top: 1.25rem">
						<div class="text1 userItem">
							订单号： <span class="text2">{{ orderData.orderNo }}</span>
						</div>
						<div class="text1 userItem">
							创建时间：<span class="text2">{{ orderData.createTime }}</span>
						</div>
					</div>
				</div>

				<div class="tableHeader">
					<div class="text1" style="margin-left: 11.875rem">商品信息</div>
					<div class="text1" style="margin-left: 19.0625rem">单价</div>
					<div class="text1" style="margin-left: 15rem">数量</div>
					<div class="text1" style="margin-left: 10rem">状态</div>
				</div>

				<div class="tableItem" v-for="(item, index) in tableData" :key="index">
					<!-- <div style='width: 5rem;height: 5rem;border: 1px solid #e6e6e6;'></div> -->
					<img style="width: 5rem; height: 5rem" :src="item.goodsImg" alt="" />
					<div class="text3" style="width: 15.4375rem; margin-left: 1.25rem">
						{{ item.goodsName }}
					</div>
					<div class="text3 tableTextBox" style="width: 6.25rem; height: 5rem; margin-left: 170px">
						￥{{ item.goodsPrice }}
					</div>
					<div class="text3 tableTextBox" style="width: 3.125rem; height: 5rem; margin-left: 175px">
						{{ item.goodsNum }}
					</div>
					<div class="text3 tableTextBox" style="margin-left: 125px; height: 5rem">
						待付款
					</div>
				</div>

				<div class="detail">
					<div class="text2" style="width: 200px; display: flex; align-items: center">
						<div style="width: 65px; display: flex; justify-content: flex-end">
							商品总价：
						</div>
						<div>￥{{ orderData.goodsMoney }}</div>
					</div>
					<div class="text2" style="
              width: 200px;
              display: flex;
              margin-top: 1.125rem;
              align-items: center;
            ">
						<div style="width: 65px; display: flex; justify-content: flex-end">
							运费：
						</div>
						<div>￥00.00</div>
					</div>
					<div class="text2" style="
              width: 200px;
              display: flex;
              margin-top: 1.125rem;
              align-items: center;
            ">
						<div style="width: 65px; display: flex; justify-content: flex-end">
							折扣优惠：
						</div>
						<div>￥00.00</div>
					</div>
					<div class="text2" style="
              width: 200px;
              display: flex;
              margin-top: 1.125rem;
              align-items: center;
            ">
						<div style="width: 65px; display: flex; justify-content: flex-end">
							实付款：
						</div>
						<div class="text4">￥{{ orderData.goodsMoney }}</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 页脚 -->
		<div class="SY-CONTENT" style="background-color: #333333">
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Api from "../request/api.js";
	import Header from "../components/Header.vue";
	import HomeFooter from "../components/Home/HomeFooter.vue";

	export default {
		components: {
			Header,
			HomeFooter
		},
		data() {
			return {
				orderData: [],
				tableData: [],
				orderStatus: [],
				isShowDialogBox:false,
			};
		},
		created() {
			this.initData();

		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			initData() {
				const data = {
					loginid: localStorage.getItem("shuying-token"),
					id: this.$route.query.pkey,
				};
				this.axios.post(Api.orderDetail, data).then((res) => {
					if (res.data.code == 200) {
						let orderLogLength = res.data.data.log.length
						this.orderStatus = res.data.data.log[orderLogLength - 1].orderStatus
						this.orderData = res.data.data;
						this.tableData = res.data.data.goods;
						for (let i in this.tableData) {
							this.tableData[i].goodsImg = `${Api.URL}/${this.tableData[i].goodsImg}`;
						}
					} else {
						this.$message.error(res.data.msg);
					}
				});
			},
			ck_goBack() {
				this.$router.go(-1);
			},
		},
	};
</script>

<style scoped>
	.orderDetails {
		background-color: #f5f5f5;
	}

	.Bread {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-left: 2.5rem;
	}

	.title {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.userBox {
		width: 1078px;
		height: 49px;
		background: #fafafa;
		margin-left: 2.5rem;
		margin-top: 1.5625rem;
		padding: 1.25rem;
	}

	.text1 {
		font-size: 12px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.text2 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.userItem {
		margin-right: 50px;
	}

	.orderBox {
		margin-top: 2.25rem;
		margin-left: 3.75rem;
	}

	.tableHeader {
		width: 1120px;
		height: 44px;
		background: #fafafa;
		border: 1px solid #e6e6e6;
		margin-top: 2.5rem;
		display: flex;
		align-items: center;
		margin-left: 2.5rem;
	}

	.tableItem {
		width: 67.5rem;
		height: 5rem;
		padding: 1.25rem;
		border: 1px solid #e6e6e6;
		margin-left: 2.5rem;
		border-top: none;
		display: flex;
	}

	.tableTextBox {
		display: flex;
		align-items: center;
	}

	.text3 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.detail {
		padding: 2.1875rem 0;
		width: 70rem;
		border: 1px solid #e6e6e6;
		border-top: none;
		margin-left: 2.5rem;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		margin-bottom: 200px;
	}

	.text4 {
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #ee4454;
	}

	.logisticsInfo {
		margin-left: 2.5rem;
		margin-right: 41px;
		padding-top: 22px;
	}

	.logisticsInfoItem {
		display: flex;
		margin-bottom: 16px;
	}

	.logisticsInfoItem_text {
		font-size: 12px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.logisticsInfoItem_text1 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.logisticsInfoItem_listText {
		display: flex;
		flex-direction: column;
	}

	.logisticsInfoItem_listText1 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.logisticsInfoItem_listText2 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-top: 3px;
		margin-bottom: 14px;
	}

	.orderDetailInfo {
		border-bottom: 1px solid #e6e6e6;
		margin-left: 2.5rem;
		padding-bottom: 12px;
		margin-right: 41px;
	}

	.orderState {
		border: 1px solid #e6e6e6;
		margin: 25px 41px 25px 2.5rem;
		padding: 33px 0 33px 42px;
		display: flex;
		align-items: center;
	}

	.orderState_text {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-bottom: 10px;
	}

	.orderState_text1 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
</style>
