<template>
	<div class='ApplyRefundStep'>
		<div>
			<Header bgIndex='4' @dialogBoxIndex="dialogBoxIndex" />
		</div>
		
		<!-- 内容区 -->
		<div class='SY-CONTENT' style='background-color: #F5F5F5;'>
			<div class='content'>
				<!-- 面包屑 -->
				<div class='BreadCrumbs' style='margin: 31px 0 0 2.4375rem;'>
					<div 
						class='breadText1' 
						style='cursor: pointer;'
						@click='ck_goPage("/PersonalCenter")'
					>
						主页
					</div>
					<div class='breadText1' style='margin: 0 1.5625rem;'>/</div>
					<div 
						class='breadText1' 
						style='cursor: pointer;'
						@click='ck_goPage("/PersonalCenter",{type: 2})'
					>
						已买到的宝贝
					</div>
					<div class='breadText1' style='margin: 0 1.5625rem;'>/</div>
					<div class='breadText2' style='cursor: pointer;'>申请退款</div>
				</div>
				
				<!-- 分布条 -->
				<div style='width: 75rem;display: flex;justify-content: center;'>
					<div class='step' style='margin-top: 2.5rem;'>
						<div 
							class='stepBox'
							v-for='(item,index) in stepList'
							:key='index'
						>
							<div style='display: flex;flex-direction: column;align-items: center;'>
								<div 
									class='stepItem DIS-CENTER'
									:style='stepValue >= index ?"background: #56C8B0":""'
								>
									1
								</div>
								<div 
									class='stepText1'
									:style='stepValue >= index?"color: #56C8B0":""'
								>
									{{item}}
								</div>
							</div>
							
							<div 
								v-if='index != 3' 
								:style='stepValue >= index?"background: #56C8B0":""'
								class='stepLine'
							></div>
						</div>
					</div>
				</div>
				
				<div class='DIS-CENTER' style='width: 75rem;'>
					<!-- 卖家申请 -->
					<div v-if='stepValue == 0' style='margin-top: 3.125rem;'>
						<ApplyRefundFirst />
					</div>
					
					<!-- 卖家处理申请 -->
					<div v-if='stepValue == 1' style='margin-top: 6.9375rem;'>
						<ApplyRefundSecond />
					</div>
					
					<!-- 买家退货 -->
					<div v-if='stepValue == 2' style='margin-top: 6.8125rem;'>
						<ApplyRefundThird />
					</div>
					
					<!-- 退货完毕 -->
					<div v-if='stepValue == 3' style='margin-top: 8.125rem;'>
						<ApplyRefundFour />
					</div>
				</div>
			</div>
		</div>
		
		<!-- 页脚 -->
		<div class='SY-CONTENT' style='background-color: #333333;'>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Header from '../components/Header.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	import ApplyRefundFirst from '../components/ApplyRefundStep/ApplyRefundFirst.vue';
	import ApplyRefundSecond from '../components/ApplyRefundStep/ApplyRefundSecond.vue';
	import ApplyRefundThird from '../components/ApplyRefundStep/ApplyRefundThird.vue';
	import ApplyRefundFour from '../components/ApplyRefundStep/ApplyRefundFour.vue';
	
	export default {
		components: {
			Header,
			HomeFooter,
			ApplyRefundFirst,
			ApplyRefundSecond,
			ApplyRefundThird,
			ApplyRefundFour
		},
		data() {
			return {
				stepList: ['买家申请','卖家处理申请','买家退货','退款完毕'],
				stepValue: 0,
				isShowDialogBox:false,
			}
		},
		methods: {
			ck_goPage(path,type) {
				this.$router.push({path,query: type})
			},
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
		}
	}
</script>

<style scoped>
	.content {
		width: 75rem;
		background-color: #FFFFFF;
	}
	
	.BreadCrumbs {
		display: flex;
		align-items: center;
	}
	
	.breadText1 {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.breadText2 {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.step {
		display: flex;
		align-items: center;
	}
	
	.stepBox {
		display: flex;
		align-items: center;
	}
	
	.stepItem {
		width: 40px;
		height: 40px;
		background: #E0E0E0;
		font-size: 20px;
		font-family: TsangerYuYangT-W05, TsangerYuYangT;
		font-weight: normal;
		color: #FFFFFF;
		border-radius: 50%;
	}
	
	.stepLine {
		width: 202px;
		height: 1px;
		background: #EDEDED;
		margin: -1.75rem 0.9375rem 0 1.125rem;
	}
	
	.stepText1 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-top: 0.6875rem;
	}
</style>
