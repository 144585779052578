<template>
	<div class='StepSecond'>
		<div class='item'>
			<div class='text1'>
				<span style='color: #EE4454;'>*</span>
				店铺名称
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-input v-model="shopInfo.shopName" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div class='item' style='align-items: flex-start;'>
			<div class='text1'>
				<span style='color: #EE4454;'>*</span>
				上传店铺logo
			</div>
			<div style='margin-left: 2.1875rem;'>
				<label for="Logo">
					<img
						v-if='shopImgShow'
						style='width: 6.25rem;height: 6.25rem;margin-left: 2.125rem;cursor: pointer;' 
						:src="shopImgShow"
						alt=""
					>
					<img 
						v-else
						style='width: 6.25rem;height: 6.25rem;cursor: pointer;' 
						src="../../assets/images/sczj.png"
						alt=""
					>
				</label>
				<input
					class='input-img' 
					type="file" 
					id='Logo'
					@change="ce_upLoad_Logo"
					accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
				>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				<span style='color: #EE4454;'>*</span>
				经营类目
			</div>
			<div style='margin-left: 2.1875rem;display: flex;flex-wrap: wrap;width: 700px;'>
				<div 
					style='display: flex;align-items: center;cursor: pointer;margin-bottom: 6px;'
					v-for='(item,index) in categoryList'
					:key='index'
					@click='ck_categoryList(index)'
				>
					<img 
						v-if='item.active'
						style='width: 0.875rem;height: 0.875rem;' 
						src="../../assets/images/choice-active.png" alt=""
					>
					<img 
						v-else
						style='width: 0.875rem;height: 0.875rem;' 
						src="../../assets/images/choice.png" alt=""
					>
					<div 
						class='text1' 
						style='margin-left: 0.9375rem;width: auto;margin-right: 2.1875rem;'
					>
						{{item.catName}}
					</div>
				</div>
			</div>
		</div>
		<!-- <div class='item'>
			<div class='text1'>
				经营许可证号
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-input v-model="input" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				营业期限
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-input v-model="input" placeholder="请输入内容"></el-input>
			</div>
		</div> -->
		<div class='item'>
			<div class='text1'>
				客服QQ
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-input v-model="shopInfo.shopQQ" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<!-- <div class='item'>
			<div class='text1'>
				书营旺旺
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-input v-model="input" placeholder="请输入内容"></el-input>
			</div>
		</div> -->
		<div class='item'>
			<div class='text1'>
				是否提供发票
			</div>
			<div style='margin-left: 2.1875rem;display: flex;'>
				<div 
					style='display: flex;align-items: center;cursor: pointer;'
					@click='ck_radio(1)'
				>
					<div class='text1' style='width: auto;'>是</div>
					<img 
						v-if='shopInfo.isInvoice == 1'
						style='width: 1rem;height: 1rem;margin-left: 0.9375rem;'
						src="../../assets/images/radio_btn_selected@2x.png" 
						alt=""
					>
					<img
						v-else
						style='width: 1rem;height: 1rem;margin-left: 0.9375rem;'
						src="../../assets/images/radio_btn@2x.png" 
						alt=""
					>
				</div>
				<div 
					style='display: flex;align-items: center;margin-left: 3.4375rem;cursor: pointer;'
					@click='ck_radio(0)'
				>
					<div class='text1' style='width: auto;'>否</div>
					<img 
						v-if='shopInfo.isInvoice == 0'
						style='width: 1rem;height: 1rem;margin-left: 0.9375rem;'
						src="../../assets/images/radio_btn_selected@2x.png" 
						alt=""
					>
					<img
						v-else
						style='width: 1rem;height: 1rem;margin-left: 0.9375rem;'
						src="../../assets/images/radio_btn@2x.png" 
						alt=""
					>
				</div>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				<span style='color: #EE4454;'>*</span>
				营业状态
			</div>
			<div style='margin-left: 2.1875rem;display: flex;'>
				<div 
					style='display: flex;align-items: center;cursor: pointer;'
					@click='ck_radioStatus(1)'
				>
					<div class='text1' style='width: auto;'>营业中</div>
					<img 
						v-if='shopInfo.shopAtive == 1'
						style='width: 1rem;height: 1rem;margin-left: 0.9375rem;'
						src="../../assets/images/radio_btn_selected@2x.png" 
						alt=""
					>
					<img
						v-else
						style='width: 1rem;height: 1rem;margin-left: 0.9375rem;'
						src="../../assets/images/radio_btn@2x.png" 
						alt=""
					>
				</div>
				<div 
					style='display: flex;align-items: center;margin-left: 3.4375rem;cursor: pointer;'
					@click='ck_radioStatus(0)'
				>
					<div class='text1' style='width: auto;'>休息中</div>
					<img 
						v-if='shopInfo.shopAtive == 0'
						style='width: 1rem;height: 1rem;margin-left: 0.9375rem;'
						src="../../assets/images/radio_btn_selected@2x.png" 
						alt=""
					>
					<img
						v-else
						style='width: 1rem;height: 1rem;margin-left: 0.9375rem;'
						src="../../assets/images/radio_btn@2x.png" 
						alt=""
					>
				</div>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				所属行业
			</div>
			<div style='margin-left: 2.1875rem;'>
				<el-select v-model="shopInfo.tradeId" @change='ce_trade' placeholder="请选择">
					<el-option
						v-for="item in tradeList"
						:key="item.tradeId"
						:label="item.tradeName"
						:value="item.tradeId"
					>
					</el-option>
				</el-select>
			</div>
		</div>
		<div class='item'>
			<div class='text1'>
				年费
			</div>
			<div style='margin-left: 2.1875rem;'>
				<div class='text1' style='width: auto;display: flex;align-items: center;'>
					<span style='color: #EE4454;'>{{yearCost}}</span>
					元 
					<span style='color: #999999;font-size: 0.75rem;'>
						(年费为店铺所属行业的类目费用)
					</span>
				</div>
			</div>
		</div>
		<div class='btnBox' style='margin-left: 15.9375rem;'>
			<div 
				class='btn1 DIS-CENTER'
				@click='ck_onstep'
			>
				上一步
			</div>
			<div 
				class='btn2 DIS-CENTER' style='margin-left: 1.25rem;'
				@click='ck_upstep'
			>
				提交
			</div>
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	
	export default {
		props:['companyInfo'],
		data() {
			return {
				input: '',
				categoryList: [],
				radioValue: 1,
				radioValueStatus: 1,
				shopInfo: {
					shopName: '',
					shopImg: '',
					goodsCatIds: '',
					shopQQ: '',
					// shopWangWang: '',
					isInvoice: 1,
					shopAtive: 1,
					tradeId: 1,
				},
				shopImgShow: '',
				tradeList: [],
				yearCost: 0.00,
			}
		},
		created() {
			this.initPageData();
		},
		methods: {
			initPageData() {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					type: 1
				}
				this.axios.post(Api.joinStepNext,data).then(res=>{
					if(res.data.code == 200) {
						this.categoryList = res.data.data.goodsCatIds;
						this.tradeList = res.data.data.tradeId;
						this.yearCost = this.tradeList[0].tradeFee;
						for(let i in this.categoryList) {
							this.$set(this.categoryList[i],'active',false);
						}
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			ck_categoryList(index) {
				this.categoryList[index].active = !this.categoryList[index].active;
				this.shopInfo.goodsCatIds = [];
				for(let i in this.categoryList) {
					if(this.categoryList[i].active) {
						this.shopInfo.goodsCatIds.push(this.categoryList[i].catId);
					}
				}
			},
			ck_radio(value) {
				this.shopInfo.isInvoice = value;
			},
			ck_radioStatus(value) {
				this.shopInfo.shopAtive = value;
			},
			ck_onstep() {
				this.$emit('step', 1)
				// this.$emit('',this.$props.companyInfo)
			},
			ck_upstep() {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					type: 2
				}
				for(let key in this.$props.companyInfo) {
					data[key] = this.$props.companyInfo[key];
				}
				for(let key in this.shopInfo) {
					data[key] = this.shopInfo[key];
				}
				this.axios.post(Api.setSettlement,data).then(res=>{
					if(res.data.code == 200) {
						this.$emit('step', 3)
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			ce_trade(value) {
				this.yearCost = this.tradeList[value-1].tradeFee;
			},
			// 上传
			ce_upLoad_Logo(value) {
				const file = value.srcElement.files[0];
				if(!file) {return}
				let data = new FormData();
				data.append('file',file);
				this.axios.post(
					Api.uploadFile,
					data,
				).then(res=>{
					if(res.data.code ==200) {
						this.shopInfo.shopImg = res.data.data.file_path;
						this.shopImgShow = Api.URL + '/' + res.data.data.file_path;
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
		}
	}
</script>

<style scoped>
	.StepSecond {
		width: 62.5rem;
	}
	
	.item {
		display: flex;
		align-items: center;
		margin-left: 4.6875rem;
		margin-bottom: 1.875rem;
	}
	
	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		width: 9.375rem;
		display: flex;
		justify-content: flex-end;
	}
	
	.StepSecond>>>.el-input__inner{
		width: 340px;
		height: 44px;
		border: 1px solid #DDDDDD;    
		border-radius: 0;
	} 
	
	.btnBox {
		display: flex;
		align-items: center;
	}
	
	.btn1 {
		width: 223px;
		height: 50px;
		background: #F4F4F4;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
	}
	
	.btn2 {
		width: 223px;
		height: 50px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
	}
	
	.input-img {
		display: none;
	}
</style>
