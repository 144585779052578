<template>
	<div class='Agreement'>
		<img 
			class='close-img' 
			src="../../assets/images/close@2x.png" alt=""
			@click='ck_goPage("/Login")'
		>
		<!-- <div class='title'>
			{{agreementTitle}}
		</div> -->
		<div class='contentBox' v-html='agreementContent'></div>
		<div class='DIS-CENTER'>
			<div 
				class='btn DIS-CENTER' 
				style='color: #000000;background-color: #FFFFFF;border: 1px solid #E8E8E8;'
				@click='ck_goPage("/Login")'
			>
				不同意
			</div>
			<div 
				class='btn DIS-CENTER' 
				style='margin-left: 1.875rem;'
				@click='ck_isAgreement'
			>
				同 意
			</div>
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	
	export default {
		data() {
			return {
				agreementTitle: '',
				agreementContent: '',
			}
		},
		created() {
			this.initData();
		},
		methods: {
			initData() {
				const data = {
					articleId: 15
				}
				this.axios.post(Api.getArticle,data).then(res=>{
					if(res.data.code == 200) {
						this.agreementTitle = res.data.data.articleTitle
						this.agreementContent = res.data.data.articleContent
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			ck_isAgreement() {
				this.$emit('ckShowAgreement',false);
			},
			ck_goPage(path) {
				this.$router.push({path})
			}
		}
	}
</script>

<style scoped>
	.Agreement {
		width: 48.125rem;
		height: 41rem;
		background-color: #FFFFFF;
		padding: 2.4375rem 2.375rem 0 2.375rem ;
		
	}
	
	.title {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.close-img {
		width: 1.125rem;
		height: 1.125rem;
		position: absolute;
		top: 1.4375rem;
		right: 1.75rem;
		cursor: pointer;
	}
	
	.contentBox {
		width: 100%;
		height: 37rem;
		margin-bottom: 1.25rem;
		overflow-y: auto;
	}
	
	.btnBox {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.btn {
		width: 70px;
		height: 30px;
		background: #EE4454;
		border-radius: 2px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
	}
</style>
