<template>
	<div class='ApplyRefund'>
		<div>
			<Header bgIndex='4' @dialogBoxIndex="dialogBoxIndex" />
		</div>

		<!-- 内容区 -->
		<div class='SY-CONTENT' style='background-color: #F5F5F5;'>
			<div class='content'>
				<!-- 面包屑 -->
				<div class='BreadCrumbs' style='margin: 31px 0 0 2.4375rem;'>
					<div class='breadText1' style='cursor: pointer;' @click='ck_goPage("/PersonalCenter")'>
						主页
					</div>
					<div class='breadText1' style='margin: 0 1.5625rem;'>/</div>
					<div class='breadText1' style='cursor: pointer;' @click='ck_goPage("/PersonalCenter",{type: 2})'>
						已买到的宝贝
					</div>
					<div class='breadText1' style='margin: 0 1.5625rem;'>/</div>
					<div class='breadText2' style='cursor: pointer;'>评价</div>
				</div>

				<!-- 主题内容 -->
				<div class='contentBox'>
					<div style='padding: 1.875rem 1.875rem 19px 1.875rem;'>
						<div style='display: flex; margin-left: 119px;'>
							<img style='width: 5rem;height: 5rem;border: 1px solid #E6E6E6;'
								src="../assets/images/homeseconddemo.png" alt="">
							<div
								style='display: flex;flex-direction: column;justify-content: space-around;margin-left: 1rem;'>
								<div class='text1' style='width: 20rem;'>
									价格的发现复杂约束市场中的拍卖设计（全新修订版 精装）上海人民出版社
								</div>
								<div class='text2'>￥59.00</div>
							</div>
						</div>
					</div>
				</div>

				<!-- 常见问题 -->
				<div class='contentBox DIS-CENTER' style='padding-bottom: 6.4375rem;'>
					<div class='content-problem'>
						<div style='margin-left: 24px;margin-top: 26px;'>
							<div class="scoreItem" v-for="(item,index) in scoreList" :key="index"
								:style='index==0?"margin-top: 25px;":"margin-top: 15px"'>
								<div class="text6">{{item.text}}</div>
								<div v-for="(item1,index1) in item.allScore" :key="index1">
									<img class="img6" :src="item.stat1>index1?ming:anStart"
										@click="ck_start(index,index1)" style="margin-left: 7px;" />
								</div>

								<!-- <div class="text7">{{item.textArr[item.stat1-1]}}</div> -->
							</div>


							<!-- 评价内容 -->
							<div class="scoreItem" style="margin-top: 15px;">
								<div class="text6" style="margin-right: 5px;">评价内容：</div>
								<el-input resize="none" type="textarea" placeholder="给个好评吧~" v-model="textarea1" maxlength="100" show-word-limit></el-input>
							</div>
							
							<div style="margin-left: 65px;display: flex;margin-top: 21px;">
								<div class="DIS-CENTER text5" style="background-color: #EE4454;color: #FFFFFF;">评价</div>
								<div class="DIS-CENTER text5" style="background-color: #FAFAFA;color: #333333; border: 1px solid #E6E6E6;margin-left: 10px;">晒图</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 页脚 -->
		<div class='SY-CONTENT' style='background-color: #333333;'>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Header from '../components/Header.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	import img1 from '../assets/images/collect.png';
	import img2 from '../assets/images/is_collect.png';

	export default {
		data() {
			return {
				ming: img2,
				anStart: img1,
				textarea1:'',
				isShowDialogBox:false,
				scoreList: [{
						text: '综合评价：',
						allScore: 5,
						textArr: ['很不满意', '不满意', '一般', '满意', '非常满意'],
						stat1: 5, // 几星
					},
					{
						text: '描述相符：',
						allScore: 5,
						textArr: ['很不满意', '不满意', '一般', '满意', '非常满意'],
						stat1: 5,
					},
					{
						text: '物流服务：',
						allScore: 5,
						textArr: ['很不满意', '不满意', '一般', '满意', '非常满意'],
						stat1: 5,
					},
					{
						text: '服务态度：',
						allScore: 5,
						textArr: ['很不满意', '不满意', '一般', '满意', '非常满意'],
						stat1: 5,
					}
				],
			}
		},
		components: {
			Header,
			HomeFooter
		},
		created() {
			console.log(this.anStart)
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			ck_goPage(path, type) {
				this.$router.push({
					path,
					query: type
				})
			},
			ck_start(index, index1) {
				this.scoreList[index].stat1 = index1 + 1
			},
		}
	}
</script>

<style scoped>
	.content {
		width: 75rem;
		background-color: #FFFFFF;
	}

	.BreadCrumbs {
		display: flex;
		align-items: center;
	}

	.breadText1 {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.breadText2 {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.contentBox {
		width: 75rem;
	}

	.text1 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.text2 {
		font-size: 14px;
		font-family: AlibabaPuHuiTiH;
		color: #333333;
		line-height: 18px;
		font-weight: 600;
	}

	.refund {
		width: 400px;
		height: 114px;
		border: 1px solid #E6E6E6;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.text3 {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.text4 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}

	.content-problem {
		width: 56.25rem;
		height: 17.125rem;
		background-color: #FAFAFA;
	}

	.title {
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.scoreItem {
		display: flex;
		align-items: center;
	}

	.text6 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.img6 {
		width: 16px;
		height: 16px;
	}
	
	.scoreItem .el-textarea{
		width: 250px !important;
	}
	
	.scoreItem .el-textarea{
		font-size: 12px !important;
	}
	
	.scoreItem .el-textarea .el-textarea__inner{
		font-size: 12px !important;
	}
	
	.text5{
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		border-radius: 2px;
		padding: 6px 18px;
	}
</style>
