<template>
	<div class='CloseAnAccount'>
		<div>
			<Header bgIndex='4' @dialogBoxIndex="dialogBoxIndex" />
		</div>

		<!-- 内容 -->
		<div class='SY-CONTENT'>
			<div class='content'>
				<!-- 面包屑 -->
				<div class='BreadCrumbsBox'>
					<div class='BreadCrumbs-text1' @click='ck_goPage("/PersonalCenter")'>
						主页
					</div>
					<div class='BreadCrumbs-text1' style='cursor: none;margin: 0 1.5625rem;'>/</div>
					<div class='BreadCrumbs-text1' @click='ck_goPage("/PersonalCenter",{type: 9})'>
						账户设置
					</div>
					<div class='BreadCrumbs-text1' style='cursor: none;margin: 0 1.5625rem;'>/</div>
					<div class='BreadCrumbs-text2'>添加银行卡</div>
				</div>

				<div class='DIS-CENTER'>
					<div style='margin-top: 62px;'>
						<div class='EditMessageFirst'>
							<div class='itemBox'>
								<div class='text2'>开户行</div>
								<div class='phoneBox'>
									<!-- <el-input v-model="bank"></el-input> -->
									<el-select v-model="bankId" placeholder="请选择" style="width: 340px;">
										<el-option v-for="item in bankCardList" :key="item.index" :label="item.bankName"
											:value="item.bankId">
										</el-option>
									</el-select>
								</div>
							</div>
							<div class='itemBox' style="margin-top: 30px;">
								<div class='text2'>真实姓名</div>
								<div class='phoneBox'>
									<el-input v-model="realName"></el-input>
								</div>
							</div>
							<div class='itemBox' style="margin-top: 30px;">
								<div class='text2'>身份证号</div>
								<div class='phoneBox'>
									<el-input v-model="idCard"></el-input>
								</div>
							</div>
							<div class='itemBox' style="margin-top: 30px;">
								<div class='text2'>卡号</div>
								<div class='phoneBox'>
									<el-input v-model="cardNumber"></el-input>
								</div>
							</div>
							<div class='itemBox' style="margin-top: 30px;">
								<div class='text2'>预留手机号</div>
								<div class='phoneBox'>
									<el-input v-model="phoneNumber"></el-input>
								</div>
							</div>

							<!-- pc版 -->
							<!-- <div class='itemBox' style="margin-top: 30px;">
								<div class='text2'>验证码</div>
								<div class='codeBox'>
									<el-input v-model="code" placeholder="请输入验证码"></el-input>
									<div class='text2 DIS-CENTER'
										style='width: 6rem;margin-right: 0.8125rem;cursor: pointer;' @click='ck_code'>
										{{codeText}}
									</div>
								</div>
							</div> -->
							<div class='btn DIS-CENTER' @click='ck_step'>
								绑定
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 页脚 -->
		<div class='SY-CONTENT' style='background-color: #333333;'>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Header from '../components/Header.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	import Api from '../request/api.js';

	export default {
		components: {
			Header,
			HomeFooter,
		},
		data() {
			return {
				phone: '',
				code: '', // 验证码
				codeText: '获取验证码',
				timer: null,
				userInfo: {},
				setEmail: '',
				newUserPhone: '',
				bank: '',
				cardNumber: '',
				phoneNumber: '',
				bankCardList: [],
				bankId: '',
				realName: '',
				idCard: '',
				isShowDialogBox:false,
			}
		},
		mounted() {
			this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
			this.newUserPhone = this.userInfo.userPhone.substr(0, 3) + '****' + this.userInfo.userPhone.substr(7);
		},
		created() {
			this.initData()
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			initData() {
				this.axios.post(Api.getBankList).then(res => {
					if (res.data.code == 200) {
						this.bankCardList = res.data.data;
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			ck_goPage(path, query) {
				this.$router.push({
					path,
					query
				});
			},
			// ck_code() {
			// 	if (!this.phoneNumber) {
			// 		this.$message.warning('请输入预留手机号')
			// 		return
			// 	}
			// 	if (!(/(^1[3|4|5|7|8|9]\d{9}$)|(^09\d{8}$)/.test(this.phoneNumber))) {
			// 		this.$message.warning('请输入正确的手机号');
			// 		return
			// 	}
			// 	if (this.codeText == '获取验证码') {
			// 		const data = {
			// 			phone: this.phoneNumber,
			// 		}
			// 		this.axios.post(Api.sendPhoneCode, data).then(res => {
			// 			if (res.data.code == 200) {
			// 				this.$message.success('验证码已发送')
			// 			} else {
			// 				this.$message.error('验证码发送失败')
			// 			}
			// 		})
			// 		this.codeText = 60;
			// 		this.timer = setInterval(() => {
			// 			this.codeText = this.codeText - 1;
			// 			if (this.codeText < 1) {
			// 				window.clearInterval(this.timer);
			// 				this.codeText = '获取验证码'
			// 			}
			// 		}, 1000)
			// 	}
			// },
			// 判断是否是银行卡号
			checkBank(bankno) {
				let lastNum = bankno.substr(bankno.length - 1, 1) // 取出最后一位（与luhm进行比较）
				let first15Num = bankno.substr(0, bankno.length - 1) // 前15或18位
				let newArr = []
				for (let i = first15Num.length - 1; i > -1; i--) { // 前15或18位倒序存进数组
					newArr.push(first15Num.substr(i, 1))
				}
				let arrJiShu = [] // 奇数位*2的积 < 9
				let arrJiShu2 = [] // 奇数位*2的积 >9

				let arrOuShu = [] // 偶数位数组
				for (let j = 0; j < newArr.length; j++) {
					if ((j + 1) % 2 === 1) { // 奇数位
						if (parseInt(newArr[j]) * 2 < 9) {
							arrJiShu.push(parseInt(newArr[j]) * 2)
						} else {
							arrJiShu2.push(parseInt(newArr[j]) * 2)
						}
					} else {
						arrOuShu.push(newArr[j])
					} // 偶数位
				}
				let jishuChild1 = [] // 奇数位*2 >9 的分割之后的数组个位数
				let jishuChild2 = [] // 奇数位*2 >9 的分割之后的数组十位数
				for (let h = 0; h < arrJiShu2.length; h++) {
					jishuChild1.push(parseInt(arrJiShu2[h]) % 10)
					jishuChild2.push(parseInt(arrJiShu2[h]) / 10)
				}
				let sumJiShu = 0 // 奇数位*2 < 9 的数组之和
				let sumOuShu = 0 // 偶数位数组之和
				let sumJiShuChild1 = 0 // 奇数位*2 >9 的分割之后的数组个位数之和
				let sumJiShuChild2 = 0 // 奇数位*2 >9 的分割之后的数组十位数之和
				let sumTotal = 0
				for (let m = 0; m < arrJiShu.length; m++) {
					sumJiShu = sumJiShu + parseInt(arrJiShu[m])
				}
				for (let n = 0; n < arrOuShu.length; n++) {
					sumOuShu = sumOuShu + parseInt(arrOuShu[n])
				}
				for (let p = 0; p < jishuChild1.length; p++) {
					sumJiShuChild1 = sumJiShuChild1 + parseInt(jishuChild1[p])
					sumJiShuChild2 = sumJiShuChild2 + parseInt(jishuChild2[p])
				}
				// 计算总和
				sumTotal = parseInt(sumJiShu) + parseInt(sumOuShu) + parseInt(sumJiShuChild1) + parseInt(sumJiShuChild2)
				// 计算Luhm值
				let k = parseInt(sumTotal) % 10 === 0 ? 10 : parseInt(sumTotal) % 10
				let luhm = 10 - k
				if (Number(lastNum) === luhm && lastNum.length !== 0 && bankno.length > 11) {
					return true
				} else {
					return false
				}
			},
			ck_step() {
				// 判断开户行是否为空   
				if (!this.bankId) {
					this.$message.error('开户行不能为空')
					return;
				}

				if (!this.realName) {
					this.$message.error('真实姓名不能为空')
					return;
				}

				if (!this.idCard) {
					this.$message.error('身份证号不能为空')
					return;
				} else {
					var regIdNo = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
					if (!regIdNo.test(this.idCard)) {
						this.$message.error('身份证号填写有误')
						return;
					}
				}

				// 判断 银行卡号是否正确
				if (!this.cardNumber) {
					this.$message.error('银行卡号不能为空')
					return;
				} else {
					if (!this.checkBank(this.cardNumber)) {
						this.$message.error('请输入正确的银行卡号')
						return;
					}
				}

				const telStr =
					/^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/;
				if (!(telStr.test(this.phoneNumber))) {
					this.$message.error('请输入正确的手机号')
					return;
				}

				const data = {
					loginid: localStorage.getItem('shuying-token'),
					accNo: this.cardNumber, //卡号
					accUser: this.realName, //  持卡人 
					bank_phone: this.phoneNumber, // 预留手机号
					bank_card: this.idCard, // 身份证号
					accAreaId: '',
					accTargetId: this.bankId,
				}
				
				this.axios.post(Api.addBank, data).then(res => {
					if (res.data.code == 200) {
						this.$message.success('添加成功')
						
						// 添加成功后的跳转
						// this.$router.push({
						// 	path: '/Login'
						// })
					} else {
						this.$message.error(res.data.msg)
					}
				})


				// const data = {
				// 	loginid: localStorage.getItem('shuying-token'),
				// 	phone: this.userInfo.userPhone,
				// 	code: this.code,
				// }
				// this.axios.post(Api.cancellation, data).then(res => {
				// 	if (res.data.code == 200) {
				// 		this.$message.success('注销成功')
				// 		localStorage.removeItem('shuying-token');
				// 		localStorage.removeItem('userInfo');
				// 		this.$router.push({
				// 			path: '/Login'
				// 		})
				// 	} else {
				// 		this.$message.error(res.data.msg)
				// 	}
				// })
			}
		}
	}
</script>

<style scoped>
	.CloseAnAccount {
		background: #F5F5F5;
	}

	.content {
		width: 75rem;
		padding-top: 1.875rem;
		min-height: 71.5625rem;
		background-color: #FFFFFF;
	}

	.BreadCrumbsBox {
		display: flex;
		align-items: center;
		margin-left: 2.4375rem;
	}

	.BreadCrumbs-text1 {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
	}

	.BreadCrumbs-text2 {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		cursor: pointer;
	}


	/* 	.EditMessageFirst {
		width: 500px;
	} */

	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.itemBox {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.text2 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.phoneBox {
		width: 340px;
		height: 44px;
		/* border: 1px solid #DDDDDD; */
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		line-height: 2.75rem;
		margin-left: 1.875rem;
	}

	.codeBox {
		width: 21.25rem;
		height: 2.75rem;
		border: 1px solid #DDDDDD;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: 1.875rem;
	}

	.codeBox>>>.el-input__inner {
		width: 200px;
		border: none;
	}

	.btn {
		width: 120px;
		height: 44px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
		margin-top: 2.0625rem;
		margin-left: 160px;
	}
</style>
