<template>
	<div class='ContentLeftImg'>
		<div class='top DIS-CENTER'>
			<img class='img1' :src="goodsImg[showImgIndex]" alt="">
			<img 
				class='search' 
				src="../../assets/images/contentLeftImg-search.png" alt=""
				@click='showContentImg = true'
			>
		</div>
		<div class='bottom'>
			<div 
				:class="index == 0 ? 'img-box1':'img-box'" 
				v-for='(item,index) in goodsImg' :key='index'
				@click='ck_imgIndex(index)'
			>
				<img style='width: 100%;height: 100%;' :src="item" alt="">
			</div>
		</div>
		<!-- 显示大图 -->
		<div v-if='showContentImg' @click='showContentImg = false'>
			<div class='contentImg'>
				<img style='width: 100%;height: 100%;' :src="goodsImg[showImgIndex]" alt="">
			</div>
			<div class='shade'></div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['goodsImg'],
		data() {
			return {
				showImgIndex: 0,
				showContentImg: false,
			}
		},
		methods: {
			ck_imgIndex(index) {
				this.showImgIndex = index;
			}
		}
	}
</script>

<style scoped>
	.ContentLeftImg {
		width: 22.5rem;
	}
	
	.ContentLeftImg .top {
		width: 22.375rem;
		height: 22.5rem;
		border: 1px solid #E6E6E6;
		position: relative;
	}
	
	.top .search {
		width: 2.5rem;
		height: 2.5rem;
		position: absolute;
		right: 0;
		top: 20rem;
		cursor: pointer;
	}
	
	.ContentLeftImg .bottom {
		margin-top: 0.9375rem;
		display: flex;
	}
	
	.bottom .img-box {
		width: 3.75rem;
		height: 3.75rem;
		background: #DACECB;
		margin-left: 0.9375rem;
		cursor: pointer;
	}
	
	.bottom .img-box1 {
		width: 3.75rem;
		height: 3.75rem;
		background: #DACECB;
		cursor: pointer;
	}
	
	.img1 {
		width: 100%;
		height: 100%;
	}
	
	.contentImg {
		width: 45rem;
		height: 45rem;
		position: fixed;
		top: 6.25rem;
		left: 50%;
		margin-left: -22.5rem;
		z-index: 9;
	}
	
	.shade {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		right: 0;
		z-index: 8;
		background: rgba(0, 0, 0, 0.2);
	}
</style>
