<template>
	<div class='PersonalIsBuy'>
		<!-- 头部 -->
		<div style='display: flex;justify-content: space-between;'>
			<div 
				:class="index==0?'HeaderItem':'HeaderItem1'"
				v-for='(item,index) in menuList'
				:key='index'
				@click='ck_menu(index)'
			>
				<div v-if='item.active' style='color: #EE4454;'>{{item.text}}</div>
				<div v-else style='color: #333333;'>{{item.text}}</div>
				<div 
					class='line'
					v-if='item.active'
				></div>
				<div
					class='line1'
					v-else
				></div>
			</div>
			<div style='width: 400px;'></div>
			<!-- <div style='display: flex;'>
				<div style='display: flex;'>
					<div style='width: 12.125rem;height: 1.75rem;'>
						<el-input v-model="input" placeholder="请输入商品标题或订单号"></el-input>
					</div>
					<div class='seathBtm DIS-CENTER'>
						搜索
					</div>
				</div>
				<div style='display: flex;align-items: center;margin-left: 2rem;cursor: pointer;'>
					<img
						style='width: 0.75rem;height: 0.75rem;'
						src="../../assets/PersonalCenter/del-img.png" alt=""
					>
					<div class='text1' style='margin-left: 0.25rem;margin-right: 0.9375rem;'>订单回收站</div>
				</div>
			</div> -->
		</div>
	
		<!-- 表头 -->
		<div class='tableHeader DIS-CENTER' style='justify-content: flex-start;'>
			<div class='text2' style='margin-left: 11.75rem;'>
				商品信息
			</div>
			<div class='text2' style='margin-left: 11.6875rem;'>
				单价
			</div>
			<div class='text2' style='margin-left: 2.4375rem;'>
				数量
			</div>
			<div class='text2' style='margin-left: 1.5625rem;'>
				实付款
			</div>
			<div class='text2' style='margin-left: 3.5rem;'>
				总计
			</div>
			<div class='text2' style='margin-left: 4.5rem;'>
				订单状态
			</div>
			<div class='text2' style='margin-left: 4.4375rem;'>
				操作
			</div>
		</div>
	
		<!-- <div style='display: flex;justify-content: space-between;align-items: center;margin-top: 1.1875rem;'>
			<div 
				style='display: flex;align-items: center;margin-left: 2.25rem;cursor: pointer;'
				@click='ck_allChoose'
			>
				<img
					v-if='allChoose'
					style='width: 0.875rem;height: 0.875rem;'
					src="../../assets/images/choice-active.png" 
					alt=""
				>
				<img 
					v-else
					style='width: 0.875rem;height: 0.875rem;'
					src="../../assets/images/choice.png" 
					alt=""
				>
				<div class='text1' style='margin-left: 0.5625rem;'>全选</div>
			</div>
			<div class='DIS-CENTER'>
				<div class='paging DIS-CENTER'>上一页</div>
				<div 
					class='paging DIS-CENTER'
					style='margin-left: 0.625rem;margin-right: 1.125rem;'
				>
					下一页
				</div>
			</div>
		</div> -->
	
		<!-- table -->
		<div 
			v-show='total > 0'
			style='margin-top: 1.25rem;margin-left: 1.125rem;'
			v-for='(item,index) in tableData'
			:key='index'
		>
			<div class='tableItem-Header'>
				<div style='display: flex;align-items: center;'>
					<!-- <img
						v-if='item.active'
						style='width: 0.875rem;height: 0.875rem;margin-left: 1.125rem;' 
						src="../../assets/images/choice-active.png" alt=""
						@click='ck_choose(false,index)'
					>
					<img
						v-else
						style='width: 0.875rem;height: 0.875rem;margin-left: 1.125rem;' 
						src="../../assets/images/choice.png" alt=""
						@click='ck_choose(true,index)'
					> -->
					<div class='text1' style='margin-left: 1.1875rem;'>订单号：{{item.orderNo}}</div>
					<div class='text2' style='margin-left: 2.5rem;'>
						店铺：
						<span class='text1'>
							{{item.shopName}}
						</span>
					</div>
					<img
						style='width: 0.75rem;height: 0.75rem;margin-left: 1.25rem;'
						src="../../assets/images/contentRIghtBox-kf.png" alt=""
					>
				</div>
				<div style='display: flex;align-items: center;'>
					<div class='text1' style='margin-right: 1rem;'>{{item.createTime}}</div>
					<img 
						style='width: 0.75rem;height: 0.75rem;margin-right: 1.125rem;' 
						src="../../assets/PersonalCenter/del-img.png" alt=""
					>
				</div>
			</div>
			
			<div style='display: flex;'>
				<div>
					<!-- 点击跳转详情页 /OrderDetails -->
					<div
						style='display: flex;width: 37.5rem;cursor: pointer;'
						v-for='(item1,index1) in item.list'
						:key='index1'
					>
						<div style='display: flex;width: 43.125rem;border: 1px solid #E6E6E6;height: 6.0625rem;padding-top: 1.0625rem;border-top: none;' @click="ck_goPage('/OrderDetails',item1.orderId)">
							<img
								style='width: 80px;height: 80px;border: 1px solid #E6E6E6;margin-left: 1.1875rem;cursor: pointer;'
								:src="item1.goodsImg" 
								alt=""
							>
							<div class='text1' style='width: 15.4375rem;height: 2.25rem;margin-left: 1.0625rem;cursor: pointer;'>
								{{item1.goodsName}}
							</div>
							<div style='margin-left: 50px;margin-top: 0.625rem;'>
								<div 
									class='text1' 
									style='font-size: 0.875rem;color: #999999;text-decoration: line-through;'
								>
									￥{{item1.marketPrice}}
								</div>
								<div class='text1' style='font-size: 0.875rem;'>￥{{item1.goodsPrice}}</div>
							</div>
							<div class='text1' style='font-size: 0.875rem;margin-top: 1.5rem;width: 20px;margin-left: 20px;'>
								{{item1.goodsNum}}
							</div>
							<div class='text1' style='font-size: 0.875rem;margin-top: 1.5rem;width: 60px;margin-left: 10px;'>
								￥{{item1.goodsPrice}}
							</div>
						</div>
					</div>
				</div>
				
				
				<div style='width: 20rem;border-right: 1px solid #EDEDED;border-bottom: 1px solid #EDEDED;display: flex;'>
					<div 
						class='text2' 
						style='font-weight: bold;font-size: 0.875rem;height: 100%;display: flex;align-items: center;margin-left: 1.25rem'
					>￥{{item.totalMoney}}</div>
					
					
					<!-- 待付款 -->
					<div v-if='item.orderStatus == -2'
						style='display: flex;align-items: center;height: 100%;margin-left: 1.25rem;'>
						<div class='text1'>
							<div>买家已下单</div>
							<div style='margin-top: 1rem;cursor: pointer;' @click='ck_goPage("/OrderDetails",item.orderId)'>订单详情</div>
						</div>
						<div style='display: flex;flex-direction: column;align-items: flex-end;margin-left: 2.875rem;'>
							<div style='display: flex;align-items: center;'>
								<img
									style='width: 0.75rem;height: 0.75rem;' 
									src="../../assets/PersonalCenter/time@2x.png" alt=""
								>
								<div class='text1' style='margin-left: 0.625rem;'>
									{{item.endTime}}
								</div>
							</div>
							<div class='tableBtn DIS-CENTER' style='margin-top: 0.75rem;' @click='ck_goPage("/Payment",item.pkey)'>
								待付款
							</div>
						</div>
					</div>
					
					<!-- 待发货 -->
					<div v-if='item.orderStatus == 0'
						style='display: flex;align-items: center;height: 100%;margin-left: 1.25rem;'>
						<div class='text1'>
							<div>买家已付款</div>
							<div style='margin-top: 1rem;cursor: pointer;' @click='ck_goPage("/OrderDetails",item.orderId)'>订单详情</div>
						</div>
						<div style='display: flex;flex-direction: column;align-items: flex-end;margin-left: 2.875rem;'>
							<div 
								class='tableBtn DIS-CENTER' 
								style='margin-top: 0.75rem;'
								@click='ck_goPage("/ApplyRefund")'
							>
								申请退款
							</div>
						</div>
					</div>
								
					<!-- 待收货 -->
					<div v-if='item.orderStatus == 1'
						style='display: flex;align-items: center;height: 100%;margin-left: 1.25rem;'>
						<div class='text1'>
							<div>买家已下单</div>
							<div style='margin-top: 1rem;cursor: pointer;' @click='ck_goPage("/OrderDetails",item.orderId)'>订单详情</div>
							<div style='margin-top: 1rem;cursor: pointer;'>查看物流</div>
						</div>
						<div style='display: flex;flex-direction: column;align-items: flex-end;margin-left: 2.875rem;'>
							<div class='tableBtn DIS-CENTER' style='margin-top: 0.75rem;'>
								确认收货
							</div>
							<div 
								class='text1' 
								style='margin-top: 0.5625rem;margin-right: 0.625rem;cursor: pointer;'
								@click='ck_goPage("/ApplyRefund")'
							>
								申请退款
							</div>
						</div>
					</div>
								
					<!-- 待评价 -->
					<div v-if='item.orderStatus == 2'
						style='display: flex;align-items: center;height: 100%;margin-left: 1.25rem;'>
						<div class='text1'>
							<div>买家已下单</div>
							<div style='margin-top: 1rem;cursor: pointer;' @click='ck_goPage("/OrderDetails",item.orderId)'>订单详情</div>
							<div style='margin-top: 1rem;cursor: pointer;'>查看物流</div>
						</div>
						<div style='display: flex;flex-direction: column;align-items: flex-end;margin-left: 2.875rem;'>
							<div class='tableBtn DIS-CENTER' style='margin-top: 0.75rem;'>
								待评价
							</div>
							<div class='text1' style='margin-top: 0.5625rem;margin-right: 0.625rem;cursor: pointer;'>
								退款售后
							</div>
						</div>
					</div>
								
					<!-- 交易成功 -->
					<div v-if='item.isAppraise == 1'
						style='display: flex;align-items: center;height: 100%;margin-left: 1.25rem;'>
						<div class='text1'>
							<div>买家已下单</div>
							<div style='margin-top: 1rem;cursor: pointer;'>订单详情</div>
							<div style='margin-top: 1rem;cursor: pointer;'>查看物流</div>
						</div>
						<div class='text1' style='margin-left: 4.6875rem;'>
							<div style='cursor: pointer;'>再次购买</div>
							<div style='margin-top: 1rem;cursor: pointer;'>申请开票</div>
							<div style='margin-top: 1rem;cursor: pointer;'>退款售后</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>
	
		<div v-if='total > 0' class='sorter'>
			<el-pagination
				background
				layout="pager"
				:total="total"
				@current-change='editPage'
				:page-size='20'
			>
			</el-pagination>
		</div>
		
		<div style='margin-top: 1.25rem;' v-if="!tableData.length">
			<NoneItem title='您还没有购买过商品哦~' text='请先去选择您心仪的商品吧'/>
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	import NoneItem from '../NoneItem.vue';
	
	export default {
		props: ['isBuyMenuValue'],
		components: { NoneItem },
		data() {
			return {
				menuList: [
					{
						text: '全部订单',
						active: true,
					},
					{
						text: '待付款',
						active: false,
					},
					{
						text: '待发货',
						active: false,
					},
					{
						text: '待收货',
						active: false,
					},
					{
						text: '待评价',
						active: false,
					},
				],
				menuListValue: 0,
				input: '',
				tableData: [], // 列表数据
				allChoose: false,
				total: 0,
				status: 3, // 订单状态（全部订单）
			}
		},
		mounted() {
			this.ck_menu(this.isBuyMenuValue);
			this.initData(this.status,1)
		},
		methods: {
			initData(status,page) {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					page,
					status
				}
				this.axios.post(Api.orderList,data).then(res=>{
					if(res.data.code == 200) {
						this.tableData = res.data.data.page;
						this.total = res.data.data.count;
						for(let i in this.tableData) {
							this.tableData[i].endTime = this.setTime(this.tableData[i].createTime)
							for(let j in this.tableData[i].list) {
								this.tableData[i].list[j].goodsImg = `${Api.URL}/${this.tableData[i].list[j].goodsImg}`
							}
						}
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			ck_menu(index) {
				for(let i in this.menuList) {
					this.menuList[i].active = false;
				}
				this.menuList[index].active = true;
				this.menuListValue = index;
				if(index == 0) {
					this.status = 3;
				} else if(index == 1) {
					this.status = -2;
				} else if(index == 2) {
					this.status = 0;
				} else if(index == 3) {
					this.status = 1;
				} else if(index == 4) {
					this.status = 2;
				} 
				this.initData(this.status,1)
			},
			ck_choose(bool,index) {
				this.tableData[index].active = bool;
				for(let i in this.tableData) {
					if(!this.tableData[i].active) {
						this.allChoose = false;
						return; 
					}
				}
				this.allChoose = true;
			},
			// 全选按钮
			// ck_allChoose() {
			// 	this.allChoose = !this.allChoose;
			// 	for(let i in this.tableData) {
			// 		this.tableData[i].active = this.allChoose;
			// 	}
			// },
			ck_goPage(path,id) {
				this.$router.push({path,query:{pkey: id}});
			},
			setTime(time) {
				let data = new Date(time);
				let time1 = Date.parse(data) / 1000;
				time1 = time1 + 86400; // 订单结束的时间
				let data1 = new Date();
				let time2 = Date.parse(data1) / 1000;
				let time3 = time1 - time2; // 等到剩余时间的时间戳
				let hour = parseInt(time3/60/60);
				let minute =  parseInt(time3/60%60);
				if(minute < 10) {
					minute = '0' + minute;
				}
				return `还剩下${hour}:${minute}分`
			},
			editPage(value) {
				this.initData(this.status,value);
			}
		}
	}
</script>

<style scoped>
	.PersonalIsBuy {
		background-color: #FFFFFF;
		width: 59.625rem;
		padding-top: 1.125rem;
		min-height: 73.4375rem;
	}
	
	.PersonalIsBuy .HeaderItem {
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		margin-left: 1.6875rem;
		margin-right: 2.5rem;
	}
	
	.PersonalIsBuy .HeaderItem1 {
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		margin-right: 2.5rem;
	}
	
	.PersonalIsBuy .line {
		width: 28px;
		height: 4px;
		background: #EE4454;
		margin-top: 0.375rem;
	}
	
	.PersonalIsBuy .line1 {
		width: 28px;
		height: 4px;
		background: #FFFFFF;
		margin-top: 0.375rem;
	}
	
	.seathBtm {
		width: 60px;
		height: 28px;
		background: #EFEFEF;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.PersonalIsBuy>>>.el-input__inner{
		font-size: 12px!important;
		width: 12.125rem;
		height: 1.75rem;
		border-radius: 0;
		border-right: none;
	} 
	
	.text1 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.tableHeader {
		width: 57.4375rem;
		height: 2.75rem;
		background: #FAFAFA;
		border: 1px solid #E6E6E6;
		margin-left: 1.125rem;
		margin-top: 0.625rem;
	}
	
	.text2 {
		font-size: 12px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.paging {
		width: 72px;
		height: 24px;
		background: #FFFFFF;
		border-radius: 2px;
		border: 1px solid #E6E6E6;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
	}
	
	.tableItem-Header {
		width: 57.5rem;
		height: 2.25rem;
		border: 1px solid #E6E6E6;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.tableItem-Header img {
		cursor: pointer;
	}
	
	.tableBtn {
		width: 70px;
		height: 28px;
		background: #EE4454;
		border-radius: 2px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
	}
	
	.sorter {
		width: 57.5rem;
		display: flex;
		justify-content: flex-end;
		margin-top: 1.875rem;
		margin-bottom: 10px;
	}
	
	.sorter>>>.el-pager li {
		background-color: transparent !important;
		color: #333333 !important;
		border: 1px solid #E6E6E6;
		width: 36px;
		height: 36px;
		line-height: 2.25rem;
	}
	
	.sorter>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color:  #333333 !important;
		color: white!important;
	}
	
	.itemPrice {
		font-size: 600;
		font-size: 14px;
		font-family: AlibabaPuHuiTiH;
		color: #333333;
	}
</style>
