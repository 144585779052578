<template>
	<div>
		<div v-if='writeMailbox' class='BusinessFirst' style='width: 23.9375rem;'>
			<div class='RegisteredSecond-input'>
				<div class='title'>电子邮箱</div>
				<el-input placeholder="请输入企业电子邮箱" v-model="input"></el-input>
			</div>
			
			<div class='agreement'>
				<div style='width: 1rem;height: 1rem;border: 1px solid #DDDDDD;'></div>
				<div class='text'>
					我已阅读并同意
					<span style='color: #333333;cursor: pointer;'>
						《书营企业用户注册协议》
					</span>
				</div>
			</div>
			
			<div class='btn' @click='ck_step'>
				下一步
			</div>
		</div>
		
		<div v-else style='width: 26.5625rem;margin-top: 5.25rem;'>
			<div class='DIS-CENTER BusinessFirst-mailbox' style='justify-content: flex-start;'>
				<div>邮件已送达</div>
				<div style='color: #EE4454;margin-left: 1.25rem;'>976694234@163.com</div>
			</div>
			<div class='mailbox-text'>
				请您登录邮箱，点击激活链接完成注册，激活链接在24小时内有效。
			</div>
			<div class='mailbox-btn'>
				<div class='left DIS-CENTER'>请查看邮件</div>
				<div class='right'>没有收到邮件？</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				input: '',
				writeMailbox: true,
			}
		},
		methods: {
			ck_step() {
				this.writeMailbox = false;
				this.$store.commit('editMailboxActive',{isActive: false})
			}
		}
	}
</script>

<style scoped>
	.RegisteredSecond-input {
		width: 21.25rem;
		display: flex;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		border: 1px solid #DDDDDD;
		height: 2.75rem;
		align-items: center;
		margin-bottom: 1.875rem;
	}
	
	.BusinessFirst>>>.el-input__inner {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		border: none;
		width: 12rem;
	}
	
	.BusinessFirst .title {
		margin-left: 1rem;
		width: 95px;
	}
	
	.agreement {
		display: flex;
		margin-top: 1.375rem;
	}
	
	.agreement .text {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-left: 0.875rem;
	}
	
	.btn {
		width: 340px;
		height: 50px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		margin-top: 2.5rem;
	}
	
	.BusinessFirst-mailbox {
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.mailbox-text {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-top: 0.75rem;
	}
	
	.mailbox-btn  {
		display: flex;
		align-items: center;
		margin-top: 2.5rem;
	}
	
	.mailbox-btn .left {
		width: 240px;
		height: 50px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
	}
	
	.mailbox-btn .right {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
		margin-left: 2.5rem;
		cursor: pointer;
	}
</style>
