<template>
	<div class='FindPassword'>
		<!-- 注册头部 -->
		<LoginHeader passWordType='1'/>
		
		<div class='SY-CONTENT'>
			<div style='width: 75rem;height: 37.1875rem;' class='DIS-CENTER'>
				<div v-if='resetPassword == 0'>
					<PhoneFindPassword @ckSwitch='ck_Switch' />
				</div>
				
				<div v-if='resetPassword == 1'>
					<EmailFindPassword @ckSwitch='ck_Switch' />
				</div>
				
				<div v-if='resetPassword == 2'>
					<EncryptedFindPassword  @ckSwitch='ck_Switch' />
				</div>
			</div>
		</div>
		
		<div style="height: 90px;"></div>
		
		<!-- 页尾文字 -->
		<div 
			class='SY-CONTENT'
			style='background-color: #FAFAFA'
		>
			<HomeText />
		</div>
		
		<!-- 页脚 -->
		<div 
			class='SY-CONTENT'
			style='background-color: #333333;'
		>
			<HomeFooter />
		</div>
	</div>
</template>

<script>
	import LoginHeader from '../../components/LoginHeader.vue'
	import HomeText from '../../components/Home/HomeText.vue';
	import HomeFooter from '../../components/Home/HomeFooter.vue';
	import PhoneFindPassword from '../../components/findPassword/phoneFindPassword.vue';
	import EmailFindPassword from '../../components/findPassword/EmailFindPassword.vue';
	import EncryptedFindPassword from '../../components/findPassword/EncryptedFindPassword.vue';
	
	export default {
		components: { 
			LoginHeader, 
			HomeText,
			HomeFooter,
			PhoneFindPassword,
			EmailFindPassword,
			EncryptedFindPassword
		},
		data() {
			return {
				resetPassword: 0,
			}
		},
		methods:{
			ck_Switch(data) {
				this.resetPassword = data;
			}
		}
	}
</script>

<style scoped>
	.FindPassword {
		
	}
</style>
