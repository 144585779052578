<template>
	<div class='RegisteredThird' style='width: 23.9375rem;'>
		<div class='RegisteredSecond'>
			<div class='RegisteredSecond-input'>
				<div class='title'>开卡银行</div>
				<el-select style='width: 300px;' v-model="bankValue" placeholder="请选择">
					<el-option v-for="item in bankList" :key="item.bankId" :label="item.bankName" :value="item.bankId">
					</el-option>
				</el-select>
			</div>
			<div class='RegisteredSecond-input'>
				<div class='title'>银行卡号</div>
				<el-input placeholder="请输入银行卡号" v-model="serveData.bank_account"></el-input>
			</div>
			<div class='RegisteredSecond-input'>
				<div class='title'>开卡省</div>
				<el-select style='width: 300px;' v-model="provinceValue" placeholder="请选择省份" @change='getCity'>
					<el-option 
						v-for="item in provinceOptions" 
						:key="item.areaId" 
						:label="item.areaName" 
						:value="item.areaId"
					>
					</el-option>
				</el-select>
			</div>
			<div class='RegisteredSecond-input'>
				<div class='title'>开卡市</div>
				<el-select v-model="cityValue" placeholder="请选择市" style='width: 300px;' @change='getArea'>
					<el-option 
						v-for="item in cityOptions" 
						:key="item.areaId" 
						:label="item.areaName" 
						:value="item.areaId"
					>
					</el-option>
				</el-select>
			</div>
			<div class='RegisteredSecond-input'>
				<div class='title'>开卡区</div>
				<el-select v-model="areaValue" placeholder="请选择区" style='width: 300px;' @change='getAddress'>
					<el-option 
						v-for="item in areaOptions" 
						:key="item.areaId" 
						:label="item.areaName" 
						:value="item.areaId"
					>
					</el-option>
				</el-select>
			</div>
			<div class='RegisteredSecond-input'>
				<div class='title'>持卡人姓名</div>
				<el-input placeholder="请输入持卡人姓名" v-model="serveData.bank_user"></el-input>
			</div>
			<div class='RegisteredSecond-input'>
				<div class='title'>身份证件号</div>
				<el-input placeholder="请输入身份号" v-model="serveData.bank_card"></el-input>
			</div>
			<div class='RegisteredSecond-input'>
				<div class='title'>手机号码</div>
				<el-input placeholder="此卡在银行预留的手机号" v-model="serveData.bank_phone"></el-input>
			</div>

			<div style='display: flex;align-items: center;' class='RegisteredThird-code'>
				<div class='RegisteredSecond-input'>
					<div class='title' style='width: 8rem;'>手机验证码</div>
					<el-input placeholder="请输入验证码" v-model="code"></el-input>
					<div style='width: 8.75rem;margin-left: -1.5rem;cursor: pointer;' class='DIS-CENTER' @click='ck_code'>
						{{codeText}}
					</div>
				</div>
			</div>

			<div class='btn-box'>
				<div class='btn' @click='nextStep({num: 3},"agree")'>
					同意协议并确定
				</div>
				<div class='btn-text' @click='nextStep({num: 3},"skip")'>
					跳过，下一步
				</div>
			</div>

			<div class='agreement' @click='isAgreement = !isAgreement'>
				<img v-if='isAgreement' style='width: 1rem;height: 1rem;' src="../../assets/images/choice-active.png" alt="">
				<img v-else style='width: 1rem;height: 1rem;' src="../../assets/images/choice.png" alt="">
				<div class='text'>
					我已阅读并同意
					<span style='color: #333333;cursor: pointer;'>
						《快捷支付服务相关协议》
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';

	export default {
		props: ['interactionData'],
		data() {
			return {
				codeText: '获取验证码',
				code: '',
				input: '',
				isAgreement: false,
				serveData: {
					bank_account: '',
					bank_user: '',
					bank_card: '',
					bank_phone: ''
				},
				bankList: [],
				bankValue: '',
				/*----------- 获取地址 ----------*/
				provinceOptions: [], // 省的数组
				provinceValue: '', // 省的数据
				provinceText: '',
				cityOptions: [], // 市的数组
				cityValue: '', // 市的数据
				cityText: '',
				areaOptions: [], // 区的数组
				areaValue: '', // 区的数据
				areaText: '',
				/*----------------------------*/
			}
		},
		created() {
			this.initBankData();
			this.initData();
		},
		methods: {
			initBankData() {
				this.axios.post(Api.getBankList).then(res=>{
					if(res.data.code == 200) {
						this.bankList = res.data.data;
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			/*--------------- 获取地址 ----------------*/
			initData(parentId,type) {
				const data = {
					parentId
				}
				this.axios.post(Api.getArea, data).then(res => {
					if (res.data.code == 200) {
						// this.provinceOptions = res.data.data
						if(type=='city') {
							this.cityOptions = res.data.data;
						} else if(type=='area') {
							this.areaOptions = res.data.data;
						} else {
							this.provinceOptions = res.data.data;
						}
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			getCity(value) {
				this.initData(value,'city');
				this.provinceText = value ? this.provinceOptions.find(ele => ele.areaId === value).areaName : ''
			},
			getArea(value) {
				this.initData(value,'area');
				this.cityText = value ? this.cityOptions.find(ele => ele.areaId === value).areaName : ''
			},
			getAddress(value) {
				this.initData(value,'area');
				this.areaText = value ? this.areaOptions.find(ele => ele.areaId === value).areaName : ''
			},
			/*------------------------------------------------*/
			nextStep(num, type) {
				// this.$store.commit('nextStep',num)
				if (type == 'agree') {
					if (this.isAgreement) {
						//同意协议并确定
						this.fn_isAgreement(num);
					} else {
						this.$message.warning('请先阅读协议')
						return
					}
				}

				if (type == 'skip') {
					// 跳过,下一步
					const data = this.interactionData;
					this.axios.post(Api.register, data).then(res => {
						if (res.data.code == 200) {
							this.$store.commit('nextStep', num)
						} else {
							this.$message.error(res.data.msg)
						}
					})
				}
			},
			ck_code() {
				if (this.codeText == '获取验证码') {
					this.codeText = 60;
					this.timer = setInterval(() => {
						if (this.codeText > 1) {
							this.codeText = this.codeText - 1;
						} else {
							this.codeText = '获取验证码';
							clearInterval(this.timer);
						}
					}, 1000)
				}
			},
			fn_isAgreement(num) {
				if (!(/^([1-9]{1})(\d{15}|\d{16}|\d{18})$/).test(this.serveData.bank_account)) {
					this.$message.warning('请输入正确的银行卡');
					return
				}
				if (!(/^[\u4E00-\u9FA5]{2,4}$/).test(this.serveData.bank_user)) {
					this.$message.warning('请输入正确的持卡人姓名');
					return
				}
				if (!(/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/).test(this.serveData.bank_card)) {
					this.$message.warning('请正确的身份证号');
					return
				}
				if (!(/(^1[3|4|5|7|8|9]\d{9}$)|(^09\d{8}$)/.test(this.serveData.bank_phone))) {
					this.$message.warning('请输入正确的手机号');
					return
				}
				if(this.areaValue == '') {
					this.$message.warning('请输入省市区地址');
					return 
				}
				const data = {
					phone: this.interactionData.phone,
					username: this.interactionData.username,
					password: this.interactionData.password,
					bank_account: this.serveData.bank_account,
					bank_card: this.serveData.bank_card,
					bank_phone: this.serveData.bank_phone,
					bank_user: this.serveData.bank_user,
					bank_name: '',
					bankId: this.bankValue,
					province: this.provinceText,
					city: this.cityText,
					area: this.areaText,
					area_id: this.areaValue
				}
				this.axios.post(Api.register, data).then(res => {
					if (res.data.code == 200) {
						this.$store.commit('nextStep', num)
					} else {
						this.$message.error(res.data.msg);
					}
				})
			}
		}
	}
</script>

<style scoped>
	.RegisteredSecond-input {
		width: 21.25rem;
		display: flex;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		border: 1px solid #DDDDDD;
		height: 2.75rem;
		align-items: center;
		margin-bottom: 1.875rem;
	}

	.RegisteredSecond>>>.el-input__inner {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		border: none;
		width: 12rem;
	}

	.RegisteredThird-code>>>.el-input__inner {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		border: none;
		width: 7rem;
	}

	.RegisteredSecond .title {
		margin-left: 1rem;
		width: 95px;
	}

	.btn-box {
		width: 21.25rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.btn-box .btn {
		width: 190px;
		height: 50px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		margin-top: 0;
	}

	.btn-box .btn-text {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
		cursor: pointer;
		margin-right: 1.5rem;
	}

	.agreement {
		display: flex;
		margin-top: 1.5625rem;
		align-items: center;
		cursor: pointer;
	}

	.agreement .text {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-left: 0.875rem;
	}
</style>
