<template>
	<div>
		<div>
			<Header bgIndex='2' @dialogBoxIndex="dialogBoxIndex" />
		</div>
		
		<!-- 标题 -->
		<div class='SY-CONTENT'>
			<div class='NewBook-title DIS-CENTER'>
				<img 
					style='width: 1.875rem;height: 1.875rem;'
					src="../assets/images/New_book.png" 
					alt=""
				>
				<div class='title'>新书上市</div>
			</div>
		</div>
		
		<!-- 菜单栏 -->
		<div class='SY-CONTENT'>
			<div class='NewBook-menu'>
				<div class='top'>
					<div 
						:class="item.active ? 'text1' : 'text2'"
						v-for='(item,index) in menuList'
						:key='index'
						@click='ck_menu(index)'
					>
						{{item.catName}}
					</div>
				</div>
				
				<div class='bottom' v-if='childList.length > 0'>
					<div
						v-for='(item,index) in childList'
						:key='index'
						class='item'
						@click='ck_getNewList(item.catId)'
					>
						{{item.catName}}
					</div>
				</div>
			</div>
		</div>
		
		<!-- 排序 -->
		<div class='SY-CONTENT'>
			<Sort @getPrice='getPrice' @setChoice='setChoice' @setStock='setStock' @setOrder='setOrder'/>
		</div>
		
		<!-- 商品列表 -->
		<div class='SY-CONTENT'>
			<div v-if='tableList.length>0'>
				<GoodList :goodList='tableList'/>
			</div>
			<div v-else>
				<TableNone />
			</div>
		</div>
		
		<!-- 分页器 -->
		<div class='SY-CONTENT'>
			<div class='sorter'>
				<el-pagination
					background
					layout="pager"
					:total="total"
					@current-change='editPage'
					:page-size='20'
				>
				</el-pagination>
			</div>
		</div>
		
		<!-- 页尾文字 -->
		<div 
			class='SY-CONTENT'
			style='margin-top: 8.5rem;background-color: #FAFAFA;'
		>
			<HomeText />
		</div>
		
		<!-- 页脚 -->
		<div 
			class='SY-CONTENT'
			style='background-color: #333333;'
		>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Api from '../request/api.js';
	
	import Header from '../components/Header.vue';
	import HomeText from '../components/Home/HomeText.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	import GoodList from '../components/childPage/GoodLIst.vue';
	import Sort from '../components/childPage/Sort.vue';
	import TableNone from '../components/TableNone.vue';
	
	export default {
		components: { 
			Header, HomeText, HomeFooter, GoodList, Sort, TableNone
		},
		data() {
			return {
				menuList: [],
				childList: [],
				tableList: [], // 数据列表
				total: 0, // 分页器总数
				
				cat_id: 0, // 分类ID
				order: 'goodsId desc', // 排序规则
				isFee: 0, // 是否包邮
				startPrice: -1, // 开始价格
				endPrice: -1, // 结束价格
				isStock: 0, // 是否有货
				isShowDialogBox:false, 
			}
		},
		created() {
			this.initData();
			this.getCatList();
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			initData(page) {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					page,
					cat_id: this.cat_id,
					order: this.order,
					isFee: this.isFee,
					startPrice: this.startPrice,
					endPrice: this.endPrice,
					isStock: this.isStock
				}
				this.axios.post(Api.NewBooksOnTheMarket,data).then(res=>{
					if(res.data.code == 200) {
						this.tableList = res.data.data.newgoods;
						this.total = res.data.data.count;
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			getCatList() {
				this.axios.post(Api.getCatList).then(res=>{
					if(res.data.code == 200) {
						this.menuList = res.data.data;
						
						for(let i in this.menuList) {
							// this.menuList[i].active = false;
							this.$set(this.menuList[i],'active',false);
						}
						// this.menuList[0].active = true;
						// this.childList = this.menuList[0].list;
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			ck_menu(index) {
				for(let i in this.menuList) {
					this.menuList[i].active = false;
				}
				this.$set(this.menuList[index],'active',true);
				this.childList = this.menuList[index].list;
			},
			editPage(value) {
				this.initData(value);
			},
			// 分类筛选
			ck_getNewList(id) {
				this.cat_id = id;
				this.initData(1);
			},
			// 获取价格
			getPrice(data) {
				this.startPrice = data.startPrice;
				this.endPrice = data.endPrice;
				this.initData(1)
			},
			// 是否包邮
			setChoice(data) {
				this.isFee = data;
				this.initData(1);
			},
			// 是否有货
			setStock(data) {
				this.isStock = data;
				this.initData(1);
			},
			// 排序
			setOrder(data) {
				this.order = data;
				this.initData(1);
			}
		}
	}
</script>

<style scoped>
	.NewBook-title {
		width: 75rem;
		margin-top: 2.1875rem;
	}
	
	.NewBook-title .title {
		font-size: 1.75rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-left: 1.25rem;
	}
	
	.NewBook-menu {
		width: 75rem;
		margin-top: 1.5625rem;
		position: relative;
	}
	
	.NewBook-menu .top {
		display: flex;
		position: relative;
		width: 75rem;
		z-index: 9;
	}
	
	.top .text1 {
		font-size: 1rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		padding: 0.375rem 1rem 0.5rem;
		border-top: 3px solid  #EE4454;
		border-left: 1px solid #E6E6E6;
		border-right: 1px solid #E6E6E6;
		border-bottom: 0.0625rem solid #FFFFFF;
		display: flex;
		cursor: pointer;
	}
	
	.top .text2 {
		font-size: 1rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		padding: 0.375rem 1rem;
		display: flex;
		cursor: pointer;

	}
	
	.NewBook-menu .bottom {
		border: 1px solid #E6E6E6;
		width: 71.25rem;
		padding: 1.25rem 1.875rem;
		display: flex;
		position: relative;
		top: -0.0625rem;
		left: 0;
	}
	
	.bottom .item {
		font-size: 0.875rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin: 0.375rem 1.25rem ;
		cursor: pointer;
	}
	
	.bottom .item:hover {
		color: #EE4454;
	}
	
	.sorter {
		width: 75rem;
		display: flex;
		justify-content: flex-end;
		margin-top: 1.875rem;
	}
	
	.sorter>>>.el-pager li {
		background-color: transparent !important;
		color: #333333 !important;
		border: 1px solid #E6E6E6;
		width: 36px;
		height: 36px;
		line-height: 2.25rem;
	}
	
	.sorter>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color:  #333333 !important;
		color: white!important;
	}
</style>
