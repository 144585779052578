<template>
	<div class='BabyClassify'>
		<div class='titleBox'>
			宝贝分类
		</div>

		<div class='menu'>
			<div
				v-for="(item,index) in menuList"
				:key='index'
				@click='ck_menu(index)'
			>
				<div
					:class="index == 0 ? 'item':'item1'"
				>
					<div class='text'>{{item.catName}}</div>
					<!-- <img v-show='item.active' style='width: 0.53125rem;height: 0.53125rem;' src="../../assets/images/arrow_up@2x.png" alt="">
					<img v-show='!item.active' style='width: 0.53125rem;height: 0.53125rem;' src="../../assets/images/arrow-bottom.png" alt=""> -->
				</div>
				
				<!-- <div 
					v-show='item.active'
					class='item2 DIS-CENTER'
					v-for='(item1,index1) in item.child' 
					:key='index1'
				>
					{{item1.text}}
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:['menuList'],
		data() {
			return {
				
			}
		},
		methods: {
			ck_menu(index) {
				for(let i in this.menuList) {
					if(i != index) {
						this.menuList[i].active = false;
					}
				}
				this.menuList[index].active = !this.menuList[index].active;
			}
		}
	}
</script>

<style scoped>
	.BabyClassify {
		width: 14rem;
	}

	.BabyClassify .titleBox {
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		border-bottom: 1px solid #EE4454;
		padding-bottom: 0.75rem;
	}

	.BabyClassify .menu {
		width: 13.875rem;
		border: 1px solid #E6E6E6;
		margin-top: 1.125rem;
	}

	.menu .item {
		padding-left: 1.5rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 2.5rem;
		padding-right: 0.8125rem;
		cursor: pointer;
	}
	
	.menu .item1 {
		padding-left: 1.5rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 2.5rem;
		padding-right: 0.8125rem;
		cursor: pointer;
		border-top: 1px solid #E6E6E6;
	}
	
	.menu .item2 {
		height: 2.5rem;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		border-top: 1px solid #E6E6E6;
	}

	.text {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
</style>
