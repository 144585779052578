<template>
	<div>
		<div style="min-height: 848px;background-color: #FFFFFF;">
			<div class="PublishSteps">
				<div class="PublishSteps_header">
					<div class="PublishSteps_text1">{{publistNum==1?'图文管理':publistNum==2?'音频管理':'视频管理'}}</div>
					<div class="PublishSteps_cutOff">/</div>
					<div class="PublishSteps_text1">进行中</div>
					<div class="PublishSteps_cutOff">/</div>
					<div class="PublishSteps_text2">{{publistTitleNew}}</div>
				</div>
			</div>
			<div class="PublishSteps_content">
				<div class="PublishSteps_contentBox">
					<div class="PublishSteps_content_steps">
						<el-steps :space="351" :active="2" finish-status="success"
							style="width: 100%;padding-left: 200px;">
							<el-step :title="publistNum==1?'上传图文':publistNum==2?'上传音频':'上传视频'"></el-step>
							<el-step title="审核中..."></el-step>
							<el-step title="发布成功"></el-step>
						</el-steps>
					</div>

					<div class="PublishSteps_content_text">
						您的{{publistNum==1?'图文':publistNum==2?'音频':'视频'}}已上传成功，请耐心等待审核…</div>
					<div class="PublishSteps_content_btn">
						<div class="PublishSteps_content_btn_text1" @click="ck_goBack('/')">返回首页</div>
						<div class="PublishSteps_content_btn_text2" @click="ck_goOnPublist">继续上传</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['publistNum', 'publistTitleNew'],
		data() {
			return {}
		},
		methods: {
			ck_goOnPublist() {
				this.$emit('goOnPublist',true)
			},
			ck_goBack(path) {
				this.$router.push({
					path
				})
			}
		}
	}
</script>

<style>
	.PublishSteps {
		width: 100%;
		display: flex;
		background: #FFFFFF;
		border-bottom: 1px solid #E8E8E8;
	}

	.PublishSteps_header {
		margin: 28px 0 27px 52px;
		display: flex;
	}

	.PublishSteps_text1 {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.PublishSteps_text2 {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.PublishSteps_cutOff {
		margin-left: 20px;
		margin-right: 20px;
	}

	.PublishSteps_content {
		width: 100%;
		display: flex;
		background-color: #FFFFFF;
		margin-right: 38px;
	}

	.PublishSteps_contentBox {
		margin-top: 116px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	.PublishSteps_content_steps {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 60%;
	}

	.PublishSteps_content_steps .el-step__head.is-success {
		color: #56C8B0;
		border-color: #56C8B0;
	}

	.PublishSteps_content_steps .el-step__title {
		font-size: 18px !important;
		font-family: PingFangSC-Regular, PingFang SC !important;
		font-weight: 400 !important;
		color: #333333 !important;
	}

	.PublishSteps_content_steps .el-step__main {
		margin-left: -5px;
		margin-top: 20px;
	}

	.PublishSteps_content_steps .el-step__icon {
		width: 51px;
		height: 51px;
	}

	.PublishSteps_content_steps .el-step.is-horizontal .el-step__line {
		top: 23px;
	}

	.PublishSteps_content_steps .is-success .el-step__icon {
		background-color: #56C8B0;
		color: #FFFFFF;
	}

	.PublishSteps_content_steps .is-process .el-step__icon {
		background-color: #DFDFDF;
		color: #FFFFFF;
		border: none;
	}

	.PublishSteps_content_steps .is-wait .el-step__icon {
		background-color: #DFDFDF;
		color: #FFFFFF;
		border: none;
	}

	.PublishSteps_content_text {
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-top: 93px;
	}

	.PublishSteps_content_btn {
		margin-top: 47px;
		display: flex;
	}

	.PublishSteps_content_btn_text1 {
		border-radius: 4px;
		border: 1px solid #E6E6E6;
		padding: 16px 64px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
	}

	.PublishSteps_content_btn_text2 {
		border-radius: 4px;
		border: 1px solid #E6E6E6;
		padding: 16px 64px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		background-color: #EE4454;
		margin-left: 20px;
		cursor: pointer;
	}
</style>
