<template>
	<div>
		<div class="PublishContentsz">推荐卡设置</div>
		<div class="PublishContentsz_line"></div>
		<div class="PublishContentsz_box">
			<div class="PublishContentsz_boxLeft">
				<div style="display: flex;align-items: center;">
					<div class="PublishContentsz_boxLeftText1">原创视频推荐卡添加水印设置</div>
					<!-- PublishContentsz_btnCheckStyle -->
					<div :class="isSwitchCheck?'PublishContentsz_btnCheckStyle':'PublishContentsz_btnStyle'">
						{{isSwitchCheck?'已开启':isText}}</div>
				</div>
				<div class="PublishContentsz_boxLeftText2">(对原创视频添加水印，开启后新上传的视频有效)</div>
			</div>
			<div class="PublishContentsz_boxRight">
				<img v-if="!isSwitchCheck" src="../../assets/Publish/switch_off.png" @click="ck_switchCheck(1)" />
				<img v-if="isSwitchCheck" src="../../assets/Publish/switch_on.png" @click="ck_switchCheck(0)" />
			</div>
		</div>

		<div class="PublishContentsz_box2">
			<div class="PublishContentsz_boxLeftText1">邀请权限设置</div>
			<div class="PublishContentsz_boxLeftText2" style="margin-bottom: 32px;">(允许邀请后可关联相似商品，以被检索和曝光)</div>
			
			
			<div v-for="(item,index) in radioList" :key='index' style="display: flex;">
				<img :src='item.active?imgUrl2:imgUrl1' alt="" class="PublishContentsz_img1" @click="ck_checkRadio(index)">
				<div class="PublishContentsz_radioText">{{item.text}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PublishContentszImg1 from '../../assets/Publish/radio_btn.png'
	import PublishContentszImg2 from '../../assets/Publish/radio_btn_selected.png'
	import Api from '../../request/api.js'
	export default {
		data() {
			return {
				isSwitchCheck: false,
				isText: '未开启',
				radioList:[
					{
						text:'允许所有人邀请我',
						active:true,
					},
					{
						text:'允许我关注的人邀请我',
						active:false,
					},
					{
						text:'允许互相关注的人邀请我',
						active:false,
					},
					{
						text:'不允许所有人邀请我',
						active:false,
					},
				],
				imgUrl1:PublishContentszImg1,
				imgUrl2:PublishContentszImg2,
			}
		},
		mounted() {
			this.initData()
			this.setInviteData()
		},
		methods: {
			initData(){
				const data = {
					loginid: localStorage.getItem('shuying-token'),
				}
				this.axios.post(Api.setWaterMarker,data).then(res=>{
					console.log(res.data,'水印默认状态')
					// if(res.data.code == 200) {
					// 	console.log('操作开启状态')
					// 	this.isSwitchCheck=true
					// } else {
					// 	this.isSwitchCheck=false
					// 	console.log('操作关闭状态')
					// }
				})
			},
			
			// 0 默认允许所有邀请 1 允许我关注的人邀请我 2 允许互相关注的人邀请我 3 不允许所有人邀请我
			setInviteData(){
				const data = {
					loginid: localStorage.getItem('shuying-token'),
				}
				this.axios.post(Api.setInvite,data).then(res=>{
					console.log(res.data,'邀请设置默认状态')
					// if(res.data.code == 200) {
						
						
					// } else {
					// 	// 默认开启状态
					// 	for(let i in this.radioList){
					// 		this.radioList[i].active=false
					// 	}
					// 	this.radioList[0].active=true
					// }
				})
			},
			ck_switchCheck(num){
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					turn:num,
				}
				this.axios.post(Api.setWaterMarker,data).then(res=>{
					console.log(res.data,'水印点击操作')
					if(res.data.code == 200) {
						if(num==0){
							this.isSwitchCheck=false
						}else{
							this.isSwitchCheck=true
						}
					} else {
						this.$message.warning(res.data.msg);
					}
				})
			},
			ck_checkRadio(index){
				console.log(index,'index')
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					allow_type:index,
				}
				this.axios.post(Api.setInvite,data).then(res=>{
					console.log(res.data,'邀请点击操作')
					if(res.data.code == 200) {
						for(let i in this.radioList){
							this.radioList[i].active=false
						}
						this.radioList[index].active=true
						this.$message.success(res.data.msg);
					} else {
						this.$message.warning(res.data.msg);
					}
				})
			}
		},
	}
</script>

<style>
	.PublishContentsz {
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-left: 44px;
		margin-top: 32px;
		padding-bottom: 27px;
		border-bottom: 1px solid #E6E6E6;
	}

	.PublishContentsz_box {
		margin: 19px 0 20px 42px;
		padding: 36px 38px 36px 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid #E6E6E6;
	}

	.PublishContentsz_box2 {
		margin: 19px 0 20px 42px;
		padding: 36px 38px 36px 40px;
		display: flex;
		flex-direction: column;
		border: 1px solid #E6E6E6;
	}

	.PublishContentsz_boxLeft {
		display: flex;
		flex-direction: column;
	}

	.PublishContentsz_boxLeftText1 {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.PublishContentsz_boxLeftText2 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-top: 6px;
	}

	.PublishContentsz_btnStyle {
		padding: 3px 7px;
		background-color: #F2F2F2;
		font-size: 10px !important;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-left: 16px;
	}

	.PublishContentsz_btnCheckStyle {
		padding: 3px 7px;
		background-color: #1FD692;
		font-size: 10px !important;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-left: 16px;
	}
	
	.PublishContentsz_img1{
		width: 16px;
		height: 16px;
		margin-bottom: 25px;
		margin-right: 12px;
		cursor: pointer;
	}
	
	.PublishContentsz_radioText{
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
</style>
