<template>
	<div class='BusinessSecond' style='width: 23.9375rem;'>
		<div class='RegisteredSecond'>
			<div  class='RegisteredSecond-input' style='border: none;margin-bottom: 1.5rem;'>
				<div class='title' style='font-weight: 600;'>登录名</div>
				<div>{{ mailbox }}</div>
			</div>
			<div :class='passwordCorrect ? "RegisteredSecond-input" : "RegisteredSecond-input-errno"'>
				<div class='title'>登录密码</div>
				<el-input @focus="phineFocus('passWord')" @blur="phoneBlur('passWord')" placeholder="设置您的登入密码" v-model="passWord"></el-input>
			</div>
			<div :class="passwordCorrect ? 'errnoText' : 'errnoText-errno'">
				请使用8~16个字符、数字的组合
			</div>
			<div :class='isPasswordCorrect ? "RegisteredSecond-input" : "RegisteredSecond-input-errno"'>
				<div class='title'>确认密码</div>
				<el-input @focus="phineFocus('isPassWord')" @blur="phoneBlur('isPassWord')" placeholder="请再次输入您的密码" v-model="isPassWord"></el-input>
			</div>
			<div :class="isPasswordCorrect ? 'errnoText' : 'errnoText-errno'">
				两次输入的密码不一致
			</div>
			<div :class='userNameCorrect ? "RegisteredSecond-input" : "RegisteredSecond-input-errno"'>
				<div class='title'>会员名</div>
				<el-input @focus="phineFocus('memberName')" @blur="phoneBlur('memberName')" placeholder="请输入会员名" v-model="memberName"></el-input>
			</div>
			<div :class="userNameCorrect ? 'errnoText' : 'errnoText-errno'">
				请填写会员名
			</div>
			<div :class='companyAddressCorrect ? "RegisteredSecond-input" : "RegisteredSecond-input-errno"'>
				<div class='title'>公司地址</div>
				<el-cascader
					style='width: 15rem;'
					v-model="address"
					:options="options"
					@change="handleChange"
					separator='-'
				>
				</el-cascader>
			</div>
			<div :class="companyAddressCorrect ? 'errnoText' : 'errnoText-errno'">
				请选择公司地址
			</div>
			<div :class='phoneCorrect ? "RegisteredSecond-input" : "RegisteredSecond-input-errno"'>
				<div class='title'>手机号</div>
				<el-input @focus="phineFocus('phone')" @blur="phoneBlur('phone')" placeholder="清输入手机号" v-model="phone"></el-input>
			</div>
			<div :class="phoneCorrect ? 'errnoText' : 'errnoText-errno'">
				请输入正确的手机号
			</div>
			<div :class='companyNameCorrect ? "RegisteredSecond-input" : "RegisteredSecond-input-errno"'>
				<div class='title'>公司名称</div>
				<el-input @focus="phineFocus('companyName')" @blur="phoneBlur('companyName')" placeholder="请填写公司名称" v-model="companyName"></el-input>
			</div>
			<div :class="companyNameCorrect ? 'errnoText' : 'errnoText-errno'">
				公司名称未填写
			</div>
			
			<div class='btn' @click='nextStep({num: 2})'>
				确认
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				mailbox: '976694233@163.com',
				passwordCorrect: true,
				isPasswordCorrect: true,
				userNameCorrect: true, 
				companyAddressCorrect: true,
				phoneCorrect: true,
				companyNameCorrect: true,
				
				/*---- Input填写的参数 ----*/
				passWord: '',
				isPassWord: '',
				memberName: '',
				address: [],
				phone: '',
				companyName: '',
				
				/*---- 地址数据 ----*/
				
				options: [
					{
						value: '1',
						label: '浙江省',
						children: [
							{
								value: '2',
								label: '杭州市',
								children: [
									{
										value: '5',
										label: '滨江区'
									},
									{
										value: '5',
										label: '上城区'
									},
									{
										value: '5',
										label: '拱墅区'
									}
								]
							},
							{
								value: '3',
								label: '台州市'
							},
							{
								value: '4',
								label: '温州市'
							},
						]
					}
				]
			}
		},
		methods: {
			// 获取焦点
			phineFocus(type) {
				if(type == 'passWord') {
					this.passwordCorrect = true;
					return
				}
				if(type == 'isPassWord') {
					this.isPasswordCorrect = true;
					return 
				}
				if(type == 'memberName') {
					this.userNameCorrect = true;
					return 
				}
				if(type == 'phone') {
					this.phoneCorrect = true;
					return 
				} 
				if(type == 'companyName') {
					this.companyNameCorrect = true;
					return 
				} 
			},
			// 失去焦点
			phoneBlur(type) {
				if(type == 'passWord') {
					if(!(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/.test(this.passWord))) {
						this.passwordCorrect = false;
					} else {
						this.passwordCorrect = true;
					}
					return 
				}
				if(type == 'isPassWord') {
					if(this.passWord != this.isPassWord) {
						this.isPasswordCorrect = false;
					} else {
						this.isPasswordCorrect = true;
					}
					return 
				}
				if(type == 'memberName') {
					if(this.memberName.length < 1) {
						this.userNameCorrect = false;
					} else {
						this.userNameCorrect = true;
					}
					return 
				}
				if(type == 'phone') {
					if (!(/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.phone))) {
						this.phoneCorrect = false;
					} else {
						this.phoneCorrect = true;
					}
					return 
				}
				if(type == 'companyName') {
					if(this.companyName.length < 1) {
						this.companyNameCorrect = false;
					} else {
						this.companyNameCorrect = true;
					}
				}
			},
			handleChange(value) {
				this.address = value;
			},
			nextStep(num) {
				this.$store.commit('nextStep',num)
				this.$store.commit('editMailboxActive',{isActive: false})
			},
		}
	}
</script>

<style scoped>
	.RegisteredSecond-input {
		width: 21.25rem;
		display: flex;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		border: 1px solid #DDDDDD;
		height: 2.75rem;
		align-items: center;
	}
	
	.RegisteredSecond-input-errno {
		width: 21.25rem;
		display: flex;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		border: 1px solid #EE4454;
		height: 2.75rem;
		align-items: center;
	}
	
	.RegisteredSecond>>>.el-input__inner {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		border: none;
		width: 12rem;
	}
	
	.RegisteredSecond .title {
		margin-left: 1rem;
		width: 75px;
	}
	
	.btn {
		width: 340px;
		height: 50px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		margin-top: 1.6875rem;
	}
	
	.errnoText {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		margin-top: 0.125rem;
		margin-bottom: 0.625rem;
	}
	
	.errnoText-errno {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
		margin-top: 0.125rem;
		margin-bottom: 0.625rem;
	}
</style>
