<template>
	<div class='HomeText'>
		<div 
			class='Text-contnet'
			v-for='(item,index) in homeTextList' 
			:key='index'
		>
			<div class='item' style='margin-left: 3.125rem;'>
				<div>
					<div class='title'>{{item.title}}</div>
					<div 
						class='text'
						v-for='(item1,index1) in item.child'
						:key='index1'
						@click="ck_goPage(item1.path,item1.id)"
					>
						{{item1.text}}
					</div>
				</div>
			</div>
			<div 
				class='line'
				v-if='index != homeTextList.length -1'
			></div>
		</div>
	</div>
</template>

<script>
	import { HOMETEXT } from '../../assets/data/home-text.js';
	
	export default {
		data() {
			return {
				homeTextList: HOMETEXT,
			}
		},
		methods: {
			ck_goPage(path,id) {
				if(id) {
					this.$router.push({path,query:{id}});
					return 
				}
				this.$router.push({path});
			}
		}
	}
</script>

<style scoped>
	.HomeText {
		width: 48.375rem;
		display: flex;
		/* padding: 0 13.3125rem; */
	}
	
	.Text-contnet {
		display: flex;
		flex: 1;
		/* justify-content: center; */
	}
	
	.HomeText  .item {
		
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 1.875rem 0;
	}
	
	.item .title {
		font-size: 0.875rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.item .text {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-top: 0.625rem;
		cursor: pointer;
	}
	
	.HomeText .line {
		height: 6.25rem;
		border-left: 0.0625rem solid #ededed;
		margin-top: 2rem;
		margin-left: 55px;
	}
</style>
