<template>
	<div>
		<div class='NewBook-List'>
			<div 
				:class="index==0 || index%5==0 ? 'list-item list-item1':'list-item list-item2'"
				v-for='(item,index) in goodList'
				:key='index'
			>
				<img 
					style='width: 13.875rem;height: 12.5rem;'
					:src="item.goodsImg" 
					alt=""
				>
				<div class='text'>
					<span class='ziying' v-if='item.isSelfSale == 1'>自营</span>
					<span class='ershou' v-if='item.isSecondhandBook == 1'>二手</span>
					{{item.goodsName}}
				</div>
				<div class='info-box'>
					<div class='left'>
						<span>￥</span>
						<span style='font-size: 1.125rem;'>{{item.shopPrice}}</span>
						<span style='color: #999999;margin-left: 0.1875rem;'>起</span>
					</div>
					<div class='right DIS-CENTER' @click='goPage("/GoodsDetails",item.goodsId)'>
						立即购买 
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['goodList'],
		methods: {
			goPage(path,id) {
				this.$router.push({path,query:{id}})
			}
		}
	}
</script>

<style scoped>
	.NewBook-List {
		width: 75rem;
		display: flex;
		flex-wrap: wrap;
	}
	
	.list-item {
		cursor: pointer;
	}
	
	.list-item:hover .right{
		animation: pulse; 
		animation-duration: 1s; 
	}
	
	.NewBook-List .list-item1 {
		width: 222px;
		height: 312px;
		background: #FFFFFF;
		border: 1px solid #E6E6E6;
		margin-top: 1.25rem;
	}
	
	.NewBook-List .list-item2 {
		width: 222px;
		height: 312px;
		background: #FFFFFF;
		border: 1px solid #E6E6E6;
		margin-top: 1.25rem;
		margin-left: 1.25rem;
	}
	
	.list-item .text {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin: 0 0.9375rem;
		margin-top: 1rem;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	
	.list-item .info-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0.8125rem 1.25rem 0;
	}
	
	.info-box .left {
		font-size: 12px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.info-box .right {
		width: 4.375rem;
		height: 1.875rem;
		background: #EE4454;
		border-radius: 2px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
	}
</style>
