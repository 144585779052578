<template>
	<div class='Agreement'>
		<div style='width: 100%;display: flex;justify-content: flex-end;cursor: pointer;'>
			<img
				style='width: 1.125rem;height: 1.125rem;'
				src="../../assets/images/close@2x.png" 
				alt=""
				@click='goPage("/")'
			>
		</div>
		<div class='title1'>
			入驻协议
		</div>
		<div class='content' v-html="contentText">
			
		</div>
		<div style='width: 100%;display: flex;justify-content: center;'>
			<div class='btn DIS-CENTER' @click='ck_sub'>
				同意并继续
			</div>
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	
	export  default {
		data() {
			return {
				contentText: '',
			}
		},
		created() {
			this.initData();
		},
		methods: {
			initData() {
				const data = {
					articleId: 32
				}
				this.axios.post(Api.getArticle,data).then(res=>{
					if(res.data.code == 200) {
						this.contentText = res.data.data.articleContent;
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			goPage(path) {
				this.$router.push({path})
			},
			ck_sub() {
				this.$emit('agreement', false)
			}
		}
	}
</script>

<style scoped>
	.Agreement {
		/* width: 53.75rem; */
		/* height: 36.25rem; */
		padding: 1.5625rem 1.875rem;
		background-color: #FFFFFF;
	}
	
	.title1 {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.content {
		width: 52.1875rem;
		height: 28.125rem;
		border: 1px solid #E6E6E6;
		margin-top: 1.3125rem;
		overflow-y: auto;
	}
	
	.btn {
		width: 250px;
		height: 50px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FFFFFF;
		cursor: pointer;
		margin-top: 1.5rem;
	}
</style>
