import { render, staticRenderFns } from "./BusinessSecond.vue?vue&type=template&id=583dd04b&scoped=true&"
import script from "./BusinessSecond.vue?vue&type=script&lang=js&"
export * from "./BusinessSecond.vue?vue&type=script&lang=js&"
import style0 from "./BusinessSecond.vue?vue&type=style&index=0&id=583dd04b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "583dd04b",
  null
  
)

export default component.exports