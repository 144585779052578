<template>
	<div class='EditMessageSecond'>
		<div class='text1'>
			您正在为账户 <span style='color: #EE4454;'>{{userInfo.userEmail}}</span> 修改邮箱：
		</div>
		<div class='itemBox' style="margin-top: 2.8125rem;">
			<div class='text2'>新邮箱</div>
			<div class='codeBox'>
				<el-input v-model="input" placeholder="请输入新邮箱"></el-input>
			</div>
		</div>
		<div class='btn DIS-CENTER' @click='ck_step'>
			验证
		</div>
	</div>
</template>

<script>
	export default {
		props:['userInfo'],
		data() {
			return {
				input: '',
			}
		},
		methods: {
			ck_step() {
				if(this.input==''){
					this.$message.warning('内容不能为空')
				}else{
					this.$emit('getEmail', this.input);
				}
			}
		}
	}
</script>

<style scoped>
	.EditMessageSecond {
		width: 26rem;
	}
	
	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.itemBox {
		display: flex;
		align-items: center;
	}
	
	.text2 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.codeBox {
		width: 21.25rem;
		height: 2.75rem;
		border: 1px solid #DDDDDD;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: 1.875rem;
	}
	
	.codeBox>>>.el-input__inner{
		width: 21.25rem;
		border: none;
	} 
	
	.btn {
		width: 120px;
		height: 44px;
		background: #EE4454;
		border-radius: 4px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
		margin-top: 2.0625rem;
		margin-left: 4.5rem;
	}
</style>
