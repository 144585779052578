<template>
	<div class='AccurateSearch'>
		<div>
			<Header bgIndex='1' @dialogBoxIndex="dialogBoxIndex" />
		</div>
		
		<!-- 内容头部 -->
		<div v-if='false' class='SY-CONTENT' style='margin-top: 1.375rem;'>
			<div style='width: 75rem;'>
				<AccurateSearchTop />
			</div>
		</div>
		
		<!-- 内容区 -->
		<div class='SY-CONTENT' style='margin-top: 2.625rem;'>
			<div style='width: 75rem;'>
				<AccurateSearchBottom 
					:dataList='dataList' 
					:total='total' 
					:dataList2='dataList2' 
					@ckPage='ckPage'
					@setPrice='setPrice'
					@setFee='setFee'
					@setNew='setNew'
					@setStock='setStock'
					@setOrder='setOrder'
				/>
			</div>
		</div>
		
		<!-- 页尾文字 -->
		<div 
			class='SY-CONTENT'
			style='margin-top: 8.5rem;background-color: #FAFAFA;'
		>
			<HomeText />
		</div>
		
		<!-- 页脚 -->
		<div 
			class='SY-CONTENT'
			style='background-color: #333333;'
		>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Api from '../request/api.js';
	
	import Header from '../components/Header.vue';
	import HomeText from '../components/Home/HomeText.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	import AccurateSearchTop from '../components/AccurateSearch/AccurateSearchTop.vue';
	import AccurateSearchBottom from '../components/AccurateSearch/AccurateSearchBottom.vue';
	
	export default {
		components: {
			Header,
			HomeText,
			HomeFooter,
			AccurateSearchTop,
			AccurateSearchBottom
		},
		data() {
			return {
				dataList: [],
				total: 0,
				dataList2: [],
				
				startPrice: -1,
				endPrice: -1,
				isFee: 0,
				isNew: 0,
				isStock: 0,
				order: 'saleNum desc',
				isShowDialogBox:false,
			}
		},
		created() {
			this.seatch(1);
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			// 搜索
			seatch(page) {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					keyword: this.$route.query.value,
					page,
					cat_id: this.$route.query.catId,
					startPrice: this.startPrice,
					endPrice: this.endPrice,
					isFee: this.isFee,
					isNew: this.isNew,
					isStock: this.isStock,
					order: this.order
				}
				this.axios.post(Api.searchProduct,data).then(res=>{
					if(res.data.code == 200) {
						this.dataList = res.data.data.product_list;
						this.total = res.data.data.count;
						this.dataList2 = res.data.data.similar_recommend_goods
					} else {
						this.$message.error(res.data.msg)
					}
				}) 
			},
			ckPage(data) {
				this.seatch(data);
			},
			// 价格
			setPrice(data) {
				this.startPrice = data.startPrice;
				this.endPrice = data.endPrice;
				this.seatch(1);
			},
			// 是否包邮
			setFee(data) {
				this.isFee = data;
				this.seatch(1);
			},
			// 是否新品
			setNew(data) {
				this.isNew = data;
				this.seatch(1);
			},
			// 是否有货
			setStock(data) {
				this.isStock = data;
				this.seatch(1)
			},
			// 排序
			setOrder(data) {
				this.order = data;
				this.seatch(1)
			}
		}
	}
</script>

<style scoped>
	.AccurateSearch {
		
	}
</style>
