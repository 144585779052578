const emoji_dir = './emoji/'

//表情库
var emojis = function () {
    var alt = ["[微笑]", "[伤心]", "[美女]", "[发呆]", "[墨镜]", "[哭]", "[羞]", "[哑]", "[睡]","[哭]","[囧]", "[怒]","[调皮]", "[笑]", "[惊讶]", "[难过]", "[酷]", "[汗]", "[抓狂]", "[吐]", "[笑]", "[快乐]", "[奇]", "[傲]", "[饿]","[累]", "[吓]", "[汗]", "[高兴]", "[闲]", "[努力]", "[骂]", "[疑问]", "[秘密]", "[乱]", "[疯]", "[哀]", "[鬼]", "[打击]", "[bye]", "[汗]", "[抠]", "[鼓掌]", "[糟糕]", "[恶搞]", "[什么]","[什么]", "[累]", "[看]", "[难过]", "[难过]", "[坏]", "[亲]", "[吓]", "[可怜]", "[刀]", "[水果]", "[酒]", "[篮球]", "[乒乓]", "[咖啡]", "[美食]", "[动物]", "[鲜花]", "[枯]", "[唇]", "[爱]", "[分手]", "[生日]", "[电]", "[炸弹]", "[刀子]", "[足球]", "[瓢虫]", "[翔]", "[月亮]", "[太阳]", "[礼物]", "[抱抱]", "[拇指]", "[贬低]", "[握手]", "[剪刀手]", "[抱拳]", "[勾引]", "[拳头]", "[小拇指]", "[拇指八]", "[食指]", "[ok]", "[情侣]", "[爱心]", "[蹦哒]", "[颤抖]", "[怄气]", "[跳舞]", "[发呆]", "[背着]", "[伸手]", "[耍帅]"], arr = {};
    alt.forEach((item, index) => {
        arr[item] = require(emoji_dir + (index + 100) + '.gif');
    });
    return arr
}();

export default emojis

