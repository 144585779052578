<template>
	<div class='ApplyRefund'>
		<div>
			<Header bgIndex='4' @dialogBoxIndex="dialogBoxIndex" />
		</div>
		
		<!-- 内容区 -->
		<div class='SY-CONTENT' style='background-color: #F5F5F5;'>
			<div class='content'>
				<!-- 面包屑 -->
				<div class='BreadCrumbs' style='margin: 31px 0 0 2.4375rem;'>
					<div 
						class='breadText1' 
						style='cursor: pointer;'
						@click='ck_goPage("/PersonalCenter")'
					>
						主页
					</div>
					<div class='breadText1' style='margin: 0 1.5625rem;'>/</div>
					<div 
						class='breadText1' 
						style='cursor: pointer;'
						@click='ck_goPage("/PersonalCenter",{type: 2})'
					>
						已买到的宝贝
					</div>
					<div class='breadText1' style='margin: 0 1.5625rem;'>/</div>
					<div class='breadText2' style='cursor: pointer;'>申请退款</div>
				</div>
				
				<!-- 主题内容 -->
				<div class='contentBox DIS-CENTER'>
					<div style='padding: 1.875rem;border: 1px solid #E6E6E6;'>
						<div style='display: flex;'>
							<img 
								style='width: 5rem;height: 5rem;border: 1px solid #E6E6E6;'
								src="../assets/images/homeseconddemo.png" 
								alt=""
							>
							<div style='display: flex;flex-direction: column;justify-content: space-around;margin-left: 1rem;'>
								<div class='text1' style='width: 20rem;'>
									价格的发现复杂约束市场中的拍卖设计（全新修订版 精装）上海人民出版社
								</div>
								<div class='text2'>￥59.00</div>
							</div>
						</div>
						<div style='display: flex;margin-top: 1.6875rem;'>
							<div class='refund' @click='ck_goPage("/ApplyRefundStep")'>
								<img 
									style='width: 2.5rem;height: 2.5rem;'
									src="../assets/images/refund-01.png" 
									alt=""
								>
								<div style='margin-left: 2.3125rem;'>
									<div class='text3'>
										我要退款（无需退货）
									</div>
									<div class='text4' style='margin-top: 0.1875rem;'>
										没收到货，或与卖家协商达成一致
									</div>
								</div>
							</div>
							
							<div class='refund' style='margin-left: 2.5rem;' @click='ck_goPage("/ApplyRefundStep")'>
								<img 
									style='width: 2.5rem;height: 2.5rem;'
									src="../assets/images/refund-02.png" 
									alt=""
								>
								<div style='margin-left: 2.3125rem;'>
									<div class='text3'>
										我要退款退货
									</div>
									<div class='text4' style='margin-top: 0.1875rem;'>
										已收到货，需要退还收到的货物
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<!-- 常见问题 -->
				<div class='contentBox DIS-CENTER' style='padding-bottom: 6.4375rem;'>
					<div class='content-problem'>
						<div style='margin-left: 2.1875rem;'>
							<div class='title' style='margin-top: 1.3125rem;'>常见问题</div>
							<div class='text5' style='margin-top: 1rem;'>
								1.卖家不发货，怎么办？
							</div>
							<div class='text6' style='margin-top: 0.375rem;'>
								亲，如果卖家迟迟不发货，您可以根据目前订单的状态选择对应方式。
							</div>
							<div class='text5' style='margin-top: 1rem;'>
								2.如何申请退款？
							</div>
							<div class='text6' style='margin-top: 0.375rem;'>
								亲，根据您的订单状态提示去申请退款哦。
							</div>
							<div class='text5' style='margin-top: 1rem;'>
								3.省钱退款后，交易退款成功，钱退到哪里？
							</div>
							<div class='text6' style='margin-top: 0.375rem;'>
								亲，要查看退款状态哦，只有当订单的退款状态为【退款成功】时，钱款才会退回原先的支付渠道。
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 页脚 -->
		<div class='SY-CONTENT' style='background-color: #333333;'>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Header from '../components/Header.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	
	export default {
		data(){
			return{
				isShowDialogBox:false,
			}	
		},
		components: { 
			Header, 
			HomeFooter 
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			ck_goPage(path,type) {
				this.$router.push({path,query: type})
			}
		}
	}
</script>

<style scoped>
	.content {
		width: 75rem;
		background-color: #FFFFFF;
	}
	
	.BreadCrumbs {
		display: flex;
		align-items: center;
	}
	
	.breadText1 {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.breadText2 {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.contentBox {
		width: 75rem;
		margin-top: 1.75rem;
	}
	
	.text1 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.text2 {
		font-size: 14px;
		font-family: AlibabaPuHuiTiH;
		color: #333333;
		line-height: 18px;
		font-weight: 600;
	}
	
	.refund {
		width: 400px;
		height: 114px;
		border: 1px solid #E6E6E6;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	
	.text3 {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.text4 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
	
	.content-problem {
		width: 56.25rem;
		height: 17.125rem;
		background-color: #FAFAFA;
	}
	
	.title {
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.text5 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.text6 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
</style>
