import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Intelligent from '../views/Intelligent.vue'
import IntelligentRecommend from '../views/IntelligentRecommend.vue'
import NewBook from '../views/NewBook.vue'
import GoodBook from '../views/GoodBook.vue'
import History from '../views/History.vue'
import Login from '../views/login/Login.vue'
import FindPassword from '../views/login/FindPassword.vue'
import RegistePhoner from '../views/login/RegistePhoner.vue'
import GoodsDetails from '../views/GoodsDetails.vue'
import ShoppingCart from '../views/ShoppingCart.vue'
import SubmitOrder from '../views/SubmitOrder.vue'
import Payment from '../views/Payment.vue'
import Restaurent from '../views/Restaurent.vue'
import Enterprises from '../views/Enterprises.vue'
import LoginSecond from '../views/LoginSecond/LoginSecond.vue'
import PersonalCenter from '../views/PersonalCenter.vue'
import MineMaterial from '../views/MineMaterial.vue'
import MineAddress from '../views/MineAddress.vue'
import Message from '../views/Message.vue'
import HelpCenter from '../views/HelpCenter.vue'
import ShopHome from '../views/ShopHome.vue'
import EditMessage from '../views/EditMessage.vue'
import ApplyRefund from '../views/ApplyRefund.vue'
import ApplyRefundStep from '../views/ApplyRefundStep.vue'
import SetPayPassword from '../views/SetPayPassword.vue'
import EditPhone from '../views/EditPhone.vue'
import AccurateSearch from '../views/AccurateSearch.vue'
import RefereesDetails from '../views/RefereesDetails.vue'
import IntelligentRank from '../views/IntelligentRank.vue'
import Market from '../views/Market.vue'
import Information from '../views/Information.vue'
import IntelligentItem from '../views/IntelligentItem.vue'
import OrderDetails from '../views/OrderDetails.vue'
import Advertising from '../views/Advertising.vue'
import RichText from '../views/RichText.vue'
import ShopList from '../views/ShopList.vue'
import LicenceOne from '../views/LicenceOne.vue'
import LicenceTwo from '../views/LicenceTwo.vue'
import PublishContent from '../views/PublishContent.vue'
import CloseAnAccount from '../views/CloseAnAccount.vue'
import ApplyRefundEvaluate from '../views/ApplyRefundEvaluate.vue'
import AddBankCard from '../views/AddBankCard.vue'
import BankManagement from '../views/BankManagement.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/Intelligent',
		name: 'Intelligent',
		component: Intelligent
	},
	{
		path: '/IntelligentRecommend',
		name: 'IntelligentRecommend',
		component: IntelligentRecommend
	},
	{
		path: '/NewBook',
		name: 'NewBook',
		component: NewBook
	},
	{
		path: '/GoodBook',
		name: 'GoodBook',
		component: GoodBook
	},
	{
		path: '/History',
		name: 'History',
		component: History
	},
	{
		path: '/Login',
		name: 'Login',
		component: Login
	},
	{
		path: '/RegistePhoner',
		name: 'RegistePhoner',
		component: RegistePhoner
	},
	{
		path: '/GoodsDetails',
		name: 'GoodsDetails',
		component: GoodsDetails
	},
	{
		path: '/ShoppingCart',
		name: 'ShoppingCart',
		component: ShoppingCart
	},
	{
		path: '/SubmitOrder',
		name: 'SubmitOrder',
		component: SubmitOrder
	},
	{
		path: '/Payment',
		name: 'Payment',
		component: Payment
	},
	{
		path: '/Restaurent',
		name: 'Restaurent',
		component: Restaurent
	},
	{
		path: '/Enterprises',
		name: 'Enterprises',
		component: Enterprises
	},
	{
		path: '/LoginSecond',
		name: 'LoginSecond',
		component: LoginSecond
	},
	{
		path: '/PersonalCenter',
		name: 'PersonalCenter',
		component: PersonalCenter
	},
	{
		path: '/MineMaterial',
		name: 'MineMaterial',
		component: MineMaterial
	},
	{
		path: '/MineAddress',
		name: 'MineAddress',
		component: MineAddress
	},
	{
		path: '/Message',
		name: 'Message',
		component: Message
	},
	{
		path: '/FindPassword',
		name: 'FindPassword',
		component: FindPassword
	},
	{
		path: '/HelpCenter',
		name: 'HelpCenter',
		component: HelpCenter
	},
	{
		path: '/ShopHome',
		name: 'ShopHome',
		component: ShopHome
	},
	{
		path: '/EditMessage',
		name: 'EditMessage',
		component: EditMessage
	},
	{
		path: '/ApplyRefund',
		name: 'ApplyRefund',
		component: ApplyRefund
	},
	{
		path: '/ApplyRefundStep',
		name: 'ApplyRefundStep',
		component: ApplyRefundStep
	},
	{
		path: '/SetPayPassword',
		name: 'SetPayPassword',
		component: SetPayPassword
	},
	{
		path: '/EditPhone',
		name: 'EditPhone',
		component: EditPhone
	},
	{
		path: '/AccurateSearch',
		name: 'AccurateSearch',
		component: AccurateSearch
	},
	{
		path: '/RefereesDetails',
		name: 'RefereesDetails',
		component: RefereesDetails
	},
	{
		path: '/IntelligentRank',
		name: 'IntelligentRank',
		component: IntelligentRank
	},
	{
		path: '/Market',
		name: 'Market',
		component: Market
	},
	{
		path: '/Information',
		name: 'Information',
		component: Information
	},
	{
		path: '/IntelligentItem',
		name: 'IntelligentItem',
		component: IntelligentItem
	},
	{
		path: '/OrderDetails',
		name: 'OrderDetails',
		component: OrderDetails
	},
	{
		path: '/Advertising',
		name: 'Advertising',
		component: Advertising
	},
	{
		path: '/RichText',
		name: 'RichText',
		component: RichText
	},
	{
		path: '/ShopList',
		name: 'ShopList',
		component: ShopList
	},
	{
		path: '/LicenceOne',
		name: 'LicenceOne',
		component: LicenceOne
	},
	{
		path: '/LicenceTwo',
		name: 'LicenceTwo',
		component: LicenceTwo
	},
	{
		path: '/PublishContent',
		name: 'PublishContent',
		component: PublishContent
	},
	{
		path: '/CloseAnAccount',
		name: 'CloseAnAccount',
		component: CloseAnAccount
	},
	{
		path: '/ApplyRefundEvaluate',
		name: 'ApplyRefundEvaluate',
		component: ApplyRefundEvaluate
	},
	{
		path: '/AddBankCard',
		name: 'AddBankCard',
		component: AddBankCard
	},
	{
		path: '/BankManagement',
		name: 'BankManagement',
		component: BankManagement
	},
]
const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return {
			x: 0,
			y: 0
		}
	}
})

export default router
