export const HOMETEXT = [
	{
		title: '新手上路',
		child: [
			{
				text: '购物流程',
				path: '/HelpCenter',
				id: '5'
			},
			{
				text: '常见问题',
				path: '/HelpCenter',
				id: '3'
			},
			{
				text: '我要入驻',
				path: '/HelpCenter',
				id: '4'
			},
		]
	},
	{
		title: '购物保障',
		child: [
			{
				text: '卖家认证',
				path: '/HelpCenter',
				id: ''
			},
			// {
			// 	text: '中介保护',
			// 	path: '',
			// },
			{
				text: '信誉保证',
				path: '/HelpCenter',
				id: '6'
			},
			// {
			// 	text: '担保金赔付',
			// 	path: '',
			// },
		]
	},
	{
		title: '支付方式',
		child: [
			{
				text: '支付宝支付',
				path: '/HelpCenter',
				id: '1'
			},
			{
				text: '微信支付',
				path: '/HelpCenter',
				id: '1'
			},
			{
				text: '余额支付',
				path: '/HelpCenter',
				id: '1'
			},
		]
	},
	{
		title: '售后服务',
		child: [
			{
				text: '退货退款',
				path: '/HelpCenter',
				id: '2'
			},
			{
				text: '投诉维权',
				path: '/HelpCenter',
				id: '2'
			},
			{
				text: '举报管理',
				path: '/HelpCenter',
				id: '2'
			},
		]
	}
]