<template>
	<div class='PersonalHome'>
		<div class='left'>
			<div class='left-top'>
				<div class='userInfo'>
					<div class='DIS-CENTER'>
						<img 
							style='width: 5rem;height: 5rem;cursor: pointer;border-radius: 50%;' 
							:src="userHeader" alt=""
							@click='ck_goPage("/MineMaterial")'
						>
						<div style='margin-left: 1.5625rem;'>
							<div class='text1'>{{userInfo.loginName}}</div>
							<div class='text2' style='margin-top: 0.375rem;'>注册于{{userInfo.createTime}}</div>
						</div>
					</div>
					<div 
						class='text3' 
						style='cursor: pointer;'
						@click='ck_goPage("/MineAddress")'
					> 
						我的收货地址
						<img 
							style='width: 0.625rem;height: 0.625rem;margin-left: 0.5625rem;'
							src="../../assets/images/arrow_right.png" alt="">
					</div>
				</div>
				<div class='statusBox'>
					<div 
						class='DIS-CENTER' style='flex-direction: column;cursor: pointer;'
						v-for='(item,index) in statusList'
						:key='index'
						@click='ck_menu(index)'
					>
						<img
							style='width: 1.875rem;height: 1.875rem;'
							:src="item.img" alt=""
						>
						<div class='text4' style='margin-top: 0.375rem;'>
							{{item.text}}
						</div>
					</div>
				</div>
				<div class='text1' style='margin-top: 45px;' v-if="orderList.length>0">我的物流</div>
				<div 
					class='DIS-CENTER' 
					style='justify-content: flex-start;margin-top: 1.25rem;'
					v-for='(item,index) in orderList'
					:key='index'
				>
					<img
						style='width: 5rem;height: 5rem;border: 1px solid #E6E6E6;'
						src="../../assets/images/homethirddemo.png" alt=""
					>
					<div style='margin-left: 1.25rem;'>
						<div class='text3' style='width: 18.4375rem;'>
							【杭州市】杭州市余杭区良渚公司派件员 龙海 电话 
							<span style='color: #EE4454;'>18788888888</span> 
							当前正在为您派件…
						</div>
						<div class='text2' style='margin-top: 0.8125rem;'>2020-11-18 09:35:55</div>
					</div>
					<div class='text3 btn DIS-CENTER' style='margin-left: 8.75rem;'>查看详情</div>
				</div>
				<div 
					class='DIS-CENTER' 
					v-if='orderList.length>0'
					style='margin-top: 1.875rem;cursor: pointer;'
				>
					<div class='text3' @click="ck_seeMore">查看更多信息</div>
					<img
						style='width: 0.625rem;height: 0.625rem;margin-left: 0.375rem;'
						src="../../assets/images/arrow-bottom.png" @click="ck_seeMore"
					>
				</div>
			</div>
			<div class='left-bottom'>
				<div class='title'>
					<div class='text1'>猜你喜欢</div>
					<div class='text3' style='cursor: pointer;' @click="ck_changeLike">换一组</div>
				</div>
				<div style='display: flex;margin-top: 1.5rem;'>
					<div 
						v-for='(item,index) in likeGoodsList' :key='index'
						:style="index>0?'margin-left:15px':''"
					>
						<GoodsListFirst :goodsItem='item'/>
					</div>
				</div>
			</div>
		</div>
		<div class='right'>
			<div class='titleBox DIS-CENTER'>
				我的足迹
			</div>
			<div 
				class='right-item DIS-CENTER' 
				style='flex-direction: column;cursor: pointer;'
				v-for='(item,index) in history'
				:key='index'
				@click='ck_goPage("/GoodsDetails",item.goodsId)'
			>
				<img 
					style='width: 7.8125rem;height: 11.4375rem;'
					:src="item.goodsImg" 
					alt=""
				>
				<div class='right-textBox'>
					<div class='text3' >
						<span style='font-weight: 600;'>￥</span>
						<span style='font-size: 1.25rem;font-weight: 600;'>{{item.shopPrice}}</span>
						<span style='color: #999999;margin-left: 0.375rem;'>起</span>
					</div>
					<div class='text3' style='font-size: 0.1875rem;'>
						已卖 <span style='color: #EE4454;'>1832</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import GoodsListFirst from '../components/GoodsListFirst.vue';
	
	import Api from '../../request/api.js';
	
	import DFK from '../../assets/PersonalCenter/DFK.png'
	import DFH from '../../assets/PersonalCenter/DFH.png'
	import DSH from '../../assets/PersonalCenter/DSH.png'
	import DPJ from '../../assets/PersonalCenter/DPJ.png'
	import TK from '../../assets/PersonalCenter/TK.png'
	
	export default {
		components: { GoodsListFirst },
		data() {
			return {
				statusList: [
					{
						text: '待付款',
						img: DFK,
						parentMenuValue: 2,
						isBuyMenuValue: 1
					},
					{
						text: '待发货',
						img: DFH,
						parentMenuValue: 2,
						isBuyMenuValue: 2
					},
					{
						text: '待收货',
						img: DSH,
						parentMenuValue: 2,
						isBuyMenuValue: 3
					},
					{
						text: '待评价',
						img: DPJ,
						parentMenuValue: 2,
						isBuyMenuValue: 4
					},
					{
						text: '退款',
						img: TK,
						parentMenuValue: 8
					}
				],
				userInfo: {}, //用户信息
				orderList: {}, //最新物流订单
				allOrderList:{},
				likeGoodsList: {}, //猜你喜欢的商品
				history: {}, //浏览记录
				userHeader: '', //用户头像
			}
		},
		created() {
			this.initData();
		},
		methods: {
			initData() {
				const data = {
					loginid: localStorage.getItem('shuying-token')
				}
				this.axios.post(Api.UsercenterIndex,data).then(res=>{
					if(res.data.code == 200) {
						this.userHeader = JSON.parse(localStorage.getItem("userInfo")).userPhoto
						this.userInfo = res.data.data.user_info;
						this.allOrderList=res.data.data.order_list
						this.orderList=res.data.data.order_list.slice(0,3)
						this.likeGoodsList = res.data.data.goods;
						this.history = res.data.data.history;
					} else {
						this.$message.warning(res.data.msg)
					}
				})
			},
			
			// 查看更多信息
			ck_seeMore(){ 
				if(this.orderList.length>3){
					this.orderList=this.allOrderList
				}
			},
			
			// 换一组 猜你喜欢
			ck_changeLike(){
				this.initData()
			},
			
			ck_goPage(path,id) {
				this.$router.push({path,query:{id}});
			},
			ck_menu(index) {
				this.$emit('setMenuList',this.statusList[index]);
			}
		}
	}
</script>

<style scoped>
	.PersonalHome {
		display: flex;
	}
	
	.PersonalHome .left {
		margin-left: 0.4375rem;
	}
	
	.PersonalHome .left-top {
		width: 630px;
		padding: 2.3125rem 30px 1.875rem;
		background-color: #FFFFFF;
		margin-top: 0.625rem;
	}
	
	.PersonalHome .userInfo {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.PersonalHome .text1 {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.PersonalHome .text2 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
	
	.PersonalHome .text3 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.PersonalHome .text4 {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.PersonalHome .statusBox {
		width: 39.375rem;
		height: 6.75rem;
		background: #FAFAFA;
		margin-top: 1.875rem;
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	
	.PersonalHome .btn {
		width: 90px;
		height: 30px;
		background: #FAFAFA;
		border: 1px solid #EDEDED;
		cursor: pointer;
	}
	
	.PersonalHome .left-bottom {
		width: 39.375rem;
		padding: 0.9375rem 1.875rem;
		background-color: #FFFFFF;
		margin-top: 0.625rem;
	}
	
	.left-bottom .title {
		padding-bottom: 0.5rem;
		border-bottom: 2px solid #EE4454;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.right {
		margin-top: 0.625rem;
		margin-left: 0.75rem;
	}
	
	.titleBox {
		width: 224px;
		height: 49px;
		background: #6F2F1B;
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FFFFFF;
	}
	
	.right-item {
		width: 12.125rem;
		padding: 0.9375rem 0.9375rem 0.625rem;
		background-color: #FFFFFF;
		margin-bottom: 0.625rem;
	}
	
	.right-textBox {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: space-between;
		margin-top: 1.5rem;
	}
</style>
