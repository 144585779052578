<template>
	<div class='Market'>
		<div>
			<Header bgIndex='2' @dialogBoxIndex="dialogBoxIndex" />
		</div>
		
		<!-- 内容区 -->
		<div class='SY-CONTENT'>
			<div style='width: 75rem;padding-top: 1.25rem;'>
				<!-- 菜单栏 -->
				<!-- <div class='menuBox'>
					<div 
						class='DIS-CENTER' style='flex-direction: column;margin-right: 1.875rem;'
						v-for='(item,index) in menuList'
						:key='index'
						@click='ck_menu(index)'
					>
						<div :class='item.active?"menuText2":"menuText1"'>{{item.text}}</div>
						<div :class='item.active?"line2":"line1"'></div>
					</div>
				</div> -->
				
				<div class='contentBox'>
					<div 
						:class="index==0||index%5==0?'contentBoxItem2':'contentBoxItem1'" 
						v-for='(item,index) in marketData' 
						:key='index'
					>
						<img style='width: 14rem;' :src="item.goodsImg" alt="">
						<div class='titleBox' style='margin-top: 1.1875rem;'>
							<span class='ziying' v-if='item.isSelfSale == 1'>自营</span>
							<span class='ershou' v-if='item.isSecondhandBook == 1'>二手</span>
							{{item.goodsName}}
						</div>
						<div class='btnBox'>
							<div class='text1'>
								￥<span style='font-size: 1.25rem;'>{{item.shopPrice}}</span>
								<span class='text2'>起</span>
							</div>
							<div class='btn DIS-CENTER' @click='ck_goPage("/GoodsDetails",item.goodsId)'>立即购买</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 页尾文字 -->
		<div 
			class='SY-CONTENT'
			style='margin-top: 8.5rem;background-color: #FAFAFA;'
		>
			<HomeText />
		</div>
		
		<!-- 页脚 -->
		<div 
			class='SY-CONTENT'
			style='background-color: #333333;'
		>
			<HomeFooter />
		</div>

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Api from '../request/api.js';
	
	import Header from '../components/Header.vue';
	import HomeText from '../components/Home/HomeText.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	
	export default {
		components: {
			Header,
			HomeText,
			HomeFooter
		},
		data() {
			return {
				menuList: [
					{
						text: '全部',
						active: true,
					},
					{
						text: '字画专场',
						active: false,
					},
					{
						text: '手工专场',
						active: false,
					},
					{
						text: '动漫专场',
						active: false,
					},
					{
						text: '非遗专场',
						active: false,
					}
				],
				total: 0,
				marketData: [],
				isShowDialogBox:false,
			}
		},
		created() {
			this.initData(1);
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			initData(page) {
				const data = {
					page
				}
				this.axios.post(Api.culturalFair,data).then(res=>{
					if(res.data.code == 200) {
						this.total = res.data.data.count;
						this.marketData = res.data.data.culturalFair;
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			ck_goPage(path,id) {
				this.$router.push({path,query:{id}});
			},
			ck_menu(index) {
				for(let i in this.menuList) {
					this.menuList[i].active = false;
				}
				this.menuList[index].active = true;
			}
		}
	}
</script>

<style scoped>
	.menuBox {
		display: flex;
		align-items: center;
		margin-top: 1.875rem;
		cursor: pointer;
	}
	
	.menuText1 {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.menuText2 {
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.line1 {
		width: 18px;
		height: 4px;
		background: #FFFFFF;
		margin-top: 0.375rem;
	}
	
	.line2 {
		width: 18px;
		height: 4px;
		background: #EE4454;
		margin-top: 0.375rem;
	}
	
	.contentBox {
		width: 75rem;
		display: flex;
		flex-wrap: wrap;
	}
	
	.contentBoxItem1 {
		width: 14rem;
		/* border: 1px solid #E6E6E6; */
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-top: 1.25rem;
		margin-left: 1.0625rem;
		cursor: pointer;
	}
	
	.contentBoxItem1:hover{
		animation: pulse;
		animation-duration: 1s; 
	}
	
	.contentBoxItem2 {
		width: 14rem;
		/* border: 1px solid #E6E6E6; */
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-top: 1.25rem;
		cursor: pointer;
	}
	
	.contentBoxItem2:hover{
		animation: pulse;
		animation-duration: 1s; 
	}
	
	.titleBox {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		width: 12.375rem;
		height: 2.25rem;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	
	.btnBox {
		width: 12.375rem;
		display: flex;
		justify-content: space-between;
		margin: 0.75rem 0 0.9375rem 0;
	}
	
	.btn {
		width: 70px;
		height: 30px;
		background: #EE4454;
		border-radius: 2px;
		cursor: pointer;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}
	
	.text1 {
		font-size: 13px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.text2 {
		font-size: 11px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-left: 0.1875rem;
	}
</style>
