<template>
  <div>
    <Header bgIndex="1" @dialogBoxIndex="dialogBoxIndex" />

    <div class="SY-CONTENT">
      <H3 class="title">{{ contentTitle }}</H3>
    </div>
    <div class="SY-CONTENT">
      <div class="contentBox" v-html="contentText"></div>
    </div>

    <!-- 页尾文字 -->
    <div
      class="SY-CONTENT"
      style="margin-top: 8.5rem; background-color: #fafafa"
    >
      <HomeText />
    </div>

    <!-- 页脚 -->
    <div class="SY-CONTENT" style="background-color: #333333">
      <HomeFooter />
    </div>

    <div
      v-if="isShowDialogBox"
      class="isShowDialogShade"
      @click="isShowDialogBox = false"
    ></div>
    <div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
      <DialogBoxIndex
        @closeDialogBox="closeDialogBox"
        :getShopId="false"
      ></DialogBoxIndex>
    </div>
  </div>
</template>

<script>
import Api from "../request/api.js";

import Header from "../components/Header.vue";
import HomeText from "../components/Home/HomeText.vue";
import HomeFooter from "../components/Home/HomeFooter.vue";

export default {
  components: { Header, HomeText, HomeFooter },
  data() {
    return {
      contentText: "",
      contentTitle: "",
      isShowDialogBox: false,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    dialogBoxIndex(data) {
      this.isShowDialogBox = data;
    },
    closeDialogBox(data) {
      this.isShowDialogBox = data;
    },
    initData() {
      const data = {
        articleId: this.$route.query.id,
      };
      this.axios.post(Api.getArticle, data).then((res) => {
        if (res.data.code == 200) {
          this.contentText = res.data.data.articleContent;
          this.contentTitle = res.data.data.articleTitle;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped>
.contentBox {
  width: 75rem;
}

.title {
  margin-top: 3rem;
}
</style>
