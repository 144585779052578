<template>
	<div class='NewPayPassword'>
		<div class='DIS-CENTER' style='margin: 2rem 0 0 0;flex-direction: column;'>
			<div class='title'>
				您还没有设置密码！
			</div>
			<div class='text' style='margin-top: 0.25rem;'>
				请先设置一个6位数字的支付密码~
			</div>
		</div>
		<div style='display: flex;align-items: center;margin-left: 2.0625rem;margin-top: 2.3125rem;'>
			<div class='text1'>支付密码</div>
			<div style="position: relative;">
				<div class='itemBox'>
					<div 
						class='item'
						v-for='(item,index) in 6'
						:key='index'
					>
					</div>
				</div>
				<div class='inputBox'>
					<input 
						class='Paddword-input'
						type="password"
						maxlength="6"
						v-model="newPass1"
					>
				</div>
			</div>
		</div>
		<div style='display: flex;align-items: center;margin-left: 2.0625rem;margin-top: 1.875rem;'>
			<div class='text1'>确认密码</div>
			<div style="position: relative;">
				<div class='itemBox'>
					<div 
						class='item'
						v-for='(item,index) in 6'
						:key='index'
					>
					</div>
				</div>
				<div class='inputBox'>
					<input 
						class='Paddword-input'
						type="password"
						maxlength="6"
						v-model="newPass2"
					>
				</div>
			</div>
		</div>
		<div class='btnBox'>
			<div class='btn1 DIS-CENTER' @click='ck_btn'>取消</div>
			<div class='btn2 DIS-CENTER' @click='ck_btn("set")'>确定</div>
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	
	export default {
		data() {
			return {
				newPass1: '',
				newPass2: '', 
			}
		},
		methods: {
			ck_btn(type) {
				if(type=='set') {
					if(this.newPass1 != this.newPass2) {
						this.$message.warning('支付密码不一致')
						return
					}
					const data = {
						loginid: localStorage.getItem('shuying-token'),
						newPass: this.newPass2,
						type: 0
					}
					this.axios.post(Api.setPayPwd,data).then(res=>{
						if(res.data.code == 200) {
							this.$message.success(res.data.msg);
							this.$emit('ckShowPassword', false); 
							this.getUserInfo();
						} else {
							this.$message.error(res.data.msg);
						}
					})
				} else {
					this.$emit('ckShowPassword', false);
				}
			},
			getUserInfo() {
				const data = {
					loginid: localStorage.getItem('shuying-token')
				}
				this.axios.post(Api.getUserInfo,data).then(res=>{
					if(res.data.code == 200) {
						localStorage.setItem('userInfo',JSON.stringify(res.data.data));
					}else {
						this.$message.error(res.data.msg)
					}
				})
			}
		}
	}
</script>

<style scoped>
	.NewPayPassword {
		width: 447px;
		height: 336px;
		background: #FFFFFF;
		border-radius: 6px;
		border: 1px solid #FFFFFF;
	}
	
	.title {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.text {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
	
	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.item {
		width: 40px;
		height: 40px;
		background: #F4F4F4;
		border: 1px solid #E6E6E6;
		margin-right:0.625rem;
	}
	
	.itemBox {
		display: flex;
		align-items: center;
	/* 	margin: 31px 0 0 2.375rem; */
		margin-left: 1.25rem;
	}
	
	.inputBox {
		width: 18.75rem;
		position: absolute;
		z-index: 9;
		top: 0;left: 2.5rem;
		background-color: rgba(0,0,0,0);
		
	}
	
	.Paddword-input {
		letter-spacing:45px;
		width: 20rem;
		height: 2.5rem;
		border: none;
		background-color: rgba(0,0,0,0);
		outline:none;
		padding-left:0px;
		color: transparent;
		text-shadow: 0 0 0 #000;  
		font-size: 1.25rem;
	}
	
	.btnBox {
		margin-top: 2.5rem;
		display: flex;
		align-items: center;
	}
	
	.btn1 {
		width: 80px;
		height: 30px;
		border-radius: 2px;
		border: 1px solid #E6E6E6;
		cursor: pointer;
		font-size: 13px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-left: 13.8125rem;
	}
	
	.btn2 {
		width: 80px;
		height: 30px;
		background: #EE4454;
		border-radius: 2px;
		cursor: pointer;
		font-size: 13px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		margin-left: 1.25rem;
	}
</style>
