<template>
	<div class='Enterprises'>
		<div class='SY-CONTENT' style='background-color: #FAFAFA;'>
			<div class='RestaurentHeader'>
				<img style='width: 6.25rem;height: 2.625rem;' src="../assets/logo/logo3.png" alt="">
			</div>
		</div>
		
		<div class='SY-CONTENT' style='margin-top: 2.5rem;'>
			<div class='title1'>
				企业用户入驻流程
			</div>
		</div>
		
		<!-- 进度条 -->
		<div class='SY-CONTENT' style='margin-top: 3.125rem;'>
			<Substep :step='step'/>
		</div>
		
		<!-- 标题 -->
		<div class='SY-CONTENT' style='margin-top: 3.5rem;'>
			<EnterprisesTitle :title='pageTitle'/>
		</div>
		
		<!-- 协议 -->
		<div class='AgreementBox' v-if='showAgreement'>
			<Agreement @agreement='agreement'/>
		</div>
		
		<!-- 填写公司信息 -->
		<div v-if='step==1' class='SY-CONTENT' style='margin-top: 3.125rem;'>
			<StepFirst @step='stepNext' @ckCompanyInfo='ckCompanyInfo' :companyInfo='companyInfo'/>
		</div>
		
		<!-- 店铺信息 -->
		<div v-if='step==2' class='SY-CONTENT' style='margin-top: 3.125rem;'>
			<StepSecond @step='stepNext' :companyInfo='companyInfo'/>
		</div>
		
		<!-- 入驻审核 -->
		<div v-if='step==3' class='SY-CONTENT' style='margin-top: 3.125rem;'>
			<StepThird />
		</div>
		
		<!-- 页脚 -->
		<div 
			class='SY-CONTENT'
			style='background-color: #333333;margin-top: 12.5rem;'
		>
			<HomeFooter />
		</div>
	</div>
</template>

<script>
	import Substep from '../components/Enterprises/Substep.vue';
	import EnterprisesTitle from '../components/Enterprises/EnterprisesTitle.vue';
	import StepFirst from '../components/Enterprises/StepFirst.vue';
	import StepSecond from '../components/Enterprises/StepSecond.vue';
	import StepThird from '../components/Enterprises/StepThird.vue';
	import Agreement from '../components/Enterprises/Agreement.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	
	export default {
		components: { 
			Substep, 
			EnterprisesTitle, 
			StepFirst, 
			HomeFooter, 
			StepSecond,
			StepThird,
			Agreement
		},
		data() {
			return {
				showAgreement: true,
				pageTitle: '公司信息',
				step: 1,
				companyInfo: [],
			}
		},
		methods: {
			stepNext(data) {
				this.step = data;
				if(data == 1) {
					this.pageTitle = '公司信息'
				} else if(data == 2) {
					this.pageTitle = '店铺信息'
				} else if(data == 3) {
					this.pageTitle = '入驻审核'
				}
			},
			agreement(data) {
				this.showAgreement = data;
			},
			ckCompanyInfo(data) {
				this.companyInfo = data;
			},
			// ckGetCompanyInfo(data) {
			// 	this.companyInfo = data;
			// }
		}
	}
</script>

<style scoped>
	.RestaurentHeader {
		width: 75rem;
		height: 60px;
		display: flex;
		align-items: center;
	}
	
	.logo {
		width: 133px;
		height: 40px;
		background: #E9E9E9;
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.title1 {
		font-size: 30px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.AgreementBox {
		width: 100vw;
		height: 100vh;
		background-color: ;
		background: rgba(0,0,0,0.2);
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 0;
		z-index: 9;
	}
</style>
