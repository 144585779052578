<template>
	<div class='contentMidBox'>
		<div class='title'>
			{{goodsInfo.goodsName}}
		</div>
		
		<div class='priceBox'>
			<div style='display: flex;align-items: center;'>
				<div class='leftText'>价格</div>
				<div class='firstPrice' style='text-decoration: line-through;'>￥{{goodsInfo.marketPrice}}</div>
			</div>
			<div style='display: flex;align-items: center;'>
				<div class='leftText'>促销价</div>
				<div v-if='modelList.length<1' class='secondPrice'>
					<span style='font-size: 0.75rem;'>￥</span>{{goodsInfo.shopPrice}}
				</div>
				<div v-else class='secondPrice'>
					<span style='font-size: 0.75rem;'>￥</span>{{specsPrice}}
				</div>
				<img style='width: 4.125rem;height: 1.4375rem;margin-left: 0.75rem;margin-bottom: 10px;' src="../../assets/images/contentMidBoxDiscounts.png" alt="">
				<div class='secondText'>
					{{goodsInfo.goodsTips}}
				</div>
			</div>
			<!-- <div style='display: flex;align-items: center;justify-content: space-between;'>
				<div style='display: flex;'>
					<div class='leftText'>本店活动</div>
					<div class='firstPrice'>满49元，包邮</div>
				</div>
				<div class='DIS-CENTER' style='cursor: pointer;'>
					<div class='firstPrice'>更多优惠</div>
					<img style='width: 0.875rem;height: 0.875rem;margin-left: 0.25rem;margin-right: 1.0625rem;' src="../../assets/images/arrow-bottom.png" alt="">
				</div>
			</div> -->
		</div>
		
		<div class='otherBox'>
			<!-- <div class='otherBox-first'>
				<div class='leftText'>运费</div>
				<div class='DIS-CENTER' style='cursor: pointer;'>
					<div class='text2' style='margin-left: 2.5rem;'>北京 至 杭州市余杭区</div>
					<img style='width: 0.875rem;height: 0.875rem;margin-left: 0.1875rem;' src="../../assets/images/arrow-bottom.png" alt="">
				</div>
				<div class='text2' style='margin-left: 1.375rem;'>
					快递费：￥6.00
				</div>
			</div> -->
			
			<div class='otherBox-second'>
				<div class='DIS-CENTER'>
					<div class='leftText'>数量</div>
					<div class='counter'>
						<div class='symbol DIS-CENTER' @click='ck_editNum("del")'>-</div>
						<div class='num DIS-CENTER'>{{num}}</div>
						<div class='symbol DIS-CENTER' @click='ck_editNum("add")'>+</div>
					</div>
					<div v-if='modelList.length<1' class='leftText' style='margin-left: 0.875rem;'>
						库存{{goodsInfo.goodsStock}}件
					</div>
					<div v-else class='leftText' style='margin-left: 0.875rem;'>
						库存{{specStock}}件
					</div>
				</div>
				
				<div class='DIS-CENTER'>
					<div class='text2'>
						月销量：<span style='color: #EE4454;'>{{goodsInfo.saleNum}}</span>
					</div>
					<div class='text2' style='margin-left: 1.4375rem;'>
						评价：<span style='color: #EE4454;'>{{goodsInfo.appraiseNum}}</span>
					</div>
				</div>
				
			</div>
			
			<div class='otherBox-third'>
				<div class='leftText'>规格</div>
				<div style='margin-left: 2.5rem;' class='DIS-CENTER'>
					<div 
						:class="item.active ? 'text2 DIS-CENTER model1':'text2 DIS-CENTER model'" 
						v-for='(item,index) in modelList'
						:key='index'
						@click='ck_model(index)'
					>
						{{ item.itemName }} 
						<img 
							v-if='item.active'
							style='width: 0.6875rem;height: 0.6875rem;position: absolute;right: 0;top: 1.1875rem;' 
							src="../../assets/images/contentMidBox-pitch.png" alt=""
						>
					</div>
				</div>
			</div>
			
			<div style='display: flex;margin-top: 1.25rem;'>
				<div 
					class='btn DIS-CENTER' 
					style='margin-right: 1.4375rem;'
					@click='ck_addCart(1)'
				>立即购买</div>
				<div 
					class='btn DIS-CENTER' 
					style='color: #FFFFFF;background-color: #EE4454;'
					@click='ck_addCart(0)'
				>加入购物车</div>
			</div>
			
			<div class='otherBox-four'>
				<div>服务承诺</div>
				<div class='text2' style='margin-left: 1.0625rem;'>
					正品保证 / 急速退款 / 七天无理由退换
				</div>
				<div class='DIS-CENTER' style='margin-left: 120px'>
					<img 
						v-if='isFavorites == 0'
						style='width: 0.875rem;height: 0.875rem;cursor: pointer;' 
						src="../../assets/images/collect.png" 
						alt=""
						@click='ck_followGoods'
					>
					<img
						v-else
						style='width: 0.875rem;height: 0.875rem;cursor: pointer;' 
						src="../../assets/images/is_collect.png" 
						alt=""
						@click='ck_followGoods'
					>
					<div style='margin-left: 0.25rem;'>收藏商品</div>
					<div style='margin-left: 1.25rem;'>举报</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	
	export default {
		props: ['goodsInfo','specs','isFavorites'],
		data() {
			return {
				modelList: [], // 规格的列表
				num: 1,
				specsPrice: 0, // 规格的价格
				specStock: 0, // 规格的库存
			}
		},
		mounted() {
			setTimeout(()=>{
				for(let i in this.specs) {
					this.specs[i] = {};
					this.$set(this.specs[i],'active',false)
				}
				
				if(this.specs.length>0){
					this.specs[0].active = true;
					this.specsPrice = this.specs[0].specPrice;
					this.specStock = this.specs[0].specStock;
				}
				this.modelList = this.specs;
				// this.specsPrice = this.specs[0].specPrice;
				// this.specStock = this.specs[0].specStock;
			},500)
		},
		methods: {
			ck_goPage(path) {
				this.$router.push({path})
			},
			ck_model(index) {
				for(let i in this.modelList) {
					this.modelList[i].active = false;
				}
				this.modelList[index].active = true;
				this.specsPrice = this.modelList[index].specPrice;
				this.specStock = this.modelList[index].specStock;
			},
			ck_editNum(type) {
				if(type == 'del') {
					if(this.num > 1) {
						this.num--;
					}
				} else {
					this.num++;
				}
			},
			ck_followGoods() {
				let type = 1;
				if(this.$props.isFavorites == 1) {
					type = 2;
				}
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					goodsId: 1,
					id: 1,
					type,
				}
				this.axios.post(Api.followGoods,data).then(res=>{
					if(res.data.code == 200) {
						this.$message.success(res.data.msg);
						if(this.$props.isFavorites == 1) {
							this.$props.isFavorites = 0;
						} else {
							this.$props.isFavorites = 1;
						}
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			ck_addCart(type) {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					goodsId: this.goodsInfo.goodsId,
					goodsSpecId: this.specStock,
					buyNum: this.num,
					type
				}
				this.axios.post(Api.addToCart,data).then(res=>{
					if(res.data.code == 200) {
						if(type == 1) {
							this.ck_goPage('/SubmitOrder');
						}
						this.$message.success(res.data.msg)
					} else {
						this.$message.error(res.data.msg)
					}
				})
			}
		}
	}
</script>

<style scoped>
	.contentMidBox {
		width: 34.375rem;
	}
	
	.contentMidBox .title {
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.contentMidBox .priceBox {
		width: 540px;
		height: 107px;
		background: #FAFAFA;
		margin-top: 0.625rem;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}
	
	.otherBox,.priceBox .leftText {
		margin-left: 0.875rem;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		width: 3.125rem;
	}
	
	.priceBox .firstPrice {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-left: 1.125rem;
	}
	
	.priceBox .secondPrice {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
		margin-left: 1.125rem;
	}
	
	.priceBox .secondText {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #EE4454;
		margin-left: 0.75rem;
	}
	
	.otherBox {
		width: 31.875rem;
	}
	
	.otherBox-first {
		margin-top: 0.75rem;
		width: 100%;
		display: flex;
	}
	
	.otherBox-second {
		margin-top: 1rem;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.otherBox-third {
		margin-top: 1.25rem;
		width: 100%;
		display: flex;
		align-items: center;
	}
	
	.otherBox-second .counter {
		width: 86px;
		height: 24px;
		border: 1px solid #E6E6E6;
		display: flex;
		margin-left: 40px;
	}
	
	.otherBox .text2 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.counter .symbol {
		flex: 1;
		font-weight: bold;
		font-size: 16px;
		font-family: AlibabaPuHuiTiR;
		cursor: pointer;
	}
	
	.counter .num {
		flex: 2;
		font-size: 14px;
		font-family: AlibabaPuHuiTiR;
		color: #333333;
		border-left: 1px solid  #E6E6E6;
		border-right: 1px solid  #E6E6E6;
	}
	
	.otherBox-third .model {
		width: 5.625rem;
		height: 1.875rem;
		border: 1px solid #e6e6e6;
		cursor: pointer;
		margin-right: 0.625rem;
		position: relative;
	}
	
	.otherBox-third .model1 {
		width: 5.625rem;
		height: 1.875rem;
		border: 1px solid #EE4454;
		cursor: pointer;
		margin-right: 0.625rem;
		position: relative;
	}
	
	.btn {
		width: 12.5rem;
		height: 3.125rem;
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #EE4454;
		background: #FFEBEB;
		border: 1px solid #EE4454;
		cursor: pointer;
	}
	
	.otherBox-four {
		margin-top: 1.375rem;
		width: 100%;
		display: flex;
		align-items: center;
	}
</style>
