<template>
	<div class='PersonalZhsz'>
		<div style='display: flex;align-items: center;'>
			<div class='line'></div>
			<div class='title'>账户设置</div>
		</div>

		<div style='margin-top: 1.25rem;'>
			<div class='contentItem' v-for='(item,index) in setList' :key='index'>
				<div style='display: flex;align-items: center;'>
					<div class='text1' style='width: 5.25rem;margin-left: 1.4375rem;'>
						{{item.title}}
					</div>
					<div class='text2' style='margin-left: 5.375rem;'>
						{{item.text?item.text:"暂无信息"}}
					</div>
				</div>
				<div 
					class='text3' 
					style='cursor: pointer;margin-right: 1.1875rem;'
					@click='ck_goPage(item.path)'
				>
					{{item.btnText}}
				</div>
			</div>
			<div class='contentItem'>
				<div style='display: flex;align-items: center;'>
					<div class='text1' style='width: 5.25rem;margin-left: 1.4375rem;'>
						收货地址
					</div>
					<div class='text2' style='margin-left: 5.375rem;'>
						已添加<span style='color: #EE4454;'>0</span>个地址，最多可添加10个
					</div>
				</div>
				<div class='text3' style='cursor: pointer;margin-right: 1.1875rem;' @click='ck_goPage("/MineAddress")'>
					管理
				</div>
			</div>
			<!-- <div class='contentItem'>
				<div style='display: flex;align-items: center;'>
					<div class='text1' style='width: 5.25rem;margin-left: 1.4375rem;'>
						安全保护问题
					</div>
					<div class='text2' style='margin-left: 5.375rem;'>
						您设置了<span style='color: #EE4454;'>1</span>个安全保护问题
					</div>
				</div>
				<div class='text3' style='cursor: pointer;margin-right: 1.1875rem;'>
					修改
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	import Api from '../../request/api.js';
	
	export default {
		data() {
			return {
				setList: [{
						title: '真实姓名',
						text: '张****',
						btnText: '查看',
						path: '/MineMaterial'
					},
					{
						title: '邮箱',
						text: 'zhang***@163.com',
						btnText: '修改',
						path: '/EditMessage'
					},
					{
						title: '手机号',
						text: '188******88',
						btnText: '修改',
						path: '/EditPhone'
					},
					{
						title: '登录密码',
						text: '登录书营平台需要输入的密码',
						btnText: '重置',
						path: '/FindPassword'
					},
					{
						title: '支付密码',
						text: '付款时所需要输入的密码',
						btnText: '添加或设置',
						path: '/SetPayPassword'
					},
					{
						title: '注册时间',
						text: '2020-11-11  20:18:54',
						btnText: '注销账户',
						path: '/CloseAnAccount'
					},
					{
						title: '银行卡',
						text: '绑定银行卡',
						btnText: '添加',
						path: '/AddBankCard'
					},
				],
				addressNum: 0,
			}
		},
		mounted() {
			this.initData();
		},
		methods: {
			initData() {
				const data = {
					loginid: localStorage.getItem('shuying-token')
				}
				this.axios.post(Api.accountPage,data).then(res=>{
					if(res.data.code == 200) {
						this.setList[0].text = res.data.data.userInfo.trueName;
						this.setList[1].text = res.data.data.userInfo.userEmail;
						this.setList[2].text = res.data.data.userInfo.userPhone;
						this.setList[5].text = res.data.data.userInfo.createTime;
						this.setList[2].text = this.setList[2].text.substr(0,3) + '****' + this.setList[2].text.substr(7);
						this.addressNum = res.data.data.userAddress;
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			ck_goPage(path) {
				this.$router.push({path})
			}
		}
	}
</script>

<style scoped>
	.PersonalZhsz {
		padding-top: 2.5625rem;
		background: #FFFFFF;
		width: 56.9375rem;
		padding-left: 1.5rem;
		min-height: 73.4375rem;
	}

	.line {
		width: 6px;
		height: 20px;
		background: #EE4454;
	}

	.title {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-left: 1.125rem;
	}

	.contentItem {
		height: 5.5625rem;
		width: 55.4375rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #E6E6E6;
	}

	.text1 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.text2 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}

	.text3 {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #56C8B0;
	}
</style>
