<template>
	<div class='JfSecond'>
		<!-- 顶部 -->
		<div class='header'>
			<div class='title'>
				我的可用积分 ：<span>{{userScore}}</span>
			</div>
			<div class='menuBox'>
				<div 
					:class='index==0?"item1 DIS-CENTER":"item DIS-CENTER"'
					v-for='(item,index) in headerMenuList'
					:key='index'
					:style="item.active?'color: #EE4454':''"
					@click='ck_headerMenu(index)'
				>
					{{item.text}}
				</div>
			</div>
		</div>
	
		<!-- 列表头部 -->
		<div class='tableHeader'>
			<div class='title1' style='margin-left: 7.0625rem;'>来源/用途</div>
			<div class='title1' style='margin-left: 16.25rem;'>积分变化</div>
			<div class='title1' style='margin-left: 6.3125rem;'>日期</div>
			<div class='title1' style='margin-left: 11.5rem;'>备注</div>
		</div>
		
		<!-- 列表内容 -->
		<div 
			v-show='total > 0'
			class='tableContent'
			v-for='(item,index) in tableData'
			:key='index'
		>
			<div 
				class='text1' 
				style='margin-left: 7.0625rem;width: 6.25rem;'
			>
				{{item.dataSrc}}
			</div>
			<div
				class='text2' 
				style='margin-left: 13.5rem;width: 4.375rem;'
			>
				{{item.scoreType==1?'+':'-'}}{{item.score}}
			</div>
			<div class='text1' style='margin-left: 4.75rem;width: 130px;'>
				{{item.createTime}}
			</div>
			<div class='text1' style='margin-left: 80px;'>
				{{item.dataRemarks}}
			</div>
		</div>
		<!-- 分页器 -->
		<div class='SY-CONTENT'>
			<div class='sorter' v-if='total > 0'>
				<el-pagination
					background
					layout="pager"
					:total="total"
					@current-change='ck_setPage'
				>
				</el-pagination>
			</div>
		</div>
		
		<div v-if='total < 1'>
			<NoneItem title='您还没有积分哦~' text='请尝试在平台购物或充值吧'/>
		</div>
	</div>
</template>

<script>
	import Api from '../../../request/api.js';
	
	import NoneItem from '../../NoneItem.vue';
	
	export default {
		components: { NoneItem },
		data() {
			return {
				headerMenuList: [
					{
						text: '积分明细',
						active: true
					},
					{
						text: '积分收入',
						active: false
					},{
						text: '积分支出',
						active: false
					},{
						text: '冻结积分',
						active: false
					}
				],
				tableData: [], // 列表数据
				total: 0, // 列表的总条数
				scoreType: -1, // 列表的类型
				userScore: 0, // 用户可用积分
			}
		},
		created() {
			this.initData(1)
		},
		methods: {
			initData(num) {
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					page: num,
					type: this.scoreType,
				}
				this.axios.post(Api.userScore,data).then(res=>{
					if(res.data.code==200) {
						this.total = res.data.data.count;
						this.tableData = res.data.data.data;
						this.userScore = res.data.data.userScore;
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			ck_headerMenu(index) {
				for(let i in this.headerMenuList) {
					this.headerMenuList[i].active = false;
				}
				this.headerMenuList[index].active = true;
				switch(index) {
					case 0:
						this.scoreType = -1;
						break;
					case 1:
						this.scoreType = 1;
						break;
					case 2:
						this.scoreType = 0;
						break;
				}
				this.initData(1);
			},
			ck_setPage(value) {
				this.initData(value);
			}
		}
	}
</script>

<style scoped>
	.JfSecond {
		
	}
	
	.header {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
	
	.title {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.title span {
		color: #EE4454;
		font-size: 1.5rem;
	}
	
	.menuBox {
		width: 444px;
		height: 40px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #E8E8E8;
		margin-right: 1.25rem;
		display: flex;
	}
	
	.menuBox .item {
		flex: 1;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		border-left: 1px solid #E8E8E8;
		cursor: pointer;
	}
	
	.menuBox .item1 {
		flex: 1;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
	}
	
	.tableHeader {
		width: 891px;
		height: 44px;
		background: #FAFAFA;
		border: 1px solid #E6E6E6;
		margin-top: 2.0625rem;
		display: flex;
		align-items: center;
	}
	
	.title1 {
		font-size: 12px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.tableContent {
		width: 55.4375rem;
		height: 7.5625rem;
		border-bottom: 1px solid #E6E6E6;
		display: flex;
		align-items: center;
	}
	
	.text1 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.text2 {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #EE4454;
	}
	
	.sorter {
		width: 58.4375rem;
		display: flex;
		justify-content: flex-end;
		margin-top: 1.875rem;
		padding-right: 1.25rem;
	}
	
	.sorter>>>.el-pager li {
		background-color: transparent !important;
		color: #333333 !important;
		border: 1px solid #E6E6E6;
		width: 36px;
		height: 36px;
		line-height: 2.25rem;
	}
	
	.sorter>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color:  #333333 !important;
		color: white!important;
	}
</style>
