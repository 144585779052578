<template>
	<div class='PersonalJf'>
		<!-- 顶部菜单 -->
		<div class='headerMenu'>
			<div 
				class='menuItem'
				v-for='(item,index) in headerMenuList'
				:key='index'
				@click='ck_menu(index)'
			>
				<div 
					class='menuText'
					:style="item.active?'color: #EE4454':''"
				>
					{{item.text}}
				</div>
				<div 
					class='line'
					:style="item.active?'background: #EE4454':''"
				></div>
			</div>
		</div>
		
		<!-- 内容区 -->
		<div v-if='pageIndex==0' style='margin-top: 2.9375rem;'>
			<JfFirst />
		</div>
		
		<div v-if='pageIndex==1' style='margin-top: 2.9375rem;'> 
			<JfSecond />
		</div>
		
		<div v-if='pageIndex==2' style='margin-top: 2.5rem;'>
			<JfThird />
		</div>
	</div>
</template>

<script>
	import JfFirst from './PersonalJf/JfFirst.vue';
	import JfSecond from './PersonalJf/JfSecond.vue';
	import JfThird from './PersonalJf/JfThird.vue';
	
	export default {
		components: {
			JfFirst,
			JfSecond,
			JfThird
		},
		data() {
			return {
				headerMenuList: [
					{
						text: '资金流水',
						active: true,
					},
					{
						text: '积分管理',
						active: false,
					},
					{
						text: '钱包充值',
						active: false,
					}
				],
				pageIndex: 0,
				
			}
		},
		methods: {
			ck_menu(index) {
				for(let i in this.headerMenuList) {
					this.headerMenuList[i].active = false;
				}
				this.headerMenuList[index].active = true
				this.pageIndex = index;
			}
		}
	}
</script>

<style scoped>
	.PersonalJf {
		width: 57rem;
		padding-left: 1.4375rem;
		padding-top: 1.3125rem;
		min-height: 73.4375rem;
		background-color: #FFFFFF;
	}
	
	.headerMenu {
		display: flex;
		align-items: center;
	}
	
	.menuItem {
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		margin-right: 3.125rem;
	}
	
	.menuText {
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.line {
		width: 28px;
		height: 4px;
		background: #FFFFFF;
		margin-top: 0.375rem;
	}
</style>