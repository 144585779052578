<template>
	<div class='HomeFooter'>
		<div style='display: flex;'>
			<div 
				:class="index==0 ? 'text' : 'text1'"
				style='cursor: pointer;'
				v-for='(item,index) in textList'
				:key='index'
				@click='ck_goPage(item.path,item.id)'
			>
				{{item.text}}
			</div>
		</div>
		<div 
			class='text'
			style='margin-top: 0.625rem;display: flex;align-items: center;'
		>
			Copyright© 艺阁书营 2020-2021，All Rights Reserved <a href="https://beian.miit.gov.cn/">浙ICP备19012181号-2</a>  <a href='/LicenceTwo'>出版物经营许可证</a> 
			<a href='/LicenceOne'>网络文化经营许可证</a>
			<img style='margin: 0 5px 0 10px;' src="../../assets/images/jinghui.png" alt="">
			浙公网安备 33010802011359号
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				textList: [
					{
						text: '关于我们',
						path: '/IntelligentItem',
						id: '26',
					},
					{
						text: '联系我们',
						path: '/IntelligentItem',
						id: '27'
					},
					{
						text: '合作伙伴',
						path: '',
						id: ''
					},
					{
						text: '有情链接',
						path: '',
						id: ''
					},
					{
						text: '帮助中心',
						path: '',
						id: ''
					},
					{
						text: '版权隐私',
						path: '',
						id: ''
					},
					{
						text: '广告业务',
						path: '',
						id: ''
					},
					{
						text: '图书标签',
						path: '',
						id: ''
					},
					// {
					// 	text: '移动版',
					// 	path: '',
					// }
				]
			}
		},
		methods: {
			ck_goPage(path,id) {
				this.$router.push({path,query:{id}})
			}
		}
	}
</script>

<style scoped>
	.HomeFooter {
		width: 75rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0.9375rem 0;
	}
	
	.HomeFooter .text {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #CCCCCC;
	}
	
	.HomeFooter .text1 {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #CCCCCC;
		margin-left: 2.0625rem;
	}
	
	a {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #CCCCCC;
		margin: 0 5px;
		text-decoration:none
	}
</style>
