<template>
	<div class='History'>
		<div>
			<Header bgIndex='3' @dialogBoxIndex="dialogBoxIndex" />
		</div>
		
		<!-- 标题 -->
		<div class='SY-CONTENT'>
			<div class='History-title-box'>
				<div 
					class='DIS-CENTER'
					style='margin-top: 1.5625rem;'
				>
					<img 
						style='width: 1.875rem;height: 1.875rem;'
						src="../assets/images/His-title-img.png" 
						alt=""
					>
					<div style='margin-left: 0.875rem;'>左图右史</div>
				</div>
				
				<!-- 菜单 -->
				<!-- <div class='menu-box'>
					<div 
						:class="item.active?'menu2' : 'menu'"
						v-for='(item,index) in menuList'
						:key='index'
						@click='ck_menu(index)'
					>
						<div>
							{{item.text}}
						</div>
						<div 
							class='line'
							v-if='item.active'
						></div>
					</div>
				</div> -->
			</div>
		</div>
		
		<!-- 内容 -->
		<div class='SY-CONTENT'>
			<div style='width: 75rem;'>
				<div class='NewBook-List'>
					<div 
						v-for='(item,index) in historyList'
						:key='index'
					>
						<div :class="index==0 || index%5==0 ? 'list-item list-item1':'list-item list-item2'">
							<img
								style='width: 13.875rem;height: 12.5rem;'
								:src="item.goodsImg" 
								alt=""
							>
							<div class='text'>
								<span class='ziying' v-if='item.isSelfSale == 1'>自营</span>
								<span class='ershou' v-if='item.isSecondhandBook == 1'>二手</span>
								{{item.goodsName}}
							</div>
							<div class='info-box'>
								<div class='left'>
									<span>￥</span>
									<span style='font-size: 1.125rem;'>{{item.shopPrice}}</span>
									<span style='color: #999999;margin-left: 0.1875rem;'>起</span>
								</div>
								<div class='right DIS-CENTER' @click='ck_goPage("/GoodsDetails",item.goodsId)'>
									立即购买
								</div>
							</div>
						</div>
						<div :class="index==0 || index%5==0 ? 'line2':'line3'"></div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 分页器 -->
		<div class='SY-CONTENT'>
			<div class='sorter'>
				<el-pagination
					background
					layout="pager"
					:total="total"
					:page-size='20'
					@current-change='editPage'
				>
				</el-pagination>
			</div>
		</div>
		
		<!-- 页尾文字 -->
		<div 
			class='SY-CONTENT'
			style='margin-top: 8.5rem;background-color: #FAFAFA;'
		>
			<HomeText />
		</div>
		
		<!-- 页脚 -->
		<div 
			class='SY-CONTENT'
			style='background-color: #333333;'
		>
			<HomeFooter />
		</div>
		

		<div v-if="isShowDialogBox" class="isShowDialogShade" @click="isShowDialogBox=false"></div>
		<div v-if="isShowDialogBox" class="SY-DIALOGWRAP">
			<DialogBoxIndex @closeDialogBox="closeDialogBox" :getShopId="false"></dialogBoxIndex>
		</div>
	</div>
</template>

<script>
	import Api from '../request/api.js';
	
	import Header from '../components/Header.vue';
	import HomeText from '../components/Home/HomeText.vue';
	import HomeFooter from '../components/Home/HomeFooter.vue';
	
	export default {
		components: { Header, HomeFooter, HomeText },
		data() {
			return {
				menuList: [
					{
						text: '全部',
						active: true
					},
					{
						text: '国学古籍',
						active: false
					},
					{
						text: '文学小说',
						active: false
					},
					{
						text: '历史地理',
						active: false
					},
					{
						text: '政治军事',
						active: false
					},
					{
						text: '哲学心理',
						active: false
					},
					{
						text: '经济社科',
						active: false
					},
					{
						text: '生活体育',
						active: false
					},
					{
						text: 'IT技术',
						active: false
					},
					{
						text: '自然科学',
						active: false
					},
					{
						text: '教材辅导',
						active: false
					},
					{
						text: '其他',
						active: false
					},
				],
				total: 0,
				historyList: [],
				isShowDialogBox:false,
			}
		},
		created() {
			this.initData(1);
		},
		methods: {
			dialogBoxIndex(data){
				this.isShowDialogBox=data
			},
			closeDialogBox(data){
				this.isShowDialogBox=data
			},
			initData(page) {
				const data  = {
					page:page,
				}
				this.axios.post(Api.zuoTuYouShi,data).then(res=>{
					if(res.data.code == 200) {
						this.total = res.data.data.count;
						this.historyList = res.data.data.oldBooks;
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			ck_menu(index) {
				for(let i in this.menuList) {
					this.menuList[i].active = false
				}
				this.menuList[index].active = true
			},
			ck_goPage(path,id) {
				this.$router.push({path,query:{id}});
			},
			editPage(value) {
				this.initData(value)
			}
		}
	}
</script>

<style scoped>
	.History {
		background-image: url(../assets/images/bg99.jpeg);
	}
	
	.History-title-box {
		width: 75rem;
		background: #6F2F1B;
		font-size: 28px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FFFFFF;
		padding-bottom: 1.25rem;
	}
	
	.History-title-box .menu-box {
		display: flex;
		margin: 1.0625rem 0;
	}
	
	.History-title-box .menu {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		margin: 0 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		width: 5rem;
	}
	
	.History-title-box .menu2 {
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FFFFFF;
		margin: 0 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		width: 5rem;
	}
	
	.History-title-box .line {
		width: 2.0625rem;
		border: 0.125rem solid #CD7624;
		margin-top: 0.1875rem;
	}
	
	.NewBook-List {
		width: 1200px;
		display: flex;
		flex-wrap: wrap;
	}
	
	.list-item {
		cursor: pointer;
	}
	
	.list-item:hover .right{
		animation: pulse; 
		animation-duration: 1s; 
	}
	
	.NewBook-List .list-item1 {
		width: 222px;
		height: 312px;
		background: #FFFFFF;
		border: 1px solid #E6E6E6;
		margin-top: 1.25rem;
	}
	
	.NewBook-List .list-item2 {
		width: 222px;
		height: 312px;
		background: #FFFFFF;
		border: 1px solid #E6E6E6;
		margin-top: 1.25rem;
		margin-left: 1.25rem;
	}
	
	.list-item .text {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin: 0 0.9375rem;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		margin-top: 20px;
	}
	
	.list-item .info-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0.8125rem 1.25rem 0;
	}
	
	.info-box .left {
		font-size: 12px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.info-box .right {
		width: 4.375rem;
		height: 1.875rem;
		background: #EE4454;
		border-radius: 2px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
	}
	
	.NewBook-List .line2 {
		margin-top: 1.25rem;
		width: 14rem;
		height: 1.25rem;
		background: #6F2F1B;
	}
	
	.NewBook-List .line3 {
		margin-top: 1.25rem;
		width: 15.25rem;
		height: 1.25rem;
		background: #6F2F1B;
	}
	
	.sorter {
		width: 75rem;
		display: flex;
		justify-content: flex-end;
		margin-top: 1.875rem;
	}
	
	.sorter>>>.el-pager li {
		background-color: transparent !important;
		color: #6F2F1B !important;
		border: 1px solid #E6E6E6;
		width: 36px;
		height: 36px;
		line-height: 2.25rem;
	}
	
	.sorter>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color:  #6F2F1B !important;
		color: white!important;
	}
</style>
