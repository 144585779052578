<template>
	<div class='AccurateSearchTop'>
		<div>
			<div class='title'>
				生老病死的生意：文化与中国人寿保险市场的形成
			</div>
			<div class='content'>
				<img style='width: 9.8125rem;height: 14.375rem;' src="../../assets/images/homethirddemo.png" alt="">
				<div style='margin-left: 4.3125rem;'>
					<div style='display: flex;'>
						<div>
							<div class='text1' style='margin-bottom: 0.875rem;'>
								<span>作者:</span> [中国香港] 陈纯菁 著；魏海涛 译
							</div>
							<div class='text1' style='margin-bottom: 0.875rem;'>
								<span>出版社:</span> 华东师范大学出版社
							</div>
							<div class='text1' style='margin-bottom: 0.875rem;'>
								<span>出版时间:</span> 2020-09
							</div>
							<div class='text1' style='margin-bottom: 0.875rem;'>
								<span>版次:</span> 1
							</div>
							<div class='text1' style='margin-bottom: 0.875rem;'>
								<span>ISBN:</span> 9787576008463
							</div>
							<div class='text1' style='margin-bottom: 0.875rem;'>
								<span>定价:</span> 79.80
							</div>
						</div>
						<div style='margin-left: 3.6875rem;'>
							<div class='text1' style='margin-bottom: 0.875rem;'>
								<span>装帧:</span> 平装
							</div>
							<div class='text1' style='margin-bottom: 0.875rem;'>
								<span>开本:</span> 32开
							</div>
							<div class='text1' style='margin-bottom: 0.875rem;'>
								<span>纸张:</span> 胶版纸
							</div>
							<div class='text1' style='margin-bottom: 0.875rem;'>
								<span>页数:</span> 408页
							</div>
						</div>
					</div>
					<div class='text1' style='width: 32.4375rem;'>
						作者通过在上海等地进行的深入细致的民族志访谈，
						记录了中国人寿保险市场在避谈死亡的文化禁忌下的发展历程及其背后的微观政治。
						书中提出的“文化多重互动模型”，对市场起源进行了具
					</div>
				</div>
			</div>
		</div>
		<div>
			<div class='classificationTitle'>
				相关分类
			</div>
			<div class='classificationContent'>
				<div
					class='classificationContent-item text1'
					v-for='(item,index) in textList'
					:key='index'
				>
					{{item}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				textList: [
					'社会学',
					'文化',
					'新闻出版',
					'图书馆学',
					'档案学',
					'文化人类学/人口学'
				]
			}
		}
	}
</script>

<style scoped>
	.AccurateSearchTop {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	
	.title {
		font-size: 20px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	
	.content {
		margin-top: 1.6875rem;
		display: flex;
	}
	
	.text1 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.text1 span {
		color: #999999;
	}
	
	.classificationTitle {
		border-bottom: 1px solid #EE4454;
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		padding-bottom: 0.5rem;
		width: 16.25rem;
	}
	
	.classificationContent {
		width: 240px;
		display: flex;
		flex-wrap: wrap;
	}
	
	.classificationContent-item {
		width: 120px;
		margin-top: 0.8125rem;
		cursor: pointer;
	}
</style>
