<template>
	<div class="IR-Content">
		<div class="title">{{ detail.title }}</div>
		<div class="time">发布于 {{ DateTrans(detail.create_time) }}</div>
		<!--判断 图片 图片组 视频 音频      轮播组件 -->
		<div class="banner">
			<img class="arrow" src="../../assets/images/IR-left.png" alt="" />
			<img class="product" :src="detail.cover_image" alt="" />
			<img class="arrow" src="../../assets/images/IR-right.png" alt="" />
		</div>

		<div class="bannerList-box">
			<div class="bannerList">
				<!-- 图片组 循环-->
				<img v-for="(item, index) in detail.images" :key="index" :class="index == 0 ? 'img1' : 'img2'" :src="item" alt="" />
			</div>
		</div>
		
		<div class="content-text">
			<div class="text" style="white-space: pre-wrap">
				{{ detail.maincontent }}
			</div>
			<div class="text share" style="font-size: 1rem; margin-top: 0.75rem">
				<div class="DIS-CENTER">
					<img style="width: 1.25rem; height: 1.25rem" :src="is_zan == 0 ? btn_dzImg : btn_dzImgCheck" alt=""
						@click="ck_Zan" />
					<div style="margin: 0 1.375rem 0 0.4375rem">{{ detail.zan_num }}</div>
					
					<!-- 点击发起评论 -->
					<div style="display: flex;" @click="ck_commentAuthor">
						<img style="width: 1.25rem; height: 1.25rem" src="../../assets/images/IR-PL.png" alt="" />
						<div style="margin: 0 1.375rem 0 0.4375rem">
							{{ detail.comment_num }}
						</div>
					</div>
					
					<img style="width: 1.25rem; height: 1.25rem"
						:src="is_collect == 0 ? contnetRightBox : isContnetRightBox" alt="" @click="ck_Collect" />
					<div style="margin: 0 1.375rem 0 0.4375rem">
						{{ detail.collect_num }}
					</div>
				</div>
				<div class="DIS-CENTER">
					<div style="margin-right: 1rem">分享到</div>

					<el-popover placement="bottom" width="150" trigger="hover">
						<vue-qr :text="downloadData.url" :logoSrc="downloadData.icon" :margin="0" colorLight="#fff"
							:logoScale="0.3" :size="150"></vue-qr>
						<img slot="reference" style="width: 2.5rem; height: 2.5rem" src="../../assets/images/IR-WX.png"
							alt="" />
					</el-popover>

					<img style="width: 2.5rem; height: 2.5rem; margin: 0 0.8125rem" src="../../assets/images/IR-WB.png"
						alt="" @click="ck_share(1)" />
					<img style="width: 2.5rem; height: 2.5rem" src="../../assets/images/IR-QQ.png" alt=""
						@click="ck_share(2)" />
				</div>
			</div>
		</div>
		
		<!-- 推荐书籍 -->
		<div class="itemBox" v-if="goods_info && goods_info.goodsImg">
			<div style="display: flex; align-items: center">
				<img style="width: 50px; height: 71px; margin-left: 1.875rem" :src="goods_info.goodsImg" alt="" />
				<div style="margin-left: 1.6875rem">
					<div class="itemText1">{{ goods_info.goodsName }}</div>
					<div class="itemText2">
						￥<span style="font-size: 1.25rem">{{ goods_info.shopPrice }}</span>
						<span class="itemSpan">起</span>
					</div>
				</div>
			</div>
			<div class="itemBtn DIS-CENTER" @click="ck_goPage('/GoodsDetails', goods_info.goodsId)">
				立刻购买
			</div>
		</div>
		
		<!-- 评论 -->
		<!-- h5评论 只有评论父级的功能  点击评论发送信息后提示是否成功 -->
		<div class="comment">
			<div class="title2">全部评论</div>
			<!-- 循环体 -->
			<div v-for="(item, index) in comment" :key="index">
				<!-- 点击评论父级 -->
				<div @click="ck_sendCommit(item.id,index)" style="cursor: pointer;">
					<div style="display: flex;align-items: center;justify-content: space-between;">
						<div class="DIS-CENTER">
							<img style="width: 1.875rem; height: 1.875rem; border-radius: 32px"
								:src="imgUrl + item.userPhoto" alt="" />
							<div style="display: flex;flex-direction: column;margin-left: 0.75rem;">
								<div class="username">{{ item.userName }}</div>
								<div class="time2">{{ timestampFormat(item.create_time) }}</div>
							</div>
						</div>
						<div class="DIS-CENTER">
							<!-- 评论点赞  h5 没有给评论点赞的功能-->
							<!-- <img style='width: 1rem;height: 1rem;margin-right: 0.1875rem;'
								src="../../assets/images/IR-DZ.png" alt="">
							<div class='time2' style='font-size: 0.875rem;'>
								50
							</div> -->
						</div>
					</div>
					<div class="remark">{{ item.content }}</div>
				</div>
				
				<div class="IntelligentRecommend_commitBox" v-if="item.isCommit">
					<el-input v-model="commitVal" :placeholder="'回复：'+commitValPla"
						style="width: 490px;height: 47px;"></el-input>
					<div class="IntelligentRecommend_commitBox_btn">
						<div class="IntelligentRecommend_commitBox_btnStyle btnStyle1"
							@click="item.isCommit=false">取消
						</div>
						<div class="IntelligentRecommend_commitBox_btnStyle btnStyle2"
							@click="ck_send(item.article_id,item.id)">发送</div>
					</div>
				</div>
				
				<!--点击 作者才能回复 -->
				<div v-if="item.child.length">
					<div class="commit_sec">
						<div v-for="(items, indexs) in item.child" :key="indexs" class="commit_secBox">
							<div style="margin-bottom: 12px;">{{ items.userName }}：</div>
							<div>{{ items.content }}</div>
						</div>
					</div>
				</div>
				<!-- 线 -->
				<div style="border-bottom: 1px solid #e6e6e6;margin-top: 16px;margin-bottom: 17px;"></div>
			</div>

			<div class="IntelligentRecommend_commitBox" v-if="isCommentAuthor">
				<el-input v-model="commitVal" :placeholder="'回复：'+author_info.userName"
					style="width: 490px;height: 47px;"></el-input>
				<div class="IntelligentRecommend_commitBox_btn">
					<div class="IntelligentRecommend_commitBox_btnStyle btnStyle1" @click="isCommentAuthor=false">取消</div>
					<!-- pid 上级评论id -->
					<div class="IntelligentRecommend_commitBox_btnStyle btnStyle2" @click="ck_send(detail.author_id)">发送</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {dateTrans} from "../../utils/util.js";
	import Api from "../../request/api.js";
	import btn_dzImgCheck from "../../assets/images/IR_XH.png";
	import btn_dzImg from "../../assets/images/like.png";
	import isContnetRightBox from "../../assets/images/contnetRightBox-xx.png";
	import contnetRightBox from "../../assets/images/isContnetRightBox-xx.png";
	import vueQr from 'vue-qr';
	export default {
		components: {
			vueQr,
		},
		props: [
			"goods_info",
			"author_info",
			"detail",
			"is_zan",
			"is_collect",
			"comment",
		],
		data() {
			return {
				id:'',   // 评论对应字段 article_id
				pid:'',
				DateTrans: dateTrans,
				btn_dzImg: btn_dzImg,
				btn_dzImgCheck: btn_dzImgCheck,
				contnetRightBox: contnetRightBox,
				isContnetRightBox: isContnetRightBox,
				imgUrl: Api.URL,
				httpUrl: Api.httpUrl,
				commitValPla:'张三',  //被回复对象名称
				commitVal:'',   //回复的内容

				downloadData: {
					url: '',
					// icon: require('../../assets/images/IR_XH.png'),   图标
				},
				isCommentAuthor:false,
				// comment:[
				// 	{
				// 		userPhoto:'userPhoto',
				// 		userName:'userName',
				// 		create_time:'create_time',
				// 		content:'content',
				// 		isCommit:false,
				// 		child:[
				// 			{
				// 				userName:'userName',
				// 				content:'content',
				// 			}
				// 		]
				// 	},
				// 	{
				// 		userPhoto:'userPhoto',
				// 		userName:'userName',
				// 		create_time:'create_time',
				// 		content:'content',
				// 		isCommit:false,
				// 		child:[
				// 			{
				// 				userName:'userName',
				// 				content:'content',
				// 			}
				// 		]
				// 	}
				// ]
			};
		},
		// updated(){
		// 	console.log(this.$props.author_info,'author_info')
		// 	console.log(this.$props.detail,'detail')
		// 	console.log(this.$props.goods_info,'goods_info')
		// },
		created() {
			this.id = this.$route.query.id;
			this.downloadData.url='http://m.showwins.com/#/pages/showDetails/index?id='+this.id
		},
		methods: {
			ck_share(type) {
				// url 文章链接地址 
				// desc == title 
				// summary 默认文本内容
				// title 标题
				// site 网易
				// pics 图片
				// desc 转发默认携带的文本内容 
				
				//新浪微博接口的传参
				if (type == 1) {
					window.open('http://service.weibo.com/share/share.php?url=' + this.httpUrl +'/#/pages/showDetails/index?id=367' + '?sharesource=weibo&title=标题&pic=图片地址&appkey=微博平台申请的key');
				}
				
				//qq好友接口的传参
				if (type == 2) {
					window.open('http://connect.qq.com/widget/shareqq/index.html?url=' + this.httpUrl +'/#/pages/showDetails/index?id=367' +'?sharesource=qzone&title=标题&pics=图片地址&summary= 描述')
				}
				
				// //微信分享
				// var url = window.location.href,
				// 	encodePath = encodeURIComponent(url),
				// 	targetUrl = 'http://qr.liantu.com/api.php?text=' + encodePath;
				// window.open(url, 'weixin', 'height=320, width=320')
				
				// //qq空间分享接口
				// if (type == 'qzone') {
				// 	window.open('https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=' + document.location.href + '?sharesource=qzone&title=标题&pics=图片地址&summary= 描述')
				// }
			},

			ck_goPage(path, id) {
				this.$router.push({
					path,
					query: {
						id,
					},
				});
			},
			// 点赞
			ck_Zan() {
				const data = {
					loginid: localStorage.getItem("shuying-token"),
					article_id: this.$route.query.id,
					type: this.$props.is_zan,
				};
				this.axios.post(Api.clickZan, data).then((res) => {
					if (res.data.code == 200) {
						this.$emit("setInitData", true);
					} else {
						this.$message.error(res.data.msg);
					}
				});
			},
			// 收藏
			ck_Collect() {
				const data = {
					loginid: localStorage.getItem("shuying-token"),
					article_id: this.$route.query.id,
					type: this.$props.is_collect,
				};
				this.axios.post(Api.clickCollect, data).then((res) => {
					this.$emit("setInitData", true);
					if (res.data.code == 200) {
						console.log(11);
					}
				});
			},
			// 评论作者
			ck_commentAuthor(){
				//评论里的 isCommit都改成false
				//在评论末尾追加 弹框
				for (let i in this.comment) {
					this.comment[i].isCommit = false
				}

				this.isCommentAuthor=true
			},
			
			// 点击弹出评论弹框 
			ck_sendCommit(id,index){
				this.pid=id
				for (let i in this.comment) {
					this.comment[i].isCommit = false
				}
				this.comment[index].isCommit = true
			},
			
			// 发送评论
			ck_send(article_id,pid){
				if(this.commitVal==''){
					this.$message.error('评论内容不能为空')
					return;
				}
				const data = {
					loginid: localStorage.getItem('shuying-token'),
					article_id: article_id,
					content: this.commitVal,
					pid: pid,
				}
				this.axios.post(Api.sendComment, data).then(res => {
					if (res.data.code == 200) {
						this.$message.success(res.data.msg)
						this.$emit("setInitData", true);
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			
			zeroize(num) {
				return (String(num).length == 1 ? "0" : "") + num;
			},

			// 时间转换
			timestampFormat(timestamp) {
				var curTimestamp = parseInt(new Date().getTime() / 1000); //当前时间戳
				var timestampDiff = curTimestamp - timestamp; // 参数时间戳与当前时间戳相差秒数
				var curDate = new Date(curTimestamp * 1000); // 当前时间日期对象
				var tmDate = new Date(timestamp * 1000); // 参数时间戳转换成的日期对象
				var Y = tmDate.getFullYear(),
					m = tmDate.getMonth() + 1,
					d = tmDate.getDate();
				var H = tmDate.getHours(),
					i = tmDate.getMinutes();
				// s = tmDate.getSeconds();  // 获取秒

				if (timestampDiff < 60) {
					// 一分钟以内
					return "刚刚";
				} else if (timestampDiff < 3600) {
					// 一小时前之内
					return Math.floor(timestampDiff / 60) + "分钟前";
				} else if (
					curDate.getFullYear() == Y &&
					curDate.getMonth() + 1 == m &&
					curDate.getDate() == d
				) {
					return "今天" + this.zeroize(H) + ":" + this.zeroize(i);
				} else {
					var newDate = new Date((curTimestamp - 86400) * 1000); // 参数中的时间戳加一天转换成的日期对象
					if (
						newDate.getFullYear() == Y &&
						newDate.getMonth() + 1 == m &&
						newDate.getDate() == d
					) {
						return "昨天" + this.zeroize(H) + ":" + this.zeroize(i);
					} else if (curDate.getFullYear() == Y) {
						return (
							this.zeroize(m) +
							"月" +
							this.zeroize(d) +
							"日 " +
							this.zeroize(H) +
							":" +
							this.zeroize(i)
						);
					} else {
						return (
							Y +
							"年" +
							this.zeroize(m) +
							"月" +
							this.zeroize(d) +
							"日 " +
							this.zeroize(H) +
							":" +
							this.zeroize(i)
						);
					}
				}
			},
		},
	};
</script>

<style scoped>
	.IR-Content {
		width: 33.25rem;
		margin-right: 84px;
	}

	.IR-Content .title {
		font-size: 1.75rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.IR-Content .time {
		font-size: 0.875rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-top: 0.5rem;
	}

	.IR-Content .banner {
		width: 33.25rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 1.875rem;
	}

	.banner .arrow {
		width: 3.125rem;
		height: 3.125rem;
		cursor: pointer;
	}

	.banner .product {
		width: 22.5rem;
		height: 30rem;
		border: 1px solid #e6e6e6;
	}

	.IR-Content .bannerList-box {
		width: 33.25rem;
		display: flex;
		justify-content: center;
		margin-top: 1.3125rem;
	}

	.bannerList-box .bannerList {
		width: 22.5rem;
	}

	.bannerList .img1 {
		width: 3.75rem;
		height: 3.75rem;
		background-color: pink;
		cursor: pointer;
	}

	.bannerList .img2 {
		width: 3.75rem;
		height: 3.75rem;
		background-color: pink;
		margin-left: 0.9375rem;
		cursor: pointer;
	}

	.IR-Content .content-text {
		margin-top: 1.1875rem;
		border-bottom: 2px solid #ee4454;
		padding-bottom: 0.8125rem;
	}

	.content-text .text {
		font-size: 0.875rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.content-text .share {
		display: flex;
		width: 33.25rem;
		justify-content: space-between;
	}

	.content-text .share img {
		cursor: pointer;
	}

	.IR-Content .comment {
		margin-top: 1.875rem;
	}

	.comment .title2 {
		font-size: 1rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-bottom: 2.0625rem;
	}

	.comment .username {
		font-size: 0.875rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.comment .time2 {
		font-size: 0.75rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}

	.comment .remark {
		font-size: 0.875rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-top: 0.625rem;
		margin-left: 2.5rem;
	}

	.itemBox {
		border-radius: 4px;
		width: 530px;
		height: 100px;
		background: #ffffff;
		border-radius: 4px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		margin-top: 1.1875rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.itemText1 {
		width: 17.4375rem;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-bottom: 4px;
	}

	.itemText2 {
		font-size: 13px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.itemSpan {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}

	.itemBtn {
		width: 80px;
		height: 30px;
		background: #ee4454;
		border-radius: 2px;
		cursor: pointer;
		font-size: 13px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		margin-right: 1.6875rem;
	}

	.commit_sec {
		margin-top: 17px;
		padding: 15px 17px 8px;
		background-color: #f5f5f5;
		margin-left: 40px;
	}

	.commit_secBox {
		display: flex;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.IntelligentRecommend_commitBox {
		margin-top: 18px;
		margin-left: 40px;
	}
	
	.IntelligentRecommend_replyBox {
		margin-top: 18px;
	}
	
	.IntelligentRecommend_commitBox .el-input__inner {
		background-color: #F5F5F5;
	}
	
	.IntelligentRecommend_commitBox_btn {
		display: flex;
		margin-top: 16px;
	}
	
	.IntelligentRecommend_commitBox_btnStyle {
		padding: 6px 27px;
		font-size: 13px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		cursor: pointer;
	}
</style>
