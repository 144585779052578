<template>
	<div class='DIS-CENTER' style='flex-direction: column;'>
		<img style='width: 26.25rem;height: 16.25rem;' src="../assets/images/none.png" alt="">
		<div class='text1'>
			{{title}}
		</div>
		<div class='text2'>
			{{text}}
		</div>
	</div>
</template>

<script>
	export default {
		props: ['title','text']
	}
</script>

<style scoped>
	.text1 {
		font-size: 24px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-top: 2.5rem;
	}
	
	.text2 {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-top: 0.9375rem;
	}
</style>
